import React from "react";
import CategoriesAside from "../Aside/CategoriesAside";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import classes from "./CategoriesWrapper.module.css";
interface Props {
  children: JSX.Element | JSX.Element[];
}
const CategoriesWrapper: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Header />
      <main className={classes.main}>
        <aside className={classes.aside}>
          <CategoriesAside />
        </aside>
        <article className={classes.article}>{children}</article>
      </main>
      <Footer />
    </>
  );
};

export default CategoriesWrapper;
