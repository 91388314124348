import React, { useEffect, useState } from "react";
import axios from "axios";
import MyVendorOrderDetails from "./MyVendorOrderDetails";
import './MyVendorOrderDetails.css'
import { Domain2 } from "../../Domain/Domain";
import { getClientToken } from "../../components/cookie/getClientToken";
import "./myorder.css"
import { Link } from "react-router-dom";
import Account from "../../components/UI/Account/Account";
import classes from "../../styles/Account.module.css";

const MyVendorOrderList = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  
  const token = getClientToken()

  useEffect(() => {
    fetchOrders(currentPage);
  }, [currentPage]);

  const fetchOrders = (page) => {
    axios
      .get(`${Domain2}/users/orders-to-vendors?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
      .then((response) => {
        if (response.data) {
          setOrders(response.data);
        }
      })
      .catch((error) => {});
  };

  const openModal = (order) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedOrder(null);
    setIsModalOpen(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <Account>
      <h1>My Orders to Vendors</h1><br /><br />
      {orders.length > 0 ? (
        <div>  
          <div>
          {orders.map((order, index) => (
            <div key={order.id} className='v-order-item'>
              <h2>{index + 1}. {order.order_id}</h2>
              <p>{order.fullname}</p>
              <p>{new Date(order.createdAt).toLocaleString()}</p>
              <p>{order.order_status ? <span style={{ color: 'green'}}>Confirmed</span> : <span style={{ color: 'red'}}>Not Confirmed</span>}</p>
              <p>
                <button className="btn btn-primary view-button" onClick={() => openModal(order)}>
                  View More Details...
                </button>
              </p>
            </div>
          ))}
          </div>

          <div className="pagination">
            <button className="btn btn-primary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className="btn btn-primary"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
          <MyVendorOrderDetails isOpen={isModalOpen} onClose={closeModal} order={selectedOrder} />
        </div>
      ) : (
        <div>
          <p>You have placed no orders at the moment</p>
          <div className="pagination">
            <button className="btn btn-primary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className="btn btn-primary"
              onClick={() => handlePageChange(currentPage + 1)}
            >
            Next
            </button>
          </div>
        </div>
      )}
    </Account>
  );
};

export default MyVendorOrderList;
