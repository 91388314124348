import React from "react";
import { orderData } from "../../data/OrderData";
import classes from './AdminMessageCenterComponent.module.css'
const AdminMessageCenterComponent = (): JSX.Element => {
  return (
    <div className={classes.order_table}>
      <table className={classes.table_section}>
        <tr className={classes.header_section}>
          <td>#</td>
          <td>From</td>
          <td>Reason</td>
          <td>Date/Time</td>
        </tr>
        {orderData.map((cur: any, index: number) => (
          <tr key={cur.id} className={classes.lists_}>
            <td>{index + 1}</td>
            <td>{cur.from}</td>
            <td>{cur.reason}</td>
            <td>{cur.dateAndTime}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default AdminMessageCenterComponent;
