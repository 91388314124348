import React, { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import {
  BsCheck,
  BsChevronDown,
  BsChevronRight,
  BsTrash,
} from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { Domain } from "../../Domain/Domain";
import { toggleLoaderAction } from "../../store/slices/LoaderSlice";
import { getCookie } from "../cookie/getCookie";

import classes from "./FAQ.module.css";
import FAQAnswerListItem from "./FAQAnswerListItem";
import { toggleAlertAction } from "../../store/slices/alertSlice";

interface Props {
  question: string;
  answer: string;
  data: any;
}
const FaqAnswerList: React.FC<Props> = ({ answer, question, data: cur }) => {
  const [toggle, setToggler] = useState<boolean>(false);
  const [isEditable, setEditable] = useState<boolean>(false);
  const ParaRef = useRef<HTMLParagraphElement>(null);
  const dispatch = useDispatch();
  const onToggleHandler = () => {
    if (isEditable === true) return;
    setToggler(!toggle);
  };

  const onEditableHandler = () => {
    setEditable(!isEditable);
  };

  useEffect(() => {
    ParaRef.current?.focus();
  }, [isEditable]);

  const onSubmitHandler = () => {
    console.log(cur);
    let value = ParaRef.current?.textContent;
    if ((value?.length as any) < 1) {
      // return alert("Question can not be empty");
      dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "Question can not be empty",
          status: "error",
        })
      );
    }
    dispatch(toggleLoaderAction(true));
    fetch(Domain() + "/api/v1/admin/faq-question/" + cur.id, {
      method: "PATCH",
      body: JSON.stringify({
        question: ParaRef.current?.textContent,
        category: cur.category, //TODO
        answer: "",
      }),
      headers: {
        Authorization: getCookie(),
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        // alert(data.message);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: data.message,
            status: "",
          })
        );

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        // alert("An error occured, please try again later");
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: "An error occured, please try again later",
            status: "error",
          })
        );
      });
  };

  // Deleteing a Category
  const onDeleteHandler = () => {
    if (window.confirm(`Are you sure you want to delete this?`)) {
      dispatch(toggleLoaderAction(true));
      fetch(Domain() + "/api/v1/admin/faq-question/" + cur.id, {
        method: "DELETE",
        headers: {
          Authorization: getCookie(),
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          // alert(data.message);
          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: data.message,
              status: "",
            })
          );

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
          // alert("An error occured, please try again later");
          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: "An error occured, please try again later",
              status: "error",
            })
          );
        });
    }
  };

  return (
    <>
      <div style={{ backgroundColor: "white",borderRadius: "10px", margin: "5px 0", padding: "10px"}}>
      <div className={classes.flex_rows} >
        <div>
          <i onClick={onToggleHandler}>
            {toggle ? <BsChevronDown /> : <BsChevronRight />}
          </i>
        </div>
        <div>
          <p
            className={classes.question}
            onClick={onToggleHandler}
            style={{
              border: isEditable ? "3px solid gray" : "",
            }}
            ref={ParaRef}
            contentEditable={isEditable}
            suppressContentEditableWarning={true}
          >
            {question}
          </p>
        </div>

        {window.location.href.includes("admin") && (
          <div className={classes.control_icon}>
            {isEditable === false && (
              <>
                <i onClick={onEditableHandler}>
                  <FiEdit />
                </i>
                <i onClick={onDeleteHandler}>
                  <BsTrash style={{ color: "red" }} />
                </i>
              </>
            )}
            {isEditable && (
              <>
                <i onClick={onEditableHandler}>
                  <AiOutlineClose style={{ color: "red" }} />
                </i>

                <i onClick={onSubmitHandler}>
                  <BsCheck style={{ color: "green" }} />
                </i>
              </>
            )}
          </div>
        )}

        {/* <div>
          <i>{window.location.href.includes("admin") && <FiEdit />}</i>
          <i>
            {window.location.href.includes("admin") && (
              <BsTrash style={{ color: "red" }} onClick={onDeleteHandler} />
            )}
          </i>
        </div> */}
      </div>
      <div
        className={classes.answer_section}
        style={{ height: toggle ? "unset" : "0px" }}
      >
        <FAQAnswerListItem answer={answer} data={cur} />
      </div>
      </div>
    </>
  );
};

export default FaqAnswerList;
