import axios from "axios";
import React, { useState, useEffect } from "react";
import { BsCart } from "react-icons/bs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Domain } from "../../Domain/Domain";
import useGetVendorCartDetailsHook from "../../Hooks/useGetVendorCartDetailsHook";
import ProductItemList from "../ProductItem/ProductItemList";
import classes from "./CartSummary.module.css";
const VendorCartSummary = () => {
  const [products, setProducts] = useState<any>([]);
  const { cartLength, cart, cartTotalPrice } = useGetVendorCartDetailsHook();
  useEffect(() => {
    axios
      .get(Domain() + "/api/v1/product/all")
      .then((response: any) => {
        // if (Array.isArray(response.data)) {
        if (response.data.status === 200)
          setProducts(
            response.data.data.splice(0, 5).sort(() => Math.random() - 0.5)
          );
        // }
      })
      .catch((error) => {});
  }, []);

  const paymentSelectror = useSelector((value: any) => value.payment);
  const totalPrice = paymentSelectror.isPaySmallSmall
    ? cartTotalPrice + (cartTotalPrice * 7.5) / 100
    : cartTotalPrice;
  return (
    <div className={classes.container}>
      <h4>Cart Summary</h4>
      <br />

      {paymentSelectror.isPaySmallSmall && (
        <>
          <div className={classes.container_section}>
            <p>Regular amount </p>
            <h3>₦ {cartTotalPrice.toLocaleString()}</h3>
          </div>

          <div className={classes.container_section}>
            <p>Interest (7.5 &)</p>
            <h3>₦ {((cartTotalPrice * 7.5) / 100).toLocaleString()}</h3>
          </div>
        </>
      )}

      <div className={classes.container_section}>
        <p>Quantity</p>
        <h3>{cartLength}</h3>
      </div>

      <div className={classes.container_section}>
        <p>Total Amount</p>
        <h3>{totalPrice?.toLocaleString()}</h3>
      </div>
      <br />
      <Link to="/vendor-cart">
        <button>
          <BsCart style={{ marginRight: "10px" }} />
          EDIT CART
        </button>
      </Link>
      <div className={classes.product_wrapper}>
        {products.map((cur: any) => (
          <ProductItemList
            pricing={cur.pricing}
            promotionalPrice={cur.promotionalPrice}
            description={cur.productDescription}
            name={cur.productName}
            image={cur.pictures}
            oldAmount={cur.oldAmount}
            colorsArray={cur.colours}
            rating={"100%"}
            key={cur.id}
            id={cur.id}
            brand={cur.brand}
          />
        ))}
      </div>
    </div>
  );
};

export default VendorCartSummary;
