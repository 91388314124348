import axios from "axios";
import React, { useEffect, useState } from "react";
import { getCookie } from "../../components/cookie/getCookie";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import BackButton from "../../components/UI/BackButton/BackButton";
import { Domain } from "../../Domain/Domain";
import classes from "../../styles/AdminFAQ.module.css";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../../store/slices/alertSlice";
const AdminCreateFaq = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState<boolean>(false);
  const [faqCatrgories, setFaqCategories] = useState<any>([]);
  const [enteredData, setEnteredData] = useState<{
    question: string;
    category: number;
    answer: string;
  }>({
    answer: "",
    category: 0,
    question: "",
  });

  useEffect(() => {
    fetch(Domain() + "/api/v1/faq/faq-categories")
      .then((res) => res.json())
      .then((data) => {
        console.log("====>", data);
        setFaqCategories(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onHandleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => [
    setEnteredData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    }),
  ];

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(enteredData);
    setLoader(true);
    axios
      .post(
        Domain() + "/api/v1/admin/faq/create",
        JSON.stringify(enteredData),
        {
          headers: {
            Authorization: getCookie(),
            "Content-Type": "application/json",
          },
        }
      )

      .then((response: any) => {
        // alert(response?.data?.message);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: response?.data?.message,
            status: "",
          })
        );
        setLoader(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        // alert(error.response.data.message);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: error.response.data.message,
            status: "error",
          })
        );
        setLoader(false);
      });
  };

  return (
    <AdminMain>
      <BackButton />
      <form onSubmit={onSubmitHandler}>
        <div>
          <br />
          <h1>Create FAQ</h1>
          <br />
          <div className={classes.seelct_container}>
            <select name="category" id="" onChange={onHandleChange} required>
              <option value="">Seelect FAQ Category </option>
              {faqCatrgories.map((cur: any) => (
                <option key={cur.id} value={cur.id}>
                  {cur.categoryName}
                </option>
              ))}
            </select>
          </div>
          <br />
          <div className={classes.question}>
            <input
              type="text"
              placeholder="Question"
              name="question"
              required
              className={classes.qustionInput}
              onChange={onHandleChange}
            />
            <div className={classes.answer_container}>
              <textarea
                name="answer"
                placeholder="Answer"
                required
                onChange={onHandleChange}
              ></textarea>
            </div>
          </div>
          <button className={classes.create_button}>Create</button>
        </div>
        {loader && (
          <>
            <br />
            <SmallSpinner />
            <br />
          </>
        )}
      </form>
    </AdminMain>
  );
};

export default AdminCreateFaq;
