import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getClientToken } from "../../components/cookie/getClientToken";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import Account from "../../components/UI/Account/Account";

import { Domain } from "../../Domain/Domain";
import useGetDefaultAddress from "../../Hooks/useGetDefaultAddress";
import { toggleAlertAction } from "../../store/slices/alertSlice";
import classes from "../../styles/Account.module.css";

const VendorCartDeliveryAddressPage = () => {
  const [addresses, setAddresses] = useState<any>([]);
  const { defaultAddress } = useGetDefaultAddress();
  const dispatch = useDispatch();

  useEffect(() => {
    fetch(Domain() + "/api/v1/user/address/all", {
      method: "POST",
      headers: {
        Authorization: getClientToken(),
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response: any) => {
        if (response.status === 200) {
          setAddresses(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
        // alert(error.response.data.message);
        console.log(error.response.data.message);
      });
  }, []);
  //
  const onAddToDefaultAddress = (
    addres: string,
    city: string,
    state: string
  ) => {
    let combinedAddress = addres + " " + city + " " + state;
    if (combinedAddress === "") {
      // alert("Address can not be empty");

      dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "Address can not be empty",
          status: "error",
        })
      );
    }
    window.localStorage.setItem("defaultAddress", combinedAddress);

    dispatch(
      toggleAlertAction({
        isAlerting: true,
        message: "Address set successfully",
        status: "",
      })
    );
  };
  return (
    <Account>
      <div>
        {addresses.length < 1 && <SmallSpinner />}
        <React.Fragment>
          {defaultAddress !== null && (
            <div className={classes.addresses_container}>
              <div className={classes.head_sextion}>
                <h3>Default address</h3>
                <Link to={"/vendor-cart-payment"}>
                  <button>Proceed to payment</button>
                </Link>
              </div>
              <p>{defaultAddress}</p>
            </div>
          )}
        </React.Fragment>
        {addresses.map((cur: any) => (
          <div key={cur.id} className={classes.addresses_container}>
            <input
              type="radio"
              name="address"
              id=""
              onClick={() =>
                onAddToDefaultAddress(cur.address, cur.city, cur.state)
              }
            />
            <div>
              {/* <p>John Doe</p> */}
              <p>
                {cur.address}, {cur.city}, {cur.state}
              </p>
              <p>
                <Link to={"/update-address?id=" + cur.id}>Edit address</Link>
              </p>
            </div>
          </div>
        ))}
        <br />
        <div className={classes.add_adderess_button}>
          <Link to={"/create-address"}>
            <button>ADD ADDRESS</button>
          </Link>
        </div>
      </div>
    </Account>
  );
};

export default VendorCartDeliveryAddressPage;
