import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { PaystackButton } from 'react-paystack';
import { API_URL, Domain } from "../../Domain/Domain";
import TechnicianMain from "../../components/UI/TechnicianMain/TechnicianMain";
import dommyText from "../../data/dommyText";
import './style1.css'

const SubscriptionPaymentPage = () => {
  const { planId } = useParams();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [paymentReference, setPaymentReference] = useState('');
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [showModal, setShowModal] = useState(false); 
  const [receiptData, setReceiptData] = useState(null);

  const token = window.localStorage.getItem("technician-token");
  const theamount = selectedPlan?.amount * 100;

  const navigate = useNavigate();

  let technicianData = JSON.parse(
    window.localStorage.getItem("technician")
  );

  const userEmail = technicianData?.email;

  // Your Paystack public key
  const publicKey = dommyText();

  useEffect(() => {
    // Fetch the selected subscription plan based on the packageId
    axios
      .get(`${API_URL}/api/v1/technician/single-technician-subscription-package/${planId}`, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json; charset=utf-8",
        }
      })
      .then((response) => {
        setSelectedPlan(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching subscription plan:', error);
      });
  }, [planId]);

  // Paystack callback function for successful payments
  const handleSuccess = (response) => {
    // Get the payment reference from the response
    const reference = response.reference;
    setPaymentReference(reference);
    handlePaymentVerification(reference);
    setPaymentStatus('Payment successful');
    setShowModal(true);
  };

  // Paystack callback function for failed payments
  const handleFailed = (response) => {
    console.error('Payment failed:', response);
    setPaymentStatus('Payment failed');
  };

  const handlePaymentVerification = async (paymentReference) => {
    try {
      if (!paymentReference) {
        console.error('Payment reference is missing.');
        return;
      }

      const data = {
        amount: selectedPlan?.amount,
        packageCode: selectedPlan?.packageId,
        paymentReference: paymentReference,
        packageName: selectedPlan?.packageName,
      };

      const response = await axios
      .post(`${API_URL}/api/v1/technician/create-subscription`, data, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json; charset=utf-8",
        }
      });

      if (response.status === 200) {
        setPaymentStatus('Payment verified successfully');
        setReceiptData(response.data.technician);
        console.log(receiptData)
      } else {
        console.error('Payment verification failed:', response.data.message);
        setPaymentStatus('Payment verification failed');
      }
    } catch (error) {
      console.error('Error verifying payment:', error);
      setPaymentStatus('Error verifying payment');
    }
  };

  const closeModal = () => {
    setShowModal(false);
    navigate('/technician-subscription-plans');
  };

  return (
    <TechnicianMain>
      <div className="container">
        {selectedPlan ? (
          <div className='plan-container'>
            <h1>{selectedPlan.packageName} Subscription</h1>
            <p><b>Price:</b> {selectedPlan.amount} Naira</p>
            <p><b>Description:</b> {selectedPlan.description}</p>
            <p><b>Duration:</b> {selectedPlan.duration} Month</p>
            <br />
            <PaystackButton
              text="Pay Now"
              className="paystack-button"
              onSuccess={handleSuccess}
              onClose={handleFailed}
              email={userEmail}
              amount={theamount}
              publicKey={publicKey}
              currency="NGN"
            />
            {paymentReference && (
              <div>
                <p>Payment Reference: {paymentReference}</p>
                <button onClick={() => handlePaymentVerification(paymentReference)}>Verify Payment</button>
                <p>Payment Status: {paymentStatus}</p>
              </div>
            )}
          </div>
        ) : (
          <p>Loading subscription plan details...</p>
        )}
        <Link to="/technician-subscription-plans">
          <button className="btn primary">Back to Plans</button>
        </Link>
      </div>

      {/* Custom Modal */}
      {showModal && (
        <div className="custom-modall">
          {receiptData && (
            <div className="modal-content">
            <h3>Payment Verification Successful</h3>
            <p>
              Active Subscription Plan Name:{' '}
              {receiptData?.activeSubscriptionPackageName}
            </p>
            <p>
              Active Subscription Start Date:{' '}
              {receiptData?.activeSubscriptionStartDate}
            </p>
            <p>
              Active Subscription End Date:{' '}
              {receiptData?.activeSubscriptionEndDate}
            </p><br />
            <button onClick={closeModal}>Continue</button>
          </div>
          )}
        </div>
      )}
    </TechnicianMain>
  );
};

export default SubscriptionPaymentPage;
