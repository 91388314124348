import React, { useState, useEffect } from "react";
import { BsEyeSlash } from "react-icons/bs";
import { FaGoogle, FaStore, FaWrench } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { setClientToken } from "../../components/cookie/setClientToken";
import ProductsAds from "../../components/ProductAds/ProductsAds";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import Main from "../../components/UI/Main/Main";
import { Domain } from "../../Domain/Domain";
import classes from "../../styles/Register.module.css";
import { setClientUserData } from "../../components/cookie/setClientUserData";
import { clearClientCookie } from "../../components/cookie/clearClientCookie";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../../store/slices/alertSlice";
const Login = () => {
  const [viewPassword, setViewPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [enteredData, setEnteredData] = useState<{
    email: string;
    password: string;
  }>({ email: "", password: "" });

  const navigate = useNavigate();

  useEffect(() => {
    clearClientCookie();
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      email: enteredData.email,
      password: enteredData.password,
    };

    if (enteredData.password.length < 6) {
      // return alert("Password Minimum length is 6");
      return dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "Password Minimum length is 6",
          status: "error",
        })
      );
    }

    fetch(Domain() + "/api/v1/auth/login", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log("success==>", response);
        setIsLoading(false);
        if (response.status === 200) {
          setClientToken(response.data.token, 30);
          setClientUserData(JSON.stringify(response.data.userDetails), 30);
          navigate("/");
        }

        if (response.status === 400) {
          setIsLoading(false);
          // alert(response.message);
          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: response.message,
              status: "error",
            })
          );
          if (response.message === "Incorrect password") {
            setError(response.message);
            dispatch(
              toggleAlertAction({
                isAlerting: true,
                message: response.message,
                status: "error",
              })
            );
            setIsLoading(false);
          }
          if (
            response.message ===
            "Your account is not verified yet. Kindy verify your account to enable you login"
          ) {
            navigate("/verify-account?email=" + enteredData.email);
          }
        }
      })
      .catch((error) => {
        // setError(error.errors[0]);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: error.errors[0],
            status: "error",
          })
        );
      });
  };

  const onForgotPasswordHandler = () => {
    if (enteredData.email.length < 1) {
      // return alert("Please type your emaila ddress and click forget password.");
      return dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "Please type your email address and click forget password",
          status: "error",
        })
      );
    }
    navigate("/reset-password?email=" + enteredData.email);
  };
  return (
    <Main>
      <form onSubmit={onSubmitHandler}>
        <div className={classes.forms_container}>
          <div className={classes.title_container}>
            <h2>Customer Login</h2>
          </div>
          <div>
            {error !== "" && (
              <p
                style={{ color: "red", textAlign: "center", marginTop: "10px" }}
              >
                {error}
              </p>
            )}
          </div>
          <div className={classes.form_container_div}>
            <div className={classes.input_div_container}>
              <input
                type="email"
                onChange={onChangeHandler}
                placeholder="Email"
                name="email"
                required
              />
            </div>
          </div>

          <div className={classes.form_container_div}>
            <div className={classes.input_div_container}>
              <input
                type={!viewPassword ? "password" : "text"}
                placeholder="Password"
                name="password"
                required
                onChange={onChangeHandler}
              />
              <div className={classes.password_toggle_container}>
                <i onClick={() => setViewPassword(!viewPassword)}>
                  <BsEyeSlash />
                </i>
              </div>
            </div>
          </div>

          <div className={classes.terms_and_condition_area}>
            <div className={classes.term_and_condition_div}>
              <input type="checkbox" />
              <div>
                <p>Keep me logged in</p>
              </div>
            </div>
          </div>
          <div className={classes.button_container}>
            <button>{!isLoading ? "LOGIN" : "Loading..."}</button>
          </div>
          {isLoading && (
            <div className={classes.login_spinner}>
              <SmallSpinner />
            </div>
          )}
          <br />
          <div>
            <p
              onClick={onForgotPasswordHandler}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              Forgot password?
            </p>
          </div>
          <div className={classes.alternative_devide}>
            <div className={classes.line_bar}>
              <div className={classes._or_text}>
                <p>Or</p>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div className={classes.button_container}>
        <Link to={"/login-v"}>
          <button
            style={{
              backgroundColor: "white",
              color: "black",
              marginTop: "50px",
            }}
          >
            <span>
              <FaStore />
            </span>
            LOGIN AS VENDOR
          </button>
        </Link>
  
        <Link to={"/login-t"}>
          <button
            style={{
              backgroundColor: "white",
              color: "black",
              marginTop: "50px",
            }}
          >
            <span>
              <FaWrench />
            </span>
            LOGIN AS TECHNICIAN
          </button>
        </Link><br />

        <div className={classes.aready_ahave_account}> 
          <h4>
            New? <Link to={"/register"}>Sign up</Link>
          </h4>
        </div>
        <br />
        <ProductsAds />
      </div>
    </Main>
  );
};

export default Login;
