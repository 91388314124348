import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import AdminPagination from "../../components/AdminPagination/AdminPagination";
import { getCookie } from "../../components/cookie/getCookie";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import { Domain } from "../../Domain/Domain";
import classes from "../../styles/AdminManageFindProducts.module.css";
import { BsSearch } from "react-icons/bs";
import { OrdersTable } from "./AdminPaySmallSmallPage";
import Spinner from "../../components/Spinner/Spinner";

type Order = {
  id: number;
  userId: number;
  firstName: string;
  surname: string;
  otherName: string;
  dob: string;
  email: string;
  phoneNumber: string;
  address: string;
  state: string;
  city: string;
  products: string;
  total: number;
  order_id: string;
  pickup_address: string | null;
  pickup_date: string | null;
  pickup_phone_number: string | null;
  firstPayment: number;
  installmentOption: number;
  order_status: number;
  createdAt: string;
  updatedAt: string;
};

type OrderDetailsProps = {
  order: Order;
};

export const OrderDetails: React.FC<OrderDetailsProps> = ({ order }) => {
  return (
    <div className="order-details">
      <h2>Order Details</h2>
      <div className="order-info">
        <div className="order-row">
          <span className="label">Order ID:</span>
          <span className="value">{order.order_id}</span>
        </div>
        <div className="order-row">
          <span className="label">Customer Name:</span>
          <span className="value">
            {order.firstName} {order.surname} {order.otherName}
          </span>
        </div>
        <div className="order-row">
          <span className="label">Email:</span>
          <span className="value">{order.email}</span>
        </div>
        <div className="order-row">
          <span className="label">Phone Number:</span>
          <span className="value">{order.phoneNumber}</span>
        </div>
        <div className="order-row">
          <span className="label">Address:</span>
          <span className="value">{order.address}</span>
        </div>
        <div className="order-row">
          <span className="label">State:</span>
          <span className="value">{order.state}</span>
        </div>
        <div className="order-row">
          <span className="label">City:</span>
          <span className="value">{order.city}</span>
        </div>
        <div className="order-row">
          <span className="label">Total:</span>
          <span className="value">{order.total}</span>
        </div>
         <div className="order-row">
 <a href={`/admin-pay-small-small-detail?id=${order.order_id}`}>
    <button>See Full detail</button>
  </a>        </div>
        {/* ... and so on for other order details */}
      </div>
    </div>
  );
};

const AdminPaySmallSmallSearchPage = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [orderData, setOrderData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSearch = (e: any) => {
    e.preventDefault();

    axios
      .get(Domain() + `/api/v1/admin/order/pay-small-small/${searchValue}`, {
        headers: {
          Authorization: getCookie(),
          "Content-Type": "application/json",
        },
      })
      .then((response: any) => {
        console.log(response);
        if (response.data.status === 200) {
          setOrderData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <AdminMain>
      <>
        <h1>Search Pay Small Small orders</h1>
        <br />

        <form className="search-form" onSubmit={handleSearch}>
          <div className="wrapper">
            <div className="search-input">
              <input
                type="text"
                className="search-input-field"
                placeholder="Search for Order ID"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                required
              />
              <button className="icon" type="submit">
                <BsSearch />
              </button>
            </div>
          </div>
        </form>
        <br />
        {orderData ? (
          isLoading ? (
            <Spinner />
          ) : (
            <OrderDetails order={orderData} />
          )
        ) : (
          <p>No order found</p>
        )}
      </>
    </AdminMain>
  );
};

export default AdminPaySmallSmallSearchPage;
