import axios from "axios";
import { useSelector } from "react-redux";

export interface Promo {
  id: number;
  category: number;
  title: string;
  images: string;
  is_live: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface Ad {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export const promotionsHeaders = {
  // Authorization:
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyRGF0YSI6eyJ1c2VySWQiOjEsImVtYWlsIjoiYWRtaW5AZ21haWwuY29tIiwidXNlcl90eXBlIjoxLCJpc1ZlcmlmaWVkIjp0cnVlLCJpc0FjdGl2ZSI6dHJ1ZX0sImlhdCI6MTY4NjMxMjIxMSwiZXhwIjoxNjg2OTE3MDExfQ.Ba4KbJpD7pvNlEoPgqyM3j23EqK2NJ1nisa67AoRdQw",
};

export const getImagesFromAd = (data: Promo[]) => {
  const imagesFromAd = data
    .map((ad: Promo) => {
      const images = JSON.parse(ad.images) as string[];

      const extractedImages = images.map((image) => image.split(",").flat());

      return extractedImages.flat().map((image: string) => ({ url: image }));
    })
    .flat();

  return imagesFromAd;
};

export const getPromotions = async () => {
  try {
    const res = await axios.get(
      "https://api.electronicshop.com.ng/api/v1/promotion/enums",
      {
        headers: {
          ...promotionsHeaders,
        },
      }
    );
    return res.data?.data;
  } catch (error) {
    console.log({
      error,
    });

    return [];
  }
};

export const getPromotionData = async (id: number, name: string) => {
  try {
    const res = await axios.get(
      `https://api.electronicshop.com.ng/api/v1/promotion/${id}`,
      {
        headers: {
          ...promotionsHeaders,
        },
      }
    );
    const data = res.data?.data;
    console.log("iron man")
    console.log(data)

    const filteredData = data?.filter((item: any) => item.is_live != false);
    console.log(filteredData)
    console.log({
      filteredData,
    });

    return {
      name,
      data: filteredData as Promo[],
      // images: getImagesFromAd(data),
      images: getImagesFromAd(filteredData),
    };
  } catch (error) {
    console.log({
      error,
    });

    return { name, data: [] as Promo[], images: [] as { url: string }[] };

  }
};
