import React from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "./AdminOrder.module.css";
interface Props {
  status: any;
  orderData: any;
}
const AdminSearchOrder: React.FC<Props> = ({
  status,
  orderData,
}) => {
  const navigate = useNavigate();
  const onNavigateHandler = (orderId: number | string) => {
    navigate("/admin-order-detail?order_id=" + orderId);
  };
  return (
    <>
      <div className={classes.order_table}>
        <table className={classes.table_section}>
          <tbody>
            <tr className={classes.header_section}>
              <td>#</td>
              <td>Order Number</td>
              <td>Items</td>
              <td>Price</td>
              <td>Location</td>
              <td>Date/Time</td>
              <td>Status</td>
            </tr>
              <tr
                className={classes.lists_}
                onClick={() => onNavigateHandler(orderData?.order_id!)}
              >
                <td>1</td>
                <td>{orderData?.order_id!}</td>
                <td>{JSON.parse(orderData?.order_items ?? null)?.length}</td>
                <td>₦ {orderData?.total?.toLocaleString()!}</td>
                <td style={{ fontSize: "12px" }}>
                  {orderData?.delivery_address!}
                </td>

                <td style={{ fontSize: "12px" }}>
                  {new Date(orderData?.createdAt! as any).toDateString()}
                  {" : "}
                  {new Date(
                    orderData?.createdAt! as any
                  ).toLocaleTimeString()}
                </td>

                <td style={{ fontSize: "12px" }}>
                  {status === "1" && "Successful and pending"}
                  {status === "2" && "Available for pickup"}
                  {status === "3" && "Delivered and completed"}
                  {status === "4" && "Suspicious/Canceled"}
                </td>
              </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AdminSearchOrder;
