import React from "react";
import { BsImageFill, BsTrash } from "react-icons/bs";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import BackButton from "../../components/UI/BackButton/BackButton";
import classes from "../../styles/AdminVendorReportPage.module.css";
const AdminOrderStatusPageReply = (): JSX.Element => {
  return (
    <AdminMain>
      <div>
        <div>
          <BackButton />
          <br />
          <div className={classes.report_wrapper}>
            <h3>Reply: Order Status</h3>
            <p>To: BIRON; </p>
            <p>17:59; 05/11/22</p>
          </div>
          <div className={classes.report_body}>
            <div className={classes.trash_icon}>
              <i>
                <BsTrash />
              </i>
            </div>
            <div className={classes.body_text}>
              <p>
                I am yet to receive my package which was expected to arrive
                yesterday. My order number is DR499AA
              </p>
            </div>
            <br />
            <textarea
              name=""
              id=""
              value={`Hello BIRON,
Our shipments were delayed to the flood crisis in many parts of the
country. Kindly exercise patience, deliveries to your location will 
resume soon as we seek solutions to the problem. We apologise for any
inconvenience that this may cost you.
Thank you for choosing Electronicshop.ng`}
            ></textarea>
            <br />
            <br />
            <div className={classes.uplaod_div}>
              <i>
                <BsImageFill />
              </i>
            </div>
            <br />
            <div className={classes.action_button}>
              <button style={{ backgroundColor: "#F14306" }}>
                SEND MESSAGE
              </button>
            </div>
          </div>
        </div>
      </div>
    </AdminMain>
  );
};

export default AdminOrderStatusPageReply;
