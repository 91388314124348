import React, { useEffect, useState } from "react";
import FAQLIST from "../../components/FAQ/FAQLIST";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import BackButton from "../../components/UI/BackButton/BackButton";
import classes from "../../styles/AdminFAQ.module.css";
import { Domain } from "../../Domain/Domain"; 
import FAQCategory from "../../components/FAQ/FAQCategory";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import { useSelector } from "react-redux";

const AdminFAQ = () => {
  const [faqCatrgories, setFaqCategories] = useState<any>([]);
  const Loaderselector = useSelector((value: any) => value.loader);
  useEffect(() => {
    fetch(Domain() + "/api/v1/faq/faq-categories")
      .then((res) => res.json())
      .then((data) => {
        setFaqCategories(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  let loaderStyles: any = {
    position: "fixed",
    top: "15%",
    right: "10px",
  };
  return (
    <AdminMain>
      {Loaderselector.isLoading && (
        <div style={loaderStyles}>
          <SmallSpinner />
        </div>
      )}
      <div>
        <BackButton />
        <br />
        <h3>FAQs</h3>
        <br />

        {faqCatrgories.map((cur: any, index: number) => (
          <FAQCategory data={cur} numbering={index} />
        ))}
      </div>
    </AdminMain>
  );
};

export default AdminFAQ;
