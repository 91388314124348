import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  isLoading: false,
};

const LoaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    toggleLoaderAction(state, { payload }) {
      state.isLoading = payload;
    },
  },
});

export const { toggleLoaderAction } = LoaderSlice.actions;
export default LoaderSlice;
