import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { API_URL, Domain } from "../../Domain/Domain";
import TechnicianMain from "../../components/UI/TechnicianMain/TechnicianMain";
import './styles.css'

const SubscriptionPlansPage = () => {
  const [plans, setPlans] = useState([]);

  const token = localStorage.getItem("technician-token")

  useEffect(() => {
    // Fetch subscription plans from your backend 
    axios
      .get(`${API_URL}/api/v1/technician/technician-subscription-packages`, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json; charset=utf-8",
        }
      })
      .then((response) => {
        setPlans(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching subscription plans:', error);
      });
  }, []);

  return (
    <TechnicianMain>
      <div>
        <h1>Technician Subscription Plans</h1>.
        <h2>Choose a Subscription Plan</h2>
        <table>
          <thead>
            <tr>
              <th>S/N</th>
              <th>Plan Name</th>
              <th>Price</th>
              <th>Duration</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {plans.map((plan, index) => (
                <tr key={plan.packageId}>
                  <td>{index + 1}</td>
                  <td>{plan.packageName}</td>
                  <td>{plan.amount} Naira</td>
                  <td>{plan.duration} Month(s)</td>
                  <td>{plan.description}</td>
                  <td>
                    <Link to={`/technician-subscription-payment/${plan.packageId}`}>
                      <button className="btn btn-success">Subscribe</button>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>        
       </table>
      </div>
    </TechnicianMain>
  );
};

export default SubscriptionPlansPage;
