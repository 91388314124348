import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import Main from "../../components/UI/Main/Main";
import { Domain } from "../../Domain/Domain";
import classes from "../../styles/Register.module.css";
const VerifyCode = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRequestingCode, setIsRequestingCode] = useState<boolean>(false);

  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [enteredData, setEnteredData] = useState<{
    code: string;
  }>({ code: "" });

  const emailURL = new URLSearchParams(window.location.search);
  let email = emailURL.get("email");
  function codeRequest() {
    setIsRequestingCode(true);
    fetch(Domain() + "/api/v1/auth/resend-verification", {
      method: "POST",
      body: JSON.stringify({
        email,
      }),
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        setIsRequestingCode(false);
      })
      .catch((error) => {
        console.log(error);
        setIsRequestingCode(false);
      });
  }

  useEffect(() => {
    codeRequest();
  }, []);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    fetch(Domain() + "/api/v1/auth/verify", {
      method: "POST",
      body: JSON.stringify({
        email,
        token: enteredData.code,
      }),
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        if (response.status === 200) {
          setSuccessMessage(response.message);
          setError("");
          navigate("/login");
        }
        if (response.status === 400) {
          setError("Wrong code please check your email and try again");
          setSuccessMessage("");
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  console.log("========>", isLoading);
  return (
    <Main>
      <form onSubmit={onSubmitHandler} autoComplete={"off"}>
        <div className={classes.forms_container}>
          <div className={classes.title_container}>
            <h2>Account confirmation</h2>
          </div>
          <div className={classes.form_notes}>
            <p>
              Your email (<span style={{ color: "#F14306" }}>{email}</span>) has
              received a code. Please check your email for the code, which you
              should then enter here.
            </p>
          </div>
          <div>
            {error !== "" && (
              <p
                style={{ color: "red", textAlign: "center", marginTop: "10px" }}
              >
                {error}
              </p>
            )}
          </div>
          <div>
            <p className={classes.success_message}>{successMessage}</p>
          </div>
          <div className={classes.form_container_div}>
            <div className={classes.input_div_container}>
              <input
                type="text"
                onChange={onChangeHandler}
                placeholder="Code"
                name="code"
                required
              />
            </div>
          </div>
          <div className={classes.resend_conde}>
            {!isRequestingCode ? (
              <div onClick={codeRequest}>Resend code</div>
            ) : (
              <div>Please wait</div>
            )}
          </div>

          <div className={classes.button_container}>
            <button>{!isLoading ? "Verify" : "Loading..."}</button>
          </div>
          {isLoading && (
            <div className={classes.login_spinner}>
              <SmallSpinner />
            </div>
          )}

          <br />
          {/* <ProductsAds /> */}
        </div>
      </form>
    </Main>
  );
};

export default VerifyCode;
