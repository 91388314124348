import React, { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { addPromotionData } from "../../../store/slices/promotionsSlice";
import { Ad, getPromotionData, Promo } from "../../../utils/promotionHelpers";
import ProductsAds from "../../ProductAds/ProductsAds";
import classes from "./Aside.module.css";
import BrandAside from "./BrandAside";
const Aside: React.FC = () => {
  const dispatch = useDispatch();

  const promotionSelector = useSelector((value: any) => value.promotion);
  const [promotions, setPromotions] = useState<Ad[]>([]);
  const [sideBarAdData, setSideBarAdData] = useState<Promo[]>([]);
  const [sideBarAdImages, setSideBarAdImages] = useState<{ url: string }[]>([]);

  const [toggleNav, setToggleNav] = useState(true);
  const [filter, setFilter] = useState("brand");
  // Check path
  const location = useLocation();
  // console.log(location.pathname);
  useEffect(() => {
    if (
      ["/register", "/login", "/login-v", "register-v", "/reset"]?.includes(
        location.pathname
      )
    ) {
      setToggleNav(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    setPromotions(promotionSelector.promotionsArray);
    console.log({
      promotions: promotionSelector.promotionsArray,
    });

    // if (promotionSelector.promotionsArray.length > 0) {
    getPromotionData(1, "Site Side Bar Ad").then((res) => {
      dispatch(addPromotionData(res));
      
      setSideBarAdData(res.data);
      setSideBarAdImages(res.images);
    });
    // }
  }, [promotionSelector.promotionsArray]);

  return (
    <div className={classes.aside_container}>
      {toggleNav && (
        <>
          <div className={classes.aside_nav}>
            <button
              style={{
                borderBottom:
                  filter === "brand" ? "4px solid #F14306" : "#ffffff",
              }}
              onClick={() => setFilter("brand")}
            >
              Brands
            </button>
            <button
              style={{
                borderBottom:
                  filter === "category" ? "4px solid #F14306" : "#ffffff",
              }}
              onClick={() => setFilter("category")}
            >
              Categories
            </button>
            {/* <Link to="/search">
              <button>Categories</button>
            </Link> */}
          </div>
          <div className={classes.brand_input}>
            <input
              type="text"
              placeholder={`Find ${filter === "brand" ? "brand" : "category"}`}
            />
            <div className={classes.search_icon_container}>
              <i>
                <BsSearch />
              </i>
            </div>
          </div>
          <BrandAside filter={filter} />
        </>
      )}

      <ProductsAds
        images={sideBarAdImages}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: ".75rem",
          marginTop: "20px",
        }}
      />
    </div>
  );
};

export default Aside;
