import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { adminApi } from "./services/admin";
import searchSlice from "./slices/searchSlice";
import paymentSlice from "./slices/paymentSlice";
import LoaderSlice from "./slices/LoaderSlice";
import alertSlice from "./slices/alertSlice";
import promotionSlice from "./slices/promotionsSlice";
export const store = configureStore({
  reducer: {
    [adminApi.reducerPath]: adminApi.reducer,
    [searchSlice.name]: searchSlice.reducer,
    [paymentSlice.name]: paymentSlice.reducer,
    [LoaderSlice.name]: LoaderSlice.reducer,
    [alertSlice.name]: alertSlice.reducer,
    [promotionSlice.name]: promotionSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // @ serializableCheck:false; This help you to work with input fomrData
    }).concat(adminApi.middleware),
});

setupListeners(store.dispatch);
