import React from "react";
import Footer from "../Footer/Footer";
import TechnicianHeader from "../Header/TechnicianHeader";
import TechnicianAside from "./TechnicianAside";
import classes from "./TechnicianMain.module.css";
interface Props {
  isTechnician?: boolean;
  children: JSX.Element | JSX.Element[];
}
const TechnicianMain: React.FC<Props> = ({ isTechnician, children }) => {
  return (
    <>
      <TechnicianHeader />
      <main className={classes.main}>
        <aside>
          <TechnicianAside isForTechnician={isTechnician} />
        </aside>
        <article className={classes.article}>{children}</article>
      </main>
      <Footer />
    </>
  );
};

export default TechnicianMain;
