import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getClientData } from "../../components/cookie/getClientData";
import { getClientToken } from "../../components/cookie/getClientToken";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import Main from "../../components/UI/Main/Main";
import { TechnicianLoginForm } from "../../data/TechnicianLoginForm";
import { API_URL, Domain } from "../../Domain/Domain";
import classes from "../../styles/Account.module.css";
export const TechnicianLogin = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [message, setMessage] = useState({ error: "", success: "" });
  const [enteredData, setEnteredData] = useState<{
    password: string;
    email: string;
  }>({
    email: "",
    password: "",
  });
  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setEnteredData((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    window.localStorage.removeItem("technician-token");
    window.localStorage.removeItem("technician");
  }, []);

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (enteredData.password.length < 6)
      return setMessage({
        error: "Password is too short",
        success: "",
      });
    setLoader(true);

    console.log(enteredData);
    let fake = {
      email: enteredData.email,
      password: enteredData.password,
    };
    axios
      .post(`${API_URL}/api/v1/technician/login`, JSON.stringify(fake), {
        headers: {
          "Content-Type": "application/json",
        },
      })

      .then((response: any) => {
        setLoader(false);
        console.log(response.data);
        if (response.data.status === 200) {
          setMessage({
            error: "",
            success: response.data.message,
          });

          window.localStorage.setItem(
            "technician",
            JSON.stringify(response.data.data.user)
          );
          window.localStorage.setItem("technician-token", response.data.data.token);

          navigate("/technician-dashboard");
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log();
        setMessage({
          error:
            typeof error.response.data.message === "string"
              ? error.response.data.message
              : "An error coccured",
          success: "",
        });
      });
  };

  let [status, setStatus] = useState<string>("");
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
    let url = new URLSearchParams(window.location.search);
    if (url.get("status") === "passwordChnaged") {
      setStatus("Successfully updated password");

      setTimeout(() => {
        setStatus("");
      }, 4000);
    }
  }, []);

  // const onForgotPasswordHandler = () => {
  //   if (enteredData.email.length < 1) {
  //     return setMessage({
  //       error: `Input your email address and the most recent password you can remember, then click the "forgot password" link.`,
  //       success: "",
  //     });
  //   }

  //   navigate("/reset-password-t?email=" + enteredData.email);
  // };
  return (
    <Main>
      <form onSubmit={onSubmitHandler} className={classes.vendor_form}>
        <h1>Technician Login</h1>
        <br />
        {status.length > 0 && (
          <>
            <p className="success-message-form-dorm">{status}</p>
            <br />
          </>
        )}
        <div className={classes.form_centered_section}>
          {TechnicianLoginForm.map((cur, index) => (
            <div key={index} className={classes.application_form}>
              <>
                <label htmlFor="">{cur.labelFor}</label>
                <input
                  type={cur.type}
                  placeholder={cur.placeHolder}
                  required
                  name={cur.name}
                  onChange={onChangeHandler}
                />
              </>
            </div>
          ))}
          <br />
          <div className={classes.messages_data}>
            <p className="error_message">
              {message.error !== "" && (
                <>
                  {message.error}
                  <br />
                  <br />
                </>
              )}
            </p>
            <p className="success_message">
              {message.success !== "" && <> {message.success}</>}
            </p>
          </div>
          {/* <div onClick={onForgotPasswordHandler} style={{ cursor: "pointer" }}>
            Forgot password?
          </div> */}
          <br />
          <div className={classes.submit_button + " " + classes.btn_2}>
            <button>Login</button>
            {loader && (
              <>
                <br />
                <SmallSpinner />
              </>
            )}
          </div>
          <br />
          {/* <Link to="/register-t">Don't have an account? Sign up</Link> */}
        </div>
      </form>
    </Main>
  );
};
