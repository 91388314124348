import axios from "axios";
import React, { useEffect, useState } from "react";
import { getClientData } from "../../components/cookie/getClientData";
import { getClientToken } from "../../components/cookie/getClientToken";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import Account from "../../components/UI/Account/Account";
import { ResetPasswordData } from "../../data/ResetPasswordData";
import { UserProfileData } from "../../data/UserProfileData";
import { Domain } from "../../Domain/Domain";
import classes from "../../styles/Account.module.css";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../../store/slices/alertSlice";

const ResetPasswordPage = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [enteredData, setEnteredData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    if (enteredData.newPassword !== enteredData.confirmPassword) {
      // return alert("password did not match");
      return dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "password did not match",
          status: "error",
        })
      );
    }
    setLoader(true);
    axios
      .patch(Domain() + "/api/v1/user/change-password", enteredData, {
        headers: {
          Authorization: getClientToken(),
          "Content-Type": "application/json",
        },
      })

      .then((response: any) => {
        setLoader(false);
        console.log(response.data.data);
        if (response.data.status === 200) {
          // window.location.reload();
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
        console.log(error.response.data.message);
        // alert(error.response.data.message);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: error.response.data.message,
            status: "error",
          })
        );
      });
  };

  return (
    <Account>
      <form onSubmit={onSubmitHandler}>
        <div>
          {ResetPasswordData.map((cur, index) => (
            <div key={index} className={classes.application_form}>
              <>
                <label htmlFor="">{cur.labelFor}</label>
                <input
                  type={cur.type}
                  name={cur.name}
                  placeholder={cur.placeHolder}
                  onChange={onChangeHandler}
                  required
                />
              </>
            </div>
          ))}
          <br />
          <div className={classes.add_adderess_button + " " + classes.btn_2}>
            <button>SAVE PASSWORD</button>
            {loader && <SmallSpinner />}
          </div>
        </div>
      </form>
    </Account>
  );
};

export default ResetPasswordPage;
