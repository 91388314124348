import axios from "axios";
import React, { useState, useEffect, useRef} from "react";
import { BsStarFill } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Domain, Domain2 } from "../../../Domain/Domain";
import { getCookie } from "../../cookie/getCookie";
import classes from "./VendorAside.module.css";
import ClipboardJS from 'clipboard';
import './VendorAside.module.css'

interface Wallet {
  realBalance: number,
  pendingBalance : number,
}


const VendorAside = ({ isForVendor = true }: { isForVendor?: boolean }) => {
  const [vendor, serVendor] = useState<any>({});
 
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const clipboardButtonRef = useRef<HTMLButtonElement | null>(null);
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };


  useEffect(() => {
    function checkAuth() {
      const parseJwt = (token: string) => {
        try {
          return JSON.parse(atob(token.split(".")[1]));
        } catch (e: any) {
          return null;
        }
      };

      let token = window.localStorage.getItem("vendor-token") as string;
      const decodedJwt: any = parseJwt(token) as unknown as any;
      if (token === null) return navigate("/login-v");

      if (decodedJwt?.exp! * 1000 < Date.now()) {
        navigate("/login-v");
      }
    }
    checkAuth();
  }, []);

  useEffect(() => {
    let vendorData = JSON.parse(
      window.localStorage.getItem("vendor") as unknown as string
    );

    axios
      .get(Domain() + "/api/v1/vendor/profile/" + vendorData?.vendorId!, {
        headers: {
          Authorization: localStorage.getItem("vendor-token") as string,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response: any) => {
        if (response.data.status === 200)
          serVendor(response.data.data.vendorProfileDetails);

      })
      .catch((error) => {});
  }, []);

  const [wallet, setWallet] = useState<Wallet | null>(null);
  const token  = localStorage.getItem("vendor-token") as string
      
  useEffect(() => {
    axios.get(`${Domain2}/vendor/fetch-wallet`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
      setWallet(response.data);
    });
  }, [token]);

  const [products, setProducts] = useState<any>([]);
  const [totalAmount, setTotalAMount] = useState<number>(0);
  useEffect(() => {
    axios
      .get(Domain() + "/api/v1/vendor/products", {
        headers: {
          Authorization: window.localStorage.getItem("vendor-token") as string,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response: any) => {
        if (response.data.status === 200) {
          setProducts(response.data.data);

          let arr = response.data.data;
          let newArr = arr.map((cur: any) => cur.pricing);
          let newTotal = newArr.reduce((a: any, b: any) => {
            return a + b;
          }, 0);
          setTotalAMount(newTotal); 
        }
      })
      .catch((error) => {
        console.log(error);
        navigate("/login-v");
      });
  }, []);

  const baseShopUrl = 'https://electronicshop.com.ng/vendorshop/'
  const vendorShopId = vendor?.customised_shop_id 

  useEffect(() => { 
    if (clipboardButtonRef.current && textAreaRef.current) {
      const clipboard = new ClipboardJS(clipboardButtonRef.current, {
        target: () => textAreaRef.current as HTMLElement,
      });

      clipboard.on('success', (e) => {
        console.log('Text copied:', e.text);
        alert(e.text)
      });

      clipboard.on('error', (e) => {
        console.error('Error copying text:', e.text);
        alert('You are not able to copy vendor cutomised url')
      });

      return () => {
        clipboard.destroy(); // Clean up clipboard.js instance when component unmounts.
      };
    }
  }, []);

  const handleCopyClick = async () => {
    await navigator.clipboard.writeText(baseShopUrl + vendorShopId)
    alert('You copied '+baseShopUrl+vendorShopId+' to your clipboard')
  }

  return (
    <div>
    <div className={`${classes.vssa} ${collapsed ? classes.collapsed : ''}`}>
        <div className={classes.vendortoppanel}>
          {isForVendor && (
            <>
              <div className={classes.title_section}>
                <Link to={"/vendor-dashboard"}>
                  <h3>Seller's Dashboard</h3>
                </Link>
              </div>
              <br />
              <Link to={"/vendor-profile-update"}>
                <p className={classes.editing_section}>
                  Personal Info{" "}
                  <i>
                    <FaEdit />
                  </i>
                </p>
              </Link>
            </>
          )}
          <h4 className={classes.vendor_name}>{vendor?.shopName!}</h4><br />
          <div>
            <p>Email</p>
            <h5>{vendor?.email ?? "-"}</h5>
          </div><br />
          <div className={classes.contact_list}>
            <p>Phone</p>
            <h5>{vendor?.phoneNumber ?? "-"}</h5>
          </div><br />
          <div className={classes.contact_list} style={{ width: "250px" }}>
            <p>Address</p>
            <h5>
              {vendor?.address!} {vendor?.city!} {vendor?.state!} {vendor?.country!}
            </h5>
          </div><br />
          <div>
            <p>Vendor Customised Shop Id</p>
            <div className={classes.bbbox}>
                  {vendorShopId !== undefined && (
                    <textarea
                      ref={textAreaRef}
                      defaultValue={baseShopUrl + vendorShopId}
                      style={{ width: '100%', height:'60px' }}
                      readOnly
                    />
                )}
            </div>
            <button className="btn btn-secondary" onClick={handleCopyClick} ref={clipboardButtonRef}>Copy to Clipboard</button>
          </div>  
        </div>  
        <br />

        <div className={classes.list_buttons}>
          {/* <Link to={"/vendor-dashboard"}> */}
          <button style={{ width: "46%" }}>
            All Products
            <br />
            {products.length}
          </button>
          {/* </Link> */}
          <button style={{ width: "46%" }}>
            Total
            <br />₦ {totalAmount.toLocaleString()}
          </button>
          <br />
          <button style={{ width: "96%" }}>
            Joined <br />
            {new Date(vendor?.createdAt!).toDateString()}{" "}
          </button>
          <button style={{ width: "96%" }}>
            Current Balance: <br />
            <span>₦ {wallet?.realBalance?.toLocaleString() ?? 'N/A'}</span>

          </button>
          <button style={{ width: "96%" }}>
            Pending Balance: <br />
            <span>₦ {wallet?.pendingBalance?.toLocaleString() ?? 'N/A'}</span>
          </button>
        </div>
        {/* <div className={classes.list_buttons}>
          <button>
            Televisions <br />5
          </button>
          <button>
            Televisions <br />5
          </button>
        </div> */}
        <br />
        {isForVendor && (
          <>
            <div className={classes.create_product_section}>
              <Link to={"/vendor-create-product"}>
                <button>CREATE PRODUCT</button>
              </Link>
            </div>

            <br />
            <div className={classes.footer_section}>
              {/* <small>Promotions</small>
              <h4>Manage Promotions</h4>
              <h4>Account Balance</h4>
              <br /> */}

              <small>Wallet</small>
              <Link to={"/vendor-withdraw"}>
                <h4 style={{ color: "black" }}>Withdraw</h4>
              </Link>
              <Link to={"/vendor-edit-bank-details"}>
                <h4 style={{ color: "black" }}>Update Bank Details</h4>
              </Link>
              <Link to={"/vendor-user-orders"}>
                <h4 style={{ color: "black" }}>User Orders</h4>
              </Link>
              <br />

              <Link to={"/login-v"} style={{ color: "red" }}>
                <h4>logout</h4>
              </Link>
            </div>
          </>
        )}
      </div>
      <button className={classes.vssacollapsiblebutton} onClick={toggleCollapse}>
          {collapsed ? 'View Your Profile' : 'Hide Your Profile'}
      </button>
    </div>
  );
};

export default VendorAside;
