import { FaRegChartBar } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import classes from "./AdminAside.module.css";
const AdminAside = (): JSX.Element => {
  return (
    <div>
      <Link to={"/admin-dashboard"}>
        <h3 className={classes.title}>
          <i>
            <FaRegChartBar style={{ color: "#F14306" }} />
          </i>
          Overview
        </h3>
      </Link>

      <div className={classes.aside_nav_wrapper_list}>
        <p>Orders</p>
        <ul>
          <li>
            <NavLink
              className="admin_nav_lisk"
              to="/admin_order_pending?status=1"
            >
              Successful and pending
            </NavLink>
          </li>

          <li>
            <NavLink
              className="admin_nav_lisk"
              to="/admin_order_completed?status=2"
            >
              Available for pickup
            </NavLink>
          </li>

          <li>
            <NavLink
              className="admin_nav_lisk"
              to="/admin_order_approved?status=3"
            >
              Delivered and completed
            </NavLink>
          </li>

          <li>
            <NavLink
              className="admin_nav_lisk"
              to="/admin_order_canceled?status=4"
            >
              Suspicious/Canceled
            </NavLink>
          </li>
          <li>
            <NavLink className="admin_nav_lisk" to="/all_admin_orders">
              Search
            </NavLink>
          </li>

          <br />
          <p>Pay Small-Small</p>
          <li>
            <NavLink
              className="admin_nav_lisk"
              to="/admin-pay-small-small-1?status=1"
            >
              Successful and pending
            </NavLink>
          </li>
          <li>
            <NavLink
              className="admin_nav_lisk"
              to="/admin-pay-small-small-2?status=2"
            >
              Available for pickup
            </NavLink>
          </li>
          <li>
            <NavLink
              className="admin_nav_lisk"
              to="/admin-pay-small-small-3?status=3"
            >
              Delivered and completed
            </NavLink>
          </li>
          <li>
            <NavLink
              className="admin_nav_lisk"
              to="/admin-pay-small-small-search"
            >
              Search Pay Small Small
            </NavLink>
          </li>
          {/* <li>
            <NavLink className="admin_nav_lisk" to="/admin_create_order">Create order</NavLink>
          </li> */}
        </ul>
      </div>

      <div className={classes.aside_nav_wrapper_list}>
        <p>Products</p>
        <ul>
          <li>
            <NavLink className="admin_nav_lisk" to="/admin-find-product">
              Manage Products
            </NavLink>
          </li>

          <li>
            <NavLink className="admin_nav_lisk" to="/admin-new-product">
              New Product
            </NavLink>
          </li>
          {/* <li>
            <NavLink className="admin_nav_lisk" to="/admin-new-brand">
              New brand / category
            </NavLink>
          </li> */}

          <li>
            <NavLink className="admin_nav_lisk" to="/admin-new-brand">
              Brand / Category
            </NavLink>
          </li>
        </ul>
      </div>

      <div className={classes.aside_nav_wrapper_list}>
        <p>Marketplace</p>
        <ul>
          {/* <li>
            <NavLink className="admin_nav_lisk" to="/">
              Products
            </NavLink>
          </li> */}

          <li>
            <NavLink className="admin_nav_lisk" to="/admin-vendor-all">
              Vendors
            </NavLink>
          </li>

          {/* <li>
            <NavLink className="admin_nav_lisk" to="/admin-report">
              Reports
            </NavLink>
          </li> */}
        </ul>
      </div>

      <div className={classes.aside_nav_wrapper_list}>
        <p>Promotions</p>
        <ul>
          {/* <li>
            <NavLink className="admin_nav_lisk" to="admin-new-manage-promotions">
              Manage Promotions
            </NavLink>
          </li> */}

          <li>
            <NavLink className="admin_nav_lisk" to="/admin-new-promotion">
              New Promotion
            </NavLink>
          </li>

          {/* <li>
            <NavLink className="admin_nav_lisk" to="/admin-vendor-report">
              Reports
            </NavLink>
          </li> */}

          <li>
            <NavLink
              className="admin_nav_lisk"
              to="/admin-new-manage-promotions"
            >
              Manage promotions
            </NavLink>
          </li>
        </ul>
      </div>

      <div className={classes.aside_nav_wrapper_list}>
        <p>Others</p>
        <ul>
          {/* <li>
            <NavLink className="admin_nav_lisk" to="/admin-vendor-report">
              Feedbacks
            </NavLink>
          </li> */}
          <li>
            <NavLink className="admin_nav_lisk" to="/admin-support-center">
              Support Center
            </NavLink>
          </li>
          <li>
            <NavLink className="admin_nav_lisk" to="/admin-message-search">
              Search Support 
            </NavLink>
          </li>
          <li>
            <NavLink className="admin_nav_lisk" to="/admin-user-search">
              Users Search
            </NavLink>
          </li>
          <li>
            <NavLink className="admin_nav_lisk" to="/admin-technical-hub">
              Technicians Hub
            </NavLink>
          </li>
          <li>
            <NavLink className="admin_nav_lisk" to="/admin-manage-technician-subscription">
              Manage Technician Subscription Plans
            </NavLink>
          </li>
        </ul>
      </div>

      <div className={classes.aside_nav_wrapper_list}>
        <p>Settings</p>
        <ul>
          <li>
            <NavLink className="admin_nav_lisk" to="/admin-settings">
              Settings
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdminAside;
