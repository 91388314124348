import React from "react";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import { BsImage } from "react-icons/bs";
import classes from "../../styles/AdminNewProductPage.module.css";
import BackButton from "../../components/UI/BackButton/BackButton";
import useEditProductHook from "../../Hooks/useEditProductHook";
import { useAdminGeneralgetRequestQuery } from "../../store/services/admin";
import { Domain } from "../../Domain/Domain";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import useVendorEditProduct from "../../Hooks/useVendorEditProduct";
import VendorMain from "../../components/UI/VendorMain/VendorMain";
const VendorEditProduct = () => {
  const {
    files,
    showMoreSpecifications,
    onShowMoreSpecifications,
    onImageChangeHandler1,
    onImageChangeHandler2,
    onImageChangeHandler3,
    onImageChangeHandler4,
    onChangeHandler,
    onCreateProductHandler,
    enteredValue,
    onChangeColorHandler,
    isSubmitting,
    promotionalPriceIsHidden,
    togglePromotionalHandler,
    toggleBulkPriceHandler,
    hasBulkPrice,
  } = useVendorEditProduct();

  const { data: initBrandData = [] } =
    useAdminGeneralgetRequestQuery("/api/v1/brand/all");

  let brandData: any = [];
  if (initBrandData.data) brandData = initBrandData.data;

  const { data: initCategoryData = [] } = useAdminGeneralgetRequestQuery(
    "api/v1/category/all"
  );

  let categoryData: any = [];
  if (initCategoryData.data) categoryData = initCategoryData.data;

  return (
    <VendorMain>
      <div>
        <BackButton />
        <form onSubmit={onCreateProductHandler}>
          <h1 className={classes.title_section}>{enteredValue.productName}</h1>

          <p className={classes.brand_text}>
            Brand: {enteredValue.brandName}; Category:{" "}
            {enteredValue.categoryName}
          </p>

          <div className={classes.form_list_div}>
            <label>
              <b>Name</b>{" "}
              <small>
                Name (Do not include brand e.g. 1.5 HP Split Air Conditioner)
              </small>
            </label>
            <br />
            <input
              type="text"
              name="productName"
              value={enteredValue.productName}
              onChange={onChangeHandler}
              required
            />
          </div>

          <div className={classes.form_list_div}>
            <label>
              <b>Brand</b>{" "}
            </label>
            <br />
            <select name="brand" id="" onChange={onChangeHandler} required>
              <option selected value={enteredValue.brandId}>
                {enteredValue.brandName}
              </option>
              {brandData.map((cur: any) => (
                <option key={cur.id} value={cur.id}>
                  {cur.name}
                </option>
              ))}
            </select>
          </div>

          <div className={classes.form_list_div}>
            <label>
              <b>Category</b>{" "}
            </label>
            <br />
            <select name="category" id="" onChange={onChangeHandler} required>
              <option selected value={enteredValue.categoryId}>
                {enteredValue.categoryName}
              </option>
              {categoryData.map((cur: any) => (
                <option key={cur.id} value={cur.id}>
                  {cur.name}
                </option>
              ))}
            </select>
          </div>

          <div className={classes.form_list_div}>
            <label>
              <b>Model</b>{" "}
            </label>
            <br />
            <input
              type="text"
              name="model"
              onChange={onChangeHandler}
              value={enteredValue.model}
              required
            />
          </div>

          <div className={classes.form_list_div}>
            <label>
              <b>Colours Available</b>{" "}
            </label>
            <br />
            <div className={classes.color_input}>
              {[
                "Red",
                "Orange",
                "Yellow",
                "Green",
                "Blue",
                "Purple",
                "Pink",
                "Black",
                "White",
                "Grey",
                "Silver",
              ].map((cur: string, index: number) => (
                <label htmlFor="" key={cur}>
                  <input
                    type={"checkbox"}
                    onChange={onChangeColorHandler}
                    id={"color" + (index + 1).toString()}
                    value={cur}
                  />
                  <span className="capitalize">{cur}</span>
                </label>
              ))}
            </div>
          </div>

          <div className={classes.form_list_div}>
            <label>
              <b>Product Description</b> (Max. Characters 1500)
            </label>
            <br />
            <textarea
              name="productDescription"
              required
              id=""
              value={enteredValue.productDescription}
              onChange={onChangeHandler}
            ></textarea>
          </div>
          <br />
          {/* Specifications */}
          <h3 className={classes.specification_title}>Specifications</h3>

          {enteredValue.specifications.map((cur: any, index) => (
            <div className={classes.flexed_container} key={index}>
              {cur.attribute !== "" && cur.value !== "" && (
                <>
                  <div className={classes.input_list_container}>
                    <p>Attribute (e.g. Dimensions, Weight,...)</p>
                    <select
                      name="specAttribute1"
                      id="specAttributeID"
                      onChange={onChangeHandler}
                    >
                      <option selected value={cur.attribute}>
                        {cur.attribute}
                      </option>
                      <option value="weight">Weight</option>
                      <option value="dimensions">Dimensions</option>
                      <option value="capacity">Capacity</option>
                      <option value="display">Display</option>
                    </select>
                  </div>
                  <div className={classes.input_list_container}>
                    <p>Value (e.g. 7kg, 100L, 25x16in,...)</p>
                    <input
                      type="text"
                      name=""
                      placeholder={cur.value}
                      id="specValueID"
                      onChange={onChangeHandler}
                    />
                  </div>
                </>
              )}
            </div>
          ))}

          {/* More Specisifations */}
          {showMoreSpecifications && (
            <>
              <div className={classes.flexed_container}>
                <div className={classes.input_list_container}>
                  <p>Attribute (e.g. Dimensions, Weight,...)</p>
                  <select
                    name="specAttribute5"
                    id="specAttributeID"
                    onChange={onChangeHandler}
                  >
                    <option value="weight">Weight</option>
                    <option value="dimensions">Dimensions</option>
                    <option value="capacity">Capacity</option>
                    <option value="display">Display</option>
                  </select>
                </div>
                <div className={classes.input_list_container}>
                  <p>Value (e.g. 7kg, 100L, 25x16in,...)</p>
                  <input
                    type="text"
                    id="specValueID"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className={classes.flexed_container}>
                <div className={classes.input_list_container}>
                  <p>Attribute (e.g. Dimensions, Weight,...)</p>
                  <select
                    name="specAttribute"
                    id="specAttributeID"
                    onChange={onChangeHandler}
                  >
                    <option value="weight">Weight</option>
                    <option value="dimensions">Dimensions</option>
                    <option value="capacity">Capacity</option>
                    <option value="display">Display</option>
                  </select>
                </div>
                <div className={classes.input_list_container}>
                  <p>Value (e.g. 7kg, 100L, 25x16in,...)</p>
                  <input
                    type="text"
                    id="specValueID"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className={classes.flexed_container}>
                <div className={classes.input_list_container}>
                  <p>Attribute (e.g. Dimensions, Weight,...)</p>
                  <select
                    name="specAttribute"
                    id="specAttributeID"
                    onChange={onChangeHandler}
                  >
                    <option value="weight">Weight</option>
                    <option value="dimensions">Dimensions</option>
                    <option value="capacity">Capacity</option>
                    <option value="display">Display</option>
                  </select>
                </div>
                <div className={classes.input_list_container}>
                  <p>Value (e.g. 7kg, 100L, 25x16in,...)</p>
                  <input
                    type="text"
                    id="specValueID"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className={classes.flexed_container}>
                <div className={classes.input_list_container}>
                  <p>Attribute (e.g. Dimensions, Weight,...)</p>
                  <select
                    name="specAttribute"
                    id="specAttributeID"
                    onChange={onChangeHandler}
                  >
                    <option value="weight">Weight</option>
                    <option value="dimensions">Dimensions</option>
                    <option value="capacity">Capacity</option>
                    <option value="display">Display</option>
                  </select>
                </div>
                <div className={classes.input_list_container}>
                  <p>Value (e.g. 7kg, 100L, 25x16in,...)</p>
                  <input
                    type="text"
                    id="specValueID"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className={classes.flexed_container}>
                <div className={classes.input_list_container}>
                  <p>Attribute (e.g. Dimensions, Weight,...)</p>
                  <select
                    name="specAttribute"
                    id="specAttributeID"
                    onChange={onChangeHandler}
                  >
                    <option value="weight">Weight</option>
                    <option value="dimensions">Dimensions</option>
                    <option value="capacity">Capacity</option>
                    <option value="display">Display</option>
                  </select>
                </div>
                <div className={classes.input_list_container}>
                  <p>Value (e.g. 7kg, 100L, 25x16in,...)</p>
                  <input
                    type="text"
                    id="specValueID"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
            </>
          )}

          <div className={classes.specifications}>
            {!showMoreSpecifications ? (
              <div
                onClick={onShowMoreSpecifications}
                style={{ backgroundColor: "#081c35" }}
                className={classes.showMoreHideMore}
              >
                MORE SPECIFICATIONS
              </div>
            ) : (
              <div
                onClick={onShowMoreSpecifications}
                className={classes.showMoreHideMore}
                style={{ backgroundColor: "#081c35" }}
              >
                HIDE MORE SPECIFICATIONS
              </div>
            )}
          </div>
          <br />
          <p>
            <b>Pictures</b>
            <span style={{ color: "red", fontWeight: "bold" }}>
              {" "}
              (Upload at least 2 pictures showing different angles of the
              product. Max size: 5MB, ratio 1:1. )
            </span>
          </p>
          <br />
          <div className={classes.uploads_container}>
            <label htmlFor="file1">
              <div className={classes.upload_list}>
                <i>
                  <BsImage />
                </i>

                {files.file1 !== "" && (
                  <div className={classes.previewed_images}>
                    <img src={files.file1} alt="" />
                  </div>
                )}
                <div id="fileLoader1" className="file_loader"></div>
              </div>
              <input
                type="file"
                accept="image/*"
                id="file1"
                style={{ display: "none" }}
                onChange={onImageChangeHandler1}
              />
            </label>

            <label htmlFor="file2">
              <div className={classes.upload_list}>
                <i>
                  <BsImage />
                </i>

                {files.file2 !== "" && (
                  <div className={classes.previewed_images}>
                    <img src={files.file2} alt="" />
                  </div>
                )}
                <div id="fileLoader2" className="file_loader"></div>
              </div>
              <input
                type="file"
                accept="image/*"
                id="file2"
                style={{ display: "none" }}
                onChange={onImageChangeHandler2}
              />
            </label>

            <label htmlFor="file3">
              <div className={classes.upload_list}>
                <i>
                  <BsImage />
                </i>

                {files.file3 !== "" && (
                  <div className={classes.previewed_images}>
                    <img src={files.file3} alt="" />
                  </div>
                )}
                <div id="fileLoader3" className="file_loader"></div>
              </div>
              <input
                type="file"
                accept="image/*"
                id="file3"
                style={{ display: "none" }}
                onChange={onImageChangeHandler3}
              />
            </label>

            <label htmlFor="file4">
              <div className={classes.upload_list}>
                <i>
                  <BsImage />
                </i>

                {files.file4 !== "" && (
                  <div className={classes.previewed_images}>
                    <img src={files.file4} alt="" />
                  </div>
                )}
                <div id="fileLoader4" className="file_loader"></div>
              </div>
              <input
                type="file"
                accept="image/*"
                id="file4"
                style={{ display: "none" }}
                onChange={onImageChangeHandler4}
              />
            </label>
          </div>
          <br />
          <br />
          <div className={classes.edit_pricing}>
            <div className={classes.flex_basis}>
              <div>
                <p>Product Price (Just figures, do not add currency)</p>
              </div>
              <input
                type="number"
                name="pricing"
                placeholder="150,000"
                value={enteredValue.pricing}
                onChange={onChangeHandler}
                required
              />
            </div>

            <div className={classes.flex_basis}>
              <div className={classes.flex_promoted_price}>
                <input type="checkbox" onChange={togglePromotionalHandler} />
                <p>Promotional Price</p>
              </div>
              <input
                type="number"
                name="promotionalPrice"
                placeholder=""
                value={enteredValue.promotionalPrice}
                disabled={promotionalPriceIsHidden}
                onChange={onChangeHandler}
                style={{ backgroundColor: "#D9D9D9" }}
              />
            </div>
          </div>

          <br />
          <div className={classes.edit_pricing}>
            <div className={classes.flex_basis}>
              <div>
                <div className={classes.flex_promoted_price}>
                  <input type="checkbox" onChange={toggleBulkPriceHandler} />
                  <p>Bulk Price</p>
                </div>
              </div>
              <input
                type="number"
                name="bulkPrice"
                placeholder="150,000"
                value={enteredValue.bulkPrice}
                onChange={onChangeHandler}
                disabled={hasBulkPrice}
                required
              />
            </div>

            <div className={classes.flex_basis}>
              <p>Range</p>
              <input
                type="number"
                name="range"
                placeholder="4"
                value={enteredValue.range}
                onChange={onChangeHandler}
                style={{ backgroundColor: "#D9D9D9", width: "200px" }}
              />
            </div>
          </div>

          <br />

          <div className={classes.footer_button}>
            <button>SAVE CHANGES</button>
            <div
              className={classes.showMoreHideMore}
              style={{ backgroundColor: "#FF0000" }}
            >
              DISCARD CHANGES
            </div>
            <div style={{ marginLeft: "10px" }}>
              {isSubmitting && <SmallSpinner />}
            </div>
          </div>
        </form>
      </div>
    </VendorMain>
  );
};

export default VendorEditProduct;
