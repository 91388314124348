import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import VendorShopAside from "./VendorShopAside";
import classes from "./VendorShopMain.module.css";
interface Props {
  children: JSX.Element | JSX.Element[];
}
const VendorShopMain: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Header />
      <main className={classes.main}>
        <aside className={classes.aside}>
          <VendorShopAside/>
        </aside>
        <article className={classes.article}>{children}</article>
      </main>
      <Footer />
    </>
  );
};

export default VendorShopMain;

