import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { BsChevronLeft, BsChevronRight, BsSearch } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import AdminPagination from "../../components/AdminPagination/AdminPagination";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import Spinner from "../../components/Spinner/Spinner";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import { Domain } from "../../Domain/Domain";
import { useAdminGeneralgetRequestQuery } from "../../store/services/admin";
import classes from "../../styles/AdminManageFindProducts.module.css";


const AdminManageFindProducts = (): JSX.Element => {
  const navigate = useNavigate();
  const [products, setProducts] = useState<any>([]);
  const [productMeta, setProductMeta] = useState<any>([]);
  const [filteringData, setFilteredState] = useState<{
    brand: string;
    category: string;
    productName: string;
    brandName: string;
    categoryName: string;
  }>({
    brand: "",
    category: "",
    productName: "",
    brandName: "All",
    categoryName: "All",
  });
  const [loading, setLoading] = useState<any>(false);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pagination, setPagination] = useState<any>(true);
  const onChangeHandler = (e: any) => {
    if (e.target.value === "")
      return setFilteredState((prevState) => {
        return {
          ...prevState,
          brand: "",
          category: "",
        };
      });

    let toArr: any = e.target.value !== "" ? e.target.value.split(",") : [];
    if (e.target.name === "brand" && e.target.value !== "")
      setFilteredState((prevState) => {
        return {
          ...prevState,
          brandName:
            e.target.name === "brand"
              ? e.target.children[toArr[1]].textContent
              : "",
        };
      });

    if (e.target.name === "category" && e.target.value !== "")
      setFilteredState((prevState) => {
        return {
          ...prevState,
          categoryName:
            e.target.name === "category"
              ? e.target.children[toArr[1]].textContent
              : "",
        };
      });

    setFilteredState((prevState) => {
      return {
        ...prevState,
        [e.target.name]: toArr[0],
      };
    });
  };

  // // FIrst load data
  const searchRef = useRef<HTMLDivElement>(null);
  useEffect(() => {

    //check if local storage item exists and pass pageNumber to it.
    //the value of this is reset in the homepage.tsx
    if(localStorage.getItem('SeeMorepage')){
      console.log("refreshedddddd", performance.navigation)
      const isPageRefreshed = performance.navigation.type == 1;
      
      setPageNumber(0)
      if(isPageRefreshed){
        //load from local storage
        const count = Number(localStorage.getItem('SeeMorepage'))
        setPageNumber(count)
      }else{
        setPageNumber(0)
        localStorage.setItem('SeeMorepage', '0')
      }
      
    }else{
    //set local storage
      localStorage.setItem('SeeMorepage', '0')
    }

    const handleOnUnload = () => {
      localStorage.removeItem("SeeMorepage");
    };

    window.addEventListener("unload", handleOnUnload);


    axios
      .get(Domain() + "/api/v1/product/new-arrivals")
      .then((response: any) => {
        if (response.data.status === 200) {
          setProducts(response.data.data);
          setProductMeta(response.data);
        }
      })
      .catch((error) => {});
  }, []);

  const { data: initBrandData = [] } =
    useAdminGeneralgetRequestQuery("/api/v1/brand/all");

  let brandData: any = [];
  if (initBrandData.data) brandData = initBrandData.data;

  const { data: initCategoryData = [] } = useAdminGeneralgetRequestQuery(
    "api/v1/category/all"
  );

  let categoryData: any = [];
  if (initCategoryData.data) categoryData = initCategoryData.data;

  const onSubmitHandler = () => {
    setPagination(true);
    setPageNumber(0)
    let url = `https://apiv2.electronicshop.com.ng/filter_products?${
      filteringData.productName !== ""
        ? "productName=" + filteringData.productName
        : ""
    }${filteringData.brand !== "" ? "&brand=" + filteringData.brand : ""}${
      filteringData.category !== "" ? "&category=" + filteringData.category : ""
    }`;

    axios
      .get(url)
      .then((response: any) => {
        console.log("RESponse: ", response);
        if (response.status === 200) {
          setProducts(response.data);
        }
      })
      .catch((error) => {});
  };

  const onDecreaseHandler = () => {
    if (pageNumber > 0) setPageNumber((prevState) => prevState - 1);
  };
  const onIncreaseHandler = () => setPageNumber((prevState) => prevState + 1);

  useEffect(() => {
    if (pageNumber) {
      setLoading(true);
      localStorage.setItem('SeeMorepage', `${pageNumber}`)

      var url 
      console.log(filteringData)

      if(pageNumber >=0 ){
        if(filteringData.productName == ""){
          url = `https://api.electronicshop.com.ng/api/v1/product/new-arrivals/more?page=${pageNumber}`;

          console.info(url);
          axios
            .get(url)
            .then((response: any) => {
              console.log("pagination: ", response);
              setLoading(false);
              console.log(response.data.data.products)
              if (response.status === 200){
                  
                  if((response.data.data.products).length ===0){
                    setPagination(false);
                  }else{
                    setProducts(response.data.data.products);
                  }
              }

            })
            .catch((error) => {
              setLoading(false);
              setPagination(false);
              setProducts(products);
              console.log("errorrrr: ", error);
            });

        }else{
          url = `https://apiv2.electronicshop.com.ng/loadmore_filter_products?page=${pageNumber}&${
            filteringData.productName !== ""
              ? "productName=" + filteringData.productName
              : ""
          }${filteringData.brand !== "" ? "&brand=" + filteringData.brand : ""}${
            filteringData.category !== ""
              ? "&category=" + filteringData.category
              : ""
          }`;

          console.info(url);
          axios
            .get(url)
            .then((response: any) => {
              console.log("pagination: ", response);
              setLoading(false);
              if (response.status === 200)
                setProducts(response.data);
                // setProducts(products.concat(response.data));
              if (response.data.includes("No more results")) {
                setPagination(false);
              }
            })
            .catch((error) => {
              setLoading(false);
              setPagination(false);
              setProducts(products);
              console.log("errorrrr: ", error);
            });

        }
      }else{
        setLoading(false);
        setPageNumber(0)
      }

    }
  }, [pageNumber]);

  useEffect(() => {
    console.log({
      productMeta,
    });
  }, [productMeta]);

  return (
    <AdminMain>
      <div>
        <p className={classes.ttitle}>Find Product</p>
        <br />
        <div className={classes.filter_section}>
          <select name="brand" id="" onChange={onChangeHandler}>
            <option value="">All Brands</option>
            {brandData.map((cur: any, index: any) => (
              <option key={cur.id} value={`${cur.id},${index + 1}`}>
                {cur.name}
              </option>
            ))}
          </select>
          <select name="category" id="" onChange={onChangeHandler}>
            <option value="">All Categories</option>
            {categoryData.map((cur: any, index: any) => (
              <option key={cur.id} value={`${cur.id},${index + 1}`}>
                {cur.name}
              </option>
            ))}
          </select>
        </div>

        <div className={classes.search_order_input}>
          <input
            type="text"
            name="productName"
            onChange={onChangeHandler}
            placeholder="Search for a product"
          />
          <div
            className={classes.search_icon}
            onClick={onSubmitHandler}
            ref={searchRef}
          >
            <i>
              <BsSearch />
            </i>
          </div>
        </div>
        <br />
        <div className={classes.results_container}>
          <h3>Results</h3>
          <p>
            for {filteringData.productName} <br />
            Brand: {filteringData.brandName}; Category:{" "}
            {filteringData.categoryName}
          </p>
        </div>
      </div>
      <br />
      {/* <div>{products.length < 1 && <SmallSpinner />}</div> */}
      <div className={classes.order_table}>
        <table className={classes.table_section}>
          <tbody>
            <tr className={classes.header_section}>
              <td>#</td>
              <td>Prodcut Name</td>
              <td>Brand</td>
              <td>Category</td>
              <td>Date</td>
            </tr>

            {products.length &&
              products.map((cur: any, index: number) => (
                <tr
                  key={index}
                  className={classes.lists_}
                  onClick={() => {
                    navigate("/admin-found-product?id=" + cur.id);
                  }}
                >
                  <td>{index + 1}</td>
                  <td>{cur.productName}</td>
                  <td>{cur?.brand_id?.name ?? cur?.brand}</td>
                  <td>{cur?.category_id?.name ?? cur?.category}</td>
                  <td style={{ fontSize: "12px" }}>
                    {new Date(cur.updatedAt as any).toDateString()}
                    {" : "}
                    {new Date(cur.updatedAt as any).toLocaleTimeString()}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <AdminPagination
        onDecreaseHandler={onDecreaseHandler}
        onIncreaseHandler={onIncreaseHandler}
        pageNumber={pageNumber + 1}
      />

      {/* {
        <button
          disabled={!pagination}
          className="btn btn-primary"
          style={{ margin: "16px 5px" }}
          onClick={() => !loading && setPageNumber(pageNumber - 1)}
        >
          {loading ? "Loading..." : "Previous"}
        </button>
      }
      {
        <button
          disabled={!pagination}
          className="btn btn-primary"
          style={{ margin: "16px 0px" }}
          onClick={() => !loading && setPageNumber(pageNumber + 1)}
        >
          {loading ? "Loading..." : "Next"}
        </button>
      } */}
      <br />
      <br />
    </AdminMain>
  );
};

export default AdminManageFindProducts;
