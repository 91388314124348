import axios from "axios";
import React, { useState, useEffect } from "react";
import { getClientToken } from "../components/cookie/getClientToken";
import { Domain2 } from "../Domain/Domain";
import useGetVendorCartDetailsHook from "./useGetVendorCartDetailsHook";
import useGetDefaultAddress from "../Hooks/useGetDefaultAddress";
import { useNavigate } from "react-router-dom";
import { getClientData } from "../components/cookie/getClientData";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../store/slices/alertSlice";

const useVendorCartCheckoutHook = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState<boolean>(false);
  const [message, setMessage] = useState({ error: "", success: "" });
  const { defaultAddress } = useGetDefaultAddress();
  const cart = useGetVendorCartDetailsHook();
  const navigate = useNavigate();

  const onSuccess = (reference: any) => {
    // console.log("====>", reference);
    console.log(reference);
    setLoader(true);
    let cartList = cart.cart;

    console.log("=====>", getClientData().email);

    let orderData = {
      payment_ref: reference.reference,
      delivery_address: defaultAddress,
      phone_number: getClientData().phoneNumber,
      order_details: cartList.map((cur: any) => {
        const productData = {
            id: cur.id,
            productName: cur.productName,
            quantity: cur.quantity,
        }

        delete cur.added_by;
        delete cur.id;
        delete cur.images;
        delete cur.productName;
        delete cur.priceing;
        delete cur.quantity

        return productData
      }),
    };
    console.log(orderData);

    const token = getClientToken()

    // return console.log(orderData)
    // console.log(orderData);
    axios
      .post(`${Domain2}/orders/vendors/create`, JSON.stringify(orderData), {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }) 

      .then((response: any) => {
        setLoader(false);
        // console.log(response.data);
        if (response.data.message) {
            localStorage.removeItem('vendor-cart')
            dispatch(
                toggleAlertAction({
                    isAlerting: true,
                    message: "Your Cart Has Successfully been checked out",
                })
           );
          navigate("/my-vendor-cart-order-list");
        } 
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response.data.data);
        setLoader(false);
        // console.log(error);
        // alert(error?.response?.data?.data);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: error?.response?.data?.data,
            status: "error",
          })
        );
      });
  };
  return { loader, onSuccess };
};

export default useVendorCartCheckoutHook;
