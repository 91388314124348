import axios from "axios";
import React, { useState, useEffect } from "react";
import Footer from "../components/UI/Footer/Footer";
import Header from "../components/UI/Header/Header";
import classes from "../components/../styles/ProductPageStyles.module.css";
import { BsCart4, BsStar, BsStarFill } from "react-icons/bs";
import ProductsAds from "../components/ProductAds/ProductsAds";
import ProductItem from "../components/ProductItem/ProductItem";
import MarketPlaceProduct from "../components/MarketPlaceProduct/MarketPlaceProduct";
import { Link, useParams } from "react-router-dom";
import { Domain } from "../Domain/Domain";
import Spinner from "../components/Spinner/Spinner";
import useAddToCartHook from "../Hooks/useAddToCartHook";
import { getClientToken } from "../components/cookie/getClientToken";
import ReviewList from "../components/ReviewProduct/ReviewList";
import StarRating from "../components/UI/StarRating";
import { useDispatch, useSelector } from "react-redux";
import {
  getImagesFromAd,
  getPromotionData,
  Promo,
} from "../utils/promotionHelpers";
import { Ad } from "./Admin/AdminManagePromotions";
import { addPromotionData } from "../store/slices/promotionsSlice";
const ProductPage = () => {
  interface SeoData {
    title: string,
    description: string
  }

  const params = useParams();
  const dispatch = useDispatch();

  const promotionSelector = useSelector((value: any) => value.promotion);
  const [promotions, setPromotions] = useState<Ad[]>([]);
  const [productPageMidAdData, setProductPageMidAdData] = useState<Promo[]>([]);
  const [productPageMidAdImages, setProductPageMidAdImages] = useState<
    { url: string }[]
  >([]);

  const [product, setProduct] = useState<any>({});
  let [images, setImages] = useState([]);
  let [specification, setSpecification] = useState([]);
  let [colors, setColors] = useState([]);
  const [largeImage, setLargeImage] = useState<string>("");
  const [products, setProducts] = useState<any>([]);
  const [reviews, setReviews] = useState<any>([]);
  const [averageRating, setAverageRating] = useState<number>(0);
  const [relatedProduct, setRelatedProduct] = useState<any>([]);
  const [seoData, setSeoData] = useState<SeoData | null>(null); 

  // Add to cart handler 
  const { addToCart } = useAddToCartHook(product);

  const getAverageRating = (ratings: [number]) => {
    const avg =
      ratings.length > 0
        ? ratings.reduce((partialSum, number) => partialSum + number, 0) /
          ratings.length
        : 0;

    return Math.round(avg);
  };

  // Scroll top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

useEffect(() => {
    const pageTitle = seoData?.title ?? 'Electronic Shop';
    document.title = pageTitle;

    const metaDescriptionElement: HTMLMetaElement | null = document.querySelector('meta[name="description"]');
    if (metaDescriptionElement) {
      const metaDescriptionContent = seoData?.description ?? 'Electronic Shop';
      metaDescriptionElement.content = metaDescriptionContent;
    } else {
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.name = 'description';
      newMetaDescription.content = seoData?.description ?? 'Electronic Shop';
      document.head.appendChild(newMetaDescription);
    }

    console.log('Updated Page Title:', pageTitle);
    console.log('Updated Meta Description:', metaDescriptionElement?.content);

   
    return () => {
      document.title = 'Electronic Shop';

      if (metaDescriptionElement) {
        metaDescriptionElement.content = 'Electronic Shop';
      }
    };
  }, [seoData?.title, seoData?.description]); 

  useEffect(() => {
    let url = new URLSearchParams(window.location.search);

    axios
      .get(Domain() + "/api/v1/product/" + url.get("id"))
      .then((response: any) => {
        console.log("PRODUCT RESPONSE ===>", response);
        setSeoData(response.data?.data!.seo_data)
        setProduct(response.data?.data!.products);
      })
      .catch((error) => {
        // console.log(error);
      });

    // fetch Reviews

    axios
      .get(Domain() + "/api/v1/review/all/" + url.get("id"), {
        headers: {
          Authorization: getClientToken(),
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      })
      .then((response: any) => {
        console.log(response);
        if (response.data.status === 200) {
          setReviews(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //
  useEffect(() => {
    {
      axios
        .get(Domain() + "/api/v1/product/all")
        .then((response: any) => {
          // console.log(response);
          if (response.data.status === 200) setProducts(response.data.data);
        })
        .catch((error) => {});
    }
  }, []);

  useEffect(() => {
    axios
      .get(
        Domain() +
          `/api/v1/product/related-product/${product?.brand!}/${product?.category!}`
      )
      .then((response: any) => {
        console.log("::::::======>", response);
        if (response.data.status === 200) setRelatedProduct(response.data.data);
      })
      .catch((error) => {});
  }, [product?.category! as any]);

  useEffect(() => {
    let imageArr = product?.pictures!;
    if (imageArr !== undefined) {
      setImages(JSON.parse(imageArr));
      setLargeImage(JSON.parse(imageArr)[0]);
    }

    let specArray = product?.specification!;
    if (specArray !== undefined) setSpecification(JSON.parse(specArray));

    let colorArray = product?.colours!;
    if (colorArray !== undefined) setColors(JSON.parse(colorArray));
  }, [product.pictures]);

  useEffect(() => {
    const reviewRatings = reviews.map((review: any) => {
      // console.log({ review });

      return review.ratings;
    });

    // console.log({
    //   reviewRatings,
    // });

    setAverageRating(getAverageRating(reviewRatings));
  }, [reviews]);

  useEffect(() => {
    // console.log({
    //   averageRating,
    // });
  }, [averageRating]);

  // console.log("==============>", largeImage);

  const onSelectImageHandler = (e: any) => {
    setLargeImage(e.target.src);
  };

  useEffect(() => {
    setPromotions(promotionSelector.promotionsArray);
    console.log({
      promotions: promotionSelector.promotionsArray,
    });

    // if (promotionSelector.promotionsArray.length > 0) {
    getPromotionData(3, "Product Page Middle Ad").then((res) => {
      dispatch(addPromotionData(res));

      setProductPageMidAdData(res.data);
      setProductPageMidAdImages(res.images);
    });
    // }
  }, [promotionSelector.promotionsArray]);

  return (
    <>
      <Header />
      <article className={classes.article}>
        <div>
          <h4 style={{ cursor: "pointer" }}>
            <Link to={"/"}>Store</Link> {">"} {product.brand_id?.name!} {">"}{" "}
            {product.category_id?.name!} {" > "}
            <span style={{ color: "#F14306" }}>{product?.productName!}</span>
          </h4>
        </div>
        <br />

        <div className={classes.first_details_section}>
          <div className={classes.large_image_preview}>
            <img src={largeImage} alt="" />
          </div>

          <div className={classes.right_detail_preview}>
            <h3>
              {" "}
              {/* {product.brand_id?.name!}  */}
              {product?.productName!}
            </h3>
            <p className={classes.brand_name} style={{ color: "black" }}>
              Brand: {product.brand_id?.name!}
            </p>

            {/* Ratings */}
            <div className={classes.rating}>
              <StarRating averageRating={averageRating} />
              <span>{reviews?.length > 0 ? reviews?.length : 0} </span>
            </div>
            {/* end of ratings */}

            <h1 style={{ color: "#F14306" }}>
              ₦ {product.promotionalPrice?.toLocaleString!()}{" "}
              <small style={{ color: "black" }}>
                <del>₦ {product.pricing?.toLocaleString!()}</del>
              </small>
            </h1>
            <div className={classes.three_small_images_prev}>
              {images.map((cur, index) => (
                <span key={index}>
                  {cur !== "" && (
                    <img onClick={onSelectImageHandler} src={cur} alt="" />
                  )}
                </span>
              ))}
            </div>
            <div className={classes.action_button}>
              <button onClick={addToCart} id="cartButton">
                <i>
                  <BsCart4 />
                </i>
                ADD TO CART
              </button>
            </div>
          </div>
        </div>

        <br />
        <div className={classes.detail_section_div}>
          <div className={classes.descritpion_section}>
            <h4>Description:</h4>
            <br />
            <p style={{ whiteSpace: 'pre-line' }}>{product?.productDescription}</p>

            <section className={classes.specifications}>
              <br />
              <h3>Specifications</h3>
              <table>
                <tbody>
                  {specification.map((cur: any, index: number) => (
                    <tr key={index}>
                      {cur.attribute !== "" && cur.value !== "" && (
                        <>
                          <td>{cur.attribute}</td>
                          <td>{cur.value}</td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>
          </div>
          <div className={classes.revires_container}>
            <h3>Reviews</h3>
            <br />
            <ReviewList reviews={reviews} />
          </div>
        </div>

        <br />
        <ProductsAds images={productPageMidAdImages} 
          style={{
            width:'100%',
          }}
          
          />
        <br />

        {/* <div style={{ backgroundColor: "#F14306", padding: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <h1 style={{ color: "white" }}>Market Place</h1>
            <p style={{ color: "white" }}>Go to Marketplace</p>
          </div>
          <MarketPlaceProduct products={[]} />
        </div>
        <br /> */}

        <div>
          <h3>Related product</h3>
        </div>
        <ProductItem data={relatedProduct} />
        {relatedProduct.length < 1 && <Spinner />}
      </article>
      <Footer />
    </>
  );
};

export default ProductPage;
