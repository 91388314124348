import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminPagination from "../../components/AdminPagination/AdminPagination";
import { getCookie } from "../../components/cookie/getCookie";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import { Domain } from "../../Domain/Domain";
import classes from "../../styles/AdminManageFindProducts.module.css";

export const OrdersTable = ({ fetchedData }: { fetchedData: any }) => {
  const navigate = useNavigate();

  return (
    <div className={classes.order_table}>
      <table className={classes.table_section}>
        <tbody>
          <tr className={classes.header_section}>
            <td>#</td>
            <td>Full name</td>
            <td>First Payment</td>
            <td>Installment Option</td>
            <td>Balance</td>
            <td>Total</td>
            <td>Status</td>
            <td>Date</td>
          </tr>

          {fetchedData.map((cur: any, index: number) => (
            <tr
              key={index}
              className={classes.lists_}
              onClick={() => {
                navigate(
                  "/admin-pay-small-small-detail?id=" + cur?.order_id!
                );
              }}
            >
              <td>{index + 1}</td>
              <td>
                {cur?.firstName!} {cur?.surname!}
              </td>
              <td>₦{cur?.firstPayment!}</td>
              <td style={{ width: "70px" }}>
                {cur?.installmentOption!}
              </td>
              <td> ₦{cur?.total - cur?.firstPayment} </td>
              <td>₦{cur?.total!.toLocaleString()}</td>
              <td>
                {cur?.order_status === 1 && "Successful and pending"}
                {cur?.order_status === 2 && "Available for pickup"}
                {cur?.order_status === 3 && "Delivered and completed"}
                {cur?.order_status === 4 && "Suspicious/Canceled"}
              </td>
              <td style={{ fontSize: "12px" }}>
                {new Date(cur?.updatedAt as any).toDateString()}
                {" : "}
                {new Date(cur?.updatedAt as any).toLocaleTimeString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};


const AdminPaySmallSmallPage = () => {
  const navigate = useNavigate();

  const [fetchedData, setFetchedData] = useState<any>([]);
  const [arrayIsEmpty, setArrayIsEmpty] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [currentStatus, setCurrentStatus] = useState<number | null>(null); // Change type to number | null

  useEffect(() => {
    const url = new URLSearchParams(window.location.search);
    const status = parseInt(url.get("status") || "1", 10); // Parse the status from URL, default to 1 if not provided
    setCurrentStatus(status);
  }, [window.location.search]);

  useEffect(() => {
    if (currentStatus !== null) { // Check if currentStatus is set
      fetch(
        `${Domain()}/api/v1/admin/order/pay-small-small/fetch-status/more/${currentStatus}?page=${pageNumber}`,
        {
          method: "GET",
          headers: {
            Authorization: getCookie(),
            "Content-Type": "application/json",
          }, 
        }
      )
        .then((res) => res.json())
        .then((response) => {
          console.log(response.data)
          if (response.status == 200) {
            const data = response.data
            console.log(data);
            setFetchedData(data.orders || []); // Set fetchedData to orders array if available, otherwise to an empty array
            setArrayIsEmpty(data.orders?.length === 0); // Update arrayIsEmpty based on the length of orders array
          } else{
            console.log("error")
          }
        })
        .catch((error) => {
          setArrayIsEmpty(true);
        });
    }
  }, [currentStatus, pageNumber]);

  const onDecreaseHandler = () => {
    if (pageNumber > 0) setPageNumber((prevPageNumber) => prevPageNumber - 1);
  };

  const onIncreaseHandler = () => setPageNumber((prevPageNumber) => prevPageNumber + 1);

  return (
    <AdminMain>
      <>
        <h1>{arrayIsEmpty ? "No items found" : ""}</h1>
        <div>
          {fetchedData.length === 0 ? (
            <div style={{ margin: "10px 0px" }}>
              <SmallSpinner />
            </div>
          ) : (
            <>
              <h1>Pay Small Small</h1>
              <br />
              <h3>
                {currentStatus === 1 && "Successful and pending"}
                {currentStatus === 2 && "Available for pickup"}
                {currentStatus === 3 && "Delivered and completed"}
              </h3>
              <br />
              <OrdersTable fetchedData={fetchedData} />
            </>
          )}
        </div>
        <AdminPagination
          onDecreaseHandler={onDecreaseHandler}
          onIncreaseHandler={onIncreaseHandler}
          pageNumber={pageNumber + 1}
        />
      </>
    </AdminMain>
  );
};

export default AdminPaySmallSmallPage;
