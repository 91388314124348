import React from "react";
import { orderData } from "../../data/OrderData";
import AdminMain from "../UI/AdminMain/AdminMain";
import classes from "./ManageAdmin.module.css";
const ManageAdminList = (): JSX.Element => {
  return (
    <div className={classes.order_table}>
      <table className={classes.table_section}>
        <tr className={classes.header_section}>
          <td>#</td>
          <td>
            <input type="checkbox" />
          </td>
          <td>Email</td>
          <td>Admin Type</td>
          <td>Email</td>
          <td>Last Seen</td>
        </tr>
        {orderData.splice(0, 5).map((cur: any, index: number) => (
          <tr key={cur.id} className={classes.lists_}>
            <td>{index + 1}</td>
            <td>
              <input type="checkbox" />
            </td>
            <td>{cur.email}</td>
            <td>{cur.adminType}</td>
            <td>{cur.email}</td>
            <td>{cur.dateAndTime}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default ManageAdminList;
