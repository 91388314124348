import React from "react";
import MarketPlaceProductItem from "./MarketPlaceProductItem";
import { marketplaceData } from "../../data/marketplaceData";
import classes from "./MarketPlaceProduct.module.css";
interface Props {
  products: any;
  linkRoute?: string;
}
const MarketPlaceProduct: React.FC<Props> = ({ products = [], linkRoute }) => { 
  return (
    <div className={classes.product_container}>
      {products.map((cur: any) => (
        <MarketPlaceProductItem 
          amount={cur.pricing}
          productName={cur.brand_id?.name! + "  " +cur.productName}
          description={cur.productDescription}
          image={cur.pictures}
          location={"Port Harcourt"} 
          oldAmount={cur.promotionalPrice}
          rating={cur.rating}
          key={cur.id}
          id={cur.id}
          // vendorId={cur.vendorId}
          linkRoute={linkRoute}
          range={cur.range}
        />
      ))}
    </div>
  );
};

export default MarketPlaceProduct;
