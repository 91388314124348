import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import Main from "../../components/UI/Main/Main";
import { Domain } from "../../Domain/Domain";
import classes from "../../styles/Register.module.css";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../../store/slices/alertSlice";
const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRequestingCode, setIsRequestingCode] = useState<boolean>(false);

  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [enteredData, setEnteredData] = useState<{
    code: string;
    newPassword: string;
    confirmNewPassword: string;
  }>({ code: "", confirmNewPassword: "", newPassword: "" });

  const emailURL = new URLSearchParams(window.location.search);
  let email = emailURL.get("email");

  function codeRequest() {
    setIsRequestingCode(true);
    fetch(Domain() + "/api/v1/auth/forgot-password", {
      method: "POST",
      body: JSON.stringify({
        email,
      }),
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        setIsRequestingCode(false);
        // alert(response.message)
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: response.message,
            status: "",
          })
        );
      })
      .catch((error) => {
        console.log(error);
        setIsRequestingCode(false);
      });
  }

  useEffect(() => {
    codeRequest();
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (enteredData.newPassword !== enteredData.confirmNewPassword)
      return setError("Password did not match");
    setIsLoading(true);

    fetch(Domain() + "/api/v1/auth/reset-password", {
      method: "POST",
      body: JSON.stringify({
        token: enteredData.code,
        email: email,
        password: enteredData.newPassword,
      }),
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        if (response.status === 200) {
          setSuccessMessage(response.message);
          setError("");
          navigate("/login");
        }
        if (response.status === 400) {
          setError("Wrong code please check your email and try again");
          setSuccessMessage("");
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  console.log("========>", isLoading);
  return (
    <Main>
      <form onSubmit={onSubmitHandler} autoComplete={"off"}>
        <div className={classes.forms_container}>
          <div className={classes.title_container}>
            <h2>Reset Password</h2>
          </div>
          <div className={classes.form_notes}>
            <p>
              A verification code has been sent to your email:
              <span style={{ color: "#F14306" }}> {email}</span>. Enter the
              verification code and new password to set it up.
            </p>
          </div>
          <div className={classes.form_container_div}>
            {/* <p>RESEND CODE. (wait for 59s)</p> */}
          </div>
          <div>
            {error !== "" && (
              <p
                style={{ color: "red", textAlign: "center", marginTop: "10px" }}
              >
                {error}
              </p>
            )}
          </div>
          <div>
            <p className={classes.success_message}>{successMessage}</p>
          </div>
          <div className={classes.form_container_div}>
            <div className={classes.input_div_container}>
              <input
                type="text"
                onChange={onChangeHandler}
                placeholder="Verification code"
                name="code"
                value={enteredData.code}
                required
              />
            </div>
          </div>
          <div className={classes.resend_conde}>
            {!isRequestingCode ? (
              <div onClick={codeRequest}>Resend code</div>
            ) : (
              <div>Please wait</div>
            )}
          </div>
          <div className={classes.form_container_div}>
            <div className={classes.input_div_container}>
              <input
                type="text"
                onChange={onChangeHandler}
                placeholder="New Password"
                name="newPassword"
                value={enteredData.newPassword}
                required
              />
            </div>
          </div>
          <div className={classes.form_container_div}>
            <div className={classes.input_div_container}>
              <input
                type="text"
                onChange={onChangeHandler}
                placeholder="Confirm New Password"
                name="confirmNewPassword"
                value={enteredData.confirmNewPassword}
                required
              />
            </div>
          </div>

          <div className={classes.button_container}>
            <button>{!isLoading ? "SUBMIT" : "Loading..."}</button>
          </div>
          {isLoading && (
            <div className={classes.login_spinner}>
              <SmallSpinner />
            </div>
          )}

          <br />
          {/* <ProductsAds /> */}
        </div>
      </form>
    </Main>
  );
};

export default ResetPassword;
