import React, { useState } from "react";
import AdminPagination from "../../components/AdminPagination/AdminPagination";
import AdminSearch from "../../components/AdminSearch/AdminSearch";
import ManageTechnicians from "../../components/TechnicalHubComponents/ManageTechnicians";
import TechnicalHubAll from "../../components/TechnicalHubComponents/TechnicalHubAll";
import TechnicalReports from "../../components/TechnicalHubComponents/TechnicalReports";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import classes from "../../styles/AdminTechnicalHub.module.css";
import AdminUser from "../../components/User/AdminUser";

const AdminUserSearch = (): JSX.Element => {
  const [currentNav, setCurrentNav] = useState({
    all: false,
    techIsActive: true,
    reportIsActive: false,
  });



  return (
    <AdminMain>
      <div>
        <h3 className={classes.title}>Search Users</h3>
        {/* <AdminSearch /> */}
        <br />
        <AdminUser />
      </div>
    </AdminMain>
  );
};

export default AdminUserSearch;
