import React, { useState } from 'react';
import './VendorOrderDetails.css';
import OrderItem from '../../components/Order/OrderItem';
import DeliveryAddress from '../../components/DeliveryAddress/DeliveryAddress';
import { BsChevronLeft } from 'react-icons/bs';
import PaymentMethod from '../../components/PaymentMethod/PaymentMethod';

const VendorOrderDetails = ({ isOpen, onClose, order }) => {
  if (!isOpen) return null;

  const createdAtDate = new Date(order.createdAt);
  const createdAtFormatted = createdAtDate.toLocaleString(); 

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Order Details</h2>
        <div className="vod-container">
          <h2>{order?.order_id}</h2>
          <p><b>Customer:</b> {order?.fullname}</p>
          <p><b>Phone Number:</b> {order?.phone_number}</p>
          <p><b>Total Purcahase:</b> ₦ {order?.total_purchase?.toLocaleString()}</p>
          <p><b>Ordered on:</b> {createdAtFormatted}</p>
          <p><b>Order Status:</b> {order?.order_status ? <button className="btn btn-primary">Confirmed</button> : <button className="btn btn-secondary">Not Confirmed</button>}</p>
        </div>
        <h4>
          Items ({order?.order_details && JSON.parse(order?.order_details)?.length})
        </h4>
        <>
        {order &&
          (order?.order_details ? JSON.parse(order?.order_details) : [])?.map((cur, index) => (
            <OrderItem
              key={index}
              productId={cur.id}
              qty={cur.quantity}
              hasReview={false}
            />
          ))
        } 
        </>
        <br />
        <DeliveryAddress
          address={order?.delivery_address}
        /><br />
        <PaymentMethod />
        <br /><br />
        <button className='vod-back-btn' onClick={onClose}>
          <i><BsChevronLeft /></i> Back</button>
      </div>
    </div>
  );
};

export default VendorOrderDetails;
