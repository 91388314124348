import AdminMain from "../../components/UI/AdminMain/AdminMain";
import Messages from "../../components/MessageCenter/Messages";

import { useState, useEffect } from "react";
import SupportTicketCard from "../../components/MessageCenter/SupportTicketCard";
import SupportTicketCardMessages from "../../components/MessageCenter/SupportTicketCardMessages";
import { getCookie } from "../../components/cookie/getCookie";
import { SupportTicketStatusString } from "../../components/MessageCenter";
import TabComponent, { Tab } from "../../components/TabComponent";
import Pagination from "../../components/Pagination/Pagination";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../../store/slices/alertSlice";
import { useNavigate } from "react-router-dom";

const AdminMessages = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<any>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [activeMessagesTab, setActiveMessagesTab] =
    useState<SupportStatusCode>(1);



  const [messagesByStatus, setMessagesByStatus] = useState<
    {
      status: SupportStatus;
      code: SupportStatusCode;
      messages: SupportTicket[];
    }[]
  >([
    {
      code: 1,
      status: SupportTicketStatusString[1],
      messages: [],
    },
    {
      code: 2,
      status: SupportTicketStatusString[2],
      messages: [],
    },
    {
      code: 3,
      status: SupportTicketStatusString[3],
      messages: [],
    },
  ]);

  const getMessagesByStatus = () => {
    setLoading(true);

    fetch(
      // `https://apiv2.electronicshop.com.ng/admin-get-messages/${activeMessagesTab}`,
      `https://apiv2.electronicshop.com.ng/loadmore-admin-get-messages/status/${activeMessagesTab}/page/${currentPage}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + getCookie(),
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        setLoading(false);
        console.log({res});
        console.log("hello");
        return res.json();
      })
      .then((response: SupportTicket[]) => {
        console.log({ response });
        setMessagesByStatus((prevState) => {
          const messageExists = prevState.some(
            (message) => message.code === activeMessagesTab
          );
          const newState = messageExists
            ? prevState.map((message) => {
                if (message.code === activeMessagesTab) {
                  return {
                    ...message,
                    messages: response,
                  };
                }
                return message;
              })
            : [
                ...prevState,
                {
                  status: SupportTicketStatusString[
                    activeMessagesTab
                  ] as SupportStatus,
                  code: activeMessagesTab,
                  messages: response,
                },
              ];

          console.log({
            prevState,
            messageExists,
            newState,
          });

          return newState;
        });
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const handleUpdateActiveTab = (tab: number) => {
    setActiveMessagesTab(tab as SupportStatusCode);
  };

  const handleUpdateReply = (ticket: SupportTicket, message: string) => {
    console.log({ ticket });
  };

  const handleUpdateStatus = (
    ticket: SupportTicket,
    status: SupportStatusCode
  ) => {
    console.log({ ticket, status });

    fetch(
      `https://apiv2.electronicshop.com.ng/update-message-status/status/${status}/ticketId/${ticket.ticket_id}`,
      {
        headers: {
          Authorization: "Bearer " + getCookie(),
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        console.log({ response });
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: "Status updated successfully",
            status: "",
          })
        );
      })
      .catch((error) => {
        console.log({ error });
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: "Error updating status",
            status: "error",
          })
        );
      })
      .finally(() => {
        getMessagesByStatus();
      });
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // const tabs: Tab[] = messagesByStatus.map((message) => ({
    const tabs: Tab[] = messagesByStatus.map((message) => ({
    id: message.code as number,
    title: message.status as string,
      content: (
        <>
          <ul>
            {message.messages.length > 0 ? (
              message.messages.map((message, i) => (
                <SupportTicketCardMessages
                  key={message.ticket_id + i}
                  updateReply={handleUpdateReply}
                  updateStatus={handleUpdateStatus}
                  ticket={message}
                  isAdmin
                />
              ))
            ) : (
              <p>No messages</p>
            )}
          </ul>
          {/* add pagination */}
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            updateCurrentPage={handlePageChange}
          />
        </>
      ),
    }));

  useEffect(() => {
    getMessagesByStatus();
  }, [activeMessagesTab, currentPage]);

  return (
    <AdminMain>
      <div>
        <h1>Support Center</h1>
        <br />
        <TabComponent
          tabs={tabs}
          defaultTab={1}
          updateActiveTab={handleUpdateActiveTab}
          loading={loading}
        />
        <br />
        <br />
        <br />
        {/* <Messages isAdmin /> */}
      </div>
    </AdminMain>
  );
};

export default AdminMessages;
