import React, { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsCheck } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { Domain } from "../../Domain/Domain";
import { toggleLoaderAction } from "../../store/slices/LoaderSlice";
import { getCookie } from "../cookie/getCookie";
import SmallSpinner from "../Spinner/SmallSpinner";
import Spinner from "../Spinner/Spinner";
import { toggleAlertAction } from "../../store/slices/alertSlice";

interface Props {
  answer: string;
  data: any;
}
const FAQAnswerListItem: React.FC<Props> = ({ answer, data: cur }) => {
  const [toggle, setToggler] = useState<boolean>(false);
  const [isEditable, setEditable] = useState<boolean>(false);
  const ParaRef = useRef<HTMLParagraphElement>(null);
  const dispatch = useDispatch();
  const onToggleHandler = () => {
    if (isEditable === true) return;
    setToggler(!toggle);
  };

  const onEditableHandler = () => {
    setEditable(!isEditable);
  };

  useEffect(() => {
    ParaRef.current?.focus();
  }, [isEditable]);

  const onSubmitHandler = () => {
    console.log(cur);
    let value = ParaRef.current?.textContent;
    if ((value?.length as any) < 1) {
      // return alert("Question can not be empty");
      dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "Question can not be empty",
          status: "error",
        })
      );
    }
    dispatch(toggleLoaderAction(true));
    fetch(Domain() + "/api/v1/admin/faq-question/" + cur.id, {
      method: "PATCH",
      body: JSON.stringify({
        question: cur.question,
        category: cur.category, //TODO
        answer: ParaRef.current?.textContent,
      }),
      headers: {
        Authorization: getCookie(),
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        // alert(data.message);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: data.message,
            status: "",
          })
        );

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        // alert("An error occured, please try again later");
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: "An error occured, please try again later",
            status: "error",
          })
        );

        console.log(error);
      });
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <p
        style={{
          border: isEditable ? "3px solid gray" : "",
        }}
        ref={ParaRef}
        contentEditable={isEditable}
        suppressContentEditableWarning={true}
      >
        {answer}{" "}
      </p>
      {window.location.href.includes("admin") && (
        <div>
          {isEditable === false && (
            <>
              <i onClick={onEditableHandler}>
                <FiEdit />
              </i>
            </>
          )}
          {isEditable && (
            <>
              <i onClick={onEditableHandler}>
                <AiOutlineClose style={{ color: "red" }} />
              </i>

              <i onClick={onSubmitHandler}>
                <BsCheck style={{ color: "green" }} />
              </i>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default FAQAnswerListItem;
