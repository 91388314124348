import React, { useState } from 'react';
import './MyVendorOrderDetails.css';
import OrderItem from '../../components/Order/OrderItem';
import DeliveryAddress from '../../components/DeliveryAddress/DeliveryAddress';
import { BsChevronLeft } from 'react-icons/bs';
import PaymentMethod from '../../components/PaymentMethod/PaymentMethod';
import { getClientToken } from "../../components/cookie/getClientToken";
import { useNavigate } from "react-router-dom";
import { Domain2 } from "../../Domain/Domain";

const MyVendorOrderDetails = ({ isOpen, onClose, order }) => {
  const [orderConfirmed, setOrderConfirmed] = useState(false)

  let vendorDetails
  if (order && order.vendor_details) {
    vendorDetails = JSON.parse(order.vendor_details);
  }

  const createdAtDate = new Date(order?.createdAt);
  const createdAtFormatted = createdAtDate.toLocaleString(); 
  const token = getClientToken()

  const navigate = useNavigate()

  const confirmOrder = () => {
    fetch(`${Domain2}/orders/vendors/confirm?order_id=` + order?.order_id, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        }
    })
        .then((response) => {
            console.log("Order confirmed:", response.data);
            setOrderConfirmed(true); 
            alert('Your order has been confirmed as successfully delivered')
            window.location.reload()
        })
        .catch((error) => {
            console.error("Error confirming order:", error);
        });
  };

  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Order Details</h2>
        <div className="vod-container">
          <h2>{order?.order_id}</h2>
          <p><b>Customer:</b> {order?.fullname}</p>
          <p><b>Phone Number:</b> {order?.phone_number}</p>
          <p><b>Total Purcahase:</b> ₦ {order?.total_purchase?.toLocaleString()}</p>
          <p><b>Ordered on:</b> {createdAtFormatted}</p>
          <p><b>Order Status:</b> {order?.order_status ? <button className="btn btn-primary">Confirmed</button> : <button className="btn btn-secondary">Not Confirmed</button>}</p>
          <div>
            {order?.order_status? (
            <p></p>
            ) : (       
            
            <div>
              <span>If you have recieved your order,then you can confirm it</span><br />
              <button className="btn btn-success" onClick={confirmOrder}>
                Confirm Order
              </button>
            </div>
            )}
        </div>
        </div>
        <h4>
          Items ({order?.order_details && JSON.parse(order?.order_details)?.length})
        </h4>
        <>
        {order &&
          (order?.order_details ? JSON.parse(order?.order_details) : [])?.map((cur, index) => (
            <OrderItem
              key={index}
              productId={cur.id}
              qty={cur.quantity}
              hasReview={false}
            />
          ))
        } 
        </><br /><br />
        <div className="vod-container">
        {vendorDetails && (        
            <div>
              <h3>Vendor Details:</h3>
              <p>Shop Name: {vendorDetails?.shopName || "N/A"}</p>
              <p>Phone Number: {vendorDetails?.phoneNumber || "N/A"}</p>
              <p>Address: {vendorDetails?.address || "N/A"}</p>
            </div>         
        )}
        </div>
        
        <br />
        <DeliveryAddress
          address={order?.delivery_address}
        /><br />
        <PaymentMethod />
        <br /><br />
        <button className='vod-back-btn' onClick={onClose}>
          <i><BsChevronLeft /></i> Back</button>
      </div>
    </div>
  );
};

export default MyVendorOrderDetails;
