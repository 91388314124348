import React, { useEffect, useRef, useState } from "react";
import FAQLIST from "./FAQLIST";
import classes from "../../styles/AdminFAQ.module.css";
import { FiEdit } from "react-icons/fi";
import { BsCheck, BsTrash } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { Domain } from "../../Domain/Domain";
import { getCookie } from "../cookie/getCookie";
import { useDispatch } from "react-redux";
import { toggleLoaderAction } from "../../store/slices/LoaderSlice";
import { toggleAlertAction } from "../../store/slices/alertSlice";
interface Props {
  data: any;
  numbering: number;
}
const FAQCategory: React.FC<Props> = ({ data: cur, numbering }) => {
  const [toggle, setToggler] = useState<boolean>(false);
  const [isEditable, setEditable] = useState<boolean>(false);
  const ParaRef = useRef<HTMLParagraphElement>(null);
  const dispatch = useDispatch();
  const onToggleHandler = () => {
    if (isEditable === true) return;
    setToggler(!toggle);
  };

  const onEditableHandler = () => {
    setEditable(!isEditable);
  };

  useEffect(() => {
    ParaRef.current?.focus();
  }, [isEditable]);

  const onSubmitHandler = () => {
    let value = ParaRef.current?.textContent;
    if ((value?.length as any) < 1) {
      // return alert("Category can not be empty");
      dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "Category can not be empty",
          status: "error",
        })
      );
    }
    dispatch(toggleLoaderAction(true));
    fetch(Domain() + "/api/v1/admin/faq-category/" + cur.id, {
      method: "PATCH",
      body: JSON.stringify({
        categoryName: ParaRef.current?.textContent,
        categoryDescription: "category updated on" + new Date().toISOString(),
      }),
      headers: {
        Authorization: getCookie(),
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        // alert(data.message);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: data.message,
            status: "",
          })
        );

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Deleteing a Category
  const onDeleteHandler = () => {
    if (
      window.confirm(`Are you sure you want to delete ${cur.categoryName}?`)
    ) {
      dispatch(toggleLoaderAction(true));
      fetch(Domain() + "/api/v1/admin/faq-category/" + cur.id, {
        method: "DELETE",
        headers: {
          Authorization: getCookie(),
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          // alert(data.message);
          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: data.message,
              status: "",
            })
          );

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className={classes.wrapper_list}>
      <div className={classes.flex_para_div}>
        <h1 onClick={onToggleHandler}>{numbering + 1}. </h1>
        <h1
          onClick={onToggleHandler}
          style={{
            textTransform: "capitalize",
            margin: "0px 10px",
            border: isEditable ? "3px solid gray" : "",
          }}
          className={classes.editable_section_para}
          ref={ParaRef}
          contentEditable={isEditable}
          suppressContentEditableWarning={true}
        >
          {cur.categoryName}
        </h1>
        <h1 onClick={onToggleHandler}> Questions</h1>

        {window.location.href.includes("admin") && (
          <div className={classes.control_icon}>
            {isEditable === false && (
              <>
                <i onClick={onEditableHandler}>
                  <FiEdit />
                </i>
                <i onClick={onDeleteHandler}>
                  <BsTrash style={{ color: "red" }} />
                </i>
              </>
            )}
            {isEditable && (
              <>
                <i onClick={onEditableHandler}>
                  <AiOutlineClose style={{ color: "red" }} />
                </i>

                <i onClick={onSubmitHandler}>
                  <BsCheck style={{ color: "green" }} />
                </i>
              </>
            )}
          </div>
        )}
      </div>

      {toggle && (
        <div>
          <FAQLIST categoryId={cur.id} />
        </div>
      )}
      <br />
    </div>
  );
};

export default FAQCategory;
