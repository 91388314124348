import React, { useState, useEffect } from "react";

const useGetVendorCartDetailsHook = () => {
  const [cartLength, setCartLength] = useState<number>(0);
  const [vendorCartLength, setVendorCartLength] = useState<number>(0);
  const [cart, setCart] = useState<any>([]);
  const [cartTotalPrice, setCartTotalprice] = useState<number>(0);

  useEffect(() => {
    let intervalId = setInterval(() => {
      const cartData = JSON.parse(localStorage.getItem("vendor-cart") as string);
      if (Array.isArray(cartData)) {
        setCart(cartData);
        setCartLength(
          cartData
            .map((cur) => cur.quantity)
            .reduce((a, b) => {
              return a + b;
            }, 0)
        );
        setVendorCartLength(
          cartData
            .map((cur) => cur.quantity)
            .reduce((a, b) => {
              return a + b;
            }, 0)
        )

        let x: any = cartData
          .map((cur: any) => cur.priceing * cur.quantity)
          .reduce((a: any, b: any) => {
            return a + b;
          }, 0);
        setCartTotalprice(x);
      }
    }, 1000);

    return () => {
      clearTimeout(intervalId);
    };
  }, []);
  return { cartLength, vendorCartLength,cartTotalPrice, cart };
};

export default useGetVendorCartDetailsHook;
