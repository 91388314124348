import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  BsBell,
  BsChevronLeft,
  BsChevronRight,
  BsSearch,
} from "react-icons/bs";
import AdminVendor from "../../components/AdminVendor/AdminVendor";
import { getCookie } from "../../components/cookie/getCookie";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import { Domain } from "../../Domain/Domain";
import classes from "../../styles/AdminOrderStyles.module.css";
const AdminVendorsPage = (): JSX.Element => {
  const [vendors, setVendors] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<any>(0)

  const decreasePageNumber = () => setPageNumber((val: any) => val-1)
  const increasePageNumber = () => setPageNumber((val: any) => val+1)
  const [loadingVendors, setLoadingVendors] = useState<any>(false)
  
  const loadAllVendors = () => {
    setLoadingVendors(true)
    axios
      .get(Domain() + "/api/v1/admin/vendors", {
        headers: {
          Authorization: getCookie(),
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response: any) => {
        console.log(response);
        if (response.data.status === 200) setVendors(response.data.data);
        setLoadingVendors(false)
      })
      .catch((error) => {
        console.log(error);
        setLoadingVendors(false)
      });
  }


  const [search, setSearch] = useState('')

  const setSearchParameter = (event:any) => {
      if(event.target.value !== null){
        setSearch(event.target.value)
      }
  }

  //search form
  const onSubmitHandler = (val :any) => {
    
    axios
    .get("https://apiv2.electronicshop.com.ng/vendor/search-vendor?resquest_payload="+search, {
      headers: {
        Authorization: "Bearer " + getCookie(),
        "Content-Type": "application/json",
      },
    })
    .then((response: any) => {
      if (response.status === 200){
        setVendors([response.data])
      }
      console.log([response.data]);
    })
    .catch((error) => {
      console.log(error);
    });
};


  useEffect(() => {
    loadAllVendors()
  }, []);

  useEffect(() => {
    if(pageNumber){
      setLoadingVendors(true)
      axios.get(`https://api.electronicshop.com.ng/api/v1/admin/vendors/more?page=${pageNumber}`, {headers: {Authorization: JSON.parse(localStorage.getItem("token") as string)}}).then(res => {
        console.log('_____admin: ', res)
        setVendors(res?.data?.data)
        setLoadingVendors(false)
      }).catch(err => {
        setLoadingVendors(false)
      })
    }else{
      loadAllVendors()
    }
  }, [pageNumber])
  return (
    <AdminMain>
      <div>
        <p className={classes.title_top}>Vendors</p>
        <br />
        <div className={classes.col_two}>
          <i>
            <BsBell />
          </i>
          <p>24 New vendors signed up today.</p>
        </div>
        <div className={classes.location_container}>
          <select name="" id="">
            <option value="">All Locations</option>
          </select>
        </div>
        <div>
          <p>
            <span style={{ color: "#F14306" }}>Search by Name</span> |{" "}
            <span>Search by ID</span>
          </p>
        </div>
        <div className={classes.search_order_input}>
          <input
            type="text"
            name="productName"
            onChange={setSearchParameter}
            placeholder="Search for Vendor"
          />
          <div 
          className={classes.search_icon}
          onClick={onSubmitHandler}
          >
            <i>
              <BsSearch />
            </i>
          </div>
        </div>
        <br />
        <br />
        {loadingVendors && <SmallSpinner />}
        {vendors.length ? (<AdminVendor vendors={vendors} />) : <h2>No vendors found!</h2>}
        <div className={classes.pagination}>
          <p>Showing </p>
          <input type="number" value={pageNumber} />
          <p>of 10</p>
          <div className={classes.left_slide} onClick={decreasePageNumber}>
            <i>
              <BsChevronLeft />
            </i>
          </div>
          <div className={classes.left_slide} onClick={increasePageNumber}>
            <i>
              <BsChevronRight />
            </i>
          </div>
        </div>
      </div>
    </AdminMain>
  );
};

export default AdminVendorsPage;
