export function getCookie() {
  let cookies = document.cookie || "no-cookie";
  if (!cookies) return "";
  // console.log(cookies);
  cookies = cookies.split(";");
  let cookieObj = {};
  for (let i = 0; i < cookies.length; i++) {
    if (cookies[i].includes("x_plg_cookie-token_x_token_secret_x_t")) {
      cookieObj.token = cookies[i];
    }
  }

  let token = cookieObj.token;
  if (!token || token === "undefined" || typeof token === null) {
    return "no-token";
  }
  token = token.split("=")[1];
  return token;
}
