import axios from "axios";
import React, { useEffect, useState } from "react";
import { getClientData } from "../../components/cookie/getClientData";
import { getClientToken } from "../../components/cookie/getClientToken";
import SmallSpinner from "../../components/Spinner/SmallSpinner"; 
import Account from "../../components/UI/Account/Account";
import { UserProfileData } from "../../data/UserProfileData";
import { Domain } from "../../Domain/Domain";
import classes from "../../styles/Account.module.css"; 
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../../store/slices/alertSlice";

const EditProfilePage = (): JSX.Element => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState<boolean>(false);
  const [enteredData, setEnteredData] = useState({
    firstName: "",
    surname: "",
    otherName: "",
    dob: "",
    email: "",
    phoneNumber: "",
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  const user = getClientData();
  useEffect(() => {
    axios
      .get(Domain() + "/api/v1/user/" + user.id, {
        headers: {
          Authorization: getClientToken(),
          "Content-Type": "application/json",
        },
      })

      .then((response: any) => {
        console.log(response.data.data);
        if (response.data.status === 200) {
          setEnteredData((prevState) => {
            return {
              ...prevState,
              ...response.data.data,
            };
          });
        }
      })
      .catch((error) => {
        console.log(error);
        // alert(error.response.data.message);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: error.response.data.message,
            status: "error",
          })
        );
        console.log(error.response.data.message);
      });
  }, [user.id]);

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    axios
      .patch(Domain() + "/api/v1/user/" + user.id, enteredData, {
        headers: {
          Authorization: getClientToken(),
          "Content-Type": "application/json",
        },
      })

      .then((response: any) => {
        setLoader(false);
        console.log(response.data.data);
        if (response.data.status === 200) {
          // alert('Successfully updated profiles')
          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: "Successfully updated profiles",
              status: "",
            })
          );
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
        console.log(error.response.data.message);
      });
  };
  return (
    <Account>
      <form onSubmit={onSubmitHandler}>
        <div>
          <div className={classes.application_form}>
            <label htmlFor="">First Name</label>
            <input
              type={"text"}
              onChange={onChangeHandler}
              name={"firstName"}
              placeholder={"John"}
              value={enteredData.firstName}
              required
            />
          </div>

          <div className={classes.application_form}>
            <label htmlFor="">Surname</label>
            <input
              type={"text"}
              onChange={onChangeHandler}
              name="surname"
              placeholder={"Doe"}
              value={enteredData.surname}
              required
            />
          </div>

          <div className={classes.application_form}>
            <label htmlFor="">Other Names</label>
            <input
              type={"text"}
              onChange={onChangeHandler}
              name={"otherName"}
              placeholder={"John"}
              value={enteredData.otherName}
              required
            />
          </div>

          <div className={classes.application_form}>
            <label htmlFor="">Email</label>
            <input
              type={"email"}
              onChange={onChangeHandler}
              name={"email"}
              placeholder={"johndoe@gmail.com"}
              value={enteredData.email}
              required
            />
          </div>
          <div className={classes.application_form}>
            <label htmlFor="">DOB</label>
            <input
              type={"date"}
              onChange={onChangeHandler}
              name={"dob"}
              value={enteredData.dob}
              // required
            />
          </div>

          <div className={classes.application_form}>
            <label htmlFor="">Phone Number</label>
            <input
              type={"number"}
              onChange={onChangeHandler}
              placeholder={"johndoe@gmail.com"}
              value={enteredData.phoneNumber}
              name={"phoneNumber"}
              required
            />
          </div>
          <br />
          <div className={classes.add_adderess_button + " " + classes.btn_2}>
            <button>SAVE PROFILE</button>
            {loader && <SmallSpinner />}
          </div>
        </div>
      </form>
    </Account>
  );
};

export default EditProfilePage;
