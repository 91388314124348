import React, { useState } from "react";
import Spinner from "./Spinner/Spinner";

export interface Tab {
  id: number;
  title: string;
  content: JSX.Element | string;
}

const TabComponent = ({
  tabs,
  defaultTab,
  updateActiveTab,
  loading,
}: {
  tabs: Tab[];
  defaultTab: number;
  updateActiveTab: (tab: number) => void;
  loading?: boolean;
}) => {
  const [activeTab, setActiveTab] = useState(defaultTab);

  const handleTabClick = (tab: number) => {
    setActiveTab(tab);
    updateActiveTab(tab);
  };

  return (
    <div className="tab-container">
      <div className="tab-header">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab-item ${activeTab === tab.id ? "active" : ""}`}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      <div className="tab-content">
        {loading ? (
          <Spinner />
        ) : (
          tabs.map((tab) => (
            <div
              key={tab.id}
              className={`tab-pane ${activeTab === tab.id ? "active" : ""}`}
            >
              {tab.content}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default TabComponent;
