import AdminMain from "../../components/UI/AdminMain/AdminMain";
import Messages from "../../components/MessageCenter/Messages";
import Account from "../../components/UI/Account/Account";
import { useState, useEffect } from "react";
import { getClientToken } from "../../components/cookie/getClientToken";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../../store/slices/alertSlice";
import { Domain } from "../../Domain/Domain";
import SupportTicket from "../../components/MessageCenter/SupportTicketCard";

const MessageCenter = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState<SupportTicketMessage>({
    message: "",
    subject: "",
    complainer_account: "Customer",
  });
  const [messages, setMessages] = useState<SupportTicket[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getMessages = () => {
    fetch(Domain() + `/api/v1/message/me`, {
      method: "GET",
      headers: {
        Authorization: getClientToken(),
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log({ data });
        setMessages(data.data.reverse());
        // setFetchedData(data.data);
      })
      .catch((err) => {
        console.log({ err });
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: err.message,
            status: "error",
          })
        );
      });
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    fetch("https://apiv2.electronicshop.com.ng/send-message-to-admin", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + getClientToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log({ data });
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: "Message sent successfully!",
            status: "",
          })
        );
        setMessage({
          message: "",
          subject: "",
          complainer_account: "Customer",
        });
        getMessages();
      })
      .catch((err) => {
        console.log({ err });
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: "Message sending failed!",
            status: "",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getMessages();
  }, []);
  return (
    <Account>
      <div>
        <h1>Message Center</h1>
        <br />
        <div className="message-form-cont">
          <h3>Send a new message</h3>
          <br />
          <form
            className={`message-form ${isLoading ? "disabled" : ""}`}
            onSubmit={handleFormSubmit}
          >
            <input
              type="text"
              className="message-subject"
              placeholder="Subject"
              value={message.subject}
              onChange={(e) =>
                setMessage({ ...message, subject: e.target.value })
              }
              required
            />
            <textarea
              className="message-input"
              placeholder="Type your reply..."
              value={message.message}
              onChange={(e) =>
                setMessage({ ...message, message: e.target.value })
              }
              required
            ></textarea>
            <button type="submit" className="btn-send">
              Send
            </button>
          </form>
        </div>

        <br />

        <h3>
          <span className="message-count">{messages.length}</span> Messages
        </h3>
        <br />
        <ul className="messages-cont">
          {messages.map((message) => (
            <li key={message.ticket_id}>
              <SupportTicket ticket={message} />
            </li>
          ))}
        </ul>

        {/* <Messages isAdmin={false} /> */}
      </div>
    </Account>
  );
};

export default MessageCenter;
