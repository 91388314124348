import React from "react";
import { BsImage } from "react-icons/bs";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import Spinner from "../../components/Spinner/Spinner";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import { Domain } from "../../Domain/Domain";
import useCreateProductHook from "../../Hooks/useCreateProductHook";
import { useAdminGeneralgetRequestQuery } from "../../store/services/admin";
import classes from "../../styles/AdminNewProductPage.module.css";
const AdminNewProductPage = (): JSX.Element => {
  const {
    files,
    showMoreSpecifications,
    onShowMoreSpecifications,
    onImageChangeHandler1,
    onImageChangeHandler2,
    onImageChangeHandler3,
    onImageChangeHandler4,
    onChangeHandler,
    onCreateProductHandler,
    enteredValue,
    onChangeColorHandler,
    isSubmitting,
    promotionalPriceIsHidden,
    togglePromotionalHandler,
    isLoadingImages,
  } = useCreateProductHook();

  const { data: initBrandData = [] } =
    useAdminGeneralgetRequestQuery("/api/v1/brand/all");

  let brandData: any = [];
  if (initBrandData.data) brandData = initBrandData.data;

  const { data: initCategoryData = [] } = useAdminGeneralgetRequestQuery(
    "api/v1/category/all"
  );

  let categoryData: any = [];
  if (initCategoryData.data) categoryData = initCategoryData.data;

  return (
    <AdminMain>
      <form onSubmit={onCreateProductHandler}>
        <div>
          <h1 className={classes.title_section}>New Product</h1>
          <div className={classes.form_list_div}>
            <label>
              <b>Name</b>{" "}
              <small>
                Name (Do not include brand e.g. 1.5 HP Split Air Conditioner)
              </small>
            </label>
            <br />
            <input
              type="text"
              name="productName"
              value={enteredValue.productName}
              onChange={onChangeHandler}
              required
            />
          </div>

          <div className={classes.form_list_div}>
            <label>
              <b>Brand</b>{" "}
            </label>
            <br />
            <select name="brand" id="" onChange={onChangeHandler} required>
              <option value={""}>Select a brand</option>
              {brandData.map((cur: any) => (
                <option key={cur.id} value={cur.id}>
                  {cur.name}
                </option>
              ))}
            </select>
          </div>

          <div className={classes.form_list_div}>
            <label>
              <b>Category</b>{" "}
            </label>
            <br />
            <select name="category" id="" onChange={onChangeHandler} required>
              <option value={""}>Select a category</option>
              {categoryData.map((cur: any) => (
                <option key={cur.id} value={cur.id}>
                  {cur.name}
                </option>
              ))}
            </select>
          </div>

          <div className={classes.form_list_div}>
            <label>
              <b>Model</b>{" "}
            </label>
            <br />
            <input
              type="text"
              name="model"
              onChange={onChangeHandler}
              value={enteredValue.model}
              required
            />
          </div>
          <div className={classes.form_list_div}>
            <label>
              <b>Colours Available</b>{" "}
            </label>
            <br />
            <div className={classes.color_input}>
              {[
                "Red",
                "Orange",
                "Yellow",
                "Green",
                "Blue",
                "Purple",
                "Pink",
                "Black",
                "White",
                "Grey",
                "Silver",
              ].map((cur: string, index: number) => (
                <label htmlFor="" key={cur}>
                  <input
                    type={"checkbox"}
                    onChange={onChangeColorHandler}
                    id={"color" + (index + 1).toString()}
                    value={cur}
                  />
                  <span className="capitalize">{cur}</span>
                </label>
              ))}
            </div>
          </div>
          {/* 
          <div className={classes.form_list_div}>
            <label>
              <b>Colours Available</b>{" "}
            </label>
            <br />
            <select name="colours" id="" onChange={onChangeHandler} required>
              <option value={enteredValue.colours}>
                {enteredValue.colours}
              </option>
              <option value="Red">Red</option>
              <option value="Orange">Orange</option>
              <option value="Yellow">Yellow</option>
              <option value="Green">Green</option>
              <option value="Blue">Blue</option>
              <option value="Purple">Purple</option>
              <option value="Pink">Pink</option>
              <option value="Black">Black</option>
              <option value="White">White</option>
              <option value="Grey">Grey</option>
              <option value="Silver">Silver</option>
            </select>
          </div> */}

          <div className={classes.form_list_div}>
            <label>
              <b>Product Description</b> (Max. Characters 1500)
            </label>
            <br />
            <textarea
              name="productDescription"
              required
              id=""
              value={enteredValue.productDescription}
              onChange={onChangeHandler}
            ></textarea>
          </div>
          <br />

          <div className={classes.form_list_div}>
            <label>
              <b>Product Search Variables</b> (Seperate with commas)
            </label>
            <br />
            <textarea
              name="search_variables"
              required
              id=""
              value={enteredValue.search_variables}
              onChange={onChangeHandler}
            ></textarea>
          </div>
          <br />
          
          {/* Specifications */}
          <h3 className={classes.specification_title}>Specifications</h3>

          <div className={classes.flexed_container}>
            <div className={classes.input_list_container}>
              <p>Attribute (e.g. Dimensions, Weight,...)</p>
              <select
                name="specAttribute1"
                id="specAttributeID"
                onChange={onChangeHandler}
              >
                <option value="weight">Weight</option>
                <option value="dimensions">Dimensions</option>
                <option value="capacity">Capacity</option>
                <option value="display">Display</option>
              </select>
            </div>
            <div className={classes.input_list_container}>
              <p>Value (e.g. 7kg, 100L, 25x16in,...)</p>
              <input
                type="text"
                name=""
                id="specValueID"
                onChange={onChangeHandler}
              />
            </div>
          </div>

          <div className={classes.flexed_container}>
            <div className={classes.input_list_container}>
              <p>Attribute (e.g. Dimensions, Weight,...)</p>
              <select
                name="specAttribute2"
                id="specAttributeID"
                onChange={onChangeHandler}
              >
                <option value="weight">Weight</option>
                <option value="dimensions">Dimensions</option>
                <option value="capacity">Capacity</option>
                <option value="display">Display</option>
              </select>
            </div>
            <div className={classes.input_list_container}>
              <p>Value (e.g. 7kg, 100L, 25x16in,...)</p>
              <input
                type="text"
                name=""
                id="specValueID"
                onChange={onChangeHandler}
              />
            </div>
          </div>

          <div className={classes.flexed_container}>
            <div className={classes.input_list_container}>
              <p>Attribute (e.g. Dimensions, Weight,...)</p>
              <select
                name="specAttribute3"
                id="specAttributeID"
                onChange={onChangeHandler}
              >
                <option value="weight">Weight</option>
                <option value="dimensions">Dimensions</option>
                <option value="capacity">Capacity</option>
                <option value="display">Display</option>
              </select>
            </div>
            <div className={classes.input_list_container}>
              <p>Value (e.g. 7kg, 100L, 25x16in,...)</p>
              <input
                type="text"
                name=""
                id="specValueID"
                onChange={onChangeHandler}
              />
            </div>
          </div>

          <div className={classes.flexed_container}>
            <div className={classes.input_list_container}>
              <p>Attribute (e.g. Dimensions, Weight,...)</p>
              <select
                name="specAttribute4"
                id="specAttributeID"
                onChange={onChangeHandler}
              >
                <option value="weight">Weight</option>
                <option value="dimensions">Dimensions</option>
                <option value="capacity">Capacity</option>
                <option value="display">Display</option>
              </select>
            </div>
            <div className={classes.input_list_container}>
              <p>Value (e.g. 7kg, 100L, 25x16in,...)</p>
              <input
                type="text"
                name=""
                id="specValueID"
                onChange={onChangeHandler}
              />
            </div>
          </div>

          <div className={classes.flexed_container}>
            <div className={classes.input_list_container}>
              <p>Attribute (e.g. Dimensions, Weight,...)</p>
              <select
                name="specAttribute5"
                id="specAttributeID"
                onChange={onChangeHandler}
              >
                <option value="weight">Weight</option>
                <option value="dimensions">Dimensions</option>
                <option value="capacity">Capacity</option>
                <option value="display">Display</option>
              </select>
            </div>
            <div className={classes.input_list_container}>
              <p>Value (e.g. 7kg, 100L, 25x16in,...)</p>
              <input
                type="text"
                name=""
                id="specValueID"
                onChange={onChangeHandler}
              />
            </div>
          </div>

          {/* More Specisifations */}
          {showMoreSpecifications && (
            <>
              <div className={classes.flexed_container}>
                <div className={classes.input_list_container}>
                  <p>Attribute (e.g. Dimensions, Weight,...)</p>
                  <input
                    type="text"
                    name="specAttribute5"
                    id="specAttributeID"
                    onChange={onChangeHandler}
                    placeholder="Enter a custom specification"
                  />
                </div>
                <div className={classes.input_list_container}>
                  <p>Value (e.g. 7kg, 100L, 25x16in,...)</p>
                  <input
                    type="text"
                    id="specValueID"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>

              <div className={classes.flexed_container}>
                <div className={classes.input_list_container}>
                  <p>Attribute (e.g. Dimensions, Weight,...)</p>
                  <input
                    type="text"
                    name="specAttribute"
                    id="specAttributeID"
                    onChange={onChangeHandler}
                    placeholder="Enter a custom specification"
                  />
                </div>
                <div className={classes.input_list_container}>
                  <p>Value (e.g. 7kg, 100L, 25x16in,...)</p>
                  <input
                    type="text"
                    id="specValueID"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
            </>
          )}

          <div className={classes.specifications}>
            {!showMoreSpecifications ? (
              <div
                onClick={onShowMoreSpecifications}
                style={{ backgroundColor: "#081c35" }}
                className={classes.showMoreHideMore}
              >
                MORE SPECIFICATIONS
              </div>
            ) : (
              <div
                onClick={onShowMoreSpecifications}
                className={classes.showMoreHideMore}
                style={{ backgroundColor: "#081c35" }} 
              >
                HIDE MORE SPECIFICATIONS
              </div>
            )}
          </div>
          <br />
          <p>
            <b>Pictures</b>
            <span style={{ color: "red", fontWeight: "bold" }}>
              {" "}
              (Upload at least 2 pictures showing different angles of the
              product. Max size: 5MB, ratio 1:1. )
            </span>
          </p>
          <br />
          <div className={classes.uploads_container}>
            <label htmlFor="file1">
              <div className={classes.upload_list}>
                <i>
                  <BsImage />
                </i>

                {isLoadingImages.loader1 && (
                  <div className={classes.spinner_container}>
                    <SmallSpinner />
                  </div>
                )}

                {files.file1 !== "" && (
                  <div className={classes.previewed_images}>
                    <img src={files.file1} alt="" />
                  </div>
                )}
                <div id="fileLoader1" className="file_loader"></div>
              </div>
              <input
                type="file"
                accept="image/*"
                id="file1"
                style={{ display: "none" }}
                onChange={onImageChangeHandler1}
              />
            </label>

            <label htmlFor="file2">
              <div className={classes.upload_list}>
                <i>
                  <BsImage />
                </i>
                {isLoadingImages.loader2 && (
                  <div className={classes.spinner_container}>
                    <SmallSpinner />
                  </div>
                )}
                {files.file2 !== "" && (
                  <div className={classes.previewed_images}>
                    <img src={files.file2} alt="" />
                  </div>
                )}
                <div id="fileLoader2" className="file_loader"></div>
              </div>
              <input
                type="file"
                accept="image/*"
                id="file2"
                style={{ display: "none" }}
                onChange={onImageChangeHandler2}
              />
            </label>

            <label htmlFor="file3">
              <div className={classes.upload_list}>
                <i>
                  <BsImage />
                </i>
                {isLoadingImages.loader3 && (
                  <div className={classes.spinner_container}>
                    <SmallSpinner />
                  </div>
                )}

                {files.file3 !== "" && (
                  <div className={classes.previewed_images}>
                    <img src={files.file3} alt="" />
                  </div>
                )}
                <div id="fileLoader3" className="file_loader"></div>
              </div>
              <input
                type="file"
                accept="image/*"
                id="file3"
                style={{ display: "none" }}
                onChange={onImageChangeHandler3}
              />
            </label>

            <label htmlFor="file4">
              <div className={classes.upload_list}>
                <i>
                  <BsImage />
                </i>

                {isLoadingImages.loader4 && (
                  <div className={classes.spinner_container}>
                    <SmallSpinner />
                  </div>
                )}

                {files.file4 !== "" && (
                  <div className={classes.previewed_images}>
                    <img src={files.file4} alt="" />
                  </div>
                )}
                <div id="fileLoader4" className="file_loader"></div>
              </div>
              <input
                type="file"
                accept="image/*"
                id="file4"
                style={{ display: "none" }}
                onChange={onImageChangeHandler4}
              />
            </label>
          </div>
          <br />
          <br />
          <div className={classes.edit_pricing}>
            <div className={classes.flex_basis}>
              <div>
                <p>Product Price (Just figures, do not add currency)</p>
              </div>
              <input
                type="number"
                name="pricing"
                placeholder="150,000"
                value={enteredValue.pricing}
                onChange={onChangeHandler}
                required
              />
            </div>

            <div className={classes.flex_basis}>
              <div className={classes.flex_promoted_price}>
                <input type="checkbox" onChange={togglePromotionalHandler} />
                <p>Promotional Price</p>
              </div>
              <input
                type="number"
                name="promotionalPrice"
                placeholder=""
                value={enteredValue.promotionalPrice}
                disabled={promotionalPriceIsHidden}
                onChange={onChangeHandler}
                style={{ backgroundColor: "#D9D9D9" }}
              />
            </div>
          </div>
          <br />

          <div className={classes.specifications}>
            <button style={{ backgroundColor: "#F14306", width: "170px" }}>
              CREATE PRODUCT
            </button>
            {isSubmitting && <SmallSpinner />}
          </div>
        </div>
      </form>
    </AdminMain>
  );
};

export default AdminNewProductPage;
