import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  isPaySmallSmall: false,
  percentage: 0,
  interest: 0,
  amountDue: 0,
  monthlyInstallment: 0,
  //
  firstPayment: 0,
  installmentOption: 0,
  paySmallProducts: "",
  totalPrice: 0,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    toggleIspaySmallSmall(state, { payload }) {
      state.isPaySmallSmall = payload;
    },
    dispatchInstallmentalOption(state, { payload }) {
      state.installmentOption = payload;
    },
    dispatchFirstPaymentPercentage(state, { payload }) {
      state.firstPayment = payload;
    },
    getPaySmallProducts(state, {payload}){
      state.paySmallProducts = payload
    },
    getTotalPrice(state, {payload}){
      state.totalPrice = payload
    },
  },
});

export const {
  toggleIspaySmallSmall,
  dispatchInstallmentalOption,
  dispatchFirstPaymentPercentage,
  getPaySmallProducts,
} = paymentSlice.actions;
export default paymentSlice;
