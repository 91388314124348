import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getClientToken } from "../../components/cookie/getClientToken";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import Account from "../../components/UI/Account/Account";
import BackButton from "../../components/UI/BackButton/BackButton";
import { Domain } from "../../Domain/Domain";
import classes from "../../styles/Account.module.css";
import { getPaySmallProducts } from "../../store/slices/paymentSlice";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../../store/slices/alertSlice";
import Pagination from "../../components/Pagination/Pagination";
import OrderList from "../../components/Order/OrderList";
import { OrderStatusString } from "../../components/Order";
import TabComponent from "../../components/TabComponent";

const PaySmall = () => {
  const [orderData, setOrderData] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [activeOrderTab, setActiveOrderTab] = useState<OrderStatus>(1);

  const [ordersByStatus, setOrdersByStatus] = useState<
    {
      status: string;
      code: OrderStatus;
      orders: UserOrder[];
    }[]
  >([
    {
      code: 1,
      status: "Successful and pending",
      orders: [],
    },
    {
      code: 2,
      status: "Available for pickup",
      orders: [],
    },
    {
      code: 3,
      status: "Delivered and completed",
      orders: [],
    },
  ]);

  const dispatch = useDispatch();

  const handleGetOrders = (currentPage?: number, code?: OrderStatus) => {
    setLoading(true);

    fetch(
      Domain() +
        `/api/v1/user/order/pay-small-small/more${
          code ? `/${code}` : ""
        }?page=${currentPage || 1}`,
      {
        method: "GET",
        headers: {
          Authorization: getClientToken(),
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((response: any) => {
        if (response.status === 200) {
          console.log("pay small small ====>", response);
          let orderList = response?.data.orders ?? [];
          setCurrentPage(response?.data.currentPage);
          setTotalItems(response?.data.totalItems);
          setTotalPages(response?.data.totalPages);
          orderList = orderList.sort((a: any, b: any) => b - a);
          console.log({
            orderList,
          });

          setOrderData(orderList);
          code &&
            setOrdersByStatus((prevState) => {
              const newOrderByStatus = {
                status: OrderStatusString[code],
                code,
                orders: response?.data.orders || [],
              };

              const oldOrderIndex = prevState.findIndex(
                (oldOrderByStatus) => code == oldOrderByStatus.code
              );

              const newState =
                oldOrderIndex > -1
                  ? prevState.map((item, i) => {
                      if (i == oldOrderIndex) return newOrderByStatus;
                      return item;
                    })
                  : [
                      ...prevState,
                      {
                        status: OrderStatusString[code],
                        code,
                        orders: response?.data.orders || [],
                      },
                    ];

              return newState;
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        // alert(error.response.data.message);
        // console.alue.paySmallProducts)log(error.response.data.message);
      });
  };

  const handleUpdateCurrentPage = (page: React.SetStateAction<number>) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    handleGetOrders(currentPage);
    const orders = handleGetOrders(currentPage, activeOrderTab);
    // setOrdersByStatus()
    console.log({
      orders,
    });
  }, [currentPage, activeOrderTab]);

  useEffect(() => {
    console.log({
      totalItems,
      totalPages,
      currentPage,
    });
  }, [totalItems, totalPages, currentPage]);

  const handlePaySmallDetail = (products: string) => {
    dispatch(getPaySmallProducts(products));
  };

  console.log("===================>", orderData);

  const tabs = ordersByStatus.map((order) => {
    const id = order.code;
    const title = order.status;

    const orderList = (
      <OrderList
        currentPage={currentPage}
        list={order.orders}
        totalPages={totalPages}
        updateCurrentPage={handleUpdateCurrentPage}
      />
    );

    return {
      id,
      title,
      content: orderList,
    };
  });

  const handleUpdateActiveTab = (tab: number) => {
    setActiveOrderTab(tab as OrderStatus);
  };

  return (
    <Account>
      <div>
        <TabComponent
          tabs={tabs}
          defaultTab={1}
          updateActiveTab={handleUpdateActiveTab}
          loading={loading}
        />
        {/* {loading ? (
          <SmallSpinner />
        ) : (
          <> */}

        {/* <OrderList
              currentPage={currentPage}
              list={orderData}
              totalPages={totalPages}
              updateCurrentPage={handleUpdateCurrentPage}
            /> */}
        {/* {orderData.map((cur: any, index: number) => {
              dispatch(getPaySmallProducts(cur?.products));
              return (
                <Link
                  //   onClick={() => handlePaySmallDetail(cur?.products)}
                  to={"/pay-small-order-detail?order_id=" + cur?.id}
                  key={index}
                >
                  <div className={classes.order_container}>
                    <h3>
                      {cur?.firstName} {cur?.otherName}
                    </h3>
                    <p>{cur?.address}</p>
                    <p> {new Date(cur?.updatedAt).toLocaleDateString()}</p>
                  </div>
                </Link>
              );
            })} */}
        {/* </>
        )} */}
      </div>
    </Account>
  );
};

export default PaySmall;
