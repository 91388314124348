import React from "react";
import { Link } from "react-router-dom";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import BackButton from "../../components/UI/BackButton/BackButton";
import classes from "../../styles/AdminSettings.module.css";
const AdminSettings = (): JSX.Element => {
  return (
    <AdminMain>
      <BackButton />
      <br />
      <div>
        <h3>Settings</h3>
        <br />
        <div className={classes.list_container}>
          <Link to={"/admin-settings-change-password"}>Change Password</Link>
          <br /><br />
          <Link to={"/admin-settings-update-email"}>Update Email</Link>
          <p>
            <small>Panel Access</small>
          </p>
          <Link to={"/admin-new-admin"}>New Admin</Link>
          <p>Manage admins</p>
          <br />
          <p>
            <small>FAQs</small>
          </p>
          <Link to={"/admin-faq"}>FAQs</Link>
          <p>
            <Link to={"/admin-create-faq"}>Create FAQ</Link>
          </p>
          <br />
          <p>
            <small>User Website Settings</small>
          </p>
          <p>Terms and Conditions</p>
          <p>Pay small small</p>
        </div>
      </div>
    </AdminMain>
  );
};

export default AdminSettings;
