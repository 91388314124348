import React, { useEffect, useState } from "react";
import { BsFillMenuAppFill, BsShop } from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import { getClientData } from "../../cookie/getClientData";
import { getClientToken } from "../../cookie/getClientToken";
import classes from "./AccountAside.module.css";

const AccountAside = () => {
  const userData = getClientData();
  const navigate = useNavigate();
  // Check Authenticatin

  useEffect(() => {
    function checkAuth() {
      const parseJwt = (token: string) => {
        try {
          return JSON.parse(atob(token.split(".")[1]));
        } catch (e: any) {
          return null;
        }
      };

      const decodedJwt: any = parseJwt(getClientToken()) as unknown as any;
      if (getClientToken() === "no-token") return navigate("/login");

      if (decodedJwt?.exp! * 1000 < Date.now()) {
        navigate("/login");
      }
    }
    checkAuth();
  }, []);

    // handles the filter button 
    const [showBtn, setShowBtn] = useState(false)
    const handleFilter = ()=> setShowBtn(!showBtn)
  

  return (
    <div>
      <div className={classes.account_detail}>
        <h3>
          <FaUserAlt style={{ marginRight: "10px" }} />
          {userData?.firstName! + " " + userData!.surname!}
        </h3>
        <p>{userData?.email!}</p>
        <p>{userData?.phoneNumber!}</p>
      </div>
      <br />

      <div className={classes.list_container_menu}>
          <button onClick={handleFilter}> <BsFillMenuAppFill /> Menu</button>
      </div>
      <br />

      <div className={!showBtn ? classes.list_container : ''} >
        <NavLink className={"nav_link"} to={"/my-order"}>
          My Orders
        </NavLink>
        <NavLink className={"nav_link"} to={"/my-vendor-cart-order-list"}>
          My Orders To Vendor
        </NavLink>
        <NavLink className={"nav_link"} to={"/pay-small"}>
          Pay Small Small
        </NavLink>
        <NavLink className={"nav_link"} to={"/account-addresses"}>
          Delivery Address
        </NavLink>
        {/* <NavLink className={"nav_link"} to={"/account-wallet"}>
          My Wallet
        </NavLink> */}

        <NavLink className={"nav_link"} to={"/edit-profile"}>
          Edit Profile
        </NavLink>
        <NavLink className={"nav_link"} to={"/account-reset-password"}>
          Change Password
        </NavLink>
        {/* <NavLink className={"nav_link"} to={"/account-inbox"}>
          Inbox
        </NavLink> */}
        <NavLink className={"nav_link"} to={"/message-center"}>
          Message center
        </NavLink>
        {/* <NavLink className={"nav_link"} to={"/create-feedback"}>
          Feedback
        </NavLink> */}
        <NavLink className={"nav_link"} to={"/faqs"}>
          FAQs
        </NavLink>
        <NavLink className={"nav_link"} to={"/login"} style={{ color: "red" }}>
          Logout
        </NavLink>
      </div>

      
      <br />
      <NavLink className={"nav_link"} to={"/login-v"}>
        <div className={classes.last_list}>
          <BsShop />
          <p>SWITCH TO VENDOR</p>
        </div>
      </NavLink>
    </div>
  );
};

export default AccountAside;
