import React, { useState, useEffect } from "react";
import axios from "axios";
import WalletDetails from "./WalletDetails";
import "./vendor-wallet.css";
import { Domain2 } from "../../Domain/Domain";
import VendorMain from "../../components/UI/VendorMain/VendorMain";
import { Link } from 'react-router-dom'

const VendorWithdraw = () => {
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [showModal, setShowModal] = useState(false);

  const token  = localStorage.getItem("vendor-token")

  const reloadPage = () => {
    window.location.reload()
  }


  const handleWithdraw = async () => {
    try {
      const response = await fetch(`${Domain2}/vendors/withdraw?amount=${withdrawAmount}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const responseData = await response.json();



        if (responseData.message === "withdrawal successful") {
          alert(responseData.message);
          reloadPage();
        } else {
          alert(responseData.message || "withdrawal not successful");
        }
      } else if (response.status === 422) {
        alert("Withdrawal Not Successful, Please confirm your details again");
      } else {
        alert("Withdrawal Not Successful");
      }
    } catch (error) {
      console.error("Withdrawal error:", error);
    }
  };


  

  const handleClick = () => {
    setShowModal(true);
  }

  return (
    <VendorMain>

      <div className="withdraw">
        
        <WalletDetails />

        <br /><br />
        <div className="withdraw-section">
          <h2>Withdraw</h2><br />
          <Link to='/vendor-edit-bank-details'>
            <p>Edit your bank details</p>
          </Link>

          <br />
          <input
              type="number"
              placeholder="Enter amount to withdraw"
              style={{ width: '100%' ,}}
              value={withdrawAmount}
              onChange={(e) => setWithdrawAmount(e.target.value)}
          /> <br /><br />
          <button className='confirm-button' onClick={handleClick}>Withdraw</button>
        </div>
        {showModal && (
          <div className="modal2">
              <div className="modal-content">
                  <h3>Confirm Withdraw</h3>
                  <p>Are you sure you want to withdraw {withdrawAmount} Naira?</p>
                  <div className="modal-buttons">
                  <button className='confirm-button' onClick={() => setShowModal(false)}>Cancel</button>
                  <button className='btn btn-success confirm-button' onClick={handleWithdraw}>Confirm</button>
                  </div>
              </div>
          </div>
        )}
      </div>
    </VendorMain>
  );
}

export default VendorWithdraw;
