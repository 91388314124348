import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getClientData } from "../../components/cookie/getClientData";
import { getClientToken } from "../../components/cookie/getClientToken";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import Main from "../../components/UI/Main/Main";
import { VendorRegisterForm } from "../../data/VendorRegisterForm";
import { Domain } from "../../Domain/Domain";
import classes from "../../styles/Account.module.css";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../../store/slices/alertSlice";
export const RegisterVendor = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [message, setMessage] = useState({ error: "", success: "" });
  const dispatch = useDispatch();
  const [enteredData, setEnteredData] = useState<{
    shopName: string;
    address: string;
    state: string;
    city: string;
    country: string;
    password: string;
    email: string;
    phoneNumber: string;
    confirmPassword: string;
  }>({
    shopName: "",
    address: "",
    state: "",
    city: "",
    country: "",
    password: "",
    email: "",
    phoneNumber: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);
  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setEnteredData((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (enteredData.password !== enteredData.confirmPassword)
      return setMessage({
        error: "Password did not match",
        success: "",
      });

    if (enteredData.password.length < 6)
      return setMessage({
        error: "Password is too short",
        success: "",
      });
    setLoader(true);
    let vendorData: any = {
      ...enteredData,
      address:
        enteredData.address +
        " " +
        enteredData.state +
        " " +
        enteredData.city +
        " " +
        enteredData.country,
    };
    delete vendorData.city as any;
    // delete vendorData.state as any;
    delete vendorData.country as any;
    delete vendorData.confirmPassword as any;
    axios
      .post(Domain() + "/api/v1/vendor/register", JSON.stringify(vendorData), {
        headers: {
          Authorization: getClientToken(),
          "Content-Type": "application/json",
        },
      })

      .then((response: any) => {
        setLoader(false);
        console.log(response.data);
        if (response.data.status === 200) {
          setMessage({
            error: "",
            success: response.data.message,
          });

          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: response.data.message,
              status: "error",
            })
          );

          window.localStorage.setItem(
            "vendor",
            JSON.stringify(response.data.data)
          );
          navigate("/login-v");
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
        setMessage({
          error: error.response.data.message,
          // error: "An error coccured",
          success: "",
        });
      });
  };
  return (
    <Main>
      <form onSubmit={onSubmitHandler}>
        <h1>Sign up as Vendor</h1>
        <br />
        <div className={classes.form_centered_section} style={{ margin: 'auto', }}>
          {VendorRegisterForm.map((cur, index) => (
            <div key={index} className={classes.application_form}>
              {cur.isInput ? (
                <>
                  <label htmlFor="">{cur.labelFor}</label>
                  <input
                    type={cur.type}
                    placeholder={cur.placeHolder}
                    required
                    name={cur.name}
                    onChange={onChangeHandler}
                  />
                </>
              ) : (
                <>
                  <label htmlFor="">{cur.placeHolder}</label>
                  <select
                    id=""
                    name={cur.name}
                    onChange={onChangeHandler}
                    required
                  >
                    <option value="">{cur.placeHolder}</option>
                    {cur.options?.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </>
              )}
            </div>
          ))}
          <br />
          <div className={classes.messages_data}>
            <p className="error_message">
              {message.error !== "" && message.error}
            </p>
            <p className="success_message">
              {message.success !== "" && message.success}
            </p>
          </div>
          <br />
          <div className={classes.submit_button + " " + classes.btn_2} style={{ margin: 'auto'}}>
            <button>Register</button>
            {loader && <SmallSpinner />}
          </div>
          <br />
          <Link to="/login-v" style={{ margin: 'auto'}}>Already have an account? Login</Link>
        </div>
      </form>
    </Main>
  );
};
