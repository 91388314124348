import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { orderData } from "../../data/OrderData";
import { Domain } from "../../Domain/Domain";
import AdminPagination from "../AdminPagination/AdminPagination";
import { getCookie } from "../cookie/getCookie";
import SmallSpinner from "../Spinner/SmallSpinner";
import classes from "./User.module.css";
import { BsSearch } from "react-icons/bs";


const AdminTicket = (): JSX.Element => {
  const [tickets, setTickets] = useState<any>([]);
  const [arrayIsEmpty, setArrayIsEmpty] = useState<boolean>(false);
  const naviagte = useNavigate();


  const [search, setSearch] = useState('')

  const setSearchParameter = (event:any) => {
      if(event.target.value !== null){
        setSearch(event.target.value)
      }
  }

  //search form
  const onSubmitHandler = (val :any) => {

      axios
      .get("https://apiv2.electronicshop.com.ng/get-message-details/"+search, {
        headers: {
          Authorization: "Bearer " + getCookie(),
          "Content-Type": "application/json",
        },
      })
      .then((response: any) => {
        if (response.status === 200){
          if(response.data === null){
            setArrayIsEmpty(true)
          }else{
            setTickets(response.data)
            // setTickets([response.data])
            setArrayIsEmpty(false)
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const onNavigateHandler = (data: any) => {
    naviagte("/admin-support-center-details?id=" + data);
  };

  return (
    <>
      <div className={classes.search_order_input}>
          <input
            type="text"
            name="productName"
            onChange={setSearchParameter}
            placeholder="Search for a ticket"
          />
          <div
            className={classes.search_icon}
            onClick={onSubmitHandler}
          >
            <i>
              <BsSearch />
            </i>
          </div>
        </div>



      <h1>{arrayIsEmpty === false ? "" : "No item found"}</h1>


      {tickets.length < 1 ? (
        <>
          {arrayIsEmpty === false && (
            <div style={{ margin: "10px 0px" }}>
              {/* <SmallSpinner /> */}
              {/* <h1>No item found</h1> */}
            </div>
          )}
        </>
      ) : (
        <div className={classes.order_table}>
            {tickets.map((ticket: any, index: number) => (
                <article id={ticket.ticket_id}  className="support-ticket support-ticket-hover"  
                    onClick={() => onNavigateHandler(ticket.ticket_id)}  
                    style={{cursor :'pointer'}}>
                    <header className="support-ticket-header">
                        <div
                        className="cont"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                        >
                        <h3>{ticket.subject}</h3>
                        <span className="support-ticket-id">
                            <span>Ticket ID:</span>
                            <span className="id">{ticket.ticket_id}</span>
                            <span className="status">{ticket.status}</span>
                        </span>
                        </div>
                        <p className="sender-email">From: {ticket.senderEmail}</p>
                    </header>
                    <div className="support-ticket-content">
                        {/* <p>{ticket.body}</p> */}
                        <p>{ticket.body?.substr(0, 100)!}..</p>
                    </div>
                    <footer className="support-ticket-footer">
                        <p>Created At: {new Date(ticket.createdAt).toLocaleDateString()}</p>
                        <p>
                        Last Updated At: {new Date(ticket.updatedAt).toLocaleDateString()}
                        </p>
                    </footer>
                    <Link to={`mailto:${ticket.senderEmail}`} target="_blank">
                        <button className="btn-reply-email">Reply to Email</button>
                    </Link>
                </article>
            ))}
        </div>
      )}

      {/* <AdminPagination
        onDecreaseHandler={onDecreaseHandler}
        onIncreaseHandler={onIncreaseHandler}
        pageNumber={pageNumber + 1}
      /> */}
    </>
  );
};

export default AdminTicket;
