import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import VendorAside from "./VendorAside";
import classes from "./VendorMain.module.css";
interface Props {
  isVendor?: boolean;
  children: JSX.Element | JSX.Element[];
}
const VendorMain: React.FC<Props> = ({ isVendor, children }) => {
  return (
    <>
      <Header />
      <main className={classes.main}>
        <aside>
          <VendorAside isForVendor={isVendor} />
        </aside>
        <article className={classes.article}>{children}</article>
      </main>
      <Footer />
    </>
  );
};

export default VendorMain;
