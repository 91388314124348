import React from "react";
import { BsStarFill } from "react-icons/bs";
import classes from "./ReviewList.module.css";
import StarRating from "../UI/StarRating";
interface Props {
  reviews: any;
}
const ReviewList: React.FC<Props> = ({ reviews }) => {
  return (
    <>
      {reviews.length < 1 && (
        <p>There are no comments yet from buyers of this product.</p>
      )}
      {reviews.map((cur: any, index: number) => (
        <div className={classes.review_container} key={index}>
          <div>
            <img src="/images/avatar.png" alt="" />
          </div>
          <div className={classes.details_section}>
            <h4>
              {cur.user?.firstName!} {cur.user?.surname!} {cur.user?.otherName!}
            </h4>
            <p>{cur?.review_description!}</p>
            <div className={classes.rating}>
              <StarRating averageRating={cur?.ratings} />
            </div>
            <div>
              <p>{new Date(cur?.createdAt!).toDateString() as any}</p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ReviewList;
