import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import AvailablePromotionsList from "../../components/AvailablePromotions/AvailablePromotionsList";
import ManageAdminList from "../../components/ManageAdmin.tsx/ManageAdminList";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import BackButton from "../../components/UI/BackButton/BackButton";
import classes from "../../styles/AdminManagePromotions.module.css";
const ManageAdmin = (): JSX.Element => {
  return (
    <AdminMain>
      <div>
        <BackButton />
        <br />
        <h3>Manage Admins</h3>
        <br />
        <ManageAdminList />
        <div className={classes.live_controller + " " + classes.second_list_}>
          <div>
            <i>
              <FiEdit />
            </i>
            <p>Edit</p>
          </div>

          <div>
            <i>
              <AiOutlineCloseCircle />
            </i>
            <p>Move to Live</p>
          </div>

          <div>
            <i>
              <BsTrash />
            </i>
            <p>Delete</p>
          </div>
        </div>
      </div>
    </AdminMain>
  );
};

export default ManageAdmin;
