import React from "react";
import classes from "./DeliveryAddress.module.css";
interface Props {
  address: string;
  fullName: string;
  phoneNumber: string;
  city?: string;
  country?: string;
  state?: string;
}
const DeliveryAddress: React.FC<Props> = ({
  address,
  fullName,
  phoneNumber,
  city,
  country,
  state,
}): JSX.Element => {
  return (
    <div className={classes.container}>
      <h3>Delivery address</h3>
      <div>
        {fullName && <p><b>Full name:</b> {fullName}</p>}
        <br />
        {address && <p><b>Address:</b> {address}</p>}
        <br />
        {phoneNumber && <p><b>Phone Number:</b> {phoneNumber}</p>}
      </div>
      {city && <p><b>city:</b> {city}</p>}
      {state && <p><b>State:</b> {state}</p>}
    </div>
  );
};

export default DeliveryAddress;
