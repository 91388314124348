export const orderData = [
  {
    id: Math.random(),
    orderNumber: Math.random().toString().substr(1, 6),
    items: Math.random().toString().substr(2, 3),
    price: parseInt(Math.random().toString().substr(2, 6)).toLocaleString(),
    location: "Port Harcourt",
    dateAndTime: `${Math.random().toString().substr(1, 3)}:${Math.random()
      .toString()
      .substr(1, 3)}; ${Math.random().toString().substr(2, 3)}/${Math.random()
      .toString()
      .substr(1, 2)}/22`,
    status: "Pending",
    brand: "Nsxus",
    category: "Refrigerators",
    review: "Pending",
    from: "Daniel",
    reason: "Order Status",
    to: "All Vendors",
    title: "Samsung Slash Deals",
    email: 'Admin@gmail.com',
    adminType: 'Super Admin',
  },
  {
    id: Math.random(),
    orderNumber: Math.random().toString().substr(1, 6),
    items: Math.random().toString().substr(2, 3),
    price: parseInt(Math.random().toString().substr(2, 6)).toLocaleString(),
    location: "Port Harcourt",
    dateAndTime: `${Math.random().toString().substr(1, 3)}:${Math.random()
      .toString()
      .substr(1, 3)}; ${Math.random().toString().substr(2, 3)}/${Math.random()
      .toString()
      .substr(1, 2)}/22`,
    status: "Pending",
    brand: "Nsxus",
    category: "Refrigerators",
    review: "Pending",
    from: "Daniel",
    reason: "Order Status",
    to: "All Vendors",
    title: "Samsung Slash Deals",
    email: 'Admin@gmail.com',
    adminType: 'Super Admin',
  },
  {
    id: Math.random(),
    orderNumber: Math.random().toString().substr(1, 6),
    items: Math.random().toString().substr(2, 3),
    price: parseInt(Math.random().toString().substr(2, 6)).toLocaleString(),
    location: "Port Harcourt",
    dateAndTime: `${Math.random().toString().substr(1, 3)}:${Math.random()
      .toString()
      .substr(1, 3)}; ${Math.random().toString().substr(2, 3)}/${Math.random()
      .toString()
      .substr(1, 2)}/22`,
    status: "Pending",
    brand: "Nsxus",
    category: "Refrigerators",
    review: "Pending",
    from: "Daniel",
    reason: "Order Status",
    to: "All Vendors",
    title: "Samsung Slash Deals",
    email: 'Admin@gmail.com',
    adminType: 'Super Admin',
  },
  {
    id: Math.random(),
    orderNumber: Math.random().toString().substr(1, 6),
    items: Math.random().toString().substr(2, 3),
    price: parseInt(Math.random().toString().substr(2, 6)).toLocaleString(),
    location: "Port Harcourt",
    dateAndTime: `${Math.random().toString().substr(1, 3)}:${Math.random()
      .toString()
      .substr(1, 3)}; ${Math.random().toString().substr(2, 3)}/${Math.random()
      .toString()
      .substr(1, 2)}/22`,
    status: "Pending",
    brand: "Nsxus",
    category: "Refrigerators",
    review: "Pending",
    from: "Daniel",
    reason: "Order Status",
    to: "All Vendors",
    title: "Samsung Slash Deals",
    email: 'Admin@gmail.com',
    adminType: 'Super Admin',
  },
  {
    id: Math.random(),
    orderNumber: Math.random().toString().substr(1, 6),
    items: Math.random().toString().substr(2, 3),
    price: parseInt(Math.random().toString().substr(2, 6)).toLocaleString(),
    location: "Port Harcourt",
    dateAndTime: `${Math.random().toString().substr(1, 3)}:${Math.random()
      .toString()
      .substr(1, 3)}; ${Math.random().toString().substr(2, 3)}/${Math.random()
      .toString()
      .substr(1, 2)}/22`,
    status: "Pending",
    brand: "Nsxus",
    category: "Refrigerators",
    review: "Pending",
    from: "Daniel",
    reason: "Order Status",
    to: "All Vendors",
    title: "Samsung Slash Deals",
    email: 'Admin@gmail.com',
    adminType: 'Super Admin',
  },
  {
    id: Math.random(),
    orderNumber: Math.random().toString().substr(1, 6),
    items: Math.random().toString().substr(2, 3),
    price: parseInt(Math.random().toString().substr(2, 6)).toLocaleString(),
    location: "Port Harcourt",
    dateAndTime: `${Math.random().toString().substr(1, 3)}:${Math.random()
      .toString()
      .substr(1, 3)}; ${Math.random().toString().substr(2, 3)}/${Math.random()
      .toString()
      .substr(1, 2)}/22`,
    status: "Pending",
    brand: "Nsxus",
    category: "Refrigerators",
    review: "Pending",
    from: "Daniel",
    reason: "Order Status",
    to: "All Vendors",
    title: "Samsung Slash Deals",
    email: 'Admin@gmail.com',
    adminType: 'Super Admin',
  },
  {
    id: Math.random(),
    orderNumber: Math.random().toString().substr(1, 6),
    items: Math.random().toString().substr(2, 3),
    price: parseInt(Math.random().toString().substr(2, 6)).toLocaleString(),
    location: "Port Harcourt",
    dateAndTime: `${Math.random().toString().substr(1, 3)}:${Math.random()
      .toString()
      .substr(1, 3)}; ${Math.random().toString().substr(2, 3)}/${Math.random()
      .toString()
      .substr(1, 2)}/22`,
    status: "Pending",
    brand: "Nsxus",
    category: "Refrigerators",
    review: "Pending",
    from: "Daniel",
    reason: "Order Status",
    to: "All Vendors",
    title: "Samsung Slash Deals",
    email: 'Admin@gmail.com',
    adminType: 'Super Admin',
  },
  {
    id: Math.random(),
    orderNumber: Math.random().toString().substr(1, 6),
    items: Math.random().toString().substr(2, 3),
    price: parseInt(Math.random().toString().substr(2, 6)).toLocaleString(),
    location: "Port Harcourt",
    dateAndTime: `${Math.random().toString().substr(1, 3)}:${Math.random()
      .toString()
      .substr(1, 3)}; ${Math.random().toString().substr(2, 3)}/${Math.random()
      .toString()
      .substr(1, 2)}/22`,
    status: "Pending",
    brand: "Nsxus",
    category: "Refrigerators",
    review: "Pending",
    from: "Daniel",
    reason: "Order Status",
    to: "All Vendors",
    title: "Samsung Slash Deals",
    email: 'Admin@gmail.com',
    adminType: 'Super Admin',
  },
  {
    id: Math.random(),
    orderNumber: Math.random().toString().substr(1, 6),
    items: Math.random().toString().substr(2, 3),
    price: parseInt(Math.random().toString().substr(2, 6)).toLocaleString(),
    location: "Port Harcourt",
    dateAndTime: `${Math.random().toString().substr(1, 3)}:${Math.random()
      .toString()
      .substr(1, 3)}; ${Math.random().toString().substr(2, 3)}/${Math.random()
      .toString()
      .substr(1, 2)}/22`,
    status: "Pending",
    brand: "Nsxus",
    category: "Refrigerators",
    review: "Pending",
    from: "Daniel",
    reason: "Order Status",
    to: "All Vendors",
    title: "Samsung Slash Deals",
    email: 'Admin@gmail.com',
    adminType: 'Super Admin',
  },
  {
    id: Math.random(),
    orderNumber: Math.random().toString().substr(1, 6),
    items: Math.random().toString().substr(2, 3),
    price: parseInt(Math.random().toString().substr(2, 6)).toLocaleString(),
    location: "Port Harcourt",
    dateAndTime: `${Math.random().toString().substr(1, 3)}:${Math.random()
      .toString()
      .substr(1, 3)}; ${Math.random().toString().substr(2, 3)}/${Math.random()
      .toString()
      .substr(1, 2)}/22`,
    status: "Pending",
    brand: "Nsxus",
    category: "Refrigerators",
    review: "Pending",
    from: "Daniel",
    reason: "Order Status",
    to: "All Vendors",
    title: "Samsung Slash Deals",
    email: 'Admin@gmail.com',
    adminType: 'Super Admin',
  },
  {
    id: Math.random(),
    orderNumber: Math.random().toString().substr(1, 6),
    items: Math.random().toString().substr(2, 3),
    price: parseInt(Math.random().toString().substr(2, 6)).toLocaleString(),
    location: "Port Harcourt",
    dateAndTime: `${Math.random().toString().substr(1, 3)}:${Math.random()
      .toString()
      .substr(1, 3)}; ${Math.random().toString().substr(2, 3)}/${Math.random()
      .toString()
      .substr(1, 2)}/22`,
    status: "Pending",
    brand: "Nsxus",
    category: "Refrigerators",
    review: "Pending",
    from: "Daniel",
    reason: "Order Status",
    to: "All Vendors",
    title: "Samsung Slash Deals",
    email: 'Admin@gmail.com',
    adminType: 'Super Admin',
  },
  {
    id: Math.random(),
    orderNumber: Math.random().toString().substr(1, 6),
    items: Math.random().toString().substr(2, 3),
    price: parseInt(Math.random().toString().substr(2, 6)).toLocaleString(),
    location: "Port Harcourt",
    dateAndTime: `${Math.random().toString().substr(1, 3)}:${Math.random()
      .toString()
      .substr(1, 3)}; ${Math.random().toString().substr(2, 3)}/${Math.random()
      .toString()
      .substr(1, 2)}/22`,
    status: "Pending",
    brand: "Nsxus",
    category: "Refrigerators",
    review: "Pending",
    from: "Daniel",
    reason: "Order Status",
    to: "All Vendors",
    title: "Samsung Slash Deals",
    email: 'Admin@gmail.com',
    adminType: 'Super Admin',
  },
  {
    id: Math.random(),
    orderNumber: Math.random().toString().substr(1, 6),
    items: Math.random().toString().substr(2, 3),
    price: parseInt(Math.random().toString().substr(2, 6)).toLocaleString(),
    location: "Port Harcourt",
    dateAndTime: `${Math.random().toString().substr(1, 3)}:${Math.random()
      .toString()
      .substr(1, 3)}; ${Math.random().toString().substr(2, 3)}/${Math.random()
      .toString()
      .substr(1, 2)}/22`,
    status: "Pending",
    brand: "Nsxus",
    category: "Refrigerators",
    review: "Pending",
    from: "Daniel",
    reason: "Order Status",
    to: "All Vendors",
    title: "Samsung Slash Deals",
    email: 'Admin@gmail.com',
    adminType: 'Super Admin',
  },
  {
    id: Math.random(),
    orderNumber: Math.random().toString().substr(1, 6),
    items: Math.random().toString().substr(2, 3),
    price: parseInt(Math.random().toString().substr(2, 6)).toLocaleString(),
    location: "Port Harcourt",
    dateAndTime: `${Math.random().toString().substr(1, 3)}:${Math.random()
      .toString()
      .substr(1, 3)}; ${Math.random().toString().substr(2, 3)}/${Math.random()
      .toString()
      .substr(1, 2)}/22`,
    status: "Pending",
    brand: "Nsxus",
    category: "Refrigerators",
    review: "Pending",
    from: "Daniel",
    reason: "Order Status",
    to: "All Vendors",
    title: "Samsung Slash Deals",
    email: 'Admin@gmail.com',
    adminType: 'Super Admin',
  },
  {
    id: Math.random(),
    orderNumber: Math.random().toString().substr(1, 6),
    items: Math.random().toString().substr(2, 3),
    price: parseInt(Math.random().toString().substr(2, 6)).toLocaleString(),
    location: "Port Harcourt",
    dateAndTime: `${Math.random().toString().substr(1, 3)}:${Math.random()
      .toString()
      .substr(1, 3)}; ${Math.random().toString().substr(2, 3)}/${Math.random()
      .toString()
      .substr(1, 2)}/22`,
    status: "Pending",
    brand: "Nsxus",
    category: "Refrigerators",
    review: "Pending",
    from: "Daniel",
    reason: "Order Status",
    to: "All Vendors",
    title: "Samsung Slash Deals",
    email: 'Admin@gmail.com',
    adminType: 'Super Admin',
  },
  {
    id: Math.random(),
    orderNumber: Math.random().toString().substr(1, 6),
    items: Math.random().toString().substr(2, 3),
    price: parseInt(Math.random().toString().substr(2, 6)).toLocaleString(),
    location: "Port Harcourt",
    dateAndTime: `${Math.random().toString().substr(1, 3)}:${Math.random()
      .toString()
      .substr(1, 3)}; ${Math.random().toString().substr(2, 3)}/${Math.random()
      .toString()
      .substr(1, 2)}/22`,
    status: "Pending",
    brand: "Nsxus",
    category: "Refrigerators",
    review: "Pending",
    from: "Daniel",
    reason: "Order Status",
    to: "All Vendors",
    title: "Samsung Slash Deals",
    email: 'Admin@gmail.com',
    adminType: 'Super Admin',
  },
  {
    id: Math.random(),
    orderNumber: Math.random().toString().substr(1, 6),
    items: Math.random().toString().substr(2, 3),
    price: parseInt(Math.random().toString().substr(2, 6)).toLocaleString(),
    location: "Port Harcourt",
    dateAndTime: `${Math.random().toString().substr(1, 3)}:${Math.random()
      .toString()
      .substr(1, 3)}; ${Math.random().toString().substr(2, 3)}/${Math.random()
      .toString()
      .substr(1, 2)}/22`,
    status: "Pending",
    brand: "Nsxus",
    category: "Refrigerators",
    review: "Pending",
    from: "Daniel",
    reason: "Order Status",
    to: "All Vendors",
    title: "Samsung Slash Deals",
    email: 'Admin@gmail.com',
    adminType: 'Super Admin',
  },
  {
    id: Math.random(),
    orderNumber: Math.random().toString().substr(1, 6),
    items: Math.random().toString().substr(2, 3),
    price: parseInt(Math.random().toString().substr(2, 6)).toLocaleString(),
    location: "Port Harcourt",
    dateAndTime: `${Math.random().toString().substr(1, 3)}:${Math.random()
      .toString()
      .substr(1, 3)}; ${Math.random().toString().substr(2, 3)}/${Math.random()
      .toString()
      .substr(1, 2)}/22`,
    status: "Pending",
    brand: "Nsxus",
    category: "Refrigerators",
    review: "Pending",
    from: "Daniel",
    reason: "Order Status",
    to: "All Vendors",
    title: "Samsung Slash Deals",
    email: 'Admin@gmail.com',
    adminType: 'Super Admin',
  },
];
