import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getClientToken } from "../../components/cookie/getClientToken";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import VendorMain from "../../components/UI/VendorMain/VendorMain";
import { Domain } from "../../Domain/Domain";
import classes from "../../styles/Account.module.css";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../../store/slices/alertSlice";
import { API_URL } from "../../Domain/Domain"

const VendorProfileUpdate = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate();

  const [message, setMessage] = useState({ error: "", success: "" });
  const [enteredData, setEnteredData] = useState({
    shopName: "",
    address: "",
    state: "",
    phoneNumber: "",
    email: "",
    bio: "",
  });
  const onChangeHandler = (e: any) => {
    setEnteredData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const urlSearch = new URLSearchParams(window.location.search);

  useEffect(() => {
    let vendorData = localStorage.getItem("vendor") as any;
    if (vendorData === null) {
      // return alert("Please login");
      dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "Please login",
          status: "error",
        })
      );
    }

    vendorData = JSON.parse(vendorData);

    fetch(`${API_URL}/api/v1/vendor/profile/` + vendorData.vendorId, {
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("vendor-token") as string,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response: any) => {
        // console.log('response on mount: ', response);
        if (response.status === 200) {
          console.log("===>", response.data);
          //   let foundAddress = response.data.filter(
          //     (cur: any) => cur.id === parseInt(urlSearch.get("id") as string)
          //   );
          let obj = response.data.vendorProfileDetails;
          setEnteredData((prevState) => {
            return {
              ...prevState,
              ...obj,
            };
          });
        }
      })
      .catch((error) => {
        console.log(error);

        // alert(error.response.data.message);
        // console.log(error.response.data.message);
      });
  }, [])

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);

    let vendorData = localStorage.getItem("vendor") as any;
    // if (vendorData === null) return
    // alert("Please login");
    if (vendorData === null)
      return dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "Please login",
          status: "error",
        })
      );

    vendorData = JSON.parse(vendorData);

    console.log(localStorage.getItem("vendor-token") as string);
    let vendorDetails = {
      shopName: enteredData.shopName,
      address: enteredData.address,
      state: enteredData.state,
      phoneNumber: enteredData.phoneNumber,
      email: enteredData.email,
      bio: enteredData.bio,
    };
    fetch(
      `${API_URL}/api/v1/vendor/update-profile/` +
        vendorData.id,
      {
        body: JSON.stringify(vendorDetails),
        method: "PATCH",
        headers: {
          Authorization: localStorage.getItem("vendor-token") as string,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response: any) => {
        setLoader(false);

        console.log("submit response: ", response);
        if (response.status === 200) {
          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: response.message,
              status: "success",
            })
          );
        } else {
          throw new Error(response.message);
        }

      })
      .catch((error) => {
        // alert(error);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: error.message,
            status: "error",
          })
        );
        setLoader(false);
        console.log(error);
        // setMessage({
        //   error: error.response.data.message,
        //   success: "",
        // });
      });
  }

  return (
    <VendorMain>
      <form onSubmit={onSubmitHandler}>
        <h1>UPDATE PERSONAL INFO</h1>
        <br />
        <div>
          <div className={classes.messages_data}>
            <p className="error_message">
              {message.error !== "" && message.error}
            </p>
            <p className="success_message">
              {message.success !== "" && message.success}
            </p>
          </div>
          <div className={classes.application_form}>
            <>
              <label htmlFor="">{"Shop name"}</label>
              <input
                type={"text"}
                name={"shopName"}
                placeholder={"Shop name"}
                value={enteredData.shopName}
                required
                onChange={onChangeHandler}
              />
            </>
            <br />

            <>
              <label htmlFor="">{"Phone number"}</label>
              <input
                type={"text"}
                name={"phoneNumber"}
                placeholder={"+01-1234567"}
                required
                value={enteredData.phoneNumber}
                onChange={onChangeHandler}
              />
            </>
            <br />
            <>
              <label htmlFor="">{"Email address"}</label>
              <input
                type={"email"}
                name={"email"}
                placeholder={"example@gmail.com"}
                value={enteredData.email}
                required
                onChange={onChangeHandler}
              />
            </>
            <br />

            <>
              <label htmlFor="">{"Address"}</label>
              <input
                type={"text"}
                name={"address"}
                placeholder={"Address"}
                required
                value={enteredData.address}
                onChange={onChangeHandler}
              />
            </>
            <br />

            <>
              <label htmlFor="">{"Bio"}</label>
              <input
                type={"text"}
                name={"bio"}
                placeholder={"Bio"}
                required
                value={enteredData.bio}
                onChange={onChangeHandler}
              />
            </>
            <br />

            <div>
              <label htmlFor="">Select State</label>
              <select id="" name={"state"} onChange={onChangeHandler} required>
                <option value={enteredData.state}>{enteredData.state}</option>
                <option value={"Rivers State"}>Port Harcourt</option>
              </select>
            </div>
            <br />
          </div>
          <Link to={"/vendor-change-password"}>Change password</Link> 
          <br />
          <br />
          <div className={classes.add_adderess_button + " " + classes.btn_2}>
            <button>UPDATE</button>
            {loader && <SmallSpinner />}
          </div>
        </div>
      </form>
    </VendorMain>
  )
}

export default VendorProfileUpdate;
