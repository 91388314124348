import React from "react";
import { Link } from "react-router-dom";
import classes from "./Topheader.module.css";
import { useDispatch } from "react-redux";
import { onSetSearchInPage } from "../../../store/slices/searchSlice";
const Topheader = () => {
  // console.log(window.location.pathname);
  const dispatch = useDispatch();
  return (
    <div className={classes.top_header_div}>
      <Link to={"/"}>
        <button
          style={{
            backgroundColor: window.location.pathname === "/" ? "black" : "",
            color: window.location.pathname === "/" ? "white" : "black",
          }}
          onClick={() => dispatch(onSetSearchInPage("store"))}
        >
          Store
        </button>
      </Link>

      <Link to={"/marketplace"}>
        <button
          style={{
            backgroundColor: window.location.pathname.includes("marketplace")
              ? "#F14306"
              : "",
            color: window.location.pathname.includes("marketplace")
              ? "white"
              : "black",
          }}
          onClick={() => dispatch(onSetSearchInPage("marketplace"))}
        >
          Marketplace
        </button>
      </Link>
      <Link to={"/payment"}>
        <button
          style={{
            backgroundColor: window.location.pathname.includes("payment")
              ? "black"
              : "",
            color: window.location.pathname.includes("payment")
              ? "white"
              : "black",
          }}
        >
          Payment
        </button>
      </Link>
    </div>
  );
};

export default Topheader;
