import axios from "axios";
import React, { useState, useEffect } from "react";
import Footer from "../components/UI/Footer/Footer";
import Header from "../components/UI/Header/Header";
import classes from "../components/../styles/ProductPageStyles.module.css";
import { BsCart4, BsStarFill } from "react-icons/bs";
import ProductsAds from "../components/ProductAds/ProductsAds";
import ProductItem from "../components/ProductItem/ProductItem";
import MarketPlaceProduct from "../components/MarketPlaceProduct/MarketPlaceProduct";
import { Link, useParams } from "react-router-dom";
import { API_URL, Domain } from "../Domain/Domain";
import Spinner from "../components/Spinner/Spinner";
import useAddToVendorCartHook from "../Hooks/useAddToVendorCartHook";
import {
  Ad,
  getImagesFromAd,
  getPromotionData,
  Promo,
} from "../utils/promotionHelpers";
import { useDispatch, useSelector } from "react-redux";
import { addPromotionData } from "../store/slices/promotionsSlice";
const MarketPlaceDealPage = () => {
  const params = useParams();
  const dispatch = useDispatch();

  interface SeoData {
    title: string,
    description: string
  }

  const promotionSelector = useSelector((value: any) => value.promotion);
  const [promotions, setPromotions] = useState<Ad[]>([]);
  const [marketPlaceProdMidAdData, setMarketPlaceProdMidAdData] = useState<
    Promo[]
  >([]);
  const [marketPlaceProdMidAdImages, setMarketPlaceProdMidAdImages] = useState<
    { url: string }[]
  >([]);

  const [product, setProduct] = useState<any>({});
  let [images, setImages] = useState([]);
  let [specification, setSpecification] = useState([]);
  let [colors, setColors] = useState([]);
  const [largeImage, setLargeImage] = useState<string>("");
  const [products, setProducts] = useState<any>([]);
  const [relatedProduct, setRelatedProduct] = useState<any>([]);
  const [vendorDetails, setShowVendorDetails] = useState<any>(false);
  const [seoData, setSeoData] = useState<SeoData | null>(null); 

  // Scroll top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { addToCart } = useAddToVendorCartHook(product);

  useEffect(() => {
    const pageTitle = seoData?.title ?? 'Electronic Shop';
    document.title = pageTitle;

    const metaDescriptionElement: HTMLMetaElement | null = document.querySelector('meta[name="description"]');
    if (metaDescriptionElement) {
      const metaDescriptionContent = seoData?.description ?? 'Electronic Shop';
      metaDescriptionElement.content = metaDescriptionContent;
    } else {
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.name = 'description';
      newMetaDescription.content = seoData?.description ?? 'Electronic Shop';
      document.head.appendChild(newMetaDescription);
    }

    console.log('Updated Page Title:', pageTitle);
    console.log('Updated Meta Description:', metaDescriptionElement?.content);

    return () => {
      document.title = 'Electronic Shop';

      if (metaDescriptionElement) {
        metaDescriptionElement.content = 'Electronic Shop';
      }
    };
  }, [seoData?.title, seoData?.description]); 

  useEffect(() => {
    let url = new URLSearchParams(window.location.search);
    axios
      .get(Domain() + `/api/v1/marketplace/product/` + url.get("id"))
      .then((response: any) => {
        console.log("================>", response.data.data);
        setSeoData(response.data?.data!.seo_data)
        setProduct(response.data?.data!.product); 
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        Domain() +
          `/api/v1/marketplace/product/related-product/${product?.brand!}/${product?.category!}
          `
      )
      .then((response: any) => {
        console.log("::::::======>", response);
        if (response.data.status === 200) setRelatedProduct(response.data.data);
      })
      .catch((error) => {});
  }, [product?.category! as any]);

  useEffect(() => {
    let imageArr = product?.pictures!;
    if (imageArr !== undefined) {
      setImages(JSON.parse(imageArr));
      setLargeImage(JSON.parse(imageArr)[0]);
    }

    let specArray = product?.specification!;
    if (specArray !== undefined) setSpecification(JSON.parse(specArray));

    let colorArray = product?.colours!;
    if (colorArray !== undefined) setColors(JSON.parse(colorArray));
  }, [product.pictures]);

  const onSelectImageHandler = (e: any) => {
    setLargeImage(e.target.src);
  };

  useEffect(() => {
    setPromotions(promotionSelector.promotionsArray);
  }, [promotionSelector.promotionsArray]);

  useEffect(() => {
    console.log({
      promotions,
    });

    // if (promotions.length > 0) {
    getPromotionData(5, "Market Place Product Page Middle Ad").then((res) => {
      dispatch(addPromotionData(res));
      const imagesFromAd = getImagesFromAd(res.data);

      setMarketPlaceProdMidAdData(res.data);
      setMarketPlaceProdMidAdImages(imagesFromAd);
    });
    // }
  }, [promotions]);

  return (
    <>
      <Header />
      <article className={classes.article}>
        <div>
          <h4 style={{ cursor: "pointer" }}>
            <Link to={"/"}>Store</Link> {">"} {product.brand_id?.name!} {">"}{" "}
            {product.category_id?.name!} {" > "}
            <span style={{ color: "#F14306" }}>{product?.productName!}</span>
          </h4>
        </div>
        <br />

        <div className={classes.first_details_section}>
          <div className={classes.large_image_preview}>
            <img src={largeImage} alt="" />
          </div>

          <div className={classes.right_detail_preview}>
            <h3>
              {" "}
              {product.brand_id?.name!} {product?.productName!}
            </h3>
            <p className={classes.brand_name} style={{ color: "black" }}>
              Brand: {product.brand_id?.name!}
            </p>

            <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
              <h1
                style={{
                  color: product.promotionalPrice > 0 ? "black" : "#F14306",
                  textDecoration:
                    product.promotionalPrice > 0 ? "line-through" : "none",
                }}
              >
                ₦{product.pricing?.toLocaleString()}
              </h1>
              {product.promotionalPrice > 0 && (
                <h1 style={{ color: "#F14306" }}>
                  ₦ {product.promotionalPrice?.toLocaleString()}
                </h1>
              )}
            </div>
            {+product.bulkPrice > 0 && product.range > 0 && (
              <p>
                This item is selling for <b>{product.bulkPrice}</b> when buying
                from <b>{product.range}</b> items.
              </p>
            )}
            <div className={classes.three_small_images_prev}>
              {images.map((cur, index) => (
                <span key={index}>
                  {cur !== "" && (
                    <img onClick={onSelectImageHandler} src={cur} alt="" />
                  )}
                </span>
              ))}
            </div>
            <div className={classes.action_button}>
              <button onClick={addToCart} id="cartButton">
                <i>
                  <BsCart4 />
                </i>
                ADD TO CART
              </button>
            </div>
            <div className={classes.powered_by}>
              <p>Posted by</p>
              <h4>{product?.shop_details?.shopName ?? "-"}</h4>
            </div>
            <div className="info-box">
              <p>
                Always take note of Vendor shop Id{" "}
                <code
                  style={{
                    backgroundColor: "#F14306",
                    color: "white",
                    padding: 5,
                    borderRadius: 5,
                  }}
                >
                  {" "}
                  {product?.shop_details?.vendorId ?? "-"}{" "}
                </code>{" "}
                should incase for resolution with Electronicshop
              </p>
            </div>
            {/* <div className={classes.rating}>
              <i>
                <BsStarFill />
              </i>
              <i>
                <BsStarFill />
              </i>
              <i>
                <BsStarFill />
              </i>
              <i>
                <BsStarFill />
              </i>
              <i>
                <BsStarFill />
              </i>{" "}
              <span>24</span>
            </div> */} 
            <br />
            <>
              {vendorDetails && (
                <div>
                  <p>
                    <b>Phone: {product?.shop_details?.phoneNumber ?? "-"}</b>
                  </p>
                  <p>Address: {product?.shop_details?.address ?? "-"}</p>
                </div>
              )}
            </>
            <div className={classes.vendor_buttons}>
              <button
                onClick={() => {
                  setShowVendorDetails(!vendorDetails);
                }}
              >
                CONTACT VENDOR
              </button>

              <Link
                to={`/vendorshop/${product?.shop_details?.customised_shop_id}`}
              >
                <button>MORE FROM VENDOR</button>
              </Link>
            </div>
          </div>
        </div>

        <br />
        <div className={classes.detail_section_div}>
          <div className={classes.descritpion_section}>
            <h4>Description:</h4>
            <br />
            <p style={{ whiteSpace: 'pre-line' }}>{product?.productDescription}</p>

          </div>
          <section className={classes.specifications}>
            <h3>Specifications:</h3>
            <br />
            <table>
              <tbody>
                {specification.map((cur: any, index: number) => (
                  <tr key={index}>
                    {cur.attribute !== "" && cur.value !== "" && (
                      <>
                        <td>{cur.attribute}</td>
                        <td>{cur.value}</td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </div>

        <br />
        <br />

        <ProductsAds images={marketPlaceProdMidAdImages} 
          style={{
            width:'100px',
            height:'200px',
            display:'block'
          }}

        />
        <br />
        <br />
        <br />
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <h1 style={{ color: "#F14306" }}>Similar Deals</h1>

            <Link
              to={
                `/seemore?title=Similar Deals&&link=` +
                Domain() +
                `/api/v1/marketplace/product/related-product/${product?.brand!}/${product?.category!}
          `
              }
            >
              <p>See more</p>
            </Link>

            {/* <p style={{ color: "#F14306" }}>
              <Link to={"/marketplace"}>More</Link>
            </p> */}
          </div>
        </div>
        <MarketPlaceProduct
          products={relatedProduct}
          linkRoute="/marketplace-deal"
        />
        {relatedProduct.length < 1 && <Spinner />}
        <br />
      </article>

      <Footer />
    </>
  );
};

export default MarketPlaceDealPage;
