import React from "react";
import Account from "../../components/UI/Account/Account";
import classes from "../../styles/Account.module.css";
const AccountInbox = () => {
  const inbox = [1, 2, 3, 4, 5];
  return (
    <Account>
      <div>
        {inbox.map((cur, index) => (
          <div className={classes.order_container} key={index}>
            <h3 style={{ color: "#0D0F11" }}>RE: Status of my order</h3>
            <p style={{ color: "black" }}>
              Panasonic 1.5HP Split Air Conditioner (XV12), Sony 55" 4K Ultra HD
              Smart TV With Android OS [2] +1 more.
            </p>
            <p>05/11/2022</p>
          </div>
        ))}
      </div>
    </Account>
  );
};

export default AccountInbox;
