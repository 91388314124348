import React, { useEffect, useState } from "react";
import { AiOutlineMinus } from "react-icons/ai";
import { BsPlus, BsTrash } from "react-icons/bs";
import { FaMinus } from "react-icons/fa";
import { Link } from "react-router-dom";
import classes from "./Cart.module.css";
interface Props {
  images: string;
  name: string;
  qty: number;
  price: number;
  productId: number;
}
const VendorCart: React.FC<Props> = ({
  images,
  price,
  name,
  qty,
  productId,
}): JSX.Element => {
  const [qtyState, setQtyState] = useState<any>(99999);
  const [imageArray, setImageArray] = useState<any>([]);

  useEffect(() => {
    setQtyState(qty);
  }, []);

  const onQtyChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== "") {
      // if (!e.target.value.includes("-")) {
      setQtyState(parseInt(e.target.value));
      // }
    }
  };
  const onDecreaseHandler = () => {
    if (qtyState > 0) setQtyState(qtyState - 1);
  };
  const onIncreaseHander = () => {
    setQtyState(qtyState + 1);
  };

  useEffect(() => {
    let storedItem = JSON.parse(localStorage.getItem("vendor-cart") as string);
    let findExistingCart = storedItem.filter(
      (cur: any) => cur.id === productId
    );

    if (findExistingCart.length > 0) {
      let newCartData = storedItem.map((cur: any) => {
        if (cur.id === productId) {
          cur.quantity = qtyState;
        }
        return cur;
      });

      localStorage.setItem("vendor-cart", JSON.stringify(newCartData));
    }
  }, [qtyState]);

  useEffect(() => {
    let newImg = JSON.parse(images);
    if (Array.isArray(newImg)) setImageArray(newImg);
  }, []);

  const onDeleteHandler = () => {
    let storedItem = JSON.parse(localStorage.getItem("vendor-cart") as string);
    if (Array.isArray(storedItem)) {
      console.log(productId);
      let filteredData = storedItem.filter(
        (cur) => cur.id !== productId
      );
      localStorage.setItem("vendor-cart", JSON.stringify(filteredData));
      console.log("=====>", filteredData);
    }
  };

  return (
    <div className={classes.cart_container}>
      <div className={classes.close_div}>
        <i onClick={onDeleteHandler}>
          <BsTrash />
        </i>
      </div>
      <Link to={"/marketplace-deal?id=" + productId}>
        <div className={classes.product_image}>
          <img src={imageArray[0]} alt="" />
        </div>
      </Link>
      <div>
        <div className={classes.descriotion_container}>
          <h3>{name}</h3>
        </div>
        <div className={classes.action_cart_area}>
          <i>
            <AiOutlineMinus onClick={onDecreaseHandler} />
          </i>
          <input
            type="number"
            min={0}
            onChange={onQtyChangeHandler}
            value={qtyState}
          />
          <i>
            <BsPlus onClick={onIncreaseHander} />
          </i>
        </div>
        <div className={classes.price_area}>
          <h2>₦ {(price * qtyState).toLocaleString()}</h2>
        </div>
      </div>
    </div>
  );
};

export default VendorCart;
