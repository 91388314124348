export const SupportTicketStatusString: Record<
  SupportStatusCode,
  SupportStatus
> = {
  1: "open",
  2: "resolving",
  3: "close/resolved",
};

export const SupportTicketStatusCode: Record<SupportStatus, SupportStatusCode> =
  {
    open: 1,
    resolving: 2,
    "close/resolved": 3,
  };
