import React, { useState, useEffect } from "react";
import classes from "./Header.module.css";
import { FiSearch } from "react-icons/fi";
import { BiUserCircle } from "react-icons/bi";
import { BsCart, BsFillCartCheckFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import Topheader from "./Topheader";
import { getClientData } from "../../cookie/getClientData";
import { toggleAlertAction } from "../../../store/slices/alertSlice";
import axios from "axios";
import { API_URL, Domain } from "../../../Domain/Domain";

const TechnicianHeader = () => {

  const navigate = useNavigate();
  const [technician, setTechnician] = useState<any>({});

  useEffect(() => {

    let technicianData = JSON.parse(
      window.localStorage.getItem("technician") as unknown as string
    );
    axios
      .get(`${API_URL}/api/v1/technician/profile/` + technicianData?.id!, {
        headers: {
          Authorization: localStorage.getItem("technician-token") as string,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response: any) => {
        if (response.data.status === 200)
        
          setTechnician(response.data.data.response);
        console.log(response.data.data);
      })
      .catch((error) => {});
  }, []);

  return (
    <>
      <Topheader />

      <header
        className={classes.header}
        style={{
          backgroundColor:
            window.location.pathname.includes("marketplace") ||
            window.location.pathname.includes("technician")
              ? "#F14306"
              : "black",
        }}
      >
        <div className={classes.logo_container}>
          <Link to={"/"}>
            <img src="/logo/logo.png" alt="" />
          </Link>
        </div>
        <div className={classes.search_container}>
          <input
            type="text"
            placeholder="Search for an item"
          />
        
        </div>
        <div className={classes.third_column}>
          <div className={classes.account_div}>
            <div>
              <Link
                to={
                  getClientData().firstName !== undefined
                    ? "/technician-dashboard"
                    : "/login"
                }
              >
                <i>
                  <BiUserCircle />
                </i>
              </Link>
            </div>
            {!window.location.pathname.includes("vendor") ? (
              <div className={classes.hide_on_mobile}>
                {technician ? (
                    <h4>Hi {technician?.firstName}</h4>
                ) : (
                  <Link to={"/login-t"}>
                    <p>Hello, sign in</p>
                  </Link>
                )}
              </div>
            ) : (
              <div className={classes.hide_on_mobile}>
                {technician ? (
                    <h4>Hi {technician?.firstName}</h4>
                ) : (
                  <Link to={"/login-t"}>
                    <p>Hello, sign in</p>
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default TechnicianHeader;
