import React from "react";
import { Link } from "react-router-dom";
import { getCookieUser } from "../../cookie/getCookieUser";
import classes from "./Header.module.css";
const AdminHeader = (): JSX.Element => {
  const admin = getCookieUser();
  return (
    <div className={classes.header}>
      <div className={classes.flex_fist_div}>
        <Link to={"/admin-dashboard"}>
          <img src="/logo/logo.png" alt="" />
        </Link>
        <Link to={"/admin-dashboard"}>
          <div>
            <p>ADMIN PANEL</p>
          </div>
        </Link>
      </div>
      <div className={classes.flex_fist_div}>
        <p>
          {typeof admin.surname === "string" && admin.surname !== "" && (
            <span>Welcome </span>
          )}
          {admin.surname}
        </p>
        {typeof admin.surname === "string" && admin.surname !== "" && (
          <Link to={"/login-v6"}>
            <button>Log out</button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default AdminHeader;
