import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getClientData } from "../../components/cookie/getClientData";
import { getClientToken } from "../../components/cookie/getClientToken";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import Account from "../../components/UI/Account/Account";
import { CreateAddressForm } from "../../data/CreateAddressForm";
import { Domain } from "../../Domain/Domain";
import { FeedbackData } from "../../data/FeedbackData";
import classes from "../../styles/Account.module.css";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../../store/slices/alertSlice";

const FeecbackProfilePage = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState<boolean>(false);
  const [message, setMessage] = useState({ error: "", success: "" });
  const [enteredData, setEnteredData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    orderNumber: "",
    feedback_reason: "",
    description: "",
    submitted_by: "",
  });
  const navigate = useNavigate();

  const onChangeHandler = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setEnteredData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    const user = getClientData();
    e.preventDefault();
    setLoader(true);
    const data = {
      ...enteredData,
      submitted_by: user.id,
    };

    console.log("submitting feedback data: ", data);

    axios
      .post(Domain() + "/api/v1/feedback", JSON.stringify(data), {
        headers: {
          Authorization: getClientToken(),
          "Content-Type": "application/json",
        },
      })

      .then((response: any) => {
        setLoader(false);
        console.log(response);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: "Feedback submitted successfully",
            status: "",
          })
        );
        setLoader(false);
        // alert("Feedback submitted successfully");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        // alert("An error occured");
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: "An error occured",
            status: "error",
          })
        );
      });
  };
  return (
    <Account>
      <form onSubmit={onSubmitHandler}>
        <div>
          {FeedbackData.map((cur, index) => (
            <div key={index} className={classes.application_form}>
              {cur.isInput && (
                <>
                  <label htmlFor="">{cur.labelFor}</label>
                  <input
                    type={cur.type}
                    name={cur.name}
                    onChange={onChangeHandler}
                    placeholder={cur.placeHolder}
                    required
                  />
                </>
              )}
              {cur.type === "select" && (
                <>
                  <label htmlFor="">{cur.placeHolder}</label>
                  <select
                    name={cur.name}
                    id=""
                    onChange={onChangeHandler}
                    required
                  >
                    <option value="">{cur.placeHolder}</option>
                    {cur.options?.map((option) => (
                      <option value={option}>{option}</option>
                    ))}
                  </select>
                </>
              )}

              {cur.type === "textarea" && (
                <>
                  <label htmlFor="">{cur.placeHolder}</label>
                  <textarea
                    name="description"
                    id=""
                    onChange={onChangeHandler}
                    required
                  ></textarea>
                </>
              )}
            </div>
          ))}

          <div className={classes.add_adderess_button + " " + classes.btn_2}>
            <button>SEND MESSAGE</button>
            {loader && <SmallSpinner />}
          </div>
        </div>
      </form>
    </Account>
  );
};

export default FeecbackProfilePage;
