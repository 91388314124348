export const FeedbackData = [
  {
    type: "text",
    placeHolder: "John Doe",
    labelFor: "Name",
    name: "name",
    value: "",
    isInput: true,
  },

  {
    type: "email",
    placeHolder: "example@email.com",
    labelFor: "Email",
    name: "email",
    value: "",
    isInput: true,
  },

  {
    type: "number",
    placeHolder: "+234 8123456789",
    labelFor: "Phone Number",
    name: "phoneNumber",
    value: "",
    isInput: true,
  },
  {
    type: "text",
    placeHolder: "",
    labelFor: "Order Number",
    name: "orderNumber",
    value: "",
    isInput: true,
  },
  {
    type: "select",
    placeHolder: "Reason for Feedback",
    labelFor: "Reason for Feedback",
    name: "feedback_reason",
    value: "",
    isInput: false,
    options: ["Return/Refund", "After Sales", "Technicians", "Others"],
  },
  {
    type: "textarea",
    placeHolder: "Description",
    labelFor: "",
    name: "description",
    value: "",
    isInput: false,
  },
];
