import { current } from "@reduxjs/toolkit";
import React, { useState, useEffect } from "react";
import Cart from "../../components/Cart/Cart";
import { getClientToken } from "../../components/cookie/getClientToken";
import DeliveryAddress from "../../components/DeliveryAddress/DeliveryAddress";
import OrderItem from "../../components/Order/OrderItem";
import PaymentMethod from "../../components/PaymentMethod/PaymentMethod";
import ReviewProduct from "../../components/ReviewProduct/ReviewProduct";
import Account from "../../components/UI/Account/Account";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import BackButton from "../../components/UI/BackButton/BackButton";
import { Domain } from "../../Domain/Domain";
import useGetCartDetailsHook from "../../Hooks/useGetCartDetailsHook";
import classes from "../../styles/Account.module.css";
import axios from "axios";
import { toggleAlertAction } from "../../store/slices/alertSlice";
import { useDispatch } from "react-redux";
import SmallSpinner from "../../components/Spinner/SmallSpinner"; 

const AdminGeneralOrder = () => {
  const dispatch = useDispatch();
  const { cart } = useGetCartDetailsHook();
  const [order, setOrderData] = useState<any>({});
  const [orderItems, setOrderItems] = useState<any>([]);
  const utlParams = new URLSearchParams(window.location.search);
  const [approved, setApproved] = useState<any>(false);
  const [cancelled, setCancelled] = useState<any>(false);
  const [pickupAddress, setPickupAddress] = useState<any>("");
  const [pickupDate, setPickupDate] = useState<any>("");
  const [pickupPhonenumber, setPickupPhonenumber] = useState<any>("");
  const [loader, setLoader] = useState<boolean>(false);
  const token = JSON.parse(localStorage.getItem("token") as string);
  useEffect(() => {
    axios(Domain() + "/api/v1/order/" + utlParams.get("order_id"), {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((response: any) => {
        if (response.status === 200) {
          setOrderData(response.data.data);
          setOrderItems(JSON.parse(response.data.data.order_items));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  const approveOrder = () => {
    // approve order
    setLoader(true);
    let data = {};
    if (order?.order_status === "1") {
      data = {
        order_status: "2",
        pickup_address: pickupAddress ?? null,
        pickup_date: pickupDate ?? null,
        phone_number: +pickupPhonenumber ?? null,
      };
    } else if (order?.order_status === "2") {
      data = {
        order_status: "3",
        pickup_address: order?.pickup_address ?? null,
        pickup_date: order?.pickup_date ?? null,
        phone_number: +order?.phone_number ?? null,
      };
    }
    if (order?.order_status === "1") {
      if (pickupAddress && pickupDate && pickupPhonenumber) {
        axios
          .patch(
            Domain() + "/api/v1/order/" + utlParams.get("order_id"),
            JSON.stringify(data),
            {
              headers: {
                Authorization: token,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response: any) => {
            setLoader(false);
            if (response.status === 200) {
              setOrderData(response.data.data);
              setOrderItems(JSON.parse(response.data.data.order_items));
              dispatch(
                toggleAlertAction({
                  isAlerting: true,
                  message: "Order updated successfully",
                })
              );
              alert('Order updated successfully')

            }
            // alert("Order updated successfully.");
            
            setPickupAddress(null);
            setPickupDate(null);
            setPickupPhonenumber(null);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // alert("Enter Pickup address, phone number and date to approve.");
        setLoader(false);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: "Enter Pickup address, phone number and date to approve",
            status: "error",
          })
        );
      }
    } else {
      console.log("he: ", data);
      axios
        .patch(
          Domain() + "/api/v1/order/" + utlParams.get("order_id"),
          JSON.stringify(data),
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response: any) => {
          setLoader(false);
          if (response.status === 200) {
            dispatch(
              toggleAlertAction({
                isAlerting: true,
                message: "Order updated successfully",
              })
            );
          
            setOrderData(response.data.data);
            setOrderItems(JSON.parse(response.data.data.order_items));

          }
          // alert("Order updated successfully.");
         
          setPickupAddress(null);
          setPickupDate(null);
          setPickupPhonenumber(null);
        })
        .catch((error) => {
          setLoader(false);
          // dispatch(
          //   toggleAlertAction({
          //     isAlerting: true,
          //     message: "There was an error while updating the status of this order",
          //     status: "error",
          //   })
          // );
          console.log(error);
        });
    }
  };
  const cancelOrder = () => {
    setLoader(true);
    // cancel order
    const payload = {
      order_status: "4",
      pickup_address: "",
      pickup_date: "",
    };

    axios(Domain() + "/api/v1/order/" + utlParams.get("order_id"), {
      method: "PATCH",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: { payload },
    })
      .then((response: any) => {
        setLoader(false);
        if (response.status === 200) {
          setOrderData(response.data.data);
          setOrderItems(JSON.parse(response.data.data.order_items));
          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: "Order canceled successfully",
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        // alert(error.response.data.message);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: error.response.data.message,
            status: "error",
          })
        );
      });
  };

  return (
    <AdminMain>
      <div className={classes.detail_section}>
        <BackButton />
        <br />
        <h3>{order?.order_id!}</h3>
        <br />
        <h4>Cart Summary</h4>

        <div className={classes.cart_sumary_div_list}>
          <p>Delivery Fee</p>
          <h3>₦ {order?.delivery_fee!}</h3>
        </div>

        <div className={classes.cart_sumary_div_list}>
          <p>Total </p>
          <h3>₦ {order?.total?.toLocaleString()}</h3>
        </div>

        <br />
        <h4>Items ({orderItems.length})</h4>
        <br />
        {orderItems.map((cur: any, index: number) => (
          <OrderItem
            key={index}
            productId={cur.productId}
            qty={cur.qty}
            hasReview={true}
          />
        ))}
        <br />
        <DeliveryAddress
          address={order?.delivery_address!}
          fullName=""
          phoneNumber=""
        />
        <br />
        <PaymentMethod />
        <br />
        <div className={classes.footer_section_for_order}>
          <div>
            <p>Status</p>
            <h3>
              <>
                {order?.order_status! === "1" && "Successful and pending"}
                {order?.order_status! === "2" && "Available for pickup"}
                {order?.order_status! === "3" && "Delivered and completed"}
                {order?.order_status! === "4" && "Suspicious/Canceled"}
              </>
            </h3>
          </div>
          {/* <div>
            <p>Delivery Date</p>
            <h3>10/11/2022</h3>
          </div> */}
          <div className={classes.review_section}>
            {/* <p>Drop a review</p> */}
          </div>
          {order?.order_status === "1" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <label htmlFor="pickupAddress">Pickup address</label>
                <input
                  id="pickupAddress"
                  placeholder="Enter address"
                  style={{ padding: "2px 4px", fontSize: 16 }}
                  onChange={(e) => setPickupAddress(e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <label htmlFor="pickupAddress">Pickup Phone number</label>
                <input
                  id="pickupAddress"
                  placeholder="Enter phone number"
                  style={{ padding: "2px 4px", fontSize: 16 }}
                  onChange={(e) => setPickupPhonenumber(e.target.value)}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <label htmlFor="pickupDate">Pickup date</label>
                <input
                  id="pickupDate"
                  type="date"
                  onChange={(e) => setPickupDate(e.target.value)}
                />
              </div>
            </div>
          ) : null}

          <div style={{ display: "flex", gap: 8 }}>
            <button
              style={{
                padding: "4px 8px",
                border: "none",
                background: "green",
                color: "white",
              }}
              onClick={approveOrder}
            >
              Approve
            </button>
            <button
              style={{
                padding: "4px 8px",
                border: "none",
                color: "white",
                background: "red",
              }}
              disabled={cancelled}
              onClick={cancelOrder}
            >
              Cancel
            </button>
            
          </div>
          <div>
          {loader && <SmallSpinner />}
          </div>
        </div>
      </div>
    </AdminMain>
  );
};

export default AdminGeneralOrder;
