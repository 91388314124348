import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineMinus } from "react-icons/ai";
import { BsPlus, BsTrash } from "react-icons/bs";
import { FaMinus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Domain } from "../../Domain/Domain";
import { getClientToken } from "../cookie/getClientToken";
import ReviewProduct from "../ReviewProduct/ReviewProduct";
import classes from "./Cart.module.css";
interface Props {
  productId: number | string;
  qty: any;
  hasReview?: boolean;
}
const OrderItem: React.FC<Props> = ({
  productId,
  qty,
  hasReview = false,
}): JSX.Element => {
  // let [product, setProduct] = useState<any>({ promotionalPrice: "" });
  let [product, setProduct] = useState<any>({})

  let [images, setImages] = useState<any>([]);
  const [showModal, setSHowModal] = useState<boolean>(false);
  const onAddReviewHandler = () => {
    setSHowModal(!showModal);
  };

  useEffect(() => {
    axios
      .get(Domain() + "/api/v1/product/" + productId, {
        method: "GET",
        headers: {
          Authorization: getClientToken(),
          "Content-Type": "application/json",
        },
      })
      .then((response: any) => {
        if (response.data.status === 200) {
          setProduct(response.data.data.products);
          setImages(JSON.parse(response.data.data.products.pictures));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className={classes.cart_container}>
      {/* <div className={classes.close_div}></div> */}
      <Link to={"/product?id=" + productId}>
        <div className={classes.product_image}>
          {Array.isArray(images) && <img src={images[0]} />}
        </div>
      </Link>
      <div>
        <div className={classes.descriotion_container}>
          <h3>{product?.productName!}</h3>
          <p>{product?.productDescription!}</p>
        </div>
        <div>
          
        </div>
        <div>
          <p>
            Quantity: <span style={{ color: "#F14306" }}> {qty}</span>
          </p>
          <p>
            Promotional Price:{" "}
            <span
              style={{ color: "#F14306" }}
              className={classes.add_review_button}
            >
              ₦ {product?.promotionalPrice?.toLocaleString()}
            </span>
          </p>
          <p>
            Regular Price: ₦<span>{product?.pricing?.toLocaleString()}</span>
          </p>
          {hasReview && (
            <div className={classes.add_review_button}>
              <button onClick={onAddReviewHandler}>Add a review</button>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <ReviewProduct
          product_id={product?.id!}
          onCloseHandler={() => onAddReviewHandler()}
        />
      )}
    </div>
  );
};

export default OrderItem;
