import { useState, useEffect } from "react";

import AdminMain from "../../components/UI/AdminMain/AdminMain";
import Messages from "../../components/MessageCenter/Messages";
import Pagination from "../../components/Pagination/Pagination";
import classes from "../../styles/AdminFeedback.module.css";
import axios from "axios";
import { Domain } from "../../Domain/Domain";
import { getCookie } from "../../components/cookie/getCookie";
import Spinner from "../../components/Spinner/Spinner";

interface Feedback {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
  orderNumber: string;
  feedback_reason: string;
  description: string;
  submitted_by: number;
  createdAt: string;
  updatedAt: string;
}

const FeedbackList = ({
  list,
  totalPages,
  currentPage,
  updateCurrentPage,
}: {
  list: Feedback[];
  totalPages: number;
  currentPage: number;
  updateCurrentPage: (page: number) => void;
}) => {
  const handlePageChange = (page: number) => {
    updateCurrentPage(page);
  };

  // render list of orders
  return (
    <div className={classes.orderList}>
      <div className={classes.orderListBody}>
        {list.length > 0 ? (
          list.map((feedback: Feedback) => {
            return (
              <article className={classes.order_container} key={feedback?.id}>
                <h3>{feedback?.name}</h3>
                <div className={classes.orderDetails}>
                  <p>{feedback?.email}</p>
                  <p>{feedback?.phoneNumber}</p>
                  <p>{feedback?.orderNumber}</p>
                </div>

                <div className={classes.orderInfo}>
                  <p>{feedback?.feedback_reason}</p>
                  <p>{feedback?.description}</p>
                </div>

                <footer className={classes.orderFooter}>
                  <p>
                    Last updated{" "}
                    {new Date(feedback?.updatedAt).toLocaleDateString()}
                  </p>
                </footer>
              </article>
            );
          })
        ) : (
          <p>No feedbacks found</p>
        )}
      </div>
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        updateCurrentPage={handlePageChange}
      />
    </div>
  );
};

const AdminFeedbackPage = () => {
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([
    {
      id: 1,
      name: "John Doe",
      email: "miracleficient@gmail.com",
      phoneNumber: "08012345678",
      orderNumber: "123456789",
      feedback_reason: "Complaint",
      description: "I have not received my order",
      submitted_by: 1,
      createdAt: "2021-08-10T12:00:00.000Z",
      updatedAt: "2021-08-10T12:00:00.000Z",
    },
  ]);
  const [totalItems, setTotalItems] = useState(0); // total items in the list
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false); // loading state

  const handleUpdateCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const fetchFeedbacks = async () => {
    setIsLoading(true);
    try {
      // fetch feedbacks from the backend
      const res = await axios.get(
        Domain() + `/api/v1/feedback/all-feedbacks/more?page=${currentPage}`,
        {
          headers: {
            Authorization: getCookie(),
          },
        }
      );

      console.log({
        feedbacks: res.data.data,
      });

      // set feedbacks
      setFeedbacks(res.data.data.messages);

      // set total pages
      setTotalPages(res.data.data.totalPages);

      // set total items
      setTotalItems(res.data.data.totalItems);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // fetch feedbacks from the backend
    fetchFeedbacks();
  }, [currentPage]);
  return (
    <AdminMain>
      <div>
        <h1>Feedbacks</h1>
        <br />
        {isLoading ? (
          <Spinner />
        ) : (
          <FeedbackList
            list={feedbacks}
            totalPages={totalPages}
            currentPage={currentPage}
            updateCurrentPage={handleUpdateCurrentPage}
          />
        )}
      </div>
    </AdminMain>
  );
};

export default AdminFeedbackPage;
