import React from "react";
import { BsCardImage } from "react-icons/bs";
import classes from "./NewmEssages.module.css";
const NewMessages = (): JSX.Element => {
  return (
    <div className={classes.container}>
      <div className={classes.input_wrapper}>
        <p>To</p>
        <input type="text" />
      </div>
      <div className={classes.input_wrapper}>
        <p>Title</p>
        <input type="text" />
      </div>
      <br />
      <div className={classes.input_wrapper}>
        <textarea name="" id=""></textarea>
      </div>
      <br />
      <div className={classes.file_upload}>
        <i>
          <BsCardImage />
        </i>
      </div>
      <div className={classes.action_button}>
        <button>SEND MESSAGE</button>
        <button>SAVE AS DRAFT</button>
      </div>
      <br />
      <div className={classes.footer_actions}>
        <input type="checkbox" />
        <p>Schedule</p>
        <input type="date" name="" id="" />
        <input type="time" name="" id="" />
      </div>
    </div>
  );
};

export default NewMessages;
