import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  isAlerting: false,
  message: "",
  status: "",
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    toggleAlertAction(state, { payload }) {
      state.isAlerting = payload.isAlerting;
      state.message = payload.message;
      state.status = payload.status;
    },
  },
});

export const { toggleAlertAction } = alertSlice.actions;
export default alertSlice;
