import React from "react";
import { BsWallet } from "react-icons/bs";
import Account from "../../components/UI/Account/Account";
import classes from "../../styles/Account.module.css";
const AccountWalletPage = () => {
  return (
    <Account>
      <div>
        <h4>Your Account Balance</h4>
        <p>
          *Non-refundable. Can only be used in the store and marketplace
          promotions
        </p>
        <br />
        <div className={classes.wallet_amount}>
          <h3>
            <BsWallet style={{marginRight: '10px'}} />
            ₦56,180.00
          </h3>
        </div>
        <br />
        <div
          className={
            classes.add_adderess_button + " " + classes.wallet_add_utton
          }
        >
          <button>TOP UP WALLET</button>
        </div>
      </div>
    </Account>
  );
};

export default AccountWalletPage;
