import React, { useState } from "react";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import classes from "../../styles/NewBrandPage.module.css";

import useCreateBrandHook from "../../Hooks/useCreateBrandHook";
import useUpdateBrandHook from "../../Hooks/useUpdateBrandHook";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import DeleteBrandAndCategory from "../../components/DeleteBrandAndCategory/DeleteBrandAndCategory";
const AdminNewBrandPage = () => {
  const {
    onhandleChangeName,
    onImageChangeHandler,
    onBrandOrToggleChange,
    onChangeDescription,
    onCreateHandler,
    isBrandorCategory,
    isSubmitting,
  } = useCreateBrandHook();

  const {
    fetchedbands,
    isBrandorCategoryForUpdate,
    onBrandOrToggleChangeForUpdate,
    onChangeNameHandler,
    onBrandUpdated,
    onNewNameHandler,
    onNewDescriptionHandler,
    onChnageFIleHandler,
    isSubmittingForUpdate,
  } = useUpdateBrandHook();

  return (
    <AdminMain>
      <div>
        <p className={classes.title}>New Brand/Category</p>
        <form onSubmit={onCreateHandler}>
          <div className={classes.brand_container_first}>
            <select name="" id="" onChange={onBrandOrToggleChange} required>
              <option value="brand">Brand</option>
              <option value="category">Category</option>
            </select>
            <br />
            <input
              type="text"
              onChange={onhandleChangeName}
              required
              placeholder="Name"
            />
            <br />
            <input
              type="text"
              onChange={onChangeDescription}
              required
              placeholder="Description"
            />
            <p></p>
            <br />
            {/* {isBrandorCategory === "brand" && ( */}
            <input
              type="file"
              required
              accept="image/*"
              onChange={onImageChangeHandler}
            />
            {/* )} */}
          </div>

          <div className={classes.button_container}>
            <button>CREATE</button>
            {isSubmitting && <SmallSpinner />}
          </div>
        </form>
        <br />
        <br />
        {/* EDIT BRAND AND CATEGORY */}
        <br />
        <p className={classes.title}>Edit Brand/Category</p>
        <br />

        <form onSubmit={onBrandUpdated}>
          <div className={classes.brand_container_first}>
            <p>Brand/Category</p>
            <select
              name=""
              id=""
              onChange={onBrandOrToggleChangeForUpdate}
              required
            >
              <option value="brand">Brand</option>
              <option value="category">Category</option>
            </select>
          </div>
          <div className={classes.brand_container_first}>
            <p>Name</p>
            <select onChange={onChangeNameHandler} required>
              <option value="">
                {isBrandorCategoryForUpdate === "brand"
                  ? "Brand names"
                  : "Category names"}
              </option>
              {fetchedbands.map((cur: any) => (
                <option key={cur.id} value={cur.id}>
                  {cur.name}
                </option>
              ))}
            </select>

            <div>
              <input
                type="text"
                onChange={onNewNameHandler}
                placeholder="New name"
                required
              />
            </div>
            <div>
              <input
                type="text"
                onChange={onNewDescriptionHandler}
                placeholder="New Description"
                required
              />
            </div>
            {/* {isBrandorCategoryForUpdate === "brand" && ( */}
            <div>
              <input type="file" name="" id="" onChange={onChnageFIleHandler} />
            </div>
            {/* )} */}
          </div>

          <div className={classes.button_container}>
            <button>SAVE CHANGES</button>
            {isSubmittingForUpdate && <SmallSpinner />}
          </div>
        </form>

        {/* DELETE BRAND AND CATEGORY */}
        <br />
        <br />

        <DeleteBrandAndCategory />
      </div>
    </AdminMain>
  );
};

export default AdminNewBrandPage;
