import React from "react";
import { BsSearch } from "react-icons/bs";
import classes from './AdminSearch.module.css'
const AdminSearch = (): JSX.Element => {
  return (
    <div className={classes.search_order_input}>
      <input type="text" placeholder="Search " />
      <div className={classes.search_icon}>
        <i>
          <BsSearch />
        </i>
      </div>
    </div>
  );
};

export default AdminSearch;
