import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getClientToken } from "../../components/cookie/getClientToken";
import LazyImage from "../../components/LazzyLoad/LazyImage";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import Spinner from "../../components/Spinner/Spinner";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import BackButton from "../../components/UI/BackButton/BackButton";
import VendorMain from "../../components/UI/VendorMain/VendorMain";
import { Domain } from "../../Domain/Domain";
import classes from "../../styles/AdminFoundProduct.module.css";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../../store/slices/alertSlice";

const VendorProduct = () => {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<any>({});
  let [images, setImages] = useState([]);
  let [specification, setSpecification] = useState([]);
  let [colors, setColors] = useState<any>([]);

  useEffect(() => {
    let url = new URLSearchParams(window.location.search);
    //
    axios
      .get(Domain() + "/api/v1/vendor/product/" + url.get("id"), {
        headers: {
          Authorization: window.localStorage.getItem("vendor-token") as string,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response: any) => {
        if (response.data.data !== null) setProduct(response.data?.data!);
        if (response.data.data !== null)
          setColors(JSON.parse(response.data.data?.colors!));
      })
      .catch((error) => {
        console.log(error);
        // alert(
        //   "There was an unusual error, please check your internet connection, or try visiting a valid page."
        // );
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message:
              "There was an unusual error, please check your internet connection, or try visiting a valid page",
            status: "error",
          })
        );
      });
  }, []);

  useEffect(() => {
    let imageArr = product?.pictures!;
    if (imageArr !== undefined) setImages(JSON.parse(imageArr));

    let specArray = product?.specification!;
    if (specArray !== undefined) setSpecification(JSON.parse(specArray));

    let colorArray = product?.colours!;
    if (colorArray !== undefined) setColors(JSON.parse(colorArray));
  }, [product.pictures]);

  const toggleActionButton = (status: string) => {
    setisLoading(true);
    let url = new URLSearchParams(window.location.search);

    fetch(
      status === "activate"
        ? Domain() + "/api/v1/vendor/product/activate/" + url.get("id")
        : Domain() + "/api/v1/vendor/product/deactivate/" + url.get("id"),

      {
        method: "PATCH",
        headers: {
          Authorization: getClientToken(),
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    )
      .then((res) => res.json())
      .then((response: any) => {
        console.log(response);
        setisLoading(false);
        // alert(response.message);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: response.message,
            status: "",
          })
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        setisLoading(false);
        // alert(
        //   "There was an unusual error, please check your internet connection, or try visiting a valid page."
        // );
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message:
              "There was an unusual error, please check your internet connection, or try visiting a valid page",
            status: "error",
          })
        );
      });
  };

  console.log("_______PRODUCT: ", product);
  return (
    <VendorMain>
      <div>
        <BackButton />
        <br />
        <div className={classes.product_container}>
          <p className={classes.title}>{product?.productName!}</p>
          <h5>
            Brand: {product.brand_id?.name!}; Category:{" "}
            {product.category_id?.name!}
          </h5>
          <div className={classes.product_images}>
            {images.map((cur, index) => (
              <span key={index}>
                {cur !== "" && <LazyImage image={cur} alt="" />}
              </span>
            ))}
          </div>
        </div>
        <br />
        {/* <div className={classes.performance}>
          <p>Performance</p>
          <img src="/images/chart/chart.png" alt="" />
        </div> */}
        {+product.bulkPrice > 0 && product.range > 0 && (
          <p style={{ fontSize: 18 }}>
            This item is selling for <b>{product.bulkPrice}</b> when buying from{" "}
            <b>{product.range}</b> items.
          </p>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            margin: "10px 0px",
          }}
        >
          <h1
            style={{
              color: product.promotionalPrice > 0 ? "black" : "#F14306",
              textDecoration:
                product.promotionalPrice > 0 ? "line-through" : "none",
            }}
          >
            ₦{product.pricing?.toLocaleString()}
          </h1>
          {product.promotionalPrice > 0 && (
            <h1 style={{ color: "#F14306" }}>
              ₦ {product.promotionalPrice?.toLocaleString()}
            </h1>
          )}
        </div>
        <div className={classes.detail_container}>
          {/* <p className={classes.price_property}>
            <span>Price:</span>
            <b> ₦ {product?.pricing!} </b>
          </p> */}
          <p className={classes.color_property}>
            <span>Color: </span>
            <b>{colors?.toString()!}</b>
          </p>
        </div>
        <p className={classes.descriptio_property}>
          <span>Description:</span>
          <b> {product?.productDescription!}</b>
        </p>
        <p className={classes.descriptio_property}>
          <span>Product Status:</span>
          <b> {product?.productStatus!}</b>
        </p>
        <div className={classes.tabler_section}>
          <p>Specifications:</p>
          <table>
            <tbody>
              {specification.map((cur: any, index: number) => (
                <tr key={index}>
                  {cur?.attribute! !== "" && cur?.value! !== "" && (
                    <>
                      <td>{cur?.attribute!}</td>
                      <td>{cur?.value!}</td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={classes.edit_button}>
          <Link to={"/vendor-edit-product?id=" + product?.id!}>
            <button style={{ marginRight: "10px" }}>EDIT PRODUCT</button>
          </Link>
        </div>

        <div className={classes.edit_button}>
          {/* {product?.productStatus! && ( */}
          <button
            style={{ marginRight: "10px", backgroundColor: "green" }}
            onClick={() => toggleActionButton("activate")}
          >
            ACTIVATE
          </button>

          <button
            style={{ marginRight: "10px", backgroundColor: "red" }}
            onClick={() => toggleActionButton("deactivate")}
          >
            DEACTIVATE
          </button>
        </div>

        {isLoading && (
          <>
            <br />
            <SmallSpinner />
          </>
        )}
      </div>
    </VendorMain>
  );
};

export default VendorProduct;
