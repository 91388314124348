import React, { useEffect, useState } from "react";
import FAQLIST from "../../components/FAQ/FAQLIST";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import BackButton from "../../components/UI/BackButton/BackButton";
import classes from "../../styles/AdminFAQ.module.css";
import { Domain } from "../../Domain/Domain";
import FAQCategory from "../../components/FAQ/FAQCategory";
import Account from "../../components/UI/Account/Account";
import Spinner from "../../components/Spinner/Spinner";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import Main from "../../components/UI/Main/Main";

const AccountFAQ = () => {
  const [faqCatrgories, setFaqCategories] = useState<any>([]);
  useEffect(() => {
    fetch(Domain() + "/api/v1/faq/faq-categories")
      .then((res) => res.json())
      .then((data) => {
        setFaqCategories(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Main>
      <div>
        <BackButton />
        <br />
        <h3>FAQs</h3>
        <br />

        {faqCatrgories.map((cur: any, index: number) => (
          <FAQCategory data={cur} numbering={index} />
        ))}
      </div>
    </Main>
  );
};

export default AccountFAQ;
