import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Domain2 } from '../../Domain/Domain';
import VendorMain from '../../components/UI/VendorMain/VendorMain';
import VendorOrderDetails from './VendorOrderDetails';
import './VendorOrderDetails.css';

const VendorOrders = () => {
  const [vendorOrder, setVendorOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const token = localStorage.getItem('vendor-token');

  useEffect(() => {
    fetchOrders(currentPage);
  }, [currentPage]);

  const fetchOrders = (page) => {
    axios
      .get(`${Domain2}/vendors/orders-by-users?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
      .then((response) => {
        if (response.data) {
          setVendorOrders(response.data);
        }
      })
      .catch((error) => {});
  };

  const openModal = (order) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedOrder(null);
    setIsModalOpen(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };


  return (
    <VendorMain>
      <h1>User Orders</h1><br /><br />
      {vendorOrder.length > 0 ? (
        <div>  
          <div>
          {vendorOrder.map((order, index) => (
            <div key={order.id} className='v-order-item'>
              <h2>{index + 1}. {order.order_id}</h2>
              <p>{order.fullname}</p>
              <p>{ new Date(order.createdAt).toLocaleString()}</p>
              <p>{order.order_status ? <span style={{ color: 'green'}}>Confirmed</span> : <span style={{ color: 'red'}}>Not Confirmed</span>}</p>
              <p>
                <button className="btn btn-primary view-button" onClick={() => openModal(order)}>
                  View More Details...
                </button>
              </p>
            </div>
          ))}
          </div>

          <div className="pagination">
            <button className="btn btn-primary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className="btn btn-primary"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
          <VendorOrderDetails isOpen={isModalOpen} onClose={closeModal} order={selectedOrder} />
        </div>
      ) : (
        <div>
          <p>No orders at the moment</p>
          <div className="pagination">
            <button className="btn btn-primary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className="btn btn-primary"
              onClick={() => handlePageChange(currentPage + 1)}
            >
            Next
            </button>
          </div>
        </div>
      )}
    </VendorMain>
  );
};

export default VendorOrders;
