import { BsEnvelope, BsPhone } from "react-icons/bs";
import { FiMapPin } from "react-icons/fi";
import classes from "./Footer.module.css";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer
      className={classes.footer}
      style={{
        backgroundColor:
          window.location.pathname.includes("marketplace") ||
          window.location.pathname.includes("vendor")
            ? "#F14306"
            : "black",
      }}
    >
      <div className={classes.col1}>
        <img src="/logo/logo.png" alt="" />
        <div
          style={{
            margin: "10px 0",
          }}
        >
          <Link
            to={
              "https://www.mediafire.com/file/cvwtkuv3rcei8b6/app-release-10.apk/file"
            }
          >
            <button className="btn btn-primary">Download App</button>
          </Link>
        </div>
        <p>
          ElectronicsShop is a brand that specializes in wholesale and
          distribution of electronics and electrical items. They offer a wide
          range of products from top brands and only source their products from
          reputable manufacturers and suppliers. The brand is committed to
          providing exceptional customer service and personalized solutions to
          help their clients achieve their business goals. ElectronicsShop aims
          to be a one-stop-shop for all electronics and electrical needs and is
          dedicated to providing the best value for their customers' money.
        </p>
      </div>
     <div className={classes.cols}>
     <div className={classes.col2}>
        <h2>Help</h2>
        <ul>
          <Link to={"/faqs"}>FAQs</Link>
          <li>Help Center</li>
          <li>How to shop on electronnicshop.ng</li>
          <li>Delivery and timelines</li>
          <li>How to hire a technician</li>
          <li>Report a product</li>
        </ul>
      </div>
      <div className={classes.col3}>
        <h2>Contact</h2>
        <ul>
          <li>
            <i>
              <BsPhone />
            </i>
            <span>07039713514 </span>
          </li>

          <li>
            <i>
              <BsEnvelope />
            </i>
            <span>support@electronicshop.com.ng</span>
          </li>

          <li>
            <i>
              <FiMapPin />
            </i>
            <span>141/149 Trans Amadi Ind. Layout, Rivers state</span>
          </li>
        </ul>
      </div>
     </div>
    </footer>
  );
};

export default Footer;
