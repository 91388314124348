import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  brandValue: "",
  searchedValue: "",
  categoryValue: "",
  model: "",
  maxPrice: "",
  searchInPage: "store",
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    onGetSearchHandler(state, { payload }) {
      state.searchedValue = payload;
    },
    onSetBrandhandler(state, { payload }) {
      console.log(payload)
      state.brandValue = payload;
    },

    onSetCategoryHandler(state, { payload }) {
      state.categoryValue = payload;
    },
    onSetModelFilter(state, { payload }) {
      state.model = payload;
    },
    onSetMaxPriceFilter(state, { payload }) {
      state.maxPrice = payload;
    },
    onSetSearchInPage(state, {payload}){
      state.searchInPage = payload;
    },
  },
});

export const { onGetSearchHandler, onSetBrandhandler, onSetCategoryHandler, onSetModelFilter, onSetMaxPriceFilter, onSetSearchInPage } =
  searchSlice.actions;
export default searchSlice;
