export const CreateAddressForm = [
  {
    type: "text",
    placeHolder: "johndoe@email.com",
    labelFor: "Contact Address",
    name: "address",
    value: "",
    isInput: true,
  },
  {
    type: "select",
    placeHolder: "Select State",
    labelFor: "Contact Address",
    name: "state",
    value: "",
    isInput: false,
    options: ["Rivers State"],
  },
  {
    type: "select",
    placeHolder: "City",
    labelFor: "City",
    name: "city",
    value: "",
    isInput: false,
    options: ["Port Harcourt"],
  },
  {
    type: "select",
    placeHolder: "Country",
    labelFor: "Contact Address",
    name: "country",
    value: "",
    isInput: false,
    options: ["Nigeria"],
  },
];
