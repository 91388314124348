import { current } from "@reduxjs/toolkit";
import React, { useState, useEffect } from "react";
import Cart from "../../components/Cart/Cart";
import { getClientToken } from "../../components/cookie/getClientToken";
import DeliveryAddress from "../../components/DeliveryAddress/DeliveryAddress";
import OrderItem from "../../components/Order/OrderItem";
import PaymentMethod from "../../components/PaymentMethod/PaymentMethod";
import ReviewProduct from "../../components/ReviewProduct/ReviewProduct";
import Account from "../../components/UI/Account/Account";
import BackButton from "../../components/UI/BackButton/BackButton";
import { Domain } from "../../Domain/Domain";
import useGetCartDetailsHook from "../../Hooks/useGetCartDetailsHook";
import classes from "../../styles/Account.module.css";
const MyOrderDetailPage = () => {
  const { cart } = useGetCartDetailsHook();
  const [order, setOrderData] = useState<any>({});
  const [orderItems, setOrderItems] = useState<any>([]);
  const utlParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    fetch(Domain() + "/api/v1/order/" + utlParams.get("order_id"), {
      method: "GET",
      headers: {
        Authorization: getClientToken(),
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response: any) => {
        if (response.status === 200) {
          setOrderData(response.data);
          setOrderItems(JSON.parse(response.data.order_items));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    console.log({
      order,
    });
  }, [order]);

  return (
    <Account>
      <div className={classes.detail_section}>
        <BackButton />
        <br />
        <h3>{order?.order_id!}</h3>
        <br />
        <h4>Cart Summary</h4>
        <div className={classes.cart_sumary_div_list}>
          <p>Delivery Fee</p>
          <h3>₦ {order?.delivery_fee!}</h3>
        </div>
        <div className={classes.cart_sumary_div_list}>
          <p>Total </p>
          <h3>₦ {order.total?.toLocaleString()}</h3>
        </div>
        <br />
        <h4>Items ({orderItems.length})</h4> 
        <br />
        {orderItems.map((cur: any, index: number) => (
          <OrderItem
            key={index}
            productId={cur.productId}
            qty={cur.qty}
            hasReview={true}
          />
        ))}
        <br />
        <DeliveryAddress
          address={order?.delivery_address!}
          fullName=""
          phoneNumber=""
        />
        <br />
        <PaymentMethod />
        <br />
        <div className={classes.footer_section_for_order}>
          <div>
            <p>Status</p>
            <h3>
              <>
                {order?.order_status! === "1" && "Successful and pending"}
                {order?.order_status! === "2" && "Available for pickup"}
                {order?.order_status! === "3" && "Delivered and completed"}
                {order?.order_status! === "4" && "Suspicious/Canceled"}
              </>
            </h3>
          </div>

          {/* <div>
            <p>Delivery Date</p>
            <h3>10/11/2022</h3>
          </div> */}
          {/* <div className={classes.review_section}>
            <p>Drop a review</p>
          </div> */}
        </div>
        {order?.order_status === "2" && (
          <div className="pickup-info">
            <br />
            <h4>Pickup Info</h4>
            <br />
            <hr />
            <br />

            {/* <div> */}
            {order?.phone_number && <p>Phone: {order?.phone_number}</p>}
            {order?.pickup_address && <p>Address: {order?.pickup_address}</p>}
            {order?.pickup_date && (
              <p>Pickup Date: {new Date(order?.pickup_date).toDateString()}</p>
            )}
            {/* </div> */}
          </div>
        )}
      </div>
    </Account>
  );
};

export default MyOrderDetailPage;
