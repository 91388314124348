import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getClientToken } from "../../components/cookie/getClientToken";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import Account from "../../components/UI/Account/Account";
import BackButton from "../../components/UI/BackButton/BackButton";
import { Domain } from "../../Domain/Domain";
import classes from "../../styles/Account.module.css";
const MyOrderPage = () => {
  const [orderData, setOrderData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(Domain() + "/api/v1/order/all", {
      method: "GET",
      headers: {
        Authorization: getClientToken(),
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response: any) => {
        if (response.status === 200) {
          console.log("====>", response.data);
          let orderList = response.data;
          orderList = orderList.sort((a: any, b: any) => b - a);
          setOrderData(orderList);
        }
      })
      .catch((error) => {
        console.log(error);
        // alert(error.response.data.message);
        console.log(error.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  console.log("===================>", orderData);
  return (
    <Account>
      <div>
        {isLoading && <SmallSpinner />}
        {orderData.length > 0 ? (
          orderData.map((cur: any, index: number) => (
            <Link
              to={"/my-order-detail?order_id=" + cur.orderDetails.id}
              key={index}
            >
              <div className={classes.order_container}>
                <h3>{cur.orderDetails.order_id}</h3>
                <p>{cur.orderDetails.delivery_address}</p>
                <p>
                  {" "}
                  {new Date(cur.orderDetails.updatedAt).toLocaleDateString()}
                </p>
              </div>
            </Link>
          ))
        ) : (
          <p>No orders at the moment</p>
        )}
      </div>
    </Account>
  );
};

export default MyOrderPage;
