import { BsStar, BsStarFill } from "react-icons/bs";

const StarRating = ({ averageRating }: { averageRating: number }) => {
  return (
    <div className="stars">
      {/* render stars based on number of average rating */}
      {Array.from({ length: averageRating }, (_, index) => {
        return (
          <i key={index}>
            <BsStarFill />
          </i>
        );
      })}

      {Array.from({ length: 5 - averageRating }, (_, index) => {
        return (
          <i key={index}>
            <BsStar />
          </i>
        );
      })}
    </div>
  );
};

export default StarRating;
