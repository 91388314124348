import React from "react";
import AdminAside from "../AdminAside/AdminAside";
import AdminHeader from "../AdminHeader/AdminHeader";
import classes from "./AdminMain.module.css";
interface Props {
  children: JSX.Element | JSX.Element[];
}
const AdminMain: React.FC<Props> = ({ children }): JSX.Element => {
  return (
    <>
      <AdminHeader />
      <main className={classes.main}>
        <aside className={classes.aside}>
          <AdminAside />
        </aside>
        <article className={classes.article}>{children}</article>
      </main>
    </>
  );
};

export default AdminMain;
