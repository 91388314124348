import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AdminPagination from "../AdminPagination/AdminPagination";
import classes from "./AdminOrder.module.css";
interface Props {
  status: any;
  orderData: any;
  onDecreaseHandler: () => void;
  onIncreaseHandler: () => void;
  pageNumber: number;
}
const AdminOrder: React.FC<Props> = ({
  status,
  orderData,
  onDecreaseHandler,
  onIncreaseHandler,
  pageNumber,
}) => {
  const navigate = useNavigate();
  const onNavigateHandler = (orderId: number | string) => {
    navigate("/admin-order-detail?order_id=" + orderId);
  };
  return (
    <>
      <div className={classes.order_table}>
        <table className={classes.table_section}>
          <tbody>
            <tr className={classes.header_section}>
              <td>#</td>
              <td>Order Number</td>
              <td>Items</td>
              <td>Price</td>
              <td>Location</td>
              <td>Date/Time</td>
              <td>Status</td>
            </tr>
            {orderData.map((cur: any, index: number) => (
              <tr
                key={index}
                className={classes.lists_}
                onClick={() => onNavigateHandler(cur.orderDetails?.order_id!)}
              >
                <td>{index + 1}</td>
                <td>{cur.orderDetails?.order_id!}</td>
                <td>{cur.productDetails?.length!}</td>
                <td>₦ {cur.orderDetails?.total?.toLocaleString()!}</td>
                <td style={{ fontSize: "12px" }}>
                  {cur.orderDetails?.delivery_address!}
                </td>

                <td style={{ fontSize: "12px" }}>
                  {new Date(cur.orderDetails?.createdAt! as any).toDateString()}
                  {" : "}
                  {new Date(
                    cur.orderDetails?.createdAt! as any
                  ).toLocaleTimeString()}
                </td>

                <td style={{ fontSize: "12px" }}>
                  {status === "1" && "Successful and pending"}
                  {status === "2" && "Available for pickup"}
                  {status === "3" && "Delivered and completed"}
                  {status === "4" && "Suspicious/Canceled"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <AdminPagination
        onDecreaseHandler={onDecreaseHandler}
        onIncreaseHandler={onIncreaseHandler}
        pageNumber={pageNumber}
      />
    </>
  );
};

export default AdminOrder;
