import axios from "axios";
import { METHODS } from "http";
import React, { useEffect, useState } from "react";
import { BiPrinter } from "react-icons/bi";
import { getClientToken } from "../../components/cookie/getClientToken";
import { getCookie } from "../../components/cookie/getCookie";
import DeliveryAddress from "../../components/DeliveryAddress/DeliveryAddress";
import OrderItem from "../../components/Order/OrderItem";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import BackButton from "../../components/UI/BackButton/BackButton"; 
import { Domain } from "../../Domain/Domain";
import useGetCartDetailsHook from "../../Hooks/useGetCartDetailsHook";
import classes from "../../styles/Account.module.css";
import { PaymentMethod } from "../Payment/Payment";
import { toggleAlertAction } from "../../store/slices/alertSlice";
import { useDispatch } from "react-redux";
const AdminPaySmallSmallDetail = () => {
  const dispatch = useDispatch();
  const { cart } = useGetCartDetailsHook();
  const [order, setOrderData] = useState<any>({});
  const [orderItems, setOrderItems] = useState<any>([]);
  const utlParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    axios
      .get(
        Domain() + "/api/v1/admin/order/pay-small-small/" + utlParams.get("id"),
        {
          headers: {
            Authorization: getCookie(),
            "Content-Type": "application/json",
          },
        }
      )

      .then((response: any) => {
        if (response.status === 200) {
          setOrderData(response.data.data);
          setOrderItems(JSON.parse(response.data.data.products));
          console.log(response.data.data.products)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const id = order?.order_id

  console.log(order?.id)
  const actioButtonHandler = (status: string) => {
    fetch(
      status === "approve"
        ? Domain() + "/api/v1/admin/order/pay-small-small/approve/" + id
        : Domain() + "/api/v1/admin/order/pay-small-small/complete/" + id,
      {
        method: "PATCH",

        headers: {
          Authorization: getCookie(),
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response: any) => {
        console.log(response);
        if (response.status === 200) {
          // alert("Request status updated to completed");

          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: "Request status updated to completed",
              status: "",
            })
          );

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        // alert("There was an error, please try again later");
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: "There was an error, please try again later",
            status: "error",
          })
        );
      });
  };

  const onPrintHandler = () => {
    window.print();
  };
  return (
    <AdminMain>
      <div className={classes.detail_section}>
        <BackButton />
        <br />
        <h3>{order?.order_id!}</h3>
        <br />
        <h4>Cart Summary</h4>

        <div className={classes.cart_sumary_div_list}>
          <p>Full name</p>
          <h3>
            {order?.firstName!} {order?.surname}
          </h3>
        </div>

        <div className={classes.cart_sumary_div_list}>
          <p>Email</p>
          <h3>{order?.email!}</h3>
        </div>
        <div className={classes.cart_sumary_div_list}>
          <p>Phone number</p>
          <h3>{order?.phoneNumber!}</h3>
        </div>
        <div className={classes.cart_sumary_div_list}>
          <p>DOB</p>
          <h3>{order?.dob!}</h3>
        </div>

        <div className={classes.cart_sumary_div_list}>
          <p>First payment </p>
          <h3> ₦ {order?.firstPayment?.toLocaleString()!}</h3>
        </div>

        <div className={classes.cart_sumary_div_list}>
          <p>Total Amount </p>
          <h3>₦ {order?.total?.toLocaleString()}</h3>

        </div>

        <div className={classes.cart_sumary_div_list}>
          <p>Balance </p>
          <h3>₦ {(order?.total - order?.firstPayment)?.toLocaleString()}</h3>

        </div>

        <div className={classes.cart_sumary_div_list}>
          <p>Installment Option </p>
          <h3>{order?.installmentOption} months</h3>

        </div>

        <br />
        <h4>Items ({orderItems.length})</h4>
        <br />
        {orderItems.map((cur: any, index: number) => (
          <OrderItem key={index} productId={cur.productId} qty={cur.qty} />
        ))}
        <br />
        <DeliveryAddress
          address={order?.address!}
          fullName=""
          phoneNumber=""
          city={order?.city}
          state={order?.state}
          country={order?.country}
        />
        <br />
        <br />
        <div className={classes.footer_section_for_order}>
          <div>
            <p>Status</p>
            <h3>
              <>
                {order?.order_status! === 1 && "Successful and pending"}
                {order?.order_status! === 2 && "Available for pickup"}
                {order?.order_status! === 3 && "Delivered and completed"}
                {order?.order_status! === 4 && "Suspicious/Canceled"}
              </>
            </h3>
          </div>
          <div className={classes.button_sections}>
            <button
              style={{ color: "white" }}
              onClick={() => actioButtonHandler("approve")}
            >
              Approve
            </button>
            <button
              onClick={() => actioButtonHandler("complete")}
              style={{ backgroundColor: "red", color: "white" }}
            >
              Complete
            </button>
          </div>
          <div>
            <p
              style={{ cursor: "pointer", marginTop: "10px" }}
              onClick={onPrintHandler}
            >
              <i>
                <BiPrinter />
              </i>{" "}
              Print this page
            </p>
          </div>{" "}
          <br />
          <br />
          <br />
        </div>
      </div>
    </AdminMain>
  );
};

export default AdminPaySmallSmallDetail;
