import axios from "axios";
import React, { useState, useEffect } from "react";
import AdminListedProducts from "../../components/AdminListedProducts/AdminListedProducts";
import AdminPagination from "../../components/AdminPagination/AdminPagination";
import { getCookie } from "../../components/cookie/getCookie";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import BackButton from "../../components/UI/BackButton/BackButton";
import { Domain } from "../../Domain/Domain";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import classes from "../../styles/VendorDetailPage.module.css";
import { toggleAlertAction } from "../../store/slices/alertSlice";
import { useDispatch } from "react-redux";

const AdminTechnicianDetail = () => {
  const dispatch = useDispatch();
  const [technician, setTechnician] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const url = new URLSearchParams(window.location.search);
    let technicianId = url.get("id")
    axios.get(`https://api.electronicshop.com.ng/api/v1/technician/profile/${technicianId}`, {headers: {Authorization: JSON.parse(localStorage.getItem('token') as string)}}).then(res => {
      console.log('________technician detail: ', res)
      setTechnician({info: res.data.data.response, jobs: res.data.data.statistics})
    }).catch(err => {
      console.log('--------detial error: ', err)
    })
  }, []);

  function fetchTechnicianDetails() {
    const url = new URLSearchParams(window.location.search);
    let technicianId = url.get("id")
    axios.get(`https://api.electronicshop.com.ng/api/v1/technician/profile/${technicianId}`, {headers: {Authorization: JSON.parse(localStorage.getItem('token') as string)}}).then(res => {
      console.log('________technician detail: ', res)
      setTechnician({info: res.data.data.response, jobs: res.data.data.statistics})
    }).catch(err => {
      console.log('--------detial error: ', err)
    })
  }
// https://api.electronicshop.com.ng/api/v1/admin/technician/activate/{id}
// https://api.electronicshop.com.ng/api/v1/admin/technician/deactivate/{id}
  const onTechnicianActionHandler = (status: string, id: string | number) => {
    setLoading(true);
    console.log(id + " the id")
    fetch(
      status === "activate"
        ? Domain() + "/api/v1/admin/technician/activate/" + id
        : Domain() + "/api/v1/admin/technician/deactivate/" + id,
      {
        method: "PATCH",
        headers: {
          Authorization: getCookie(),
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    )
      .then((res) => res.json())
      .then((response: any) => {
        // alert(response?.message);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: response?.message,
            status: "",
          })
        );
        setLoading(false);
        fetchTechnicianDetails();
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
      });
  };

  return (
    <AdminMain>
      <div>
        <BackButton />
        <br />
        <div className={classes.vendor_head_name}>
          <p>{technician?.info?.shopName!}</p>
          <p>Technician ID : {technician?.info?.technicianId}</p>
        </div>
        <div className={classes.contact_detail}>
          <p>Email</p>
          <p>{technician?.info?.email!}</p>
        </div>
        <div className={classes.contact_detail}>
          <p>Phone</p>
          <p>{technician?.info?.phoneNumber!}</p>
        </div>
        <div className={classes.contact_detail}>
          <p>Address</p>
          <p>
            {technician?.info?.address!} {technician?.info?.city!} {technician?.info?.state!}{" "}
            {technician?.info?.country!}
          </p>
        </div>

        <div className={classes.contact_detail}>
          <p>
            <b>Verification status</b>
          </p>
          <p>{technician?.info?.is_verified! ? "Verified" : "Unverified"}</p>
        </div>
        <br />
        {/* Other details section */}
        <div className={classes.other_detail_section}>
          <div className={classes.other_detail_list}>
            <p>Joined</p>
            <p>{new Date(technician?.info?.createdAt!).toDateString()}</p>
          </div>

          {/* <div className={classes.other_detail_list}>
            <p>Listed Products</p>
            <p>5</p>
          </div>

          <div className={classes.other_detail_list}>
            <p>Closed Sales</p>
            <p>2</p>
          </div>

          <div className={classes.other_detail_list}>
            <p>Rating</p>
            <p>4.5/5</p>
          </div> */}
        </div>
        <br />
        {/* listed products */}
        <div className={classes.listed_products}>
          <h2>Jobs</h2>
          <div style={{display: 'flex', flexDirection: 'column', gap: 8, marginTop: 8, fontSize: 22}}>
          <p>Completed Jobs: {technician?.jobs?.completedJobs ?? 0}</p>
          <p>Current Jobs: {technician?.jobs?.currentJob ?? 0}</p>
          <p>Declined Jobs: {technician?.jobs?.declinedJobs ?? 0}</p>
          <p>Total Jobs: {technician?.jobs?.totalJobs ?? 0}</p>
          </div>
        </div>
        <div className={classes.activate_button}>
          <button
            onClick={() => onTechnicianActionHandler("activate", technician?.info?.id)}
            className={classes.action_buttons + " " + classes.activate_btn}
          >
            Activate
          </button>
          <button
            onClick={() => onTechnicianActionHandler("deactivate", technician?.info?.id)}
            className={classes.action_buttons + " " + classes.deactivate_button}
          >
            Deactivate 
          </button>
        </div>
        {isLoading && (
          <>
            <br />
            <SmallSpinner />
            <br />
          </>
        )}

        {/* Paginations */}
        {/* <AdminPagination /> */}
      </div>
    </AdminMain>
  );
};

export default AdminTechnicianDetail;
