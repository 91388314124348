import React, { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom'
import { Domain } from '../../../Domain/Domain';
import axios from 'axios'
import './VendorShopAside.css'

const VendorShopAside = () => {
    const { customisedShopId } = useParams();
    const [vendor, setVendor] = useState(null);
    const [products, setProducts] = useState([]); 
    const [collapsed, setCollapsed] = useState(true);

    const toggleCollapse = () => {
      setCollapsed(!collapsed);
    };
    

    useEffect(() => {
        axios
        .get(Domain() + `/api/v1/vendor/${customisedShopId}`)
        .then((response) => {
          setVendor(response.data?.data.vendorDetails);
          console.log(vendor)
          setProducts(response.data?.data.products);
        })
        .catch((error) => {
          console.error('Error fetching vendor details:', error);
        });
      }, [customisedShopId]);


  return (
      <div>
        <div className={`vsa  ${collapsed ? 'collapsed' : ''}`}>
          <div>
            <h2>Vendor Details</h2><br />
            {vendor ? (
              <div>
                <div className="card">
                    <h5 className="card-text">SHOP NAME</h5>
                    <p className="card-text">{vendor.shopName}</p>
                </div><br />
                {/* <div className="card">
                    <h5 className="card-text">DESCRIPTION</h5>
                    <p className="card-text">{vendor.bio}</p>
                </div><br /> */}
                <div className="card">
                    <h5 className="card-text">ADDRESS</h5>
                    <p className="card-text">{vendor.address}</p>
                </div><br />
                <div className="card">
          
                  <h5 className="card-text">EMAIL</h5>
                    <p className="card-text">{vendor.email}</p>
 
                </div><br />
                <div className="card">
                    <h5 className="card-text">PHONE NUMBER</h5>
                    <p className="card-text">{vendor.phoneNumber}</p>
                </div><br />
                <div className="card">
                    <h5 className="card-text">TOTAL PRODUCTS</h5>
                    <p className="card-text">{products.length}</p>                 
                </div>
              </div>
            ) : (
              <div>This vendor does not exist</div>
            )}
          </div>   
        </div><br />
        <button className="collapsible-button" onClick={toggleCollapse}>
            {collapsed ? 'View Vendor Details' : 'Hide Vendor Details'}
        </button>
    </div>
   
  )
}

export default VendorShopAside
