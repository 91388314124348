import React from "react";
import { BsChevronLeft } from "react-icons/bs";
import AdminCartSummary from "../../components/AdminCartSummary/AdminCartSummary";
import DeliveryAddress from "../../components/DeliveryAddress/DeliveryAddress";
import PaymentMethod from "../../components/PaymentMethod/PaymentMethod";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import classes from "../../styles/AdminOrderCartSummary.module.css";
const AdminOrderCartSummary = (): JSX.Element => {
  return (
    <AdminMain>
      <div>
        <div className={classes.back_button_container}>
          <button>
            <i>
              <BsChevronLeft />
            </i>
            <span>back</span>
          </button>
        </div>
        <br />
        <div>
          <p className={classes.order_detail}>Order Details</p>
          <p className={classes.order_number}>Order Details</p>
        </div>
        <br />
        <div className={classes.cart_summary_container}>
          <p className={classes.summary_title}>Cart Summary</p>
          <AdminCartSummary inputIsEditable={true} />
          <AdminCartSummary inputIsEditable={false} />
          <br />
          <DeliveryAddress address="" fullName="" phoneNumber="" />
          <br />
          <PaymentMethod />
        </div>
        <br />
        <br />

        <div className={classes.footer_container}>
          <p>
            <b>Delivery:</b>
            <big>₦10,000</big>
          </p>
          <p>
            <b>Total:</b>
            <big>₦1,617,020</big>
          </p>
          <p>
            <b>Status:</b>
            <big>Delivered</big>
          </p>
        </div>
        <br />
        <div className={classes.action_button}>
          <button>CALL CUSTOMER</button>
          <button>RETURNED ITEMS</button>
        </div>
      </div>
    </AdminMain>
  );
};

export default AdminOrderCartSummary;
