import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { orderData } from "../../data/OrderData";
import { Domain } from "../../Domain/Domain";
import AdminPagination from "../AdminPagination/AdminPagination";
import { getCookie } from "../cookie/getCookie";
import SmallSpinner from "../Spinner/SmallSpinner";
import classes from "./User.module.css";
import { BsSearch } from "react-icons/bs";


const AdminUser = (): JSX.Element => {
  const [technicians, setTechnicians] = useState<any>([]);
  const [arrayIsEmpty, setArrayIsEmpty] = useState<boolean>(false);
  const naviagte = useNavigate();

  useEffect(() => {
    axios
      .get("https://apiv2.electronicshop.com.ng/search-user?resquest_payload=alade5673@gmail.com", {
        headers: {
          Authorization: "Bearer " + getCookie(),
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response: any) => {
        if (response.status === 200){
          setTechnicians([response.data])
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  const [search, setSearch] = useState('')

  const setSearchParameter = (event:any) => {
      if(event.target.value !== null){
        setSearch(event.target.value)
      }
  }

  //search form
  const onSubmitHandler = (val :any) => {

      axios
      .get("https://apiv2.electronicshop.com.ng/search-user?resquest_payload="+search, {
        headers: {
          Authorization: "Bearer " + getCookie(),
          "Content-Type": "application/json",
        },
      })
      .then((response: any) => {
        if (response.status === 200){
          // setTechnicians([response.data])
          // console.log(response)
          if(response.data === null){
            setArrayIsEmpty(true)
          }else{
            setTechnicians([response.data])
            setArrayIsEmpty(false)
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const onNavigateHandler = (data: any) => {
    naviagte("/admin-technical-details?id=" + data);
  };

  const [pageNumber, setPageNumber] = useState<number>(0);
  const onDecreaseHandler = () => {
    if (pageNumber > 0) setPageNumber((prevState) => prevState - 1);
  };
  const onIncreaseHandler = () => setPageNumber((prevState) => prevState + 1);

  return (
    <>
      <div className={classes.search_order_input}>
          <input
            type="text"
            name="productName"
            onChange={setSearchParameter}
            placeholder="Search for a user"
          />
          <div
            className={classes.search_icon}
            onClick={onSubmitHandler}
            // ref={searchRef}
          >
            <i>
              <BsSearch />
            </i>
          </div>
        </div>



      <h1>{arrayIsEmpty === false ? "" : "No item found"}</h1>


      {technicians.length < 1 ? (
        <>
          {arrayIsEmpty === false && (
            <div style={{ margin: "10px 0px" }}>
              <SmallSpinner />
            </div>
          )}
        </>
      ) : (
        <div className={classes.order_table}>
          <table className={classes.table_section}>
            <tbody>
              <tr className={classes.header_section}>
                <td>#</td>
                <td>Technician</td>
                <td>Email</td>
                <td>Status</td>
                <td>Joined</td>
              </tr>
              {technicians.map((cur: any, index: number) => (
                <tr
                  key={cur.id}
                  className={classes.lists_}
                  // onClick={() => onNavigateHandler(cur.id)}
                >
                  <td>{index + 1}</td>
                  <td>
                    {cur.firstName} {cur.surname}
                    red
                  </td>
                  <td>{cur.email}</td>
                  <td>{cur?.is_active! ? "Active" : "Inactive"}</td>
                  <td>{new Date(cur?.createdAt!).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <AdminPagination
        onDecreaseHandler={onDecreaseHandler}
        onIncreaseHandler={onIncreaseHandler}
        pageNumber={pageNumber + 1}
      />
    </>
  );
};

export default AdminUser;
