import React, { useEffect } from "react";
import ProductItemList from "./ProductItemList";
import { ProductData } from "../../data/productData";
import classes from "./ProductItem.module.css";
import { useGetRequestQuery } from "../../store/services/admin";
import { Domain } from "../../Domain/Domain";
import ProductItemListMarketplace from "./ProductItemListMarketplace";
interface Props {
  data?: any;
}
const ProductItemMarketplace: React.FC<Props> = ({ data = [] }): JSX.Element => {
  return (
    <div className={classes.product_container}>
      {data.map((cur: any, index: any) => (
        <ProductItemListMarketplace
          pricing={cur?.pricing!} 
          promotionalPrice={cur?.promotionalPrice!}
          description={cur?.productDescription!}
          name={cur?.productName!}
          image={cur?.pictures!}
          oldAmount={cur?.oldAmount!}
          colorsArray={cur?.colours!}
          rating={"100%"}
          key={index}
          id={cur?.id!}
          brand={cur?.brand!}
        />
      ))}
    </div>
  );
};

export default ProductItemMarketplace;
