import axios from "axios";
import React, { useState, useEffect } from "react";
import { BsChevronLeft, BsChevronRight, BsSearch } from "react-icons/bs";
import AdminOrder from "../../components/AdminOrder/AdminOrder"; 
import { getCookie } from "../../components/cookie/getCookie";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import { Domain } from "../../Domain/Domain";
import classes from "../../styles/AdminOrderStyles.module.css";
import { Link } from "react-router-dom";

const AdminOrderPage = (): JSX.Element => {
  const [statusName, setStatus] = useState<string | null>();
  const [orderData, setorder] = useState<[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const [arrayIsEmpty, setArrayIsEmpty] = useState<boolean>(false);

  function loadMoreFunction() {
    const url = new URLSearchParams(window.location.search);
    let endpoint =
      Domain() +
      `/api/v1/admin/order/pagination/${url.get(
        "status"
      )}?page=${pageNumber}&size=20`;
    console.log("endpoint:", endpoint);
    axios
      .get(
        endpoint,

        {
          headers: {
            Authorization: getCookie(),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response: any) => {
        console.log(response);

        if (response.data.status === 200) {
          setorder(response?.data?.data);
          if (response.data.data?.length! < 1) {
            setArrayIsEmpty(true);
          } else {
            setArrayIsEmpty(false);
          }
        }
      })
      .catch((error) => {
        setArrayIsEmpty(true);
      });
  }
  useEffect(() => {
    const url = new URLSearchParams(window.location.search);
    setStatus(url.get("status"));
    loadMoreFunction();
    // setPageCount(url.get("status") as string);
  }, [window.location.href, pageNumber]);

  const onDecreaseHandler = () => {
    if (pageNumber > 1) setPageNumber((prevState) => prevState - 1);
  };
  const onIncreaseHandler = () => setPageNumber((prevState) => prevState + 1);

  return (
    <AdminMain>
      <div className={classes.order_header}>
        <div className={classes.order_list}>
          <p>From</p>
          <input type="date" name="" id="" />
        </div>

        <div className={classes.order_list}>
          <p>To</p>
          <input type="date" name="" id="" />
        </div>
        <div className={classes.order_list}>
          <select name="" id="">
            <option value="newest-first">Newest First</option>
            <option value="newest-first">Oldest First</option>
          </select>
        </div>
      </div>

      {/* <div className={classes.search_order_input}>
        <input type="text" placeholder="Search name" />
        <div className={classes.search_icon}>
          <i>
            <BsSearch />
          </i>
        </div>
      </div>
      <br /> */}
      <br />
      <h1>{arrayIsEmpty === false ? "" : "No item found"}</h1>

      <div>
        {orderData.length < 1 ? (
          <>
            {arrayIsEmpty === false && (
              <div style={{ margin: "10px 0px" }}>
                <SmallSpinner />
              </div>
            )}
          </>
        ) : (
          <AdminOrder
            onDecreaseHandler={onDecreaseHandler}
            onIncreaseHandler={onIncreaseHandler}
            pageNumber={pageNumber}
            orderData={orderData}
            status={statusName}
          />
        )}
      </div>
    </AdminMain>
  );
};

export default AdminOrderPage;
