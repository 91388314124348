import React from "react";
import classes from "./PaymentMethod.module.css";
const PaymentMethod = (): JSX.Element => {
  return (
    <div className={classes.container}>
      <h3>Payment Method</h3>
      <div className={classes.flex}>
        <p>Pay with bank ATM card </p>
        <img src="/images/paystack.png" alt="" />
      </div>
    </div>
  );
};

export default PaymentMethod;
