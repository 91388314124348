import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getClientData } from "../../components/cookie/getClientData";
import { getClientToken } from "../../components/cookie/getClientToken";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import Account from "../../components/UI/Account/Account";
import { CreateAddressForm } from "../../data/CreateAddressForm";
import { Domain } from "../../Domain/Domain";
import classes from "../../styles/Account.module.css";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../../store/slices/alertSlice";
const CreateAddress = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState<boolean>(false);
  const [message, setMessage] = useState({ error: "", success: "" });
  const [enteredData, setEnteredData] = useState({
    address: "",
    state: "",
    city: "",
    country: "",
  });
  const navigate = useNavigate();

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setEnteredData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  const user = getClientData();
  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);

    let data = {
      address: enteredData.address,
      state: enteredData.state,
      country: enteredData.country,
      city: enteredData.city,
    };
    axios
      .post(Domain() + "/api/v1/user/address/new/", JSON.stringify(data), {
        headers: {
          Authorization: getClientToken(),
          "Content-Type": "application/json",
        },
      })

      .then((response: any) => {
        setLoader(false);
        // console.log(response.data.data);
        // setMessage({
        //   error: response.data.data,
        //   success: "",
        // });
        if (response.data.status === 200) {
          navigate("/account-addresses");
        }
      })
      .catch((error) => {
        //  alert("An error occured")
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: "An error occured",
            status: "error",
          })
        );
      });
  };
  return (
    <Account>
      <form onSubmit={onSubmitHandler}>
        <div>
          <div className={classes.messages_data}>
            <p className="error_message">
              {message.error !== "" && message.error}
            </p>
            <p className="success_message">
              {message.success !== "" && message.success}
            </p>
          </div>
          {CreateAddressForm.map((cur, index) => (
            <div key={index} className={classes.application_form}>
              {cur.isInput ? (
                <>
                  <label htmlFor="">{cur.labelFor}</label>
                  <input
                    type={cur.type}
                    placeholder={cur.placeHolder}
                    required
                    name={cur.name}
                    onChange={onChangeHandler}
                  />
                </>
              ) : (
                <>
                  <label htmlFor="">{cur.placeHolder}</label>
                  <select
                    id=""
                    name={cur.name}
                    onChange={onChangeHandler}
                    required
                  >
                    <option value="">{cur.placeHolder}</option>
                    {cur.options?.map((option) => (
                      <option value={option}>{option}</option>
                    ))}
                  </select>
                </>
              )}
            </div>
          ))}
          <br />
          <div className={classes.add_adderess_button + " " + classes.btn_2}>
            <button>ADD ADDRESS</button>
            {loader && <SmallSpinner />}
          </div>
        </div>
      </form>
    </Account>
  );
};

export default CreateAddress;
