import axios from "axios";
import React, { useState, useEffect } from "react";
import { getCookie } from "../components/cookie/getCookie";
import { Domain } from "../Domain/Domain";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../store/slices/alertSlice";

const useCreatepromotionHook = () => {
  const dispatch = useDispatch();

  const [enteredData, setEnteredData] = useState({
    category: "",
    title: "",
  });
  const [files, setFIles] = useState<{
    file1: string;
  }>({
    file1: "",
  });

  const [imageFiles, setImageFiles] = useState<
    {
      file: string;
    }[]
  >([
    {
      file: "",
    },
  ]);

  const [isLoadingImages, setIsloadingImages] = useState({
    loader1: false,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onPromotionalChangeHandler = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setEnteredData((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onImageChangeHandler = (e: any) => {
    if (e.target.files[0].size > 5442880) {
      // return alert("File is too large");
      dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "File is too large",
          status: "error",
        })
      );
    }

    
    let fileName = e.target.files[0].name;
    if (
      fileName.endsWith(".webp") ||
      fileName.endsWith(".svg") ||
      fileName.endsWith(".pjp") ||
      fileName.endsWith(".pjpeg") ||
      fileName.endsWith(".jfif ")
    ) {
      // return alert(
      //   "Please utilize supported formats, such as JPG and PNG, instead of file extensions that are not supported."
      // );
      return dispatch(
        toggleAlertAction({
          isAlerting: true,
          message:
            "Please utilize supported formats, such as JPG and PNG, instead of file extensions that are not supported.",
          status: "error",
        })
      );
    }

    setIsloadingImages((prevState) => {
      return {
        ...prevState,
        loader1: true,
      };
    });
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    var config = {
      onUploadProgress: function (progressEvent: any) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        console.log(percentCompleted);
        let uploadProductLoader1 = document.getElementById(
          "fileLoader1"
        ) as any;
        uploadProductLoader1.style.width = percentCompleted + "%";
        if (percentCompleted >= 100)
          setTimeout(() => {
            uploadProductLoader1.style.width = "0%";
          }, 1000);
      },
    };
    axios
      .post(Domain() + "/api/v1/file/new", formData, config)
      .then((response: any) => {
        console.log(response);
        setFIles((prevState) => {
          return {
            ...prevState,
            file1: response.data.data,
          };
        });

        setImageFiles((prevState) => {
          if (prevState.length >= 4) {
            return [
              { file: response.data.data },
              ...prevState.filter((item) => item.file !== ""),
            ];
          } else {
            // if (prevState.find((item) => item.file === "")) {
            //   return [
            //     { file: response.data.data },
            //     ...prevState.filter((item) => item.file !== ""),
            //   ];
            // } else {
            return [{ file: response.data.data }, ...prevState];
            // }
          }

          // return [{ file: response.data.data }, ...prevState];
        });

        setIsloadingImages((prevState) => {
          return {
            ...prevState,
            loader1: false,
          };
        });
      })
      .catch((error) => {
        console.log(error);
        // alert(
        //   "There was a problem this could be a network issue, thus posting a little image might be a good idea."
        // );
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message:
              "There was a problem this could be a network issue, thus posting a little image might be a good idea.",
            status: "error",
          })
        );

        setIsloadingImages((prevState) => {
          return {
            ...prevState,
            loader1: false,
          };
        });
      });
  };

  const onCreatehandler = (e: React.FormEvent) => {
    e.preventDefault();
    if (enteredData.category === "") {
      // return alert("Please select enum");
      dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "Please select enum",
          status: "error",
        })
      );
    }
    if (files.file1 === "") {
      // return alert("Please upload an image");
      dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "Please upload an image",
          status: "error",
        })
      );
    }
    setIsLoading(true);

    axios
      .post(
        Domain() + "/api/v1/promotion/create",
        JSON.stringify({
          category: parseInt(enteredData.category),
          title: enteredData.title,
          images: imageFiles.map((item) => item.file),
        }),
        {
          headers: {
            Authorization: getCookie(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
      .then((response: any) => {
        console.log(response);
        // alert("Promotion created successfully");
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: "Promotion created successfully",
            status: "success",
          })
        );
        setIsLoading(false);
        // setEnteredData({
        //   category: "",
        //   title: "",
        // });
        setFIles({
          file1: "",
        });
        setImageFiles([
          {
            file: "",
          },
        ]);
      })
      .catch((error) => {
        console.log(error);
        // alert("There was a problem creating promotion");
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: "There was a problem creating promotion",
            status: "error",
          })
        );
        setIsLoading(false);
      });
  };

  const onImageFileDeleteHandler = (index: number) => {
    setImageFiles((prevState) => {
      return [...prevState.filter((item, i) => i !== index)];
    });
  };

  useEffect(() => {
    console.log({ imageFiles });
  }, [imageFiles]);

  return {
    onPromotionalChangeHandler,
    enteredData,
    files,
    imageFiles,
    setImageFiles,
    isLoadingImages,
    onCreatehandler,
    isLoading,
    onImageChangeHandler,
    onImageFileDeleteHandler,
  };
};

export default useCreatepromotionHook;
