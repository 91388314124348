import axios from "axios";
import React, { useEffect, useState } from "react";
import { getCookie } from "../components/cookie/getCookie";
import { Domain } from "../Domain/Domain";
const useFetchPromotionEnums = () => {
  const [fetchedData, setfechedData] = useState<any>([]);
  useEffect(() => {
    axios
      .get(Domain() + "/api/v1/promotion/enums", {
        headers: {
          Authorization: getCookie(),
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response: any) => {
        if (Array.isArray(response.data.data))
          setfechedData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return { fetchedData };
};

export default useFetchPromotionEnums;
