export const VendorRegisterForm = [
  {
    type: "text",
    placeHolder: "",
    labelFor: "Shop name",
    name: "shopName",
    value: "",
    isInput: true,
  },
  {
    type: "text",
    placeHolder: "+234 8123456789",
    labelFor: "Phone Number",
    name: "phoneNumber",
    value: "",
    isInput: true,
  },
  {
    type: "email",
    placeHolder: "John",
    labelFor: "Email",
    name: "email",
    value: "",
    isInput: true,
  },

  {
    type: "text",
    placeHolder: "Block No -  House name - Nearest landmark",
    labelFor: "Address",
    name: "address",
    value: "",
    isInput: true,
  },

  {
    type: "select",
    placeHolder: "Select State",
    labelFor: "",
    name: "state",
    value: "",
    isInput: false,
    options: ["Rivers State"],
  },
  {
    type: "select",
    placeHolder: "City",
    labelFor: "City",
    name: "city",
    value: "",
    isInput: false,
    options: ["Port Harcourt"],
  },
  {
    type: "select",
    placeHolder: "Country",
    labelFor: "Contact Address",
    name: "country",
    value: "",
    isInput: false,
    options: ["Nigeria"],
  },
  {
    type: "password",
    placeHolder: "Enter your password",
    labelFor: "",
    name: "password",
    value: "",
    isInput: true,
  },
  {
    type: "password",
    placeHolder: "Confirm password",
    labelFor: "",
    name: "confirmPassword",
    value: "",
    isInput: true,
  },
];
