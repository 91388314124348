import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LazyImage = (props) => {
  const onHandleClick = (e) => {
    // props.onClick(e);
  };
  return (
    <LazyLoadImage
      alt={"image.alt"}
      effect="blur"
      src={props.image}
      className={props.className}
      onClick={onHandleClick}    
      placeholderSrc={"/images/image-loader.png"}
    />
  );
};

export default LazyImage;
