import React, { useState, useEffect } from "react";
import classes from "./Header.module.css";
import { FiSearch } from "react-icons/fi";
import { BiUserCircle } from "react-icons/bi";
import { BsCart, BsFillCartCheckFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import Topheader from "./Topheader";
import { useDispatch } from "react-redux";
import {
  onGetSearchHandler,
  onSetBrandhandler,
  onSetCategoryHandler,
  onSetMaxPriceFilter,
  onSetModelFilter,
} from "../../../store/slices/searchSlice";
import useGetCartDetailsHook from "../../../Hooks/useGetCartDetailsHook";
import { getClientData } from "../../cookie/getClientData";
import { toggleAlertAction } from "../../../store/slices/alertSlice";
import axios from "axios";
import { Domain } from "../../../Domain/Domain";
import useGetVendorCartDetailsHook from "../../../Hooks/useGetVendorCartDetailsHook";
const Header = () => {
  // console.log(window.location.pathname);
  const dispatch = useDispatch(); 
  const navigate = useNavigate();
  const { cartLength } = useGetCartDetailsHook();
  const { vendorCartLength } = useGetVendorCartDetailsHook();
  const [enteredvalue, setEnteredvalue] = useState<string>("");
  const [vendor, serVendor] = useState<Vendor>();

  const onChangeHandler = (e: any) => {
    if (e.target.value) {
      setEnteredvalue(e.target.value);
    }
  };
  const totalCartLength = cartLength+vendorCartLength

  const onSearchHandler = () => {
    if (enteredvalue) {
      dispatch(onGetSearchHandler(enteredvalue));
      dispatch(onSetBrandhandler(""));
      dispatch(onSetCategoryHandler(""));
      dispatch(onSetMaxPriceFilter(0));
      dispatch(onSetModelFilter(""));
      // navigate("/search");
      window.location.pathname.includes("marketplace") || window.location.pathname.includes("vendor")
      ? navigate("/search-marketplace")
      : navigate("/search")
    } else {
      // alert('Enter product name to search!')
      dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "Enter product name to search!",
          status: "error",
        })
      );
    }
  };

  useEffect(() => {
    // console.log(localStorage.getItem("vendor-token"));
    let vendorData = JSON.parse(
      window.localStorage.getItem("vendor") as unknown as string
    );
    // serVendor(vendorData)
    // console.log(vendorData);
    axios
      .get(Domain() + "/api/v1/vendor/profile/" + vendorData?.vendorId!, {
        headers: {
          Authorization: localStorage.getItem("vendor-token") as string,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response: any) => {
        if (response.data.status === 200)
          serVendor(response.data.data.vendorProfileDetails);
        console.log(response.data.data);
      })
      .catch((error) => {});
  }, []);

  return (
    <>
      <Topheader />

      <header
        className={classes.header}
        style={{
          backgroundColor:
            window.location.pathname.includes("marketplace") ||
            window.location.pathname.includes("vendor")
              ? "#F14306"
              : "black",
        }}
      >
        <div className={classes.logo_container}>
          <Link to={"/"}>
            <img src="/logo/logo.png" alt="" />
          </Link>
        </div>
        <div className={classes.search_container}>
          <input
            type="text"
            onChange={onChangeHandler}
            placeholder="Search for an item"
          />
          <div
            onClick={onSearchHandler}
            className={classes.custome_search_button}
            style={{
              backgroundColor:
                window.location.pathname.includes("marketplace") ||
                window.location.pathname.includes("vendor")
                  ? "black"
                  : "#F14306",
            }}
          >
            <i>
              <FiSearch />
            </i>
          </div>

          {/* <div className="">
            <i>
              <BsCart />
            </i>
          </div> */}
        </div>
        <div className={classes.third_column}>
          <div className={classes.account_div}>
            <div>
              <Link to={"/cart"}>
                <i>
                  <BsFillCartCheckFill />
                </i>
              </Link>
            </div>
            <div>
              <Link to={"/cart"}>
                <h4 className={classes.cart_length}>
                  {" "}
                  <span className={classes.hide_on_mobile}>Cart</span>{" "}
                  {totalCartLength}
                </h4>
              </Link>
            </div>
          </div>
          <div className={classes.account_div}>
            <div>
              <Link
                to={
                  getClientData().firstName !== undefined
                    ? "/edit-profile"
                    : "/login"
                }
              >
                <i>
                  <BiUserCircle />
                </i>
              </Link>
            </div>
            {!window.location.pathname.includes("vendor") ? (
              <div className={classes.hide_on_mobile}>
                {getClientData().firstName !== undefined ? (
                  <Link to={"/edit-profile"}>
                    <h4>Hi {getClientData().firstName}</h4>
                  </Link>
                ) : (
                  <Link to={"/login"}>
                    <p>Hello, sign in</p>
                  </Link>
                )}
              </div>
            ) : (
              <div className={classes.hide_on_mobile}>
                {vendor?.shopName !== undefined ? (
                  <Link to={"/vendor-profile-update"}>
                    <h4>Hi {vendor?.shopName}</h4>
                  </Link>
                ) : (
                  <Link to={"/login-v"}>
                    <p>Hello, sign in</p>
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
