import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SupportTicketStatusCode, SupportTicketStatusString } from ".";

type SupportTicketProps = {
  ticket: SupportTicket;
  updateReply?: (ticket: SupportTicket, message: string) => void;
  updateStatus?: (ticket: SupportTicket, status: SupportStatusCode) => void;
  isAdmin?: boolean;
};

const SupportTicket: React.FC<SupportTicketProps> = ({
  ticket,
  updateReply,
  updateStatus,
  isAdmin,
}) => {
  const [replyActive, setReplyActive] = useState(false);
  const [message, setMessage] = useState("");

  const handleReply = () => {
    setReplyActive(!replyActive);
  };

  const naviagte = useNavigate();

  const onNavigateHandler = (data: any) => {
    naviagte("/admin-support-center-details?id=" + data);
  };

  const handleChangeStatus = (status: number) => {
    console.log({ status });
    // console.log("hi")

    // Handle change status action
    updateStatus?.(ticket, status as SupportStatusCode);
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    updateReply?.(ticket, message);
    setMessage("");
    setReplyActive(false);
  };

  return (
    <article id={ticket.ticket_id}
    onClick={() => onNavigateHandler(ticket.ticket_id)}
    className="support-ticket support-ticket-hover"
    style={{cursor :'pointer'}}
    >
      <header className="support-ticket-header">
        <div
          className="cont"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h3>{ticket.subject}</h3>
          <span className="support-ticket-id">
            <span>Ticket ID:</span>
            <span className="id">{ticket.ticket_id}</span>
            <span className="status">{ticket.status}</span>
          </span>
        </div>
        <p className="sender-email">From: {ticket.senderEmail}</p>
        <p>Complainant Account: {ticket.complainer_account}</p>
      </header>
      <div className="support-ticket-content">
        <p>{ticket.body}</p>
        {/* <p>{ticket.body?.substr(0, 100)!}....</p> */}
      </div>
      <footer className="support-ticket-footer">
        <p>Created At: {new Date(ticket.createdAt).toLocaleDateString()}</p>
        <p>
          Last Updated At: {new Date(ticket.updatedAt).toLocaleDateString()}
        </p>
        {isAdmin && (
          <div className="support-ticket-actions">
            {/* <button className="btn-reply" onClick={handleReply}>
            {replyActive ? "Cancel" : "Reply"}
          </button> */}
            <Link to={`mailto:${ticket.senderEmail}`} target="_blank">
              <button className="btn-reply-email">Reply to Email</button>
            </Link>
            <div className="form-control">
              <label htmlFor="status">Update Status</label>
              <select
                className="status-select"
                name="status"
                onChange={(e) => handleChangeStatus(parseInt(e.target.value))}
                value={SupportTicketStatusCode[ticket.status]}
              >
                {Object.keys(SupportTicketStatusCode).map((key) => {
                  return (
                    <option
                      key={key}
                      value={SupportTicketStatusCode[key as SupportStatus]}
                    >
                      {key}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        )}
      </footer>
      {replyActive && (
        <div className="message-reply-form">
          <form onSubmit={handleFormSubmit}>
            <textarea
              className="message-input"
              placeholder="Type your reply..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
            <button type="submit" className="btn-send">
              Send
            </button>
          </form>
        </div>
      )}
    </article>
  );
};

export default SupportTicket;
