import axios from "axios";
import React, { useState, useEffect } from "react";
import { BsChevronLeft, BsChevronRight, BsSearch } from "react-icons/bs";
import AdminSearchOrder from "../../components/AdminOrder/AdminSearchOrder";
import { getCookie } from "../../components/cookie/getCookie";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import { Domain } from "../../Domain/Domain";
import classes from "../../styles/AdminOrderStyles.module.css";
import { Link } from "react-router-dom";

const SearchAdminOrders = (): JSX.Element => {
  const [statusName, setStatus] = useState<string | null>();
  const [orderData, setorder] = useState<any>({});
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [searchedOrder, setSearchedOrder] = useState<string>("");
  const [arrayIsEmpty, setArrayIsEmpty] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(false);

  function searchOrder() {
    if (searchedOrder) {
      setLoading(true);
      let endpoint = Domain() + `/api/v1/order/${searchedOrder}`;
      console.log("endpoint:", endpoint);
      axios
        .get(endpoint, {
          headers: {
            Authorization: getCookie(),
            "Content-Type": "application/json",
          },
        })
        .then((response: any) => {
          console.log("********: ", response);
          if (response.data.status === 200) {
            setorder(response.data.data);
            setStatus(response.data.data.order_status)
          }
          setLoading(false);
        })
        .catch((error) => {
          setArrayIsEmpty(true);
          setLoading(false);
        });
    }
  }

  console.log('___________order data: ', orderData)
  const onDecreaseHandler = () => {
    if (pageNumber > 1) setPageNumber((prevState) => prevState - 1);
  };
  const onIncreaseHandler = () => setPageNumber((prevState) => prevState + 1);

  const handleChange = (e: any) => {
    setSearchedOrder(e.target.value);
  };
  return (
    <AdminMain>
      <div className={classes.order_header}>
        <div className={classes.order_list}>
          <p>From</p>
          <input type="date" name="" id="" />
        </div>

        <div className={classes.order_list}>
          <p>To</p>
          <input type="date" name="" id="" />
        </div>
        <div className={classes.order_list}>
          <select name="" id="">
            <option value="newest-first">Newest First</option>
            <option value="newest-first">Oldest First</option>
          </select>
        </div>
      </div>

      <div className={classes.search_order_input}>
        <input type="text" placeholder="Search order" onChange={handleChange} />
        <div className={classes.search_icon} onClick={searchOrder}>
          <i>
            <BsSearch />
          </i>
        </div>
      </div>
      <br />
      <br />

      <div>
        {loading ? 
          <div style={{ margin: "10px 0px" }}>
            <SmallSpinner />
          </div>
         : orderData['order_id'] ? 
          <AdminSearchOrder
            orderData={orderData}
            status={statusName}
          />
         : "No Items."}
      </div>
    </AdminMain>
  );
};

export default SearchAdminOrders;
