import React, { useEffect, useState } from "react";
import { orderData } from "../../data/OrderData";
import classes from "./AvailablePromotionsList.module.css";
import { Ad } from "../../Pages/Admin/AdminManagePromotions";

export const PromotionsTable = ({
  promotions,
  updateSelected,
}: {
  promotions: Ad[];
  updateSelected: (selected: number[]) => void;
}) => {
  const [selected, setSelected] = useState<number[]>([]);
  const handleSelect = (isChecked: boolean, id: number) => {
    if (isChecked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    updateSelected(selected);
  }, [selected, updateSelected]);

  return (
    <div className={classes.order_table}>
      <table className={classes.table_section}>
        <thead>
          <tr className={classes.header_section}>
            <th>ID</th>
            <th>Category</th>
            <th>Title</th>
            <th>Images</th>
            <th>Is Live</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>
              <input type="radio" />
            </th>
          </tr>
        </thead>
        {promotions.length > 0 ? (
          <tbody>
            {promotions.map((ad) => {
              console.log({ ad });
              let images = JSON.parse(ad.images);
              if (images.length === 1) {
                images = images[0].split(",");
              }
              console.log({ images });

              return (
                <tr key={ad.id} className={classes.lists_}>
                  <td>{ad.id}</td>
                  <td>{ad.category}</td>
                  <td>{ad.title}</td>
                  <td>
                    <ul
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: ".5rem",
                      }}
                    >
                      {images.map(
                        (
                          image: string | undefined,
                          index: React.Key | null | undefined
                        ) =>
                          image && (
                            <li key={index}>
                              <img
                                src={image}
                                alt={`${index}`}
                                style={{
                                  width: "50px",
                                  height: "50px",
                                }}
                              />
                            </li>
                          )
                      )}
                    </ul>
                  </td>
                  <td>{ad.is_live ? "Yes" : "No"}</td>
                  <td>{new Date(ad.createdAt).toLocaleDateString()}</td>
                  <td>{new Date(ad.updatedAt).toLocaleDateString()}</td>
                  <td>
                    <input
                      type="checkbox"
                      name="selected"
                      onChange={(e) => handleSelect(e.target.checked, ad.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={7}>No data found</td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

const AvailablePromotionsList = () => {
  return (
    <div className={classes.order_table}>
      <table className={classes.table_section}>
        <thead>
          <tr className={classes.header_section}>
            <td>#</td>
            <td>
              <input type="checkbox" />
            </td>
            <td>Title</td>
            <td>Last modified</td>
          </tr>
        </thead>

        <tbody>
          {orderData.splice(0, 5).map((cur: any, index: number) => (
            <tr key={cur.id} className={classes.lists_}>
              <td>{index + 1}</td>
              <td>
                <input type="checkbox" />
              </td>
              <td>{cur.title}</td>
              <td>{cur.dateAndTime}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AvailablePromotionsList;
