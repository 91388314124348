import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminMessageCenterComponent from "../../components/AdminMessageCenter/AdminMessageCenterComponent";
import DraftMessages from "../../components/AdminMessageCenter/DraftMessages";
import NewMessages from "../../components/AdminMessageCenter/NewMessages";
import SentMessageCenter from "../../components/AdminMessageCenter/SentMessageCenter";
import SheduledMessages from "../../components/AdminMessageCenter/SheduledMessages";
import AdminPagination from "../../components/AdminPagination/AdminPagination";
import AdminSearch from "../../components/AdminSearch/AdminSearch";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import classes from "../../styles/AdminMessageCenter.module.css";
const AdminMessageCenter = (): JSX.Element => {
  const [curNav, setCurNav] = useState<string>("inbox");
  const navigate = useNavigate();
  useEffect(() => {
    const url = new URLSearchParams(window.location.search);
    let page = url.get("page");
    if (page === null) navigate("/admin-message-center?page=inbox");
    setCurNav(page as string);
  }, [window.location.search]);
  return (
    <AdminMain>
      <div>
        <h3 className={classes.title}>Message center</h3>
        <br />
        <div className={classes.nav_container}>
          <button
            style={{
              backgroundColor: curNav === "inbox" ? "black" : "unset",
              color: curNav === "inbox" ? "white" : "#7692B4",
            }}
            onClick={() => navigate("/admin-message-center?page=inbox")}
          >
            Inbox
          </button>
          <button
            style={{
              backgroundColor: curNav === "sent" ? "black" : "unset",
              color: curNav === "sent" ? "white" : "#7692B4",
            }}
            onClick={() => navigate("/admin-message-center?page=sent")}
          >
            Sent
          </button>
          <button
            style={{
              backgroundColor: curNav === "newMessage" ? "black" : "unset",
              color: curNav === "newMessage" ? "white" : "#7692B4",
            }}
            onClick={() => navigate("/admin-message-center?page=newMessage")}
          >
            New Message
          </button>
          <button
            style={{
              backgroundColor: curNav === "draft" ? "black" : "unset",
              color: curNav === "draft" ? "white" : "#7692B4",
            }}
            onClick={() => navigate("/admin-message-center?page=draft")}
          >
            Drafts
          </button>
          <button
            style={{
              backgroundColor: curNav === "schedule" ? "black" : "unset",
              color: curNav === "schedule" ? "white" : "#7692B4",
            }}
            onClick={() => navigate("/admin-message-center?page=schedule")}
          >
            Scheduled
          </button>
        </div>
        <br />

        <div className={classes.order_header}>
          <div className={classes.order_list}>
            <p>From</p>
            <input type="date" name="" id="" />
          </div>

          <div className={classes.order_list}>
            <p>To</p>
            <input type="date" name="" id="" />
          </div>
          <div className={classes.order_list}>
            <select name="" id="">
              <option value="newest-first">Newest First</option>
              <option value="newest-first">Oldest First</option>
            </select>
          </div>
        </div>
        <AdminSearch />
        <br />
        <br />
        {curNav === "inbox" && (
          <>
            <AdminPagination />
            <AdminMessageCenterComponent />
          </>
        )}

        {curNav === "sent" && (
          <>
            <SentMessageCenter />
            <AdminPagination />
          </>
        )}

        {curNav === "newMessage" && <NewMessages />}

        {curNav === "draft" && (
          <>
            <DraftMessages />
            <AdminPagination />
          </>
        )}

        {curNav === "schedule" && (
          <>
            <SheduledMessages />
            <AdminPagination />
          </>
        )}
        <br />
        <br />
      </div>
    </AdminMain>
  );
};

export default AdminMessageCenter;
