import React, { useState, useEffect } from "react";
import { BsCartCheckFill, BsCheck2 } from "react-icons/bs";
import { Link } from "react-router-dom";
import Cart from "../components/Cart/Cart";
import SmallSpinner from "../components/Spinner/SmallSpinner";
import Main from "../components/UI/Main/Main";
import useCheckoutHook from "../Hooks/useCheckoutHook";
import useGetCartDetailsHook from "../Hooks/useGetCartDetailsHook";
import useGetDefaultAddress from "../Hooks/useGetDefaultAddress";
import classes from "../styles/CartPageStyles.module.css";
const CartPage = (): JSX.Element => {
  const { cartLength, cart, cartTotalPrice } = useGetCartDetailsHook();
  const { defaultAddress } = useGetDefaultAddress();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [arrayIsEmpty, setArrayIsEmpty] = useState<boolean>(false);
  useEffect(() => {
    let intervalid = setTimeout(() => {}, 4000);
    return () => {
      clearTimeout(intervalid);
    };
  }, []);

  const onConfrimClearHandler = () => {
    if (window.confirm("Are you sure you want to clear cart?")) {
      localStorage.removeItem("cart");
      window.location.reload();
    }
  };

  return (
    <Main>
      <div>
        <div className={classes.cart_navs_container}>
          <Link to={"/cart"}>
            <button>Cart details</button>
          </Link>
          <Link to={"/account-addresses"}>
            <button>Delivery Address</button>
          </Link>
          <Link to={"/payment"}>
            <button>Payment method</button>
          </Link>
        </div>
        <br />

        <div className={classes.cart_main}>
          <div className={classes.first_col}>
            <div className={classes.cart_hed_cetails_line}>
              <h3>Cart ({cartLength})</h3>
              <p onClick={onConfrimClearHandler}>Clear cart</p>
            </div>
            {Array.isArray(cart) && (
              <>
                {cart.length < 1 && (
                  <div>
                    <br />
                    <div>
                      <img
                        className={classes.empty_cart_image}
                        src="/images/empty-empty-shopping-cart.png"
                        alt=""
                      />
                    </div>
                    <br />
                    <h1 style={{ textAlign: "center" }}>Your cart is empty</h1>
                  </div>
                )}
              </>
            )}

            {cart.map((cur: any, index: number) => (
              <Cart
                key={index}
                price={cur.priceing}
                name={cur.productName}
                qty={cur.qty}
                productId={cur.productId}
                images={cur.images}
              />
            ))}
            <div className={classes.other_cart_link}>
              <Link to="/vendor-cart">View your vendor cart</Link>
            </div>
          </div>
          
          

          <div className={classes.summarry_section}>
            <div className={classes.summary_wrapper}>
              <h4>Cart Summary</h4>
              <div className={classes.sub_total_section}>
                <span>Subtotal</span>
                <span
                  style={{
                    color: "#F14306",
                    fontSize: "20px",
                    textAlign: "right",
                  }}
                >
                  ₦ {cartTotalPrice.toLocaleString()}
                </span>
              </div>
              <div className={classes.check_out_btn}>
                {defaultAddress !== null ? (
                  <Link to={"/payment"}>
                    <button>
                      <i>
                        <BsCartCheckFill />
                      </i>
                      CHECKOUT
                    </button>
                  </Link>
                ) : (
                  <span>
                    To add a default address so that you can check out,
                    <Link to={"/account-addresses"}> click here.</Link>
                  </span>
                )}

                <br />
                <br />
                <p style={{ color: "#F14306", textAlign: "center" }}>
                  <Link to={"/"}>CONTINUE SHOPPING</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default CartPage;
