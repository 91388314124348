import React, { useState, useEffect } from "react";
import { BsEyeSlash } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import classes from "../../styles/Register.module.css";
import AdminHeader from "../../components/UI/AdminHeader/AdminHeader";
import { setCookie } from "../../components/cookie/setCookie";
import { setCookieUser } from "../../components/cookie/setCookieUser";
import { Domain, API_URL } from "../../Domain/Domain";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import { clearCookie } from "../../components/cookie/clearCookie copy";

const AdminLogin = (): JSX.Element => {
  const [viewPassword, setViewPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [enteredEmail, setEmail] = useState<string>("");
  const [enteredPassword, setPassword] = useState<string>("");

  const [error, setError] = useState<{
    hasError: boolean;
    error: string;
  }>({ hasError: false, error: "" });

  useEffect(() => {
    clearCookie();    
  }, []);

  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const passwordChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      email: enteredEmail,
      password: enteredPassword,
    };

    if (enteredEmail === "")
      return setError({
        hasError: true,
        error: "Email is required",
      });
    if (!enteredEmail.includes("@"))
      return setError({
        hasError: true,
        error: "Email is invalid",
      });

    if (enteredPassword === "")
      return setError({
        hasError: true,
        error: "Password is required",
      });

    fetch(`${API_URL}/api/v1/auth/login`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log("___________________success==>", response);
        setIsLoading(false);
        if (response.status === 200) {
          if(response.data.userDetails.user_type === 1 || response.data.userDetails.user_type === 2 || response.data.userDetails.user_type === 3){
            setCookie(response.data.token, 30);
            localStorage.setItem("token", JSON.stringify(response.data.token))
            setCookieUser(JSON.stringify(response.data.userDetails), 30);
            navigate("/admin-dashboard");
          }else{
            //errors for non logged in users
            setError({
              hasError: true,
              error: 'Incorrect email or password',
            });
          }
          
        }

        if (response.status === 400) {
          setError({
            hasError: true,
            error: response.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        // console.log(error.message);
        setError({
          hasError: true,
          error: "An error occured. Failed to login",
        });
        setIsLoading(false);
      });
  };

  return (
    <div>
      <AdminHeader />
      <br />
      <br />
      <form onSubmit={onSubmitHandler}>
        <div className={classes.forms_container}>
          <div className={classes.title_container}>
            <h2>Login up</h2>
            {error.hasError && <p className="error">{error.error}</p>}
          </div>
          <div className={classes.form_container_div}>
            <div className={classes.input_div_container}>
              <input
                type="email"
                placeholder="Email"
                onChange={emailChangeHandler}
              />
            </div>
          </div>

          <div className={classes.form_container_div}>
            <div className={classes.input_div_container}>
              <input
                type={!viewPassword ? "password" : "text"}
                placeholder="Password"
                onChange={passwordChangeHandler}
              />
              <div className={classes.password_toggle_container}>
                <i onClick={() => setViewPassword(!viewPassword)}>
                  <BsEyeSlash />
                </i>
              </div>
            </div>
          </div>

          <div className={classes.button_container}>
            <button>{!isLoading ? "LOGIN" : "Loading..."}</button>
          </div>
          {isLoading && (
            <div className={classes.login_spinner}>
              <SmallSpinner />
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default AdminLogin;
