import React, { useState } from "react";
import { useAdminGeneralgetRequestQuery } from "../store/services/admin";
import axios from "axios";
import { getCookie } from "../components/cookie/getCookie";
import { Domain } from "../Domain/Domain";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../store/slices/alertSlice";

const useDeleteBrandAndCategory = () => {
  const dispatch = useDispatch();
  const [isBrandorCategoryForUpdate, setIsBrandorCategoryForUpdate] =
    useState<String>("brand");
  const [name, setName] = useState<string>("");
  const [newName, setNewName] = useState<string>("");
  const [newDescription, setNewDescription] = useState<string>("");
  const [id, setId] = useState<string | number>(0);
  const [file, setFIle] = useState<any>({});
  const token = getCookie();
  const [isSubmittingForUpdate, setIsSubmittingForUpdate] =
    useState<boolean>(false);
  // Fetching Banrd.Category
  const { data = [], refetch } = useAdminGeneralgetRequestQuery(
    isBrandorCategoryForUpdate === "brand"
      ? "/api/v1/brand/all"
      : "api/v1/category/all"
  );
  let fetchedbands: any = [];
  if (data.data) fetchedbands = data.data;

  // Selecting Brand or Category for Editing
  const onBrandOrToggleChangeForUpdate = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setIsBrandorCategoryForUpdate(e.target.value);
  };

  //   Selecting The names
  const onChangeNameHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setId(e.target.value);
  };

  const onNewNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(e.target.value);
  };
  const onNewDescriptionHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewDescription(e.target.value);
  };

  const onChnageFIleHandler = (e: any) => {
    setFIle(e.target.files[0]);
  };

  const domain = Domain();
  console.log(domain);

  // TODO: FORM UPDATING FUNCTION
  const onBrandUpdated = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("==>", file);
    setIsSubmittingForUpdate(true);
    let formData = new FormData();
    formData.append("name", newName);
    formData.append("description", newDescription);
    formData.append("image", file);
    console.log(id);
    console.log(isBrandorCategoryForUpdate);
    axios
      .delete(
        isBrandorCategoryForUpdate === "brand"
          ? domain + "/api/v1/brand/" + id
          : domain + "/api/v1/category/" + id,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )

      .then((response: any) => {
        if (response.data.status === 200) {
          // alert(response.data.message);
          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: response.data.message,
              status: "error",
            })
          );

          setIsSubmittingForUpdate(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
        // alert(error.response.data.message);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: error.response.data.message,
            status: "error",
          })
        );
        setIsSubmittingForUpdate(false);
        console.log(error.response.data.message);
      });
  };

  return {
    onBrandUpdated,
    onBrandOrToggleChangeForUpdate,
    isBrandorCategoryForUpdate,
    onChangeNameHandler,
    fetchedbands,
    onNewNameHandler,
    onChnageFIleHandler,
    onNewDescriptionHandler,
    isSubmittingForUpdate,
  };
};

export default useDeleteBrandAndCategory;
