import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../components/cookie/getCookie";
import { Domain } from "../Domain/Domain";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../store/slices/alertSlice";

const useEditProductHook = () => {
  const dispatch = useDispatch();
  const [showMoreSpecifications, setShowMoreSpecifications] =
    useState<boolean>(false);
  const [existingSpecifications, setExistingSpecifications] = useState<any>([]);
  const [colors, setColor] = useState({});
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmittng] = useState<boolean>(false);
  const [promotionalPriceIsHidden, setpromotionalPriceIsHidden] =
    useState<boolean>(true);

  // Toggling Promotional input
  const togglePromotionalHandler = () => {
    setpromotionalPriceIsHidden(!promotionalPriceIsHidden);
  };
  const [isLoadingImages, setIsloadingImages] = useState({
    loader1: false,
    loader2: false,
    loader3: false,
    loader4: false,
  });

  const [enteredValue, setEnteredValue] = useState<{
    productId: number | string;
    productName: string;
    brand: string;
    category: string;
    model: string;
    colours: string;
    productDescription: string;
    pricing: string;
    promotionalPrice: string;
    specifications: [];
    brandName: string;
    categoryName: string;
    brandId: number | string;
    categoryId: number | string;
    search_variables: string;
  }>({
    productId: "",
    productName: "",
    brand: "",
    category: "",
    model: "",
    colours: "",
    productDescription: "",
    pricing: "",
    promotionalPrice: "",
    specifications: [],
    brandName: "",
    categoryName: "",
    brandId: 0,
    categoryId: 0,
    search_variables: ""
  });

  const [files, setFIles] = useState<{
    file1: string;
    file2: string;
    file3: string;
    file4: string;
  }>({
    file1: "",
    file2: "",
    file3: "",
    file4: "",
  });

  // Fetching Product to Edit.
  useEffect(() => {
    let url = new URLSearchParams(window.location.search);
    axios
      .get(Domain() + "/api/v1/product/" + url.get("id"))
      .then((response: any) => {
        // console.log(response);
        if (response.data.data !== null) {
          let newSpecificationArray = JSON.parse(
            response.data.data?.products?.specification
          );
          // Extract the data inside the square brackets
const searchVariablesString = response.data.data.products.search_variables;
const extractedData = searchVariablesString.substring(1, searchVariablesString.length - 1);

// Split the extracted data by commas and remove surrounding double quotes
const searchVariablesArray = extractedData.split(',').map((variable: any) => variable.trim().replace(/^"(.*)"$/, '$1'));

          setEnteredValue((prevState) => {
            return {
              ...prevState,
              productId: response.data.data?.products.id,
              productName: response.data.data?.products.productName,
              productDescription: response.data.data.products.productDescription,
              model: response.data.data.products.model,
              pricing: response.data.data.products.pricing,
              promotionalPrice: response.data.data.products.promotionalPrice,
              brand: response.data.data.products.brand,
              category: response.data.data.products.category,
              specifications: newSpecificationArray,
              brandName: response.data.data.products.brand_id.name,
              categoryName: response.data.data.products.category_id.name,
              brandId: response.data.data.products.brand_id.id,
              categoryId: response.data.data.products.category_id.id,
              search_variables: searchVariablesArray,
            };
          });

          console.log(response.data.data.products.pictures!)
          console.log('hello')
          let imagesArray = JSON.parse(response.data.data.products.pictures!);

          //   Images Section
          setFIles((prevState) => {
            return {
              ...prevState,
              file1: imagesArray.length > 0 ? imagesArray[0] : "",
              file2: imagesArray.length > 1 ? imagesArray[1] : "",
              file3: imagesArray.length > 2 ? imagesArray[2] : "",
              file4: imagesArray.length > 3 ? imagesArray[3] : "",
            };
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onImageChangeHandler1 = (e: any) => {
    if (e.target.files[0].size > 5442880) {
      // return alert("File is too large");
      return dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "File is too large",
          status: "error",
        })
      );
    }
    let fileName = e.target.files[0].name;
    if (
      fileName.endsWith(".webp") ||
      fileName.endsWith(".svg") ||
      fileName.endsWith(".pjp") ||
      fileName.endsWith(".pjpeg") ||
      fileName.endsWith(".jfif ")
    ) {
      // return alert(
      //   "Please utilize supported formats, such as JPG and PNG, instead of file extensions that are not supported."
      // );
      return dispatch(
        toggleAlertAction({
          isAlerting: true,
          message:
            "Please utilize supported formats, such as JPG and PNG, instead of file extensions that are not supported.",
          status: "error",
        })
      );
    }

    setIsloadingImages((prevState) => {
      return {
        ...prevState,
        loader1: true,
      };
    });
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    var config = {
      onUploadProgress: function (progressEvent: any) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        console.log(percentCompleted);
        let uploadProductLoader1 = document.getElementById(
          "fileLoader1"
        ) as any;
        uploadProductLoader1.style.width = percentCompleted + "%";
        if (percentCompleted >= 100)
          setTimeout(() => {
            uploadProductLoader1.style.width = "0%";
          }, 1000);
      },
    };
    axios
      .post(Domain() + "/api/v1/file/new", formData, config)
      .then((response: any) => {
        console.log(response);
        setFIles((prevState) => {
          return {
            ...prevState,
            file1: response.data.data,
          };
        });
        setIsloadingImages((prevState) => {
          return {
            ...prevState,
            loader1: false,
          };
        });
      })
      .catch((error) => {
        console.log(error);
        // alert(
        //   "There was a problem this could be a network issue, thus posting a little image might be a good idea."
        // );
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message:
              "There was a problem this could be a network issue, thus posting a little image might be a good idea.",
            status: "error",
          })
        );
      });
  };
  const onImageChangeHandler2 = (e: any) => {
    let fileName = e.target.files[0].name;
    if (
      fileName.endsWith(".webp") ||
      fileName.endsWith(".svg") ||
      fileName.endsWith(".pjp") ||
      fileName.endsWith(".pjpeg") ||
      fileName.endsWith(".jfif ")
    ) {
      // return alert(
      //   "Please utilize supported formats, such as JPG and PNG, instead of file extensions that are not supported."
      // );
      return dispatch(
        toggleAlertAction({
          isAlerting: true,
          message:
            "Please utilize supported formats, such as JPG and PNG, instead of file extensions that are not supported.",
          status: "error",
        })
      );
    }
    if (e.target.files[0].size > 5442880) {
      return dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "File is too large",
          status: "error",
        })
      );
    }
    setIsloadingImages((prevState) => {
      return {
        ...prevState,
        loader2: true,
      };
    });
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    var config = {
      onUploadProgress: function (progressEvent: any) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        console.log(percentCompleted);
        let uploadProductLoader1 = document.getElementById(
          "fileLoader2"
        ) as any;
        uploadProductLoader1.style.width = percentCompleted + "%";
        if (percentCompleted >= 100)
          setTimeout(() => {
            uploadProductLoader1.style.width = "0%";
          }, 1000);
      },
    };

    axios
      .post(Domain() + "/api/v1/file/new", formData, config)
      .then((response: any) => {
        console.log(response);
        setFIles((prevState) => {
          return {
            ...prevState,
            file2: response.data.data,
          };
        });
        setIsloadingImages((prevState) => {
          return {
            ...prevState,
            loader2: false,
          };
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message:
              "There was a problem this could be a network issue, thus posting a little image might be a good idea.",
            status: "error",
          })
        );
        setIsloadingImages((prevState) => {
          return {
            ...prevState,
            loader2: false,
          };
        });
      });
  };
  const onImageChangeHandler3 = (e: any) => {
    let fileName = e.target.files[0].name;
    if (
      fileName.endsWith(".webp") ||
      fileName.endsWith(".svg") ||
      fileName.endsWith(".pjp") ||
      fileName.endsWith(".pjpeg") ||
      fileName.endsWith(".jfif ")
    ) {
      // return alert(
      //   "Please utilize supported formats, such as JPG and PNG, instead of file extensions that are not supported."
      // );
      return dispatch(
        toggleAlertAction({
          isAlerting: true,
          message:
            "Please utilize supported formats, such as JPG and PNG, instead of file extensions that are not supported.",
          status: "error",
        })
      );
    }
    if (e.target.files[0].size > 5442880) {
      // return alert("FIle is too large");
      return dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "File is too large",
          status: "error",
        })
      );
    }

    setIsloadingImages((prevState) => {
      return {
        ...prevState,
        loader3: true,
      };
    });

    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    var config = {
      onUploadProgress: function (progressEvent: any) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        console.log(percentCompleted);
        let uploadProductLoader1 = document.getElementById(
          "fileLoader3"
        ) as any;
        uploadProductLoader1.style.width = percentCompleted + "%";
        if (percentCompleted >= 100)
          setTimeout(() => {
            uploadProductLoader1.style.width = "0%";
          }, 1000);
      },
    };
    axios
      .post(Domain() + "/api/v1/file/new", formData, config)
      .then((response: any) => {
        console.log(response);
        setFIles((prevState) => {
          return {
            ...prevState,
            file3: response.data.data,
          };
        });
        setIsloadingImages((prevState) => {
          return {
            ...prevState,
            loader3: false,
          };
        });
      })
      .catch((error) => {
        console.log(error);
        // alert(
        //   "There was a problem this could be a network issue, thus posting a little image might be a good idea."
        // );
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message:
              "There was a problem this could be a network issue, thus posting a little image might be a good idea.",
            status: "error",
          })
        );
        setIsloadingImages((prevState) => {
          return {
            ...prevState,
            loader3: false,
          };
        });
      });
  };

  const onImageChangeHandler4 = (e: any) => {
    let fileName = e.target.files[0].name;
    if (
      fileName.endsWith(".webp") ||
      fileName.endsWith(".svg") ||
      fileName.endsWith(".pjp") ||
      fileName.endsWith(".pjpeg") ||
      fileName.endsWith(".jfif ")
    ) {
      // return alert(
      //   "Please utilize supported formats, such as JPG and PNG, instead of file extensions that are not supported."
      // );
      return dispatch(
        toggleAlertAction({
          isAlerting: true,
          message:
            "Please utilize supported formats, such as JPG and PNG, instead of file extensions that are not supported.",
          status: "error",
        })
      );
    }
    if (e.target.files[0].size > 5442880) {
      // return alert("FIle is too large");
      return dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "File is too large",
          status: "error",
        })
      );
    }

    setIsloadingImages((prevState) => {
      return {
        ...prevState,
        loader4: true,
      };
    });
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    var config = {
      onUploadProgress: function (progressEvent: any) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        console.log(percentCompleted);
        let uploadProductLoader1 = document.getElementById(
          "fileLoader4"
        ) as any;
        uploadProductLoader1.style.width = percentCompleted + "%";
        if (percentCompleted >= 100)
          setTimeout(() => {
            uploadProductLoader1.style.width = "0%";
          }, 1000);
      },
    };
    axios
      .post(Domain() + "/api/v1/file/new", formData, config)
      .then((response: any) => {
        console.log(response);
        setFIles((prevState) => {
          return {
            ...prevState,
            file4: response.data.data,
          };
        });
        setIsloadingImages((prevState) => {
          return {
            ...prevState,
            loader4: false,
          };
        });
      })
      .catch((error) => {
        console.log(error);
        // alert(
        //   "There was a problem this could be a network issue, thus posting a little image might be a good idea."
        // );
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message:
              "There was a problem this could be a network issue, thus posting a little image might be a good idea.",
            status: "error",
          })
        );
        setIsloadingImages((prevState) => {
          return {
            ...prevState,
            loader4: false,
          };
        });
      });
  };

  //   OnCHnage Event for name, brand, catgory etc
  const onChangeHandler = (e: any) => {
    setEnteredValue((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  //   Chaging colors___________
  const onChangeColorHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setColor((prevState) => {
      return {
        ...prevState,
        [e.target.id]: e.target.checked ? e.target.value : "",
      };
    });
  };

  const onShowMoreSpecifications = () => {
    setShowMoreSpecifications(!showMoreSpecifications);
  };

  // Submitting the file
  const onCreateProductHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Validating the file
    setIsSubmittng(true);

    let specAttributeID = document.querySelectorAll("#specAttributeID");
    let specValueID = document.querySelectorAll("#specValueID");
    let selectArray: any = [];
    let specValueArray: any = [];

    for (let i = 0; i < specAttributeID.length; i++) {
      for (let i = 0; i < specValueID.length; i++) {
        let specvalues = specValueID[i] as unknown as any;
        specValueArray.push({ value: specvalues.value });
        let selects = specAttributeID[i] as unknown as any;
        selectArray.push({ attribute: selects.value, value: specvalues.value });
      }
    }

    const newSelectedData = selectArray
      .map((cur: any) => {
        return {
          attribute: cur.attribute,
          value: cur.value,
        };
      })
      .splice(0, specAttributeID.length);

    // Remove empty string
    let newSpecData = [];
    for (let i = 0; i < newSelectedData.length; i++) {
      if (newSelectedData[i].value !== "") newSpecData.push(newSelectedData[i]);
    }

    //

    
const searchVariablesArray = enteredValue.search_variables.split(',').map(variable => variable.trim());

    let submittingData = {
      productName: enteredValue.productName,
      brand: enteredValue.brand,
      category: enteredValue.category,
      model: enteredValue.model,
      colours: Object.values(colors),
      productDescription: enteredValue.productDescription,
      specification: newSpecData,
      pictures: [files.file1, files.file2, files.file3, files.file4],
      pricing: enteredValue.pricing,
      promotionalPrice:
        enteredValue.promotionalPrice !== ""
          ? enteredValue.promotionalPrice
          : 0,
          search_variables: searchVariablesArray
    };

    axios
      .patch(
        Domain() + "/api/v1/product/" + enteredValue.productId,
        JSON.stringify(submittingData),
        {
          headers: {
            Authorization: getCookie(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )

      .then((response: any) => {
        if (response.data.status === 200) {
          console.log(response);
          // alert(response.data.message);
          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: response.data.message,
              status: "",
            })
          );
          setIsSubmittng(false);
          setTimeout(() => {
            navigate("/admin-found-product?id=" + enteredValue.productId);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsSubmittng(false);
        // alert(error.response.data.message);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: error.response.data.message,
            status: "error",
          })
        );
      });
  };

  return {
    onChangeHandler,
    onCreateProductHandler,
    showMoreSpecifications,
    onImageChangeHandler1,
    onImageChangeHandler2,
    onImageChangeHandler3,
    onImageChangeHandler4,
    files,
    onShowMoreSpecifications,
    enteredValue,
    onChangeColorHandler,
    isSubmitting,
    promotionalPriceIsHidden,
    togglePromotionalHandler,
    isLoadingImages,
  };
};

export default useEditProductHook;
