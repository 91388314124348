import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminPagination from "../components/AdminPagination/AdminPagination";
import MarketPlaceProduct from "../components/MarketPlaceProduct/MarketPlaceProduct";
import Spinner from "../components/Spinner/Spinner";
import Main from "../components/UI/Main/Main";
import { Domain } from "../Domain/Domain";
import { useAdminGeneralgetRequestQuery } from "../store/services/admin"; 
import { addPromotionData } from "../store/slices/promotionsSlice";
import {
  Ad,
  getImagesFromAd,
  getPromotionData,
  Promo,
} from "../utils/promotionHelpers";

const MarketPlacePage = () => {
  const dispatch = useDispatch();
  const promotionSelector = useSelector((value: any) => value.promotion);

  const [products, setProducts] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [promotions, setPromotions] = useState<Ad[]>([]);
  const [marketPlaceHeaderAdData, setMarketPlaceHeaderAdData] = useState<
    Promo[]
  >([]);
  const [marketPlaceHeaderAdImages, setMarketPlaceHeaderAdImages] = useState<
    { url: string }[]
  >([]);

  const onDecreaseHandler = () => {
    if (pageNumber > 0) setPageNumber((prevState) => prevState - 1);
  };

  const onIncreaseHandler = () => setPageNumber((prevState) => prevState + 1);

  useEffect(() => {

    //check if local storage item exists and pass pageNumber to it.
      //the value of this is reset in the homepage.tsx
      if(localStorage.getItem('SeeMorepage')){
        console.log("refreshedddddd", performance.navigation)
        const isPageRefreshed = performance.navigation.type == 1;
       
        setPageNumber(0)
        if(isPageRefreshed){
          //load from local storage
          const count = Number(localStorage.getItem('SeeMorepage'))
          setPageNumber(count)
        }else{
          setPageNumber(0)
          localStorage.setItem('SeeMorepage', '0')
        } 
        
      }else{
      //set local storage
        localStorage.setItem('SeeMorepage', '0')
      }
      
    axios
      .get(Domain() +"/api/v1/marketplace/product/all")
      .then((response: any) => {
        // console.log("marrrrrrrrrrrrrr: ",response);
        if (response.data.status === 200) setProducts(response.data.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    setPromotions(promotionSelector.promotionsArray);
  }, [promotionSelector.promotionsArray]);

  useEffect(() => {
    console.log({
      promotions,
    });

    // if (promotions.length > 0) {
    getPromotionData(4, "Market Place Home Page Middle Ad").then((res) => {
      dispatch(addPromotionData(res));
      const imagesFromAd = getImagesFromAd(res.data);

      setMarketPlaceHeaderAdData(res.data);
      setMarketPlaceHeaderAdImages(imagesFromAd);
    });
    // }
  }, [promotions]);

  useEffect(() => {
    if (pageNumber) {
      localStorage.setItem('SeeMorepage', `${pageNumber}`)
      


      axios
        .get(
          Domain() + `/api/v1/marketplace/product/fetch-more?page=${pageNumber}`
        )
        .then((response: any) => {
          // console.log(response.data.data);
          if (response.data.status === 200) {
            setProducts(response.data.data.products); 
            window.scrollTo(0, 0);
          }
        })
        .catch((error) => {});
    }
  }, [pageNumber]);
  return (
    <Main>
      <article>
        <h1 style={{ color: "#F14306", marginBottom: "7px", fontSize: "25px" }}>
          Deals near you
        </h1>

        <MarketPlaceProduct
          products={products.length > 5 ? products.slice(0, 5) : products}
          linkRoute="/marketplace-deal"
        />
        {/* Ad */}
        <ul
          className="images-list" 
          style={{
            display: "flex",
            gap: ".75rem",
          }}
        >
          {marketPlaceHeaderAdImages.map((image) => (
            <li className="image-item img-cont">
              <img
                src={marketPlaceHeaderAdImages[0].url}
                style={{
                  width: "100%",
                  
                }}
              />
            </li>
          ))}
        </ul>
        {/* End Ad */}
        <MarketPlaceProduct
          products={
            products.length > 5
              ? products.slice(5, products.length)
              : products
          }
          linkRoute="/marketplace-deal"
        />
        {products?.length < 1 ? (
          <Spinner />
        ) : (
          <div className="center-pagination">
            <AdminPagination
              onDecreaseHandler={onDecreaseHandler}
              onIncreaseHandler={onIncreaseHandler}
              pageNumber={pageNumber}
              hasReactedLimit={products?.length >= 20}
            />
          </div>
        )}
      </article>
    </Main>
  );
};

export default MarketPlacePage;
