import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MarketPlaceProduct from "../components/MarketPlaceProduct/MarketPlaceProduct";
import ProductsAds from "../components/ProductAds/ProductsAds";
import ProductItem from "../components/ProductItem/ProductItem";
import SmallSpinner from "../components/Spinner/SmallSpinner";
import Spinner from "../components/Spinner/Spinner";
import CategoriesWrapper from "../components/UI/Wrapper/CategoriesWrapper";
import { Domain } from "../Domain/Domain";
import { useAdminGeneralgetRequestQuery } from "../store/services/admin";
import {
  onSetCategoryHandler,
  onGetSearchHandler,
  onSetMaxPriceFilter,
  onSetBrandhandler,
  onSetModelFilter,
} from "../store/slices/searchSlice";
import AdminPagination from "../components/AdminPagination/AdminPagination";
import {
  Ad,
  getImagesFromAd,
  getPromotionData,
  Promo,
} from "../utils/promotionHelpers";
import { addPromotionData } from "../store/slices/promotionsSlice";
import ProductItemMarketplace from "../components/ProductItem/ProductItemMarketplace";

const CategoriesPageMarketplace = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [category, setCategory] = useState<any>({});
  const [message, setMessage] = useState<any>("");

  const promotionSelector = useSelector((value: any) => value.promotion);

  const [promotions, setPromotions] = useState<Ad[]>([]);
  const [ProductSearchAdData, setProductSearchAdData] = useState<Promo[]>([]);
  const [ProductSearchAdImages, setProductSearchAdImages] = useState<
    { url: string }[]
  >([]);

  let isMobile = window.matchMedia("(max-width: 600px)").matches;
  const filter = useSelector((value: any) => value.search);
  const { data = [], refetch } = useAdminGeneralgetRequestQuery(
    "/api/v1/category/all"
  );
  let fetchedbands: any = [];
  if (data.data) fetchedbands = data.data;
  // useEffect(() => {
  //   axios
  //     .get(Domain() + "/api/v1/product/all")
  //     .then((response: any) => {
  //       console.log(response);
  //       if (response.data.status === 200) {
  //         setProducts(response.data.data);
  //       }
  //     })
  //     .catch((error) => {});
  // }, []);

  console.log("___________FILTERS: ", filter);
  const [endpoint, setEndpoint] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(0);

  const onIncreaseHandler = () => setPageNumber((prevState) => prevState + 1);

  const handleGetSearchedProducts = (val: string) => {
    setLoading(true);
    const url = "https://apiv2.electronicshop.com.ng" + val;
    console.log("urrrrrrl; ", url);
    fetch(url, {
      headers: {
        "Content-Type": "Application/json",
      },
    })
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((response: any) => {
        if (Array.isArray(response)) {
          setProducts(response);
        } else {
          setProducts([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        setProducts([]);
      });
  };

  console.log("Filters: ", filter);

  useEffect(() => {
    if (
      filter.searchedValue ||
      filter.brandValue ||
      filter.categoryValue ||
      filter.maxPrice ||
      filter.productModel
    ) {
      const path = `/${
        filter?.searchInPage === "store"
          ? "filter_products"
          : "market_place_products_filter"
      }?${
        filter.searchedValue !== "" ? "productName=" + filter.searchedValue : ""
      }${filter.brandValue !== "" ? "&brand=" + filter.brandValue : ""}${
        filter.categoryValue !== "" ? "&category=" + filter.categoryValue : ""
      }${filter.maxPrice ? "&pricingMax=" + filter.maxPrice : ""}`;

      setEndpoint(path);
      handleGetSearchedProducts(path);
    }
  }, [
    filter.searchedValue,
    filter.brandValue,
    filter.categoryValue,
    filter.maxPrice,
    filter.productModel,
  ]);

  useEffect(() => {
    let url = `https://apiv2.electronicshop.com.ng/${
      filter?.searchInPage === "store"
        ? "loadmore_filter_products"
        : "marketplace_loadmore_products_filter"
    }?${endpoint?.split("?").slice(1).join("")}&page=${pageNumber}`;
    console.log("paginated url: ", url);
    axios
      .get(url)
      // /api/v1/product/new-arrivals/more?page=100&size=80
      // https://api.electronicshop.com.ng/api/v1/product/new-arrivals
      .then((response: any) => {
        if (response.status === 200) {
          if (Array.isArray(response.data)) {
            setProducts((prev: any) => prev.concat(response.data));
            setMessage("");
          } else {
            setMessage(response.data.message);
          }
        }
      })
      .catch((error) => {});
  }, [pageNumber]);

  const handleChangeCategory = (e: any) => {
    const { value } = e.target;
    const label = e.target[e.target.selectedIndex].label;
    setCategory({ id: value, label: label });
    // dispatch(onGetSearchHandler(label))
    dispatch(onSetCategoryHandler(value));
  };

  useEffect(() => {
    setPromotions(promotionSelector.promotionsArray);
  }, [promotionSelector.promotionsArray]);

  useEffect(() => {
    console.log({
      promotions,
    });

    // if (promotions.length > 0) {
    getPromotionData(6, "Product Search Ad").then((res) => {
      dispatch(addPromotionData(res));
      const imagesFromAd = getImagesFromAd(res.data);

      setProductSearchAdData(res.data);
      setProductSearchAdImages(imagesFromAd);
    });
    // }
  }, [promotions]);

  return (
    <CategoriesWrapper>
      {/* <h1>{arrayIsEmpty === false ? "" : "No item found"}</h1> */}

      {loading ? (
        <div style={{ margin: "10px 0px" }}>
          <SmallSpinner />
        </div>
      ) : products?.length ? (
        <article>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 5,
            }}
          >
            <select
              onChange={handleChangeCategory}
              value={category?.value ?? filter?.categoryValue}
            >
              <option value="">Select category</option>
              {fetchedbands?.map((item: any, index: number) => {
                return (
                  <option key={index} value={item?.id} label={item?.name}>
                    {item?.name}
                  </option>
                );
              })}
            </select>
          </div>
          <br />
          <ProductItemMarketplace data={products} />

          <div style={{ textAlign: "center" }}>
            {message === "No more results" ? (
              <p style={{ fontSize: 16, marginBottom: 4 }}>{message}</p>
            ) : (
              <p
                onClick={onIncreaseHandler}
                style={{
                  fontSize: 16,
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "blue",
                  marginBottom: 4,
                }}
              >
                See More
              </p>
            )}
          </div>

          {products?.length < 1 && <SmallSpinner />}

          <div style={{ backgroundColor: "#F14306", padding: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <h1 style={{ color: "white" }}>Market Place</h1>
              <p style={{ color: "white" }}>Go to Marketplace</p>
            </div>
            <MarketPlaceProduct products={[]} />
          </div>
          <ProductItem />
          <br />
          <ProductsAds images={ProductSearchAdImages} />
        </article>
      ) : (
        <h3>No Products Found!</h3>
      )}
    </CategoriesWrapper>
  );
};

export default CategoriesPageMarketplace;
