import React, { useEffect, useState } from "react";
import axios from "axios";
import MarketPlaceProduct from "../../components/MarketPlaceProduct/MarketPlaceProduct";
import VendorMain from "../../components/UI/VendorMain/VendorMain";
import { Domain } from "../../Domain/Domain";
import { getClientData } from "../../components/cookie/getClientData";
import { getClientToken } from "../../components/cookie/getClientToken";
import { useNavigate } from "react-router-dom";
import WalletDetails from "./WalletDetails";
import { Link } from "react-router-dom";


const VendorDashboard = () => {
  const [products, setProducts] = useState<any>([]);
  const [vendor, setVendor] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchVendor();
  }, []);


  const fetchVendor = () => {
     let vendorData = JSON.parse(
       window.localStorage.getItem("vendor") as unknown as string
     );

     axios
       .get(Domain() + "/api/v1/vendor/profile/" + vendorData?.vendorId!, {
         headers: {
           Authorization: localStorage.getItem("vendor-token") as string,
           "Content-Type": "application/json; charset=utf-8",
         },
       })
       .then((response: any) => {
         if (response.data.status === 200)
           setVendor(response.data.data.vendorProfileDetails);
       })
       .catch((error) => {});
   }


  const navigate = useNavigate()
  useEffect(() => {
    axios
      .get(Domain() + "/api/v1/vendor/products", {
        headers: {
          Authorization: window.localStorage.getItem("vendor-token") as string,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response: any) => {
        if (response.data.status === 200) {
          setProducts(response.data.data);
        }
      })
      .catch((error) => {
        navigate('/login-v')
      });
  }, []);

  return (
    <VendorMain>
      <div>
        <div>      
          <div style={{ backgroundColor: 'white', padding: '25px', fontSize: '20px', borderRadius: '10px'}}>
            <b>Bio:</b>
            <p style={{ fontSize: '20px'}}>{vendor?.bio}</p>
          </div>
        </div><br />
        <div style={{ backgroundColor: "#F14306", padding: "10px" }}>
          <div 
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <h1 style={{ color: "white" }}>All products</h1>
            <Link to="/marketplace">
              <p style={{ color: "white" }}>Go to Marketplace</p>
            </Link>
          </div>
          <MarketPlaceProduct products={products} linkRoute="/vendor-product" />
        </div>
      </div>
    </VendorMain>
  );
};

export default VendorDashboard;
