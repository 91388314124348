import React, { useEffect, useState } from "react";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { BsChevronRight } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Domain } from "../../Domain/Domain";
import classes from "./FAQ.module.css";
import FaqAnswerList from "./FaqAnswerList";

interface Props {
  categoryId: string;
}

const FAQLIST: React.FC<Props> = ({ categoryId }) => {
  const [question, setQuestions] = useState<any>([]);

  useEffect(() => {
    fetch(Domain() + "/api/v1/faq")
      .then((res) => res.json())
      .then((data) => {
        let list = data.data;
        list = list.filter((cur: any) => cur.category === categoryId);
        console.log(list);
        setQuestions(list);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {question.map((cur: any, index: number) => (
        <FaqAnswerList
          data={cur}
          key={index}
          answer={cur.answer}
          question={cur.question}
        />
      ))}

      {window.location.href.includes("admin") && (
        <Link to={"/admin-create-faq"}>
          {" "}
          <span>
            <AiOutlinePlusSquare />
          </span>{" "}
          Create question
        </Link>
      )}
    </>
  );
};

export default FAQLIST;
