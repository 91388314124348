import React from "react";
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import classes from "./BackButton.module.css";
const BackButton = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.back_button_container}>
      <button onClick={() => navigate(-1)}>
        <i>
          <BsChevronLeft />
        </i>
        <span>back</span>
      </button>
    </div>
  );
};

export default BackButton;
