import React from "react";
import AdminPagination from "../../components/AdminPagination/AdminPagination";
import AdminSearch from "../../components/AdminSearch/AdminSearch";
import AdminVendorProduct from "../../components/AdminVendorProduct/AdminVendorProduct";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import classes from "../../styles/AdminVendorProduct.module.css";

const AdminVendorProductPage = () => {
  return (
    <AdminMain>
      <div>
        <div className={classes.title_div}>
          <p>Vendor Products</p>
        </div>

        <div className={classes.button_lists}>
          <button>Inbox</button>
          <button>Sent</button>
          <button>New Message</button>
          <button>Drafts</button>
          <button>Scheduled</button>
        </div>

        <div className={classes.order_header}>
          <div className={classes.order_list}>
            <p>From</p>
            <input type="date" name="" id="" />
          </div>

          <div className={classes.order_list}>
            <p>To</p>
            <input type="date" name="" id="" />
          </div>
          <div className={classes.order_list}>
            <select name="" id="">
              <option value="newest-first">Newest First</option>
              <option value="newest-first">Oldest First</option>
            </select>
          </div>
        </div>
        <AdminSearch />
        <br />
        <br />
        <AdminVendorProduct />
        <br />
        <AdminPagination />
      </div>
    </AdminMain>
  );
};

export default AdminVendorProductPage;
