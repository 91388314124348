import axios from "axios";
import React, { useState, useEffect, useRef} from "react";
import { BsStarFill } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { API_URL, Domain } from "../../../Domain/Domain";
import { getCookie } from "../../cookie/getCookie";
import classes from "./TechnicianAside.module.css";

const TechnicianAside = ({ isForTechnician = true }: { isForTechnician?: boolean }) => {
  const [technician, setTechnician] = useState<any>({});
 
  const navigate = useNavigate();
  useEffect(() => {
    function checkAuth() {
      const parseJwt = (token: string) => {
        try {
          return JSON.parse(atob(token.split(".")[1]));
        } catch (e: any) {
          return null;
        }
      };

      let token = window.localStorage.getItem("technician-token") as string;
      const decodedJwt: any = parseJwt(token) as unknown as any;
      if (token === null) return navigate("/login-t");

      if (decodedJwt?.exp! * 1000 < Date.now()) {
        navigate("/login-t");
      }
    }
    checkAuth();
  }, []);

  useEffect(() => {
    let technicianData = JSON.parse(
      window.localStorage.getItem("technician") as unknown as string
    );

    axios
      .get(`${API_URL}/api/v1/technician/profile/` + technicianData?.id!, {
        headers: {
          Authorization: localStorage.getItem("technician-token") as string,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response: any) => {
        if (response.data.status === 200)
          setTechnician(response.data.data.response);
      })
      .catch((error) => {});
  }, []);

  return (
    <div className={classes.container}>
      {isForTechnician && (
        <>
          <div className={classes.title_section}>
            <Link to={"/technician-dashboard"}>
              <h3>Technician's Dashboard</h3>
            </Link>
          </div>
          <br />
            <h3 className={classes.editing_section}>
              Personal Info
            </h3>
       </>
      )}<br />
      <p>Name</p>
      <h4 className={classes.vendor_name}>
        {technician?.firstName!}{technician?.surname!}{technician?.otherName!}
      </h4><br />
      <div>
        <p>Email</p>
        <h5>{technician?.email ?? "-"}</h5>
      </div><br />
      <div className={classes.contact_list}>
        <p>Phone</p>
        <h5>{technician?.phoneNumber ?? "-"}</h5>
      </div><br />
      <div className={classes.contact_list} style={{ width: "250px" }}>
        <p>Address</p>
        <h5>
          {technician?.address!} {technician?.city!} {technician?.state!} {technician?.country!}
        </h5>
      </div>

      <br />
      {isForTechnician && (
        <>
          <br />
          <div className={classes.footer_section}>
            <small>Subscription</small>
            <Link to={"/technician-subscription-plans"}>
              <h4>Subscription Plans</h4>
            </Link>
            <br />
            <Link to={"/login-t"} style={{ color: "red" }}>
              <h4>logout</h4>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default TechnicianAside;
