import { useDispatch } from "react-redux";
import classes from "../../styles/Account.module.css";

const Pagination = ({
  totalPages,
  currentPage,
  updateCurrentPage,
}: {
  totalPages: number;
  currentPage: number;
  updateCurrentPage: (page: number) => void;
}) => {
  const dispatch = useDispatch();

  const handlePageChange = (page: number) => {
    updateCurrentPage(page);
  };

  return (
    <div className={classes.pagination}>
      <div className={classes.paginationLeft}>
        <p>
          Page {currentPage} of {totalPages}
        </p>
      </div>
      <div className={classes.paginationRight}>
        <button
          className={classes.btn}
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Prev
        </button>
        <button
          className={classes.btn}
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
