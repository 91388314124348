import React from "react";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import BackButton from "../../components/UI/BackButton/BackButton";
import classes from "../../styles/SettingsStyles.module.css";
const AdminSettingsChangePassword = () => {
  return (
    <AdminMain>
      <div>
        <BackButton />
        <br />
        <h3>Change Password</h3>
        <br />
        <div className={classes.input_wrapper}>
          <input type="text" placeholder="Old Password" />
          <input type="text" placeholder="New Password" />
          <input type="text" placeholder="Confirm New Password" />
        </div>
        <br />
        <div className={classes.button_container}>
          <button>Confirm New Password</button>
        </div>
      </div>
    </AdminMain>
  );
};

export default AdminSettingsChangePassword;
