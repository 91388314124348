import React, { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom'
import { API_URL, Domain } from '../Domain/Domain';
import axios from 'axios'
// import Main from "../components/UI/Main/Main"
import VendorShopMain from '../components/UI/VendorShopMain/VendorShopMain';
import MarketPlaceProduct from '../components/MarketPlaceProduct/MarketPlaceProduct';
import { Link } from 'react-router-dom';
import { BiRefresh } from 'react-icons/bi';


const VendorShop = () => {
  const { customisedShopId } = useParams();
  const [vendor, setVendor] = useState(null);
  const [products, setProducts] = useState([]);
  const [seoData, setSeoData] = useState({})
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery,   setSearchQuery] = useState('')
  const [searchedProducts, setSearchedProducts] = useState([])

  useEffect(() => {
    fetchVendor(currentPage);
  }, [currentPage]);

  useEffect(() => {
    // Update the title and meta tags when the component mounts
    const pageTitle = seoData?.title ?? 'Electronic Shop';
    document.title = pageTitle;

    // Update or create the 'description' meta tag
    const metaDescriptionElement = document.querySelector('meta[name="description"]');
    if (metaDescriptionElement) {
      const metaDescriptionContent = seoData?.description ?? 'Electronic Shop';
      metaDescriptionElement.content = metaDescriptionContent;
    } else {
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.name = 'description';
      newMetaDescription.content = seoData?.description ?? 'Electronic Shop';
      document.head.appendChild(newMetaDescription);
    }

    console.log('Updated Page Title:', pageTitle);
    console.log('Updated Meta Description:', metaDescriptionElement?.content);

   
    return () => {
      document.title = 'Electronic Shop';

      if (metaDescriptionElement) {
        metaDescriptionElement.content = 'Electronic Shop';
      }
    };
  }, [seoData?.title, seoData?.description]); 


  const fetchVendor = (page) => {
    axios
    .get(Domain() + `/api/v1/vendor/${customisedShopId}?page=`+page)
    .then((response) => {
      setVendor(response.data?.data.vendorDetails);
      console.log(vendor)
      setSeoData(response.data?.data.seoData)
      setProducts(response.data?.data.products);
    })
    .catch((error) => {
      console.error('Error fetching vendor details:', error);
    });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const vendor_id = vendor?.id

  const handleSearch = () => {
    axios
      .get(
        Domain() + `/api/v1/vendor/products/${vendor_id}/${searchQuery}`
      )
      .then((response) => {
        setProducts(response.data?.data);
        console.log(products)
      })
      .catch((error) => {
        console.error('Error fetching search results:', error);
      });
  };

  const refresh = () => {
    window.location.reload()
  }

  return (
    <VendorShopMain> 
      <div className="container mt-5">
        <div>
        {vendor && (
          <div>
            <h2>Welcome to, {vendor?.shopName}</h2>
            <p style={{ fontSize: '20px'}}>{vendor.bio}</p>
          </div>
        )}
        </div><br /><br /><br />
        
        <div className='products-section'>
          {products ? (
            <div>
              <div 
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <h1 style={{ color: "orangeRed" }}>All products</h1>
            <Link to="/marketplace">
              <p style={{ color: "orange" }}>Go to Marketplace</p>
            </Link>
            

          </div>
          <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();
          }}
          >
            {/* Search input field */}
            <input
            type="text"
            placeholder="Search for products..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button type="submit" className='btn btn-primary'>Search</button>
            <button onClick={refresh} className='btn btn-primary'><BiRefresh /> Refresh</button>
          </form>
            </div>
          ) : (
              <div>There are no available products for this vendor.</div> 
          )}
        </div><br /><br />
        <MarketPlaceProduct products={products} linkRoute="/marketplace-deal" />
        <div className="pagination">
          <button className="btn btn-primary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
          </button>
          <button
              className="btn btn-primary"
              onClick={() => handlePageChange(currentPage + 1)}
            >
            Next
          </button>
        </div>
      </div>
    </VendorShopMain>
  );
}

export default VendorShop