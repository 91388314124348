import React, { useEffect, useState } from "react";
import { BsEyeSlash } from "react-icons/bs";
import { FaGoogle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import { setCookie } from "../../components/cookie/setCookie";
// import { setUser } from "../../components/cookie/setClientToken";
import ProductsAds from "../../components/ProductAds/ProductsAds";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import Main from "../../components/UI/Main/Main";
import { Domain } from "../../Domain/Domain";
import { toggleAlertAction } from "../../store/slices/alertSlice";
import classes from "../../styles/Register.module.css";
const Register = () => {
  const [viewPassword, setViewPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [viewConfirmPassword, setViewConfirmPassword] =
    useState<boolean>(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const [enteredData, setEnteredData] = useState<{
    email: string;
    password: string;
    phoneNumber: string;
    firstName: string;
    surname: string;
    otherName: string;
    confirmPassword: string;
  }>({
    email: "",
    password: "",
    firstName: "",
    otherName: "",
    phoneNumber: "",
    surname: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, []);

  const onChaneHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (enteredData.confirmPassword !== enteredData.password) {
      dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "Password did not match",
          status: "error",
        })
      );
      return setError("Password did not match");
    }
    if (
      enteredData.confirmPassword.length < 6 ||
      enteredData.password.length < 6
    ) {
      setError("Password is too short");
      dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "Password is too short",
          status: "error",
        })
      );
      return;
    }

    if (enteredData.email.includes("admin"))
      return setError("Email restricted please use a diffrent email address");
    const data = {
      email: enteredData.email,
      firstName: enteredData.firstName,
      surname: enteredData.surname,
      otherName: enteredData.otherName,
      phoneNumber: enteredData.phoneNumber,
      password: enteredData.password,
    };

    console.log(data);
    setIsLoading(true);

    if (isLoading) return;

    fetch(Domain() + "/api/v1/auth/register", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          // alert(response.message);

          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: response.message,
              status: "error",
            })
          );

          // setCookie(response.data.token, 30);
          // setUser(JSON.stringify(response.data.userDetails), 30);
          setIsLoading(false);
          navigate("/verify-account?email=" + enteredData.email);
        }

        if (response.status === 400) {
          setIsLoading(false);
          setError(response.message);
          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: response.message,
              status: "error",
            })
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.errors[0]);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: error.errors[0],
            status: "error",
          })
        );
      });
  };

  return (
    <Main>
      <form onSubmit={onSubmitHandler}>
        <div className={classes.forms_container}>
          <div className={classes.title_container}>
            <h2>Sign up</h2>
          </div>
          <div>
            {error !== "" && (
              <p
                style={{ color: "red", textAlign: "center", marginTop: "10px" }}
              >
                {error}
              </p>
            )}
          </div>
          <div className={classes.form_container_div}>
            <div className={classes.input_div_container}>
              <input
                onChange={onChaneHandler}
                required
                type="email"
                name="email"
                placeholder="Email"
              />
            </div>

            <div className={classes.input_div_container}>
              <input
                onChange={onChaneHandler}
                required
                type="text"
                name="phoneNumber"
                placeholder="Phone Number"
              />
            </div>
          </div>

          <div className={classes.form_container_div}>
            <div className={classes.input_div_container}>
              <input
                onChange={onChaneHandler}
                required
                type="text"
                name="firstName"
                placeholder="First Name"
              />
            </div>

            <div className={classes.input_div_container}>
              <input
                onChange={onChaneHandler}
                required
                type="text"
                name="surname"
                placeholder="Surname"
              />
            </div>
          </div>
          <div className={classes.form_container_div}>
            <div className={classes.input_div_container}>
              <input
                onChange={onChaneHandler}
                required
                type="text"
                name="otherName"
                placeholder="Other name"
              />
            </div>
            <div className={classes.input_div_container}>
              <input
                onChange={onChaneHandler}
                required
                type="hidden"
                name="otherName"
                placeholder="Other name"
              />
            </div>
          </div>
          <div className={classes.form_container_div}>
            <div className={classes.input_div_container}>
              <input
                onChange={onChaneHandler}
                required
                type={!viewPassword ? "password" : "text"}
                name="password"
                min={6}
                placeholder="Password"
              />
              <div className={classes.password_toggle_container}>
                <i onClick={() => setViewPassword(!viewPassword)}>
                  <BsEyeSlash />
                </i>
              </div>
            </div>

            <div className={classes.input_div_container}>
              <input
                onChange={onChaneHandler}
                required
                type={!viewConfirmPassword ? "password" : "text"}
                name="confirmPassword"
                min={6}
                placeholder="Confrim Password"
              />
              <div className={classes.password_toggle_container}>
                <i onClick={() => setViewConfirmPassword(!viewConfirmPassword)}>
                  <BsEyeSlash />
                </i>
              </div>
            </div>
          </div>

          <div className={classes.terms_and_condition_area}>
            <div className={classes.term_and_condition_div}>
              <input onChange={onChaneHandler} required type="checkbox" />
              <div>
                <p>
                  I have read and accepted the{" "}
                  <a href="">terms and condition</a> of Eletronicshop.ng
                </p>
              </div>
            </div>

            <div className={classes.term_and_condition_div}>
              <div className={classes.term_and_condition_div}>
                <input onChange={onChaneHandler} type="checkbox" />
                <div>
                  <p>
                    Keep me updated on the latest deals and special offers on
                    electronicshop,ng
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.button_container}>
            <button>SIGN UP</button>
          </div>
          {isLoading && (
            <div className={classes.login_spinner}>
              <SmallSpinner />
            </div>
          )}
          <br />

          <div className={classes.alternative_devide}>
            <div className={classes.line_bar}>
              <div className={classes._or_text}>
                <p>Or</p>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.button_container}>
          <button
            style={{
              backgroundColor: "white",
              color: "black",
              marginTop: "50px",
            }}
          >
            <span>
              <FaGoogle />
            </span>
            SIGN UP WITH GOOGLE
          </button>

          <br />
          <div className={classes.aready_ahave_account}>
            <h4>
              Aready have an account? <Link to={"/login"}>Login</Link>
            </h4>
          </div>
          <br />
          <ProductsAds />
        </div>
      </form>
    </Main>
  );
};

export default Register;
