import React from "react";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../store/slices/alertSlice";

const useAddToVendorCartHook = (product: any) => {
  console.log(product.id);
  const dispatch = useDispatch();
  const addToCart = () => {
    if (typeof product.id !== "number") return;

    let storedItem = JSON.parse(localStorage.getItem("vendor-cart") || "[]"); 

    let cartData = {
      productName: product.productName,
      id: product.id,
      images: product.pictures,
      priceing: product.promotionalPrice ? product.promotionalPrice : product.pricing,
      quantity: 1,
      added_by: product.added_by,
    };

    const existingItemIndex = storedItem.findIndex((item: any) => item.added_by !== cartData.added_by);

    if (existingItemIndex !== -1) {
      // Products in the cart have different 'added_by' values
      dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "You can only add products from the same vendor to the cart.",
        })
      );
    } else {
      // All products in the cart have the same 'added_by' value, so it's safe to add
      const existingProductIndex = storedItem.findIndex((item: any) => item.id === cartData.id);
    
      if (existingProductIndex !== -1) {
        // If the item already exists, update its quantity
        storedItem[existingProductIndex].quantity += 1;
      } else {
        // Otherwise, add it to the cart
        storedItem.push(cartData);
      }

      localStorage.setItem("vendor-cart", JSON.stringify(storedItem));
      dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "Item added to cart successfully",
        })
      );
    }
  };

  return { addToCart };
};

export default useAddToVendorCartHook;
