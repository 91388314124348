import React, { useState,useEffect } from "react";
import axios from "axios";
import { Domain2 } from "../../Domain/Domain";
import "./vendor-wallet.css"


function WalletDetails() {
  const [wallet, setWallet] = useState(null);
  const token  = localStorage.getItem("vendor-token")
      
  useEffect(() => {
    axios.get(`${Domain2}/vendor/fetch-wallet`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
      setWallet(response.data);
    });
  }, [token]);

  return (
    <div className="wallet-details-container">
        <h3>Vendor Wallet</h3><br />
        {wallet ? (
        <div className="wallet-details">
            <div>
                <p>
                  <span>Current Balance: </span><br />
                  <span>₦ {wallet.realBalance?.toLocaleString()}</span>
                </p>
            </div>
            <div>
                <p>
                  <span>Pending Balance: </span><br />
                  <span>₦ {wallet?.pendingBalance?.toLocaleString()}</span>
                </p>
            </div>
        </div>
        ) : (
          <p>Loading Wallet Balance</p>
        )}  
    </div>
  );
}

export default WalletDetails;
