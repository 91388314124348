import React, { useState, useEffect } from "react";
import { BsCartCheckFill, BsCheck2 } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import Cart from "../components/Cart/Cart";
import CartSummary from "../components/Cart/CartSummary";
import Main from "../components/UI/Main/Main";
import useGetCartDetailsHook from "../Hooks/useGetCartDetailsHook";
import classes from "../styles/CartPageStyles.module.css";
import { PaymentMethod, PaymentType } from "./Payment/Payment";
import { usePaystackPayment } from "react-paystack";
import useCheckoutHook from "../Hooks/useCheckoutHook";
import dommyText from "../data/dommyText";
import { useDispatch, useSelector } from "react-redux";
import { toggleIspaySmallSmall } from "../store/slices/paymentSlice";
import { getClientData } from "../components/cookie/getClientData";

const PaymentPage = () => {
  const { cartLength, cart, cartTotalPrice } = useGetCartDetailsHook();
  const { loader, onSuccess } = useCheckoutHook();
  const dispatch = useDispatch();
  const [paymentType, sePaymentType] = useState<string | null>("");
  const navigate = useNavigate();
  const paymentSelectror = useSelector((value: any) => value.payment);

  const onSelectPaymentType = (e: React.ChangeEvent<HTMLInputElement>) => {
    sePaymentType(e.target.id);
    if (e.target.id === "paySmallSmall") {
      dispatch(toggleIspaySmallSmall(true));
    } else {
      dispatch(toggleIspaySmallSmall(false));
    }
  };

  const onNavigateForwardHandler = () => {
    // if (paymentType === "paySmallSmall")
    // return navigate("/pay-small-small-aplication");
  };

  // Scroll top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const config = {
    reference: new Date().getTime().toString(),
      email: getClientData().email,
    amount: cartTotalPrice * 100,
    publicKey: dommyText(),
  };
  // you can call this function anything
  // const onSuccess: any = (reference: any) => {
  //   // Implementation for whatever you want to do with reference and after success call.
  //   console.log(reference);
  // };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const PaystackHookExample = () => {
    const initializePayment = usePaystackPayment(config);
    return (
      <button
        className={classes.check_out_btn}
        onClick={() => {
          initializePayment(onSuccess as any, onClose);
        }}
      >
        NEXT
      </button>
    );
  };

  useEffect(() => {
    if (paymentSelectror.isPaySmallSmall) {
      sePaymentType("paySmallSmall");
    }
  }, []);

  return (
    <Main>
      <div>
        <div className={classes.cart_navs_container}>
          <Link to={"/cart"}>
            <button>Cart details</button>
          </Link>
          <Link to={"/account-addresses"}>
            <button>Delivery Address</button>
          </Link>
          <Link to={"/payment"}>
            <button>Payment method</button>
          </Link>
        </div>
        <br />

        <div className={classes.cart_main}>
          <div className={classes.first_col}>
            <div className={classes.cart_hed_cetails_line}>
              <h3>Payment Method</h3>
            </div>
            <br />
            <PaymentType onChangeHandler={onSelectPaymentType} />
            <br />
            {paymentType === "paySmallSmall" && <PaymentMethod />}
            {/* {paymentSelectror.isPaySmallSmall && <PaymentMethod />} */}
            <br />

            <div className={classes.navigating_button}>
              <button onClick={() => navigate(-1)}>BACK</button>
              {paymentType === "payStack" && (
                <>
                  {paymentType === "payStack" ? (
                    <PaystackHookExample />
                  ) : (
                    <button onClick={onNavigateForwardHandler}>NEXT</button>
                  )}
                </>
              )}
              {paymentSelectror.firstPayment > 0 &&
                paymentSelectror.installmentOption > 0 && (
                  <>
                    {paymentType === "paySmallSmall" && (
                      <Link to="/pay-small-small-application">
                        <button>NEXT</button>
                      </Link>
                    )}
                  </>
                )}
            </div>
          </div>

          <div className={classes.summarry_section}>
            <div className={classes.summary_wrapper}>
              <div className={classes.check_out_btn}>
                <CartSummary />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default PaymentPage;
