import React, { useState } from "react";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import BackButton from "../../components/UI/BackButton/BackButton";
import VendorMain from "../../components/UI/VendorMain/VendorMain";
import { Domain } from "../../Domain/Domain";
import classes from "../../styles/SettingsStyles.module.css";
const VendorChangePassword = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let [status, setStatus] = useState<{
    message: string;
    isError: boolean;
  }>({ message: "", isError: false });
  const [enteredData, setEnteredData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    fetch(Domain() + "/api/v1/vendor/change-password", {
      method: "PATCH",
      body: JSON.stringify({
        oldPassword: enteredData.oldPassword,
        newPassword: enteredData.newPassword,
      }),
      headers: {
        Authorization: window.localStorage.getItem("vendor-token") as string,
        "Content-Type": "application/json; charset=utf-8",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setIsLoading(false);
          setStatus({
            isError: false,
            message: response.message,
          });
          setIsLoading(false);
        }

        if (response.status === 400) {
          setStatus({
            isError: true,
            message: response.message,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setStatus({
          isError: false,
          message: "Unable to update password",
        });
      });
  };

  return (
    <VendorMain>
      <BackButton />
      <form onSubmit={onSubmitHandler}>
        <div>
          <br />
          <h3>Change Password</h3>
          {status.message.length > 0 && (
            <>
              <br />
              <p
                style={{
                  color: status.isError ? "white" : "white",
                  backgroundColor: status.isError ? "red" : "#00800080",
                }}
                className="success-message-form-dorm"
              >
                {status.message}
              </p>
              <br />
            </>
          )}
          <br />
          <div className={classes.input_wrapper}>
            <input
              type="password"
              onChange={onChangeHandler}
              name={"oldPassword"}
              value={enteredData.oldPassword}
              placeholder="Old Password"
              required
            />
            <input
              type="password"
              onChange={onChangeHandler}
              name={"newPassword"}
              value={enteredData.newPassword}
              placeholder="New Password"
              required
            />
            <input
              type="password"
              onChange={onChangeHandler}
              name={"confirmPassword"}
              value={enteredData.confirmPassword}
              placeholder="Confirm Password"
              required
            />
          </div>
          <br />
          <div className={classes.button_container}>
            <button>Confirm New Password</button>
          </div>
          <br />
          {isLoading && <SmallSpinner />}
        </div>
      </form>
    </VendorMain>
  );
};

export default VendorChangePassword;
