import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getClientData } from "../../components/cookie/getClientData";
import { getClientToken } from "../../components/cookie/getClientToken";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import Account from "../../components/UI/Account/Account";
import { CreateAddressForm } from "../../data/CreateAddressForm";
import { Domain } from "../../Domain/Domain";
import classes from "../../styles/Account.module.css";
const UpdateAddress = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [message, setMessage] = useState({ error: "", success: "" });
  const [enteredData, setEnteredData] = useState({
    address: "",
    state: "",
    city: "",
    country: "",
  });
  const navigate = useNavigate();

  const onChangeHandler = (e: any) => {
    setEnteredData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  

  const urlSearch = new URLSearchParams(window.location.search);
  useEffect(() => {
    fetch(Domain() + "/api/v1/user/address/all", {
      method: "POST",
      headers: {
        Authorization: getClientToken(),
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response: any) => {
        console.log(response.data);
        if (response.status === 200) {
          let foundAddress = response.data.filter(
            (cur: any) => cur.id === parseInt(urlSearch.get("id") as string)
          );
          setEnteredData((prevState) => {
            return {
              ...prevState,
                ...foundAddress[0],
            };
          });
        }
      })
      .catch((error) => {
        console.log(error);
        // alert(error.response.data.message);
        console.log(error.response.data.message);
      });
  }, []);

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);

    let data = {
      address: enteredData.address,
      state: enteredData.state,
      country: enteredData.country,
      city: enteredData.city,
    };
    axios
      .post(
        Domain() + "/api/v1/user/address/" + urlSearch.get("id"),
        JSON.stringify(data),
        {
          headers: {
            Authorization: getClientToken(),
            "Content-Type": "application/json",
          },
        }
      )

      .then((response: any) => {
        setLoader(false);
        console.log(response.data.data);
        setMessage({
          error: response.data.data,
          success: "",
        });
        if (response.data.status === 200) {
          navigate("/account-addresses");
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
        setMessage({
          error: error.response.data.message,
          success: "",
        });
      });
  };
  return (
    <Account>
      <form onSubmit={onSubmitHandler}>
        <div>
          <div className={classes.messages_data}>
            <p className="error_message">
              {message.error !== "" && message.error}
            </p>
            <p className="success_message">
              {message.success !== "" && message.success}
            </p>
          </div>
          <div className={classes.application_form}>
            <>
              <label htmlFor="">{"Address"}</label>
              <input
                type={"text"}
                name={"address"}
                placeholder={"Address"}
                required
                // value={enteredData.address}
                onChange={onChangeHandler}
              />
            </>
            <br />
            <div>
              <label htmlFor="">City</label>
              <select id="" name={"city"} onChange={onChangeHandler} required>
                <option value={enteredData.city}>{enteredData.city}</option>
                <option value={"Port Harcourt"}>Port Harcourt</option>
              </select>
            </div>
            <br />

            <div>
              <label htmlFor="">Select State</label>
              <select id="" name={"state"} onChange={onChangeHandler} required>
                <option value={enteredData.state}>{enteredData.state}</option>
                <option value={"Rivers State"}>Port Harcourt</option>
              </select>
            </div>
            <br />
            <div>
              <label htmlFor="">Contact Address</label>
              <select
                id=""
                name={"country"}
                onChange={onChangeHandler}
                required
              >
                <option value={enteredData.country}>
                  {enteredData.country}
                </option>
                <option value={"Nigeria"}>Nigeria</option>
              </select>
            </div>
          </div>
          <br />
          <div className={classes.add_adderess_button + " " + classes.btn_2}>
            <button>ADD ADDRESS</button>
            {loader && <SmallSpinner />}
          </div>
        </div>
      </form>
    </Account>
  );
};

export default UpdateAddress;
