import React, { useState, useEffect, useCallback } from "react";
import MarketPlaceProduct from "../components/MarketPlaceProduct/MarketPlaceProduct";
import ProductsAds from "../components/ProductAds/ProductsAds";
import ProductItem from "../components/ProductItem/ProductItem";
import Main from "../components/UI/Main/Main";
import { titleStyles } from "../styles/ProductStyles";
import SimpleImageSlider from "react-simple-image-slider";
import axios from "axios";
import { Domain } from "../Domain/Domain";
import Spinner from "../components/Spinner/Spinner";
import { useSelector, useDispatch } from "react-redux";
import {
  onSetBrandhandler,
  onSetCategoryHandler,
  onGetSearchHandler,
  onSetModelFilter,
  onSetMaxPriceFilter,
} from "../store/slices/searchSlice";
import { Link } from "react-router-dom";
import {
  addPromotionData,
  generatePromotionsObj,
  setPromotionsArray,
} from "../store/slices/promotionsSlice";
import {
  Ad,
  getImagesFromAd,
  getPromotionData, 
  getPromotions,
  Promo,
  promotionsHeaders,
} from "../utils/promotionHelpers";

const HomePage = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState<any>([]);
  const [randomProducts, setRandomProducts] = useState<any>([]);
  const [promotions, setPromotions] = useState<Ad[]>([]);
  const [homePageHeaderAdData, setHomePageHeaderAdData] = useState<Promo[]>([]);
  const [homePageHeaderAdImages, setHomePageHeaderAdImages] = useState<
    { url: string }[]
  >([]);

  const promotionSelector = useSelector((value: any) => value.promotion);

  let isMobile = window.matchMedia("(max-width: 600px)").matches;

  const dummyImages = [
    { url: "/images/slides/slide.png" },
    { url: "/images/slides/slide1.jpg" },
    { url: "/images/slides/slide2.jpg" },
    { url: "/images/slides/slide3.jpg" },
    { url: "/images/slides/slide4.jpg" },
  ];

  useEffect(() => {
    dispatch(onSetBrandhandler(""));
    dispatch(onSetCategoryHandler(""));
    dispatch(onGetSearchHandler(""));
    dispatch(onSetModelFilter(null));
    dispatch(onSetMaxPriceFilter(null));
    //reset seemore page item
    localStorage.setItem('SeeMorepage', '0')
    axios
      .get("https://apiv2.electronicshop.com.ng/recommended-products")
      .then((response: any) => {
        if (response.status === 200) setRandomProducts(response.data ?? []);
      })
      .catch((error) => {});

    axios
      .get(Domain() + "/api/v1/product/new-arrivals/more?page=0&size=10")
      .then((response: any) => {
        if (response.data.status === 200)
          setProducts(response.data.data.products);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    setPromotions(promotionSelector.promotionsArray);
  }, [promotionSelector.promotionsArray]);

  useEffect(() => {
    const getHomePageHeaderAdData = async () => {
      const homePageHeaderAd = await getPromotionData(2, "Home Page Header Ad");
      dispatch(addPromotionData(homePageHeaderAd));
      const imagesFromAd = getImagesFromAd(homePageHeaderAd.data);

      console.log({
        imagesFromAd,
      });

      setHomePageHeaderAdData(homePageHeaderAd.data);
      setHomePageHeaderAdImages(imagesFromAd);

      console.log({
        homePageHeaderAd,
      });
    };

    getHomePageHeaderAdData();
  }, [promotions]);

  useEffect(() => {

    const metaDescriptionElement: HTMLMetaElement | null = document.querySelector('meta[name="description"]');
    if (metaDescriptionElement) {
      metaDescriptionElement.content = 
      "ElectronicsShop is a brand that specializes in wholesale and distribution of electronics and electrical items. They offer a widerange of products from top brands and only source their products from reputable manufacturers and suppliers. The brand is committed to providing exceptional customer service and personalized solutions to help their clients achieve their business goals. ElectronicsShop aims to be a one-stop-shop for all electronics and electrical needs and is dedicated to providing the best value for their customers money"
    }
}); 

  // https://apiv2.electronicshop.com.ng/recommended-products
  return (
    <Main>
      <div className="slider_container">
        <SimpleImageSlider
          width={!isMobile ? "100%" : "100%"}
          height={!isMobile ? "404px" : "300px"}
          images={
            homePageHeaderAdImages.length > 0
              ? homePageHeaderAdImages
              : dummyImages
          }
          showBullets={true}
          showNavs={true}
          autoPlay={true}
          loop={true}
          autoPlayDelay={1.5}
        />
      </div>
      <article>
        <div style={titleStyles}>
          <h1>New arrivals</h1>
          <Link
            to={
              "/seemore?title=New arrivals&&link=" +
              Domain() +
              "/api/v1/product/new-arrivals"
            }
          >
            <p>See more</p>
          </Link>
        </div>
        <ProductItem data={products} /> 
        {products.length < 1 && <Spinner />}
        <br />
        <br />
        <div style={titleStyles}>
          <h1>New recommended</h1>
          {/* <p>See more</p> */}
        </div>
        <ProductItem data={randomProducts} />
      </article>
    </Main>
  );
};

export default HomePage;
