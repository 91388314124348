export const PaySmallSmallForm = [
  {
    type: "text",
    placeHolder: "John",
    labelFor: "First Name",
    name: "firstName",
    value: "",
    isInput: true,
  },
  {
    type: "text",
    placeHolder: "Doe",
    labelFor: "Surname",
    name: "surname",
    value: "",
    isInput: true,
  },
  {
    type: "text",
    placeHolder: "Doe",
    labelFor: "Other Names",
    name: "otherName",
    value: "",
    isInput: true,
  },
  {
    type: "date",
    placeHolder: "01/07/1990",
    labelFor: "DOB",
    name: "dob",
    value: "",
    isInput: true,
  },
  {
    type: "email",
    placeHolder: "John",
    labelFor: "Email",
    name: "email",
    value: "",
    isInput: true,
  },
  {
    type: "text",
    placeHolder: "+234 123 456 89",
    labelFor: "Phone Number",
    name: "phoneNumber",
    value: "",
    isInput: true,
  },
  {
    type: "text",
    placeHolder: "",
    labelFor: "Contact Address",
    name: "address",
    value: "",
    isInput: true,
  },
  {
    type: "select",
    placeHolder: "State",
    labelFor: "Contact Address",
    name: "state",
    value: "",
    isInput: false,
    options: ["Rivers State", "lagos"],
  },
  {
    type: "select",
    placeHolder: "City",
    labelFor: "Contact Address",
    name: "city",
    value: "city",
    isInput: false,
    options: ["Port Harcourt", "Rivers State"],
  },

  // {
  //   type: "password",
  //   placeHolder: "Enter your password",
  //   labelFor: "Password",
  //   name: "",
  //   value: "",
  //   isInput: true,
  // },
  // {
  //   type: "password",
  //   placeHolder: "Enter your password",
  //   labelFor: "Confirm Password",
  //   name: "",
  //   value: "",
  //   isInput: true,
  // },
];
