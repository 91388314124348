import React, { useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import classes from "./AdminPagination.module.css";

interface Props {
  onIncreaseHandler?: () => void;
  onDecreaseHandler?: () => void;
  pageNumber?: number;
  hasReactedLimit?: boolean;
}
const AdminPagination: React.FC<Props> = ({
  onDecreaseHandler,
  onIncreaseHandler,
  pageNumber,
  hasReactedLimit = false,
}) => {
  return (
    <div className={classes.pagination}>
      <p>Showing </p>
      <input type="number" readOnly onChange={() => {}} value={pageNumber} />
      <p>of 10</p>
      <div className={classes.left_slide} onClick={onDecreaseHandler}>
        <i>
          <BsChevronLeft />
          
        </i>
      </div>

      {/* {hasReactedLimit ? ( */}
        <div className={classes.left_slide} onClick={onIncreaseHandler}>
          <i>
            <BsChevronRight />
          </i>
        </div>
       {/* ) : (
         <div className={classes.left_slide} style={{cursor: 'not-allowed'}}>
           <i style={{cursor: 'not-allowed'}}>
             <BsChevronRight style={{cursor: 'not-allowed'}} />
           </i>
         </div>
       )} */}
    </div>
  );
};

export default AdminPagination;
