import React, { useState, useEffect } from "react";
import { FaFastBackward, FaFastForward } from "react-icons/fa";
import {
  AiFillSchedule,
  AiOutlineCloseSquare,
  AiOutlineSchedule, 
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import classes from "../../styles/AdminManagePromotions.module.css";
import AvailablePromotionsList, {
  PromotionsTable,
} from "../../components/AvailablePromotions/AvailablePromotionsList";
import { BsTrash } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import PromotionLeftSidebar from "../../components/AvailablePromotions/PromotionLeftSidebar";
import PromoList from "../../components/AvailablePromotions/PromoList";
import useFetchPromotionEnums from "../../Hooks/useFetchPromotionEnums";
import { getCookie } from "../../components/cookie/getCookie";
import axios from "axios";
import { Domain } from "../../Domain/Domain";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../../store/slices/alertSlice";

export interface Ad {
  id: number;
  category: number;
  title: string;
  images: string;
  is_live: boolean;
  createdAt: string;
  updatedAt: string;
}

// export const PromotionsTable = ({ promotions }: { promotions: Ad[] }) => {
//   return (
//     <div className={classes.promotions_table}>
//       <table>
//         <thead>
//           <tr>
//             <th>ID</th>
//             <th>Category</th>
//             <th>Title</th>
//             <th>Images</th>
//             <th>Is Live</th>
//             <th>Created At</th>
//             <th>Updated At</th>
//           </tr>
//         </thead>
//         <tbody>
//           {promotions.map((ad) => (
//             <tr key={ad.id}>
//               <td>{ad.id}</td>
//               <td>{ad.category}</td>
//               <td>{ad.title}</td>
//               <td>
//                 <ul>
//                   {JSON.parse(ad.images).map(
//                     (
//                       image: string | undefined,
//                       index: React.Key | null | undefined
//                     ) =>
//                       image && (
//                         <li key={index}>
//                           <img
//                             src={image}
//                             alt={`${index}`}
//                             style={{
//                               width: "50px",
//                               height: "50px",
//                             }}
//                           />
//                         </li>
//                       )
//                   )}
//                 </ul>
//               </td>
//               <td>{ad.is_live ? "Yes" : "No"}</td>
//               <td>{ad.createdAt}</td>
//               <td>{ad.updatedAt}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

const AdminManagePromotions = (): JSX.Element => {
  const dispatch = useDispatch();
  const [curNav, setCurNav] = useState<string>("inbox");
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [selectedPromotion, setSelectedPromotion] = useState<number[]>([]); // [1,2,3,4,5
  const navigate = useNavigate();
  useEffect(() => {
    const url = new URLSearchParams(window.location.search);
    let page = url.get("page");
    if (page === null) navigate("/admin-new-manage-promotions?page=slider");
    setCurNav(page as string);
  }, [window.location.search]);

  const { fetchedData } = useFetchPromotionEnums(); //Fetch list of promotions

  const [promotions, setPromotions] = useState<Ad[]>([]);

  const handleUpdateSelectedPromotion = (selected: number[]) => {
    console.log("AdminManagePromotions -> selected", selected);
    setSelectedPromotion(selected);
  };

  const handleMoveSelectedPromotionLive = async (selected: number[]) => {
    await Promise.all(
      selected.map(async (id) => {
        try {
          const response = await axios.patch(
            Domain() + "/api/v1/promotion/" + activeIndex + "/" + id,
            {},
            {
              headers: {
                Authorization: getCookie(),
                "Content-Type": "application/json; charset=utf-8",
              },
            }
          );
          console.log(response);
          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: response.data.message,
              status: response.data.status === 200 ? "success" : "error",
            })
          );
          return response;
        } catch (error) {
          console.log(error);
          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: "There was a problem moving promotion",
              status: "error",
            })
          );
          return error;
        }
      })
    );

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  useEffect(() => {
    axios
      .get(Domain() + "/api/v1/promotion/" + activeIndex, {
        headers: {
          Authorization: getCookie(),
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response: any) => {
        console.log(response);
        setPromotions(response.data.data);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: response.data.message,
            status: response.data.status === 200 ? "success" : "error",
          })
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: "There was a problem fetching promotion",
            status: "error",
          })
        );
      });
  }, [activeIndex, dispatch]);

  useEffect(() => {
    console.log({ promotions });
  }, [promotions]);

  return (
    <AdminMain>
      <div>
        <h3>Manage Promotions</h3>

        <div className={classes.nav_container}>
          {fetchedData.map((cur: any, i: React.Key | null | undefined) => {
            return (
              <button
                key={i}
                style={{
                  backgroundColor: curNav === "slider" ? "black" : "unset",
                  color: curNav === "slider" ? "white" : "#7692B4",
                }}
                onClick={
                  () => setActiveIndex(cur.id)
                  // navigate("/admin-new-manage-promotions?page=" + cur.name)
                }
              >
                {cur.name}
              </button>
            );
          })}
        </div>

        <h4>Live</h4>

        {curNav === "slideer" && (
          <>
            <div className={classes.slide_container}>
              <div>
                <img src="/images/slides/slide5.png" alt="" />
              </div>
              <div>
                <img src="/images/slides/slide5.png" alt="" />
              </div>
              <div>
                <img src="/images/slides/slide5.png" alt="" />
              </div>
              <div>
                <img src="/images/slides/slide5.png" alt="" />
              </div>
            </div>

            <div className={classes.live_controller}>
              <div>
                <i>
                  <FaFastBackward />
                </i>
                <p>Move forward</p>
              </div>

              <div>
                <i>
                  <AiOutlineCloseSquare />
                </i>
                <p>Remove</p>
              </div>

              <div>
                <i>
                  <FaFastForward />
                </i>
                <p>Move backward</p>
              </div>

              <div>
                <i>
                  <AiFillSchedule />
                </i>
                <p>Schedule</p>
              </div>
            </div>
          </>
        )}

        {curNav === "leftSlider1" && <PromotionLeftSidebar />}

        {curNav === "promo-list" && <PromoList />}
        <p className={classes.available_title}>Available</p>

        <PromotionsTable
          promotions={promotions}
          updateSelected={handleUpdateSelectedPromotion}
        />

        {/* <AvailablePromotionsList /> */}
        <div className={classes.live_controller + " " + classes.second_list_}>
          <button
            onClick={() => handleMoveSelectedPromotionLive(selectedPromotion)}
            disabled={selectedPromotion.length === 0}
          >
            <i>
              <FaFastBackward />
            </i>
            <p>Move to Live</p>
          </button>
          {/* 
          <div>
            <i>
              <AiOutlineSchedule />
            </i>
            <p>Schedule</p>
          </div>

          <div>
            <i>
              <FiEdit />
            </i>
            <p>Edit</p>
          </div>

          <div>
            <i>
              <BsTrash />
            </i>
            <p>Delete</p>
          </div> */}
        </div>
        <br />
        <div className={classes.save_changes_container}>
          <button>SAVE CHANGES</button>
        </div>
      </div>
    </AdminMain>
  );
};

export default AdminManagePromotions;
