import React from "react";
import { BsTrash } from "react-icons/bs";
import { FiSend } from "react-icons/fi";
import { orderData } from "../../data/OrderData";
import classes from "./AdminMessageCenterComponent.module.css";

const SheduledMessages = () => {
  return (
    <div className={classes.order_table}>
      <table className={classes.table_section}>
        <tr className={classes.header_section}>
          <td>#</td>
          <td>To</td>
          <td>From</td>
          <td>Date/Time</td>
          <td>Send Now</td>
          <td>Delete</td>
        </tr>
        {orderData.map((cur: any, index: number) => (
          <tr key={cur.id} className={classes.lists_}>
            <td>{index + 1}</td>
            <td>{cur.to}</td>
            <td>{cur.from}</td>
            <td>{cur.dateAndTime}</td>
            <td>
              <i>
                <FiSend />
              </i>
            </td>
            <td>
              <i>
                <BsTrash />
              </i>
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default SheduledMessages;
