export const VendorLofinForm = [
  {
    type: "email",
    placeHolder: "John",
    labelFor: "Email",
    name: "email",
    value: "",
    isInput: true,
  },
  {
    type: "password",
    placeHolder: "Password",
    labelFor: "Password",
    name: "password",
    value: "",
    isInput: true,
  },
];
