import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getClientToken } from "../components/cookie/getClientToken";

const useClientAuth = () => {
  const [clineIsLoggedIn, setClientIsLggedIn] = useState(true);
  useEffect(() => {
    function checkAuth() {
      const parseJwt = (token: string) => {
        try {
          return JSON.parse(atob(token.split(".")[1]));
        } catch (e: any) {
          return null;
        }
      };

      const decodedJwt: any = parseJwt(getClientToken()) as unknown as any;
      if (decodedJwt === null) return setClientIsLggedIn(false);
      if (decodedJwt.exp * 1000 < Date.now()) {
        setClientIsLggedIn(false);
      } else {
        setClientIsLggedIn(true);
      }
    }
    checkAuth();
    let interval = setInterval(() => {
      checkAuth();
    }, 1000);

    return () => clearTimeout(interval);
  }, []);
  return { clineIsLoggedIn };
};

export default useClientAuth;
