import React, { useState } from "react";
import AdminPagination from "../../components/AdminPagination/AdminPagination";
import AdminSearch from "../../components/AdminSearch/AdminSearch";
import ManageTechnicians from "../../components/TechnicalHubComponents/ManageTechnicians";
import TechnicalHubAll from "../../components/TechnicalHubComponents/TechnicalHubAll";
import TechnicalReports from "../../components/TechnicalHubComponents/TechnicalReports";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import classes from "../../styles/AdminTechnicalHub.module.css";

const AdminReport = (): JSX.Element => {
  const [currentNav, setCurrentNav] = useState({
    all: true,
    techIsActive: false,
    reportIsActive: false,
  });
  return (
    <AdminMain>
      <div>
        <h3 className={classes.title}>Reports</h3>
        <br />

        <div className={classes.order_header}>
          <div className={classes.order_list}>
            <p>From</p>
            <input type="date" name="" id="" />
          </div>

          <div className={classes.order_list}>
            <p>To</p>
            <input type="date" name="" id="" />
          </div>
        </div>
        <AdminSearch />
        <br />
        <br />
        {currentNav.all && <TechnicalHubAll />}
        {currentNav.techIsActive && <ManageTechnicians />}
        {currentNav.reportIsActive && <TechnicalReports />}
        <AdminPagination />
      </div>
    </AdminMain>
  );
};

export default AdminReport;
