import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsCartCheckFill, BsCheck2 } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cart from "../components/Cart/Cart";
import CartSummary from "../components/Cart/CartSummary";
import { getClientToken } from "../components/cookie/getClientToken";
import SmallSpinner from "../components/Spinner/SmallSpinner";
import BackButton from "../components/UI/BackButton/BackButton";
import Main from "../components/UI/Main/Main";
import { PaySmallSmallForm } from "../data/PaySmallSmallForm";
import { Domain } from "../Domain/Domain";
import useGetCartDetailsHook from "../Hooks/useGetCartDetailsHook";
import classes from "../styles/CartPageStyles.module.css";
import { PaymentMethod, PaymentType } from "./Payment/Payment";
import { toggleAlertAction } from "../store/slices/alertSlice";

const PaySmallSmallApplication = () => {
  const dispatch = useDispatch();
  const [enteredData, serEnteredData] = useState({});
  const cart = useGetCartDetailsHook();
  const [loader, setLoader] = useState<boolean>(false);
  const paymentSelectror = useSelector((value: any) => value.payment);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChnagehandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    serEnteredData((prevState: any) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onSUbmitFormHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);
    let cartList = cart.cart;
    let formData = {
      ...enteredData,
      products: cartList.map((cur: any) => {
        delete cur.color;
        delete cur.images;
        delete cur.date;
        delete cur.priceing;
        return cur;
      }),
      firstPayment: paymentSelectror.firstPayment,
      installmentOption: paymentSelectror.installmentOption,
    };
    console.log(formData);
    axios
      .post(
        Domain() + "/api/v1/order/pay-small-small",
        JSON.stringify(formData),
        {
          headers: {
            Authorization: getClientToken(),
            "Content-Type": "application/json",
          },
        }
      )

      .then((response: any) => {
        setLoader(false);
        console.log(response.data);
        setLoader(false);
        if (response.data.status === 200) {
          // alert("Pay small small request submitted successfully");
          localStorage.removeItem('cart')
          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: "Pay small small request submitted successfully",
              status: "",
            })
          );
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
        console.log(error?.response?.data?.message);
        // alert(error?.response?.data?.message);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: error.response.data.message,
            status: "error",
          })
        );

        // alert(
        //   "Please try again later. An error has occurred; it could be a network issue or some invalid credentials were entered."
        // );
      });
  };
  return (
    <Main>
      <div>
        <div className={classes.cart_main}>
          <div className={classes.first_col}>
            <div className={classes.cart_hed_cetails_line}>
              <h3>Pay Small Small Form</h3>
              <div>
                <BackButton />
              </div>
            </div>
            <br />
            <PaymentMethod />
            <br />
            <div>
              <form onSubmit={onSUbmitFormHandler}>
                {PaySmallSmallForm.map((cur, index) => (
                  <div key={index} className={classes.application_form}>
                    <br />
                    {cur.isInput ? (
                      <>
                        <label htmlFor="">{cur.labelFor}</label>
                        <input
                          type={cur.type}
                          placeholder={cur.placeHolder}
                          name={cur.name}
                          required
                          onChange={onChnagehandler}
                        />
                      </>
                    ) : (
                      <select
                        name={cur.name}
                        id=""
                        onChange={onChnagehandler}
                        required
                      >
                        <option value="">{cur.placeHolder}</option>
                        {cur.options?.map((option, index: number) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                ))}
                <br />
                <div className={classes.terms_section}>
                  <input type="checkbox" name="" id="" required />
                  <p>
                    I have read and accepted the Terms and conditions of using
                    Pay Small Small on electronicshop.ng{" "}
                  </p>
                </div>
                <br />
                <div className={classes.submit_buttin}>
                  <button>Submit</button>
                  <br />
                  {loader && <SmallSpinner />}
                </div>
              </form>
            </div>
          </div>

          <div className={classes.summarry_section}>
            <div className={classes.summary_wrapper}>
              <div className={classes.check_out_btn}>
                <CartSummary />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default PaySmallSmallApplication;
