import React, { useState } from "react";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import classes from "../../styles/NewBrandPage.module.css";

import useCreateBrandHook from "../../Hooks/useCreateBrandHook";
import useUpdateBrandHook from "../../Hooks/useUpdateBrandHook";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import useDeleteBrandAndCategory from "../../Hooks/useDeleteBrandAndCategory";
const DeleteBrandAndCategory = () => {
  const {
    fetchedbands,
    isBrandorCategoryForUpdate,
    onBrandOrToggleChangeForUpdate,
    onChangeNameHandler,
    onBrandUpdated,    
    isSubmittingForUpdate,
  } = useDeleteBrandAndCategory();

  return (
    <div>
      {/* DELETE BRAND AND CATEGORY */}
      <br />
      <br />
      <p className={classes.title}>DELETE Brand/Category</p>
      <br />

      <form onSubmit={onBrandUpdated}>
        <div className={classes.brand_container_first}>
          <p>Brand/Category</p>
          <select
            name=""
            id=""
            onChange={onBrandOrToggleChangeForUpdate}
            required
          >
            <option value="brand">Brand</option>
            <option value="category">Category</option>
          </select>
        </div>
        <div className={classes.brand_container_first}>
          <p>Name</p>
          <select onChange={onChangeNameHandler} required>
            <option value="">
              {isBrandorCategoryForUpdate === "brand"
                ? "Brand names"
                : "Category names"}
            </option>
            {fetchedbands.map((cur: any) => (
              <option key={cur.id} value={cur.id}>
                {cur.name}
              </option>
            ))}
          </select>
        </div>

        <div className={classes.button_container}>
          <button>DELETE</button>
          {isSubmittingForUpdate && <SmallSpinner />}
        </div>
      </form>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default DeleteBrandAndCategory;
