import React, { useEffect, useState } from "react";
import axios from "axios";
import MarketPlaceProduct from "../components/MarketPlaceProduct/MarketPlaceProduct";
import VendorMain from "../components/UI/VendorMain/VendorMain";
import { Domain } from "../Domain/Domain";
import { getClientData } from "../components/cookie/getClientData";
import { getClientToken } from "../components/cookie/getClientToken";
import { useNavigate } from "react-router-dom";
import Main from "../components/UI/Main/Main";
import VendorAside from "../components/UI/VendorMain/VendorAside";
const MarketPlaceVendorProducts = () => {
  const [products, setProducts] = useState<any>([]);
  const navigate = useNavigate();
  useEffect(() => {
    let url = new URLSearchParams(window.location.search);

    axios
      .get(Domain() + "/api/v1/vendor/view-vendor-products/" + url.get("id"), {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response: any) => {
        console.log(response);
        if (response.data.status === 200) {
          setProducts(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Main>
      <div
        style={{
          display: "flex",
        }}
      >
        <VendorAside isForVendor={false} />

        <div
          style={{ backgroundColor: "#F14306", padding: "10px", width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <h1 style={{ color: "white" }}>All products</h1>
            <p style={{ color: "white" }}>Go to Marketplace</p>
          </div>
          <MarketPlaceProduct products={products} linkRoute="/vendor-product" />
        </div>
      </div>
    </Main>
  );
};

export default MarketPlaceVendorProducts;
