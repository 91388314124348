import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getClientData } from "../../components/cookie/getClientData";
import { getClientToken } from "../../components/cookie/getClientToken";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import Account from "../../components/UI/Account/Account";
import { CreateAddressForm } from "../../data/CreateAddressForm";
import { Domain } from "../../Domain/Domain";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import BackButton from "../../components/UI/BackButton/BackButton";
import classes from "../../styles/SettingsStyles.module.css";
import { getCookie } from "../../components/cookie/getCookie";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../../store/slices/alertSlice";
const AdminNewAdmin = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState<boolean>(false);
  const [enteredData, setEnteredData] = useState({
    email: "",
    role_id: 1,
    adminPassword: "",
  });
  const [roles, setRoles] = useState<any>([]);

  useEffect(() => {
    axios
      .get(`https://api.electronicshop.com.ng/api/v1/admin/roles`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("token") as string),
        },
      })
      .then((res) => {
        console.log("roles we have: ", res);
        setRoles(res.data.data);
      })
      .catch((error) => {
        console.log("ero: ", error);
      });
  }, []);

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setEnteredData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoader(true);
    const data = {
      ...enteredData,
    };

    axios
      .post(Domain() + "/api/v1/admin/register", JSON.stringify(data), {
        headers: {
          Authorization: getCookie(),
          "Content-Type": "application/json",
        },
      })

      .then((response: any) => {
        // alert(response.data.message);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: response.data.message,
            status: "",
          })
        );
        setLoader(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        // alert(error.response.data.message);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: error.response.data.message,
            status: "error",
          })
        );
        setLoader(false);
      });
  };

  return (
    <AdminMain>
      <BackButton />
      <form onSubmit={onSubmitHandler}>
        <div>
          <br />
          <h3>New Admin</h3>
          <br />
          <div className={classes.input_wrapper}>
            <p>
              <small>Admin Type</small>
            </p>
            <select name="role_id" id="" onChange={onChangeHandler} required>
              {roles.length &&
                roles.map((role: any, index: any) => {
                  return (
                    <option key={index} value={role?.id}>
                      {role?.roleName}
                    </option>
                  );
                })}
            </select>
            <input
              type="text"
              name="email"
              onChange={onChangeHandler}
              required
              placeholder="New Admin Email"
            />
            <input
              type="password"
              required
              name="adminPassword"
              onChange={onChangeHandler}
              placeholder="Your Password"
            />
          </div>
          <br />
          <div className={classes.button_container}>
            <button>Confirm New Password</button>
          </div>
          {loader && (
            <>
              <br />
              <SmallSpinner />
              <br />
            </>
          )}
        </div>
      </form>
    </AdminMain>
  );
};

export default AdminNewAdmin;
