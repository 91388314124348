import React from "react";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import classes from "../../styles/AdminManagePromotions.module.css";
const PromotionLeftSidebar = (): JSX.Element => {
  return (
    <div>
      <img src="/images/slides/slide6.png" alt="" />
      <div className={classes.live_controller + " " + classes.second_list_}>
        <div>
          <i>
            <AiOutlineCloseSquare />
          </i>
          <p>Edit</p>
        </div>

        <div>
          <i>
            <BsTrash />
          </i>
          <p>Delete</p>
        </div>
      </div>
    </div>
  );
};

export default PromotionLeftSidebar;
