import React, { useState } from "react";
import { AiFillCloseCircle, AiOutlineClose } from "react-icons/ai";
import { BsStarFill } from "react-icons/bs";
import { Domain } from "../../Domain/Domain";
import { getClientToken } from "../cookie/getClientToken";
import Spinner from "../Spinner/Spinner";
import classes from "./ReviewProduct.module.css";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../../store/slices/alertSlice";

interface Props {
  product_id: string | number;
  onCloseHandler: () => void;
}
const ReviewProduct: React.FC<Props> = ({ product_id, onCloseHandler }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [enteredInput, setEnteredInput] = useState({
    ratings: 0,
    review_description: "",
  });

  const [colors, setColors] = useState<any>({
    color1: "",
    color2: "",
    color3: "",
    color4: "",
    color5: "",
  });
  const onChandleStartClick = (id: number) => {
    setEnteredInput((prevState) => {
      return {
        ...prevState,
        ratings: id,
      };
    });

    if (id === 1) {
      setColors({
        color1: "#FFB800",
      });
    }

    if (id === 2) {
      setColors({
        color1: "#FFB800",
        color2: "#FFB800",
      });
    }
    if (id === 3) {
      setColors({
        color1: "#FFB800",
        color2: "#FFB800",
        color3: "#FFB800",
      });
    }

    if (id === 4) {
      setColors({
        color1: "#FFB800",
        color2: "#FFB800",
        color3: "#FFB800",
        color4: "#FFB800",
      });
    }

    if (id === 5) {
      setColors({
        color1: "#FFB800",
        color2: "#FFB800",
        color3: "#FFB800",
        color4: "#FFB800",
        color5: "#FFB800",
      });
    }
  };
  const onSubmitHandler = () => {
    let data = {
      ...enteredInput,
      product_id,
    };

    console.log(enteredInput.ratings);
    if (enteredInput.review_description === "") {
      // return alert("Please type a review description");
      return dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "Please type a review description",
          status: "error",
        })
      );
    }
    if (enteredInput.ratings < 1) {
      // return alert("Rating can't be empty")
      return dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "Rating can't be empty",
          status: "error",
        })
      );
    }
    setIsLoading(true);
    console.log("=====>", data);
    fetch(Domain() + "/api/v1/review/new", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: getClientToken(),
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        // alert("Review submitted successfully");
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: "Review submitted successfully",
            status: "",
          })
        );
        onCloseHandler();
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div>
          <textarea
            name=""
            id=""
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setEnteredInput((prevState) => {
                return {
                  ...prevState,
                  review_description: e.target.value,
                };
              })
            }
            placeholder="Type a review"
          ></textarea>
          <br />
          <div className={classes.start_container}>
            <i id="1" style={{ color: colors.color1 }}>
              <BsStarFill onClick={() => onChandleStartClick(1)} id="1" />
            </i>
            <i id="2" style={{ color: colors.color2 }}>
              <BsStarFill onClick={() => onChandleStartClick(2)} />
            </i>
            <i
              onClick={() => onChandleStartClick(3)}
              id="3"
              style={{ color: colors.color3 }}
            >
              <BsStarFill />
            </i>
            <i
              onClick={() => onChandleStartClick(4)}
              id="4"
              style={{ color: colors.color4 }}
            >
              <BsStarFill />
            </i>
            <i
              onClick={() => onChandleStartClick(5)}
              id="5"
              style={{ color: colors.color5 }}
            >
              <BsStarFill />
            </i>
          </div>
          <button onClick={onSubmitHandler}>SUBMIT</button>
          <button className={classes.close_container} onClick={onCloseHandler}>
            CANCEL
          </button>
          {isLoading && <Spinner />}
        </div>
      </div>
    </div>
  );
};

export default ReviewProduct;
