import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCookie } from "../../components/cookie/getCookie";
const token = getCookie();
export const adminApi = createApi({
  reducerPath: "users",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.electronicshop.com.ng",
    // baseUrl: "http://localhost:65533", 
  }),
  tagTypes: ["post"],
  endpoints: (builder) => ({
    getRequest: builder.query({
      query: (params) => params,
      providesTags: ["post"],
    }),
    adminGeneralgetRequest: builder.query({
      query: (params) => params,
      providesTags: ["post"],
    }),
    fetchBrands: builder.query({
      query: (params) => params,
      providesTags: ["post"],
    }),
    fetchCategories: builder.query({
      query: (params) => params,
      providesTags: ["post"],
    }),
    // Login
    adminLogin: builder.mutation({
      query: (body) => ({
        url: "/api/v1/auth/login",
        method: "POST",
        body: body,
        // headers: {
        //   Accept: "application/json, text/plain, */*",
        //   "Content-Type": "application/json",
        // },
        // headers: { "Content-Type": "application/json" },
      }),
      invalidatesTags: ["post"],
    }),
    updateBrands: builder.mutation({
      query: (body) => ({
        url: "/api/v1/auth/login",
        method: "POST",
        body: body,
        // headers: {
        //   Accept: "application/json, text/plain, */*",
        //   "Content-Type": "application/json",
        // },
        // headers: { "Content-Type": "application/json" },
      }),
      invalidatesTags: ["post"],
    }),
    creatBrand: builder.mutation({
      query: (body) => ({
        url: "/api/v1/brand/new",
        method: "POST",
        body: body,
        headers: {
          Authorization: token,
          "Content-Type": "application/json; charset=utf-8",
        },
      }),
      invalidatesTags: ["post"],
    }),
    createProduct: builder.mutation({
      query: (body) => ({
        url: "/api/v1/file/new",
        method: "POST",
        body: body,
        // headers: {
        //   Authorization: token,
        //   "Content-Type": "application/json; charset=utf-8",
        // },
      }),
      invalidatesTags: ["post"],
    }),
  }),
});

export const {
  useCreateProductMutation,
  useAdminGeneralgetRequestQuery,
  useAdminLoginMutation,
  useFetchBrandsQuery,
  useCreatBrandMutation,
  useGetRequestQuery,
} = adminApi;
