import React, { useEffect, useState } from "react";
import { BsStarFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useAdminGeneralgetRequestQuery } from "../../../store/services/admin";
import {
  onGetSearchHandler,
  onSetBrandhandler,
  onSetCategoryHandler,
  onSetModelFilter,
  onSetMaxPriceFilter,
} from "../../../store/slices/searchSlice";
import classes from "./CategoriesAside.module.css";

const CategoriesAside = () => {
  const dispatch = useDispatch();
  const { data = [], refetch } =
    useAdminGeneralgetRequestQuery("/api/v1/brand/all");
  const [brand, setBrand] = useState("");
  const [maxPrice, setMaxPrice] = useState(0)
  const [productModel, setProductModel] = useState(null)
  let fetchedCategories: any = [];
  if (data.data) fetchedCategories = data.data;

  const onSelectBrand = (e: React.ChangeEvent<HTMLInputElement>) => {
    // dispatch(onSetCategoryHandler(e.target.value));
    setBrand(e.target.value);
  };
  const onSelectAllHandler = (e: any) => {
    e.preventDefault()
    dispatch(onSetBrandhandler(""));
    dispatch(onSetCategoryHandler(""));
    dispatch(onSetModelFilter(""));
    dispatch(onSetMaxPriceFilter(0));
  };
  // Scroll top
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const onApplyHandler = (e: any) => {
    e.preventDefault()
    dispatch(onSetBrandhandler(brand));
    dispatch(onSetModelFilter(productModel));
    dispatch(onSetMaxPriceFilter(maxPrice));
  };

  // handles the filter button 
  const [filterBtn, setFilterBtn] = useState(false)
  const handleFilter = ()=> setFilterBtn(!filterBtn)


  return (
    <div className={classes.category_nav_container}>

      <div className={classes.filter_btn}>
          <button onClick={handleFilter}>Filter</button>
        </div>
        <h3>{fetchedCategories.length} Items found</h3>

      <form className={!filterBtn ? classes.filter_section : ''}>
        
        <p className={classes.title}>Brands</p>
        <label htmlFor="xtg">
          <div className={classes.check_box_list}>
            <input
              onChange={onSelectAllHandler}
              type="radio"
              name={"brand"}
              value=""
              id={"xtg"}
            />
            <p>All ({fetchedCategories.length})</p>
          </div>
        </label>
        {fetchedCategories.map((cur: any, index: number) => (
          <label htmlFor={"xtg2" + index} key={index}>
            <div className={classes.check_box_list}>
              <input
                onChange={onSelectBrand}
                type="radio"
                name={"brand"}
                value={cur.id}
                id={"xtg2" + index}
              />
              <p>{cur.name} </p>
            </div>
          </label>
        ))}

        <br />
        <p className={classes.title}>Price range</p>
        {/* <div className={classes.price_rage_container}>
          <p>From</p>
          <input type="number" placeholder="0 ₦" />
        </div> */}
        <div className={classes.price_rage_container}>
          <p>Max Price</p>
          <input type="number" placeholder="0 ₦" onChange={(e:any) => setMaxPrice(e.target.value)}/>
        </div>
        <br />
        <div>
          <p><label htmlFor="model">Product Model</label></p>
          <input style={{padding: "4px 8px", fontSize: 14, marginTop: 4}} id="model" type='text' placeholder="Enter Model" onChange={(e: any) => setProductModel(e.target.value)}/>
        </div>
        <br />
        <div className={classes.apply_btn}>
          <button onClick={onApplyHandler}>Apply</button>
        </div>
        <div className={classes.reset}>
          <button onClick={onSelectAllHandler}>Reset</button>
        </div>
        {/* <p className={classes.title}>Rating</p>
        <div className={classes.check_box_list}>
          <input type="checkbox" name="" id="" />
          <i className={classes.color}>
            <BsStarFill />
          </i>
          <i>
            <BsStarFill />
          </i>
          <i>
            <BsStarFill />
          </i>
          <i>
            <BsStarFill />
          </i>
          <i>
            <BsStarFill />
          </i>
        </div>

        <div className={classes.check_box_list}>
          <input type="checkbox" name="" id="" />
          <i className={classes.color}>
            <BsStarFill />
          </i>
          <i className={classes.color}>
            <BsStarFill />
          </i>
          <i>
            <BsStarFill />
          </i>
          <i>
            <BsStarFill />
          </i>
          <i>
            <BsStarFill />
          </i>
        </div>

        <div className={classes.check_box_list}>
          <input type="checkbox" name="" id="" />
          <i className={classes.color}>
            <BsStarFill />
          </i>
          <i className={classes.color}>
            <BsStarFill />
          </i>
          <i className={classes.color}>
            <BsStarFill />
          </i>
          <i>
            <BsStarFill />
          </i>
          <i>
            <BsStarFill />
          </i>
        </div>

        <div className={classes.check_box_list}>
          <input type="checkbox" name="" id="" />
          <i className={classes.color}>
            <BsStarFill />
          </i>
          <i className={classes.color}>
            <BsStarFill />
          </i>
          <i className={classes.color}>
            <BsStarFill />
          </i>
          <i className={classes.color}>
            <BsStarFill />
          </i>
          <i>
            <BsStarFill />
          </i>
        </div>

        <div className={classes.check_box_list}>
          <input type="checkbox" name="" id="" />
          <i className={classes.color}>
            <BsStarFill />
          </i>
          <i className={classes.color}>
            <BsStarFill />
          </i>
          <i className={classes.color}>
            <BsStarFill />
          </i>
          <i className={classes.color}>
            <BsStarFill />
          </i>
          <i className={classes.color}>
            <BsStarFill />
          </i>
        </div>
      */}
      </form>


    </div>
  );
};

export default CategoriesAside;
