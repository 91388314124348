import React, {useState, useEffect} from 'react'
import TechnicianMain from "../../components/UI/TechnicianMain/TechnicianMain";
import { API_URL, Domain } from "../../Domain/Domain";
import './styles.css'
import axios from 'axios'

const TechnicianDashboard = () => {
  const [subscription, setSubscription] = useState(null);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("technician-token")

  const [technician, setTechnician] = useState({});

  useEffect(() => {
    let technicianData = JSON.parse(
      window.localStorage.getItem("technician")
    );
    axios
      .get(`${API_URL}/api/v1/technician/profile/` + technicianData?.id, {
        headers: {
          Authorization: localStorage.getItem("technician-token"),
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        if (response.data.status === 200)
          setTechnician(response.data.data.response);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    axios
    .get(`${API_URL}/api/v1/technician/fetch-active-subscription`, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json; charset=utf-8",
      }
    })
      .then((response) => {
        const formattedStartDate = new Date(response.data.startDate).toLocaleDateString();
        const formattedEndDate = new Date(response.data.endDate).toLocaleDateString();
        
        setSubscription({
          ...response.data,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        });
      })
      .catch((err) => {
        setError(err.response ? err.response.data.message : 'Something went wrong');
      });
  }, []);

  return (
    <TechnicianMain>
      <div className='container'>
        <div>
          Welcome, {technician?.firstName} {technician?.surname} {technician?.otherName}
        </div><br /><br />
        {error && <div className="alert">{error}</div>}
        {subscription ? (
          <div className="subscription-details">          
            <h2>Active Subscription Details</h2>
            <p>Package Name: {subscription.packageName}</p>
            <p>Start Date: {subscription.startDate}</p>
            <p>End Date: {subscription.endDate}</p>    
          </div>
        ) : (
          <div className="alert">No active subscription found.</div>
        )}
      </div>
    </TechnicianMain>
  );
}

export default TechnicianDashboard

