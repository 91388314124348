import React, { useState, useEffect } from "react";

const useGetDefaultAddress = () => {
  const [defaultAddress, setDefaultAddress] = useState<string>("");
  useEffect(() => {
    setInterval(() => {
      setDefaultAddress(
        window.localStorage.getItem("defaultAddress") as string
      );
    }, 1000);
  }, []);

  return { defaultAddress };
};

export default useGetDefaultAddress;
