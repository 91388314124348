import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import '../../styles/AdminManageTechnicianSubscription.css'
import Modal from 'react-modal';
import { getCookie } from "../../components/cookie/getCookie";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import { API_URL, Domain } from "../../Domain/Domain"
import './style1.css'

const AdminManageTechnicianSubscription = () => {
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [editFormData, setEditFormData] = useState({
    packageId: '',
    packageName: '',
    amount: '',
    duration: '',
    description: '',
  });

  const [loading, setLoading] = useState(true);
  const [noPlansAvailable, setNoPlansAvailable] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [createForm, setCreateForm] = useState({
    packageName: '',
    amount: '',
    duration: '',
    description: '',
  });

  const [createErrors, setCreateErrors] = useState({
    packageName: '',
    amount: '',
    duration: '',
    description: '',
  });

  const fetchSubscriptionPlans = async () => { 
    try {
    const response = await axios.get(`${API_URL}/api/v1/admin/technician/technician-subscription-packages`, {
        headers: {
          Authorization: getCookie(), 
          "Content-Type": "application/json; charset=utf-8",
        },
      });
      if (Array.isArray(response.data.data)) {
        setSubscriptionPlans(response.data.data);
        setLoading(false);
        setNoPlansAvailable(response.data.data.length === 0);
      } else {
        console.error('API response is not an array:', response.data.data);
      }
    } catch (error) {
      console.error('Error fetching subscription plans:', error);
      setLoading(false);
      setNoPlansAvailable(true);
    }
  };

  useEffect(() => {
    fetchSubscriptionPlans();
  }, []);


  // Validation function for create form
  const validateCreateForm = () => {
    let valid = true;
    const newErrors = { ...createErrors };

    // Validate package name
    if (!createForm.packageName) {
      newErrors.packageName = 'Package Name is required';
      valid = false;
    } else {
      newErrors.packageName = '';
    }

    // Validate amount
    if (!createForm.amount) {
      newErrors.amount = 'Amount is required';
      valid = false;
    } else if (isNaN(createForm.amount) || parseFloat(createForm.amount) <= 0) {
      newErrors.amount = 'Amount must be a valid positive number';
      valid = false;
    } else {
      newErrors.amount = '';
    }

    // Validate duration
    if (!createForm.duration) {
      newErrors.duration = 'Duration is required';
      valid = false;
    } else if (isNaN(createForm.duration) || parseInt(createForm.duration) <= 0) {
      newErrors.duration = 'Duration must be a valid positive integer';
      valid = false;
    } else {
      newErrors.duration = '';
    }

    // Validate description
    if (!createForm.description) {
      newErrors.description = 'Description is required';
      valid = false;
    } else {
      newErrors.description = '';
    }

    setCreateErrors(newErrors);
    return valid;
  };

  const handleCreateInputChange = (e) => {
    const { name, value } = e.target;
    setCreateForm({ ...createForm, [name]: value });
  };

  const handleCreateSubmit = async (e) => {
    e.preventDefault();
    
    // Validate the create form
    if (validateCreateForm()) {
      try {
        await axios.post(`${API_URL}/api/v1/admin/technician/create-technician-subscription-package`, createForm, {
          headers: {
            Authorization: getCookie(),
            "Content-Type": "application/json; charset=utf-8",
          },
        });

        // Clear the form and fetch updated subscription plans
        setCreateForm({
          packageName: '',
          amount: '',
          duration: '',
          description: '',
        });

        setShowCreateModal(false);
        alert('A new technician subscription plan has been created succesfully')
        fetchSubscriptionPlans();
      } catch (error) {
        console.error('Error creating subscription plan:', error);
      }
    }
  };


  const toggleCreateModal = () => {
    setShowCreateModal(!showCreateModal);
  };

  const handleEdit = (plan) => {
    setEditFormData(plan);
    setEditModalIsOpen(true);
  };

  const handleSaveEdit = async () => {
    try {
      await axios.post(`${API_URL}/api/v1/admin/technician/technician-subscription-packages/${editFormData.packageId}`, editFormData, {
        headers: {
          Authorization: getCookie(), 
          "Content-Type": "application/json; charset=utf-8",
        },
      });
      setEditModalIsOpen(false);
      alert('Technician subscription plan has been edited succesfully')
      fetchSubscriptionPlans();
    } catch (error) {
      console.error('Error saving edited subscription plan:', error);
    }
  };

  const handleDelete = async (packageId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this plan?');
    if (confirmDelete) {
      try {
        await axios.delete(`${API_URL}/api/v1/admin/technician/technician-subscription-packages/${packageId}`, {
          headers: {
            Authorization: getCookie(), 
            "Content-Type": "application/json; charset=utf-8",
          },
        });
        alert('Technician subscription plan has been deleted succesfully')
        fetchSubscriptionPlans();
      } catch (error) {
        console.error('Error deleting subscription plan:', error);
      }
    }
  };

  return (
    <AdminMain>
      <div>
        <h1>Technician Subscription Plans</h1>
        <button onClick={toggleCreateModal} className="btn btn-primary btn-right">
        <FaPlus /> Create Subscription Plan
        </button>
    
        <Modal isOpen={showCreateModal} >
          <h2 className='h2-center'>Create Subscription Plan</h2>
          <form id="form11" onSubmit={handleCreateSubmit}>
            <div>
              <label>Package Name</label>
              <input
                type="text"
                name="packageName"
                value={createForm.packageName}
                onChange={handleCreateInputChange}
                required
              />
              <span className="error">{createErrors.packageName}</span>
            </div>
            <div>
              <label>Amount</label>
              <input
                type="number"
                name="amount"
                value={createForm.amount}
                onChange={handleCreateInputChange}
                required
              />
              <span className="error">{createErrors.amount}</span>
            </div>
            {/* <div>
              <label>Duration (in months)</label>
              <input
                type="number"
                name="duration"
                value={createForm.duration}
                onChange={handleCreateInputChange}
                required
              />
              <span className="error">{createErrors.duration}</span>
            </div> */}
            <div>
              <label>Duration (in months)</label>
              <select
                name="duration"
                value={createForm.duration}
                onChange={handleCreateInputChange}
                required
              >
                {[...Array(24).keys()].map((month) => (
                  <option key={month + 1} value={month + 1}>
                    {month + 1} month{month + 1 > 1 ? 's' : ''}
                  </option>
                ))}
              </select>
              <span className="error">{createErrors.duration}</span>
            </div>

            <div>
              <label>Description</label>
              <input
                type="text"
                name="description"
                value={createForm.description}
                onChange={handleCreateInputChange}
                required
              />
              <span className="error">{createErrors.description}</span>
            </div>
            <button type="submit" className='btn btn-success'>Create</button>
            <button type="button" className='btn btn-danger' onClick={toggleCreateModal}>
              Cancel
            </button>
          </form>
        </Modal>

        {loading ? (
          <p>Loading...</p>
        ) : noPlansAvailable ? (
          <div>
            <p>No technician subscription plans available.</p>
          </div>
        ) : (
          <table className="table table-striped">
            <thead>
              <tr>
                <th>S/N</th>
                <th>Package ID</th>
                <th>Package Name</th>
                <th>Amount</th>
                <th>Duration (in months)</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {subscriptionPlans.map((plan, index) => (
                <tr key={plan.packageId}>
                  <td>{index + 1}</td>
                  <td>{plan.packageId}</td>
                  <td>{plan.packageName}</td>
                  <td>{plan.amount}</td>
                  <td>{plan.duration}</td>
                  <td>{plan.description}</td>
                  <td>
                    <button onClick={() => handleEdit(plan)} className="btn btn-warning">
                      <FaEdit /> Edit
                    </button>
                    <button onClick={() => handleDelete(plan.packageId)} className="btn btn-danger">
                      <FaTrash /> Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {/* Edit Modal */}
        <Modal isOpen={editModalIsOpen}>
          <h2 className='h2-center'>Edit Subscription Plan</h2>
          <form id="form11">
            <div>
              <label>Package ID</label>
              <input
                type="text"
                name="packageId"
                value={editFormData.packageId}
                onChange={(e) => setEditFormData({ ...editFormData, packageId: e.target.value })}
                readOnly
              />
            </div>
            <div>
              <label>Package Name</label>
              <input
                type="text"
                name="packageName"
                value={editFormData.packageName}
                onChange={(e) => setEditFormData({ ...editFormData, packageName: e.target.value })}
                required
              />
            </div>
            <div>
              <label>Amount</label>
              <input
                type="number"
                name="amount"
                value={editFormData.amount}
                onChange={(e) => setEditFormData({ ...editFormData, amount: e.target.value })}
                required
              />
            </div>
            {/* <div>
              <label>Duration (in months)</label>
              <input
                type="number"
                name="duration"
                value={editFormData.duration}
                onChange={(e) => setEditFormData({ ...editFormData, duration: e.target.value })}
                required
              />
            </div> */}
            <div>
              <label>Duration (in months)</label>
              <select
                name="duration"
                value={editFormData.duration}
                onChange={(e) =>
                  setEditFormData({ ...editFormData, duration: parseInt(e.target.value) })
                }
                required
              >
                {[...Array(24).keys()].map((month) => (
                  <option key={month + 1} value={month + 1}>
                    {month + 1} month{month + 1 > 1 ? 's' : ''}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label>Description</label>
              <input
                type="text"
                name="description"
                value={editFormData.description}
                onChange={(e) => setEditFormData({ ...editFormData, description: e.target.value })}
                required
              />
            </div>
            <button type="button" className='btn btn-primary' onClick={handleSaveEdit}>
              Save
            </button>
            <button type="button" className='btn btn-danger' onClick={() => setEditModalIsOpen(false)}>
              Cancel
            </button>
          </form>
        </Modal>
      </div>
    </AdminMain>
  );
}

export default AdminManageTechnicianSubscription;
