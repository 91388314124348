import React from "react";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import BackButton from "../../components/UI/BackButton/BackButton";
import classes from "../../styles/AdminFoundProduct.module.css";
const AdminProductDeactivationPage = () => {
  return (
    <AdminMain>
      <div>
        <BackButton />
        <br />
        <div className={classes.product_container}>
          <p className={classes.title}>
            Nexus SPLIT AC 1HP NX-SAC9000AF WITH KIT
          </p>
          <h5>Brand: Nexus; Category: Air Conditioners</h5>
          <br />
          <div className={classes.posted_by_name}>
            <p>
              Posted by <br />
            </p>
            <p>GoodHope Ventures</p>
          </div>
          <br />
          <div className={classes.product_images}>
            <img src="/images/products/product1.png" alt="" />
            <img src="/images/products/product2.png" alt="" />
          </div>
        </div>
        <br />
        <div className={classes.performance}>
          <p>Performance</p>
          <img src="/images/chart/chart.png" alt="" />
        </div>
        <br />
        <div className={classes.detail_container}>
          <p className={classes.price_property}>
            <span>Price:</span>
            <b> N150,000 </b>
          </p>
          <p className={classes.color_property}>
            <span>Bulk price:</span>
            <b> N145,000 (5-10 pcs) </b>
          </p>
          <p className={classes.color_property}>
            <span>Color:</span>
            <b> Silver, white </b>
          </p>
        </div>
        <p className={classes.descriptio_property}>
          <span>Description:</span>
          <b>
            Description: Lorem ipsum dolor sit amet, consectetur adipiscing
            elit. Mauris tellus erat, semper id nisi sed, dapibus laoreet magna.
            Vestibulum commodo pellentesque blandit. Pellentesque habitant morbi
            tristique senectus et netus et malesuada fames ac turpis egestas.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu
            arcu ac ligula convallis eleifend et sed nisl. Orci varius natoque
            penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Integer sodales ligula a augue molestie, nec volutpat orci
            ullamcorper. Integer dictum libero vel nisi placerat, et vulputate
            lectus feugiat. Pellentesque imperdiet, sem vel maximus facilisis,
            justo orci ornare nunc, sed egestas nisi justo a nunc. Morbi
            convallis, lacus et hendrerit convallis, eros velit viverra purus,
            eget facilisis orci erat a nisi. Aenean hendrerit tristique semper.
            Donec tristique, lacus eget mollis maximus, lectus nisl facilisis
            risus, id laoreet eros neque ut sapien. Donec non accumsan quam.
            Curabitur ac fringilla tellus. Donec commodo tellus eget dui rutrum,
            vel imperdiet erat pretium. Morbi efficitur aliquet blandit. Aenean
            quis tortor sit amet massa bibendum egestas. Pellentesque habitant
            morbi tristique senectus et netus et malesuada fames ac turpis
            egestas. Aenean mattis justo in libero tincidunt pharetra. Quisque
            ultrices arcu erat, eu consequat nulla viverra non.
          </b>
        </p>
        <div className={classes.tabler_section}>
          <p>Specifications</p>
          <table>
            <tr>
              <td>Brand</td>
              <td>Nexus</td>
            </tr>
            <tr>
              <td>Model</td>
              <td>NX-SAC9000AF</td>
            </tr>
            <tr>
              <td>KIT</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>Weight</td>
              <td>35Kg</td>
            </tr>
            <tr>
              <td>Horsepower</td>
              <td>1 HP</td>
            </tr>
            <tr>
              <td>Brand</td>
              <td>Nexus</td>
            </tr>
          </table>
        </div>
        <div className={classes.edit_button}>
          <button>DEACTIVATE</button>
        </div>
      </div>
    </AdminMain>
  );
};

export default AdminProductDeactivationPage;
