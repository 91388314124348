import axios from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { Domain } from "../Domain/Domain";
import { toggleAlertAction } from "../store/slices/alertSlice";

const useAddToCartHook = (product: any) => {
  console.log(product.id);
  const dispatch = useDispatch();
  const addToCart = () => {
    if (typeof product.id !== "number") return;
    let storedItem = JSON.parse(localStorage.getItem("cart") as string);
    let cartDataInStorage = localStorage.getItem("cart");

    let colorIndex = "";

    const color = JSON.parse(product.colours);

    let cartData = {
      productName: product.productName,
      productId: product.id,
      images: product.pictures,
      priceing: product.promotionalPrice ? product.promotionalPrice : product.pricing,
      qty: 1,
      color: color.toString(),
      date: new Date().toLocaleDateString(),
    };
    console.log(Array.isArray(JSON.parse(cartDataInStorage as any)));
    if (!Array.isArray(JSON.parse(cartDataInStorage as any))) {
      let item = [cartData];
      window.localStorage.setItem("cart", JSON.stringify(item));
      dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "Item added to cart successfuly",
        })
      );
      return;
    }

    if (Array.isArray(storedItem)) {
      let findExistingCart = storedItem.filter(
        (cur: any) => cur.productId === cartData.productId
      );
      // Incraete quantity
      if (findExistingCart.length > 0) {
        let newEditedItem = storedItem.map((cur: any) => {
          if (cur.productId === cartData.productId) {
            cur.qty = cur.qty + 1;
          }
            return cur;
        });

        localStorage.setItem("cart", JSON.stringify(newEditedItem));
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: "Item added to cart successfuly",
          })
        );
        return;
      }
      console.log(storedItem);
      console.log(cartData);
      // const newPeendedData = storedItem.push([cartData]);
      // console.log("Adding data", );
      localStorage.setItem("cart", JSON.stringify([...storedItem, cartData]));
    }
  };
  return { addToCart };
};

export default useAddToCartHook;
