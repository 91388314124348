import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAdminGeneralgetRequestQuery } from "../../../store/services/admin";
import {
  onGetSearchHandler,
  onSetBrandhandler,
  onSetCategoryHandler,
} from "../../../store/slices/searchSlice";
import classes from "./Aside.module.css";
// https://api.electronicshop.com.ng/api/v1/category/all
const BrandAside = (props: any) => {
  const {filter} = props;
  const navigate  =  useNavigate()
  const endpoint = filter === 'brand' ? "/api/v1/brand/all" : "/api/v1/category/all"
  const { data = [], refetch } =
    useAdminGeneralgetRequestQuery(endpoint);

  let fetchedbands: any = [];
  if (data.data) fetchedbands = data.data;

  // console.log(fetchedbands);

  const dispatch = useDispatch();
  const onBrandClickHandler = (name:string, e: any) => {
    console.log(e.target.id);
    filter === 'brand' ? dispatch(onSetBrandhandler(e.target.id)) : dispatch(onSetCategoryHandler(e.target.id));
    dispatch(onGetSearchHandler(name))
    navigate('/search')
  };

  // Filter By ahh
  const onSelectAllHandler = () => {
    dispatch(onSetBrandhandler(""));
    dispatch(onSetCategoryHandler(""));
    dispatch(onGetSearchHandler(""));
  };

  return (
    <div className={classes.bran_items_container}>
      <p>
        {/* <img
          alt=""
          onClick={onBrandClickHandler}
          id={""}
        /> */}
        <span onClick={onSelectAllHandler} id={""}>
          All {filter === 'brand' ? "Brands" : "Categories"}
        </span>
      </p>
      <div style={{overflow: 'auto', height: '500px'}}>
      {fetchedbands.map((cur: any, index: any) => (
        <p key={index}>
          <img
            src={cur.image_url}
            onClick={(e:any) => onBrandClickHandler(cur.name, e)}
            id={cur.id as unknown as string}
            alt=""
          />
          <span onClick={(e:any) => onBrandClickHandler(cur.name, e)} id={cur.id as unknown as string}>
            {cur.name}
          </span>
        </p>
      ))}
      </div>
    </div>
  );
};

export default BrandAside;
