import React, { useEffect, useState } from "react";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Area,
  Bar,
  Line,
} from "recharts";
import { Domain } from "../../Domain/Domain";
import { getCookie } from "../../components/cookie/getCookie";
import axios from "axios";
const AdminDashboard = (): JSX.Element => {
  const [statistics, setStat] = useState<{
    users: number;
    vendors: number;
    technicians: number;
  }>({
    users: 100,
    vendors: 200,
    technicians: 0,
  });
  const [stats, setStats] = useState<any>([])
  const [year, setYear] = useState<any>(new Date().getFullYear())
  const years = []
  const currentYear = new Date().getFullYear()
  for(let i = 0; i < 50; i++){
    years.push(currentYear-i)
  }

  const sales_stats = stats?.map((item: any) => ({name: item?.month, total: item?.total_sales}))
  const users_stats =  [
    {
      name: "Registered users",
      // uv: statistics.users,
      // pv: statistics.users,
      total: statistics?.users,
    },
    {
      name: "Vendors",
      // uv: statistics.vendors,
      // pv: statistics.vendors,
      total: statistics?.vendors,
    },
    {
      name: "Technicians",
      // uv: statistics.technicians,
      // pv: statistics.technicians,
      total:  statistics?.technicians,
    },  
  ];



  useEffect(() => {
    if(year){
      axios.get(`https://apiv2.electronicshop.com.ng/sales-stats/${year}`).then(res => {
        console.log('stats: ', res)
        setStats(res.data)
      }).catch(err => {
        console.log('stats erro: ', err)
      })
    }
  }, [year])

  useEffect(() => {
    async function fetchMoviesAndCategories() {
      const [usersResponse, techniciansResponse, vendorRespose] =
        await Promise.all([
          fetch(Domain() + "/api/v1/statistics/users", {
            headers: {
              Authorization: getCookie(),
              "Content-Type": "application/json",
            },
          }),
          fetch(Domain() + "/api/v1/statistics/technicians", {
            headers: {
              Authorization: getCookie(),
              "Content-Type": "application/json",
            },
          }),

          fetch(Domain() + "/api/v1/statistics/vendors", {
            headers: {
              Authorization: getCookie(),
              "Content-Type": "application/json",
            },
          }),
        ]);
      const users = await usersResponse.json();
      const technicians = await techniciansResponse.json();
      const vendor = await vendorRespose.json();
      return [users, technicians, vendor];
    }
    fetchMoviesAndCategories()
      .then(([users, technicians, vendor]) => {
        setStat((prevState) => {
          return {
            ...prevState,
            vendors: vendor?.data?.totalNumOfVendors ?? null,
            users: users?.data?.totalNumOfUsers ?? null,
            technicians: technicians?.data?.totalNumOfTechnicians ??null
          };
        });
      })
      .catch((error) => {
        // /movies or /categories request failed
      });
  }, []);

  return (
    <AdminMain>
      <div>
        <h1>Statistic</h1>
        <br />
        <h2>Sales Stats</h2>
        <select style={{fontSize: 20}} onChange={(e: any) => setYear(e.target.value)}>
          {
            years?.length && years?.map((year: any, index: any) => (<option value={year} key={index}>{year}</option>))
          }
        </select>
        
        <ComposedChart width={830} height={250} data={sales_stats}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <CartesianGrid stroke="#f5f5f5" />
          <Area type="monotone" dataKey="total" fill="#bdbdbdab" stroke="#F14306" />
          <Bar dataKey="total" barSize={20} fill="#F14306" />
          <Line type="monotone" dataKey="total" stroke="#ff7300" />
        </ComposedChart>

        <h2>Users Stats</h2>
        <ComposedChart width={830} height={250} data={users_stats}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <CartesianGrid stroke="#f5f5f5" />
          <Area type="monotone" dataKey="total" fill="#bdbdbdab" stroke="#F14306" />
          <Bar dataKey="total" barSize={20} fill="#F14306" />
          <Line type="monotone" dataKey="total" stroke="#ff7300" />
        </ComposedChart>
      </div>
    </AdminMain>
  );
};

export default AdminDashboard;
