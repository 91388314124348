import React from "react";
import { BsImageFill, BsTrash } from "react-icons/bs";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import BackButton from "../../components/UI/BackButton/BackButton";
import classes from "../../styles/AdminVendorReportPage.module.css";
const AdminOrderStatusPage = (): JSX.Element => {
  return (
    <AdminMain>
      <div>
        <div>
          <BackButton />
          <br />
          <div className={classes.report_wrapper}>
            <h3>Order Status</h3>
            <p>From: BIRON; </p>
            <p>17:59; 05/11/22</p>
          </div>
          <div className={classes.report_body}>
            <div className={classes.trash_icon}>
              <i>
                <BsTrash />
              </i>
            </div>
            <div className={classes.body_text}>
              <p>
                I am yet to receive my package which was expected to arrive
                yesterday. My order number is DR499AA
              </p>
            </div>
            <br />
            <div className={classes.input_section}>
              <input type="text" placeholder="Reply" />
            </div>
            <br />
            <br />
            <div className={classes.uplaod_div}>
              <i>
                <BsImageFill />
              </i>
            </div>
            <br />
            <div className={classes.action_button}>
              <button style={{ backgroundColor: "#F14306" }}>
                SEND MESSAGE
              </button>
            </div>
          </div>
        </div>
      </div>
    </AdminMain>
  );
};

export default AdminOrderStatusPage;
