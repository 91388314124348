import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleAlertAction } from "../../store/slices/alertSlice";
import classes from "./Alert.module.css";
const Alert = () => {
  const dispatch = useDispatch();
  const alertSelector = useSelector((value: any) => value.alert);
  function dismissAlert() {
    dispatch(
      toggleAlertAction({
        isAlerting: false,
        message: "",
      })
    );
  }
  useEffect(() => {
    setTimeout(() => {
      dismissAlert();
    }, 4000);
  }, []);
  return (
    <div
      className={classes.container}
      style={{
        borderColor: alertSelector.status === "error" ? "red" : "green",
      }}
      onClick={() => dismissAlert()}
    >
      <p>{alertSelector.message}</p>
    </div>
  );
};

export default Alert;
