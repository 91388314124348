import React, { useState } from "react";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import BackButton from "../../components/UI/BackButton/BackButton";
import classes from "../../styles/SettingsStyles.module.css";
import axios from "axios";
const AdminSettingsUpdateEmail = () => {
  const [email, setEmail] = useState<any>("");
  const [password, setPassword] = useState<any>("");
  const [message, setMessage] = useState<any>({});
  const handleUpdateEmail = () => {
    if (email && password) {
      axios
        .post(
          `https://api.electronicshop.com.ng/api/v1/admin/update-email`,
          { email, password },
          {
            headers: {
              Authorization: JSON.parse(
                localStorage.getItem("token") as string
              ),
            },
          }
        )
        .then((res) => {
          setMessage({ status: "success", message: res.data.message });
          console.log("email updated: ", res);
        })
        .catch((err) => {
          console.log("failed to update email: ", err);
          setMessage({ status: "failed", message: err.response.data.message });
        });
    }
  };
  console.log(message);
  return (
    <AdminMain>
      <div>
        <BackButton />
        <br />
        <h3>Update Email</h3>
        <br />
        <div className={classes.input_wrapper}>
          <input
            type="text"
            placeholder="Enter email"
            onChange={(e: any) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Enter password"
            onChange={(e: any) => setPassword(e.target.value)}
          />
        </div>
        <br />
        {message?.status === "failed" ? (
          <p style={{ color: "red" }}>{message?.message}</p>
        ) : message?.status === "success" ? (
          <p style={{color: 'green'}}>{message?.message}</p>
        ) : null}
        <br />
        <div className={classes.button_container}>
          <button onClick={handleUpdateEmail}>Update</button>
        </div>
      </div>
    </AdminMain>
  );
};

export default AdminSettingsUpdateEmail;
