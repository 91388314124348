import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useGetCartDetailsHook from "../../Hooks/useGetCartDetailsHook";
import {
  dispatchFirstPaymentPercentage,   
  dispatchInstallmentalOption,
} from "../../store/slices/paymentSlice";

import classes from "./Payment.module.css";
interface Props {
  onChangeHandler: any;
}
export const PaymentType: React.FC<Props> = ({
  onChangeHandler,
}): JSX.Element => {
  return (
    <div className={classes.payment_type_container}>
      <div className={classes.flex_div}>
        <input
          onChange={onChangeHandler}
          type="radio"
          id="payStack"
          name="paymentType"
        />
        <div>
          <p>Pay with bank ATM card </p>
        </div>
        <div>
          <img src="/images/paystack.png" alt="" />
        </div>
      </div>
      <br />
      <div className={classes.flex_div}>
        <input
          onChange={onChangeHandler}
          type="radio"
          id="paySmallSmall"
          name="paymentType"
        />
        <div>
          <p>Apply for PAY SMALL SMALL. </p>
          <p>Make split payments over a given period of time</p>
        </div>
      </div>
      <br />

      {/* <div className={classes.flex_div}>
        <input
          onChange={onChangeHandler}
          type="radio"
          id="wallet"
          name="paymentType"
        />
        <div>
          <p>Pay from Electronicshop wallet </p>
        </div>
        <div className={classes.wallet_section}>
          <BsFillWalletFill />
          <h3>₦56,180.00</h3>
          <p>TOP UP</p>
        </div>
      </div> */}
    </div>
  );
};

export const PaymentMethod = () => {
  const [installmentOption, setInstallmentOption] = useState<number>(0);
  const [enteredPercentage, serEnteredpercentage] = useState<number>(0);
  const { cartTotalPrice } = useGetCartDetailsHook();
  const dispatch = useDispatch();
  const paymentSelectror = useSelector((value: any) => value.payment);
  const onSelectInstalentalOption = (num: number) => {
    setInstallmentOption(num);
    dispatch(dispatchInstallmentalOption(num));
  };

  useEffect(() => {
    if (paymentSelectror.installmentOption === 0) {
      dispatch(dispatchInstallmentalOption(2));
      return;
    }

    setInstallmentOption(paymentSelectror.installmentOption);
  }, [paymentSelectror.installmentOption]);

  const onChangehandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.includes("-")) return;
    serEnteredpercentage(parseInt(e.target.value));
    dispatch(dispatchFirstPaymentPercentage(parseInt(e.target.value)));
  };

  const [newTotalAMount, setNewTotalAmount] = useState<number>(0);
  const [cartNewAmount, setCartNewAmount] = useState<number>(0);
  useEffect(() => {
    localStorage.setItem("isPaySmallSmall", "true"); // Convert boolean to string
    localStorage.setItem("installmentOption", installmentOption.toString());
    let newTotalAmount = cartTotalPrice + (cartTotalPrice * 7.5 * installmentOption) / 100;
    console.log("--->", cartTotalPrice);
    console.log("===>", newTotalAmount);
    if (enteredPercentage > 0) {
      setNewTotalAmount(newTotalAMount);
      setCartNewAmount(newTotalAmount);
    } else {
      setNewTotalAmount(newTotalAmount);
    }
  }, [enteredPercentage, installmentOption]);

  useEffect(() => {
    let newTotalAmount = cartTotalPrice + (cartTotalPrice * 7.5 * installmentOption) / 100;
    console.log(newTotalAmount);
    setNewTotalAmount(newTotalAmount);
  }, [cartTotalPrice, installmentOption]);

  return (
    <div className={classes.payment_method_container}>
      <div className={classes.method_list_div}>
        <div>
          <h4>First deposit payment</h4>
          <input
            type="number"
            className={classes.percentage_number}
            placeholder="Enter amount eg: 100,000"
            onChange={onChangehandler}
            value={paymentSelectror.firstPayment}
          />
        </div>
        <div className={classes.price_div}>
          <h3>% {enteredPercentage > 0 ? ((enteredPercentage/newTotalAMount)*100).toLocaleString() : 0}</h3>
        </div>
      </div>

      <div className={classes.method_list_div}>
        <div>
          <h4>Installment options</h4>
        </div>
        <div className={classes.floating_buton}>
          <button
            style={{
              backgroundColor: installmentOption === 2 ? "#0A2342" : "#7692B4",
            }}
            onClick={() => onSelectInstalentalOption(2)}
          >
            2 months
          </button>
          <button
            style={{
              backgroundColor: installmentOption === 3 ? "#0A2342" : "#7692B4",
            }}
            onClick={() => onSelectInstalentalOption(3)}
          >
            3 month
          </button>
          <button
            style={{
              backgroundColor: installmentOption === 4 ? "#0A2342" : "#7692B4",
            }}
            onClick={() => onSelectInstalentalOption(4)}
          >
            4 months
          </button>
          <button
            style={{
              backgroundColor: installmentOption === 5 ? "#0A2342" : "#7692B4",
            }}
            onClick={() => onSelectInstalentalOption(5)}
          >
            5 months
          </button>
          <button
            style={{
              backgroundColor: installmentOption === 6 ? "#0A2342" : "#7692B4",
            }}
            onClick={() => onSelectInstalentalOption(6)}
          >
            6 months
          </button>
        </div>
      </div>

      <div className={classes.method_list_div}>
        <div>
          <h4>Amount remaining</h4>
        </div>
        <div className={classes.price_div}>
          <h3>₦ {enteredPercentage ? (newTotalAMount-enteredPercentage).toLocaleString() : 0}</h3>
        </div>
      </div>

      <div className={classes.method_list_div}>
        <div>
          <h4>Monthly Installment</h4>
          {/* <p>30% of total</p> */}
        </div>
        <div className={classes.price_div}>
          <h3>{(newTotalAMount-enteredPercentage) > 0 ? ((newTotalAMount-enteredPercentage)/installmentOption).toLocaleString() : 0}</h3>
        </div>
      </div>
    </div>
  );
};
