import React, { useState, useEffect } from "react";
import classes from "./MarketPlaceProductItem.module.css";
import LazyImage from "../LazzyLoad/LazyImage";
import { Link } from "react-router-dom";
interface Props {
  image: string;
  description: string;
  rating: string | number;
  amount: number | string;
  oldAmount: number | string;
  location: string;
  productName: string;
  id: number | string;
  linkRoute?: any;
  range?: any;
}
const MarketPlaceProductItem: React.FC<Props> = ({
  amount,
  description,
  image,
  location,
  linkRoute = "",
  productName = "",
  range = 4,
  id,
  oldAmount,
  rating,
}) => {
  const [images, setImages] = useState<any>([]);
  useEffect(() => {
    if (image !== undefined)
      if (Array.isArray(JSON.parse(image) as unknown as any))
        setImages(JSON.parse(image));
  }, [image]);
  return ( 
    <Link to={linkRoute + "?id=" + id}>
      <div className={classes.product_list_container}>
        <div className={classes.product_list_item}>
          <div className={classes.image_sitter}>
          
              <LazyImage image={images[0]} />
          
          </div>
          <div className={classes.bottom_details}>
            <h4 className={classes.description}>
              {productName?.substr(0, 22)!}

              {productName.length > 22 ? "..." : ""}
            </h4>
            {/* <p style={{ marginTop: "15px" }} className={classes.description}>
            {description.substr(0, 50)}
          </p> */}
            <p className={classes.location}>{location}</p>
            <div className={classes.amount_container}>
              <h3>
                  ₦ {oldAmount?.toLocaleString()}
                </h3>
                <small>
                  <del>₦ {amount?.toLocaleString()}</del>
                </small><br />
                <span>({range} pcs)</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default MarketPlaceProductItem;
