import React, { useState } from "react";
import AdminPagination from "../../components/AdminPagination/AdminPagination";
import AdminSearch from "../../components/AdminSearch/AdminSearch";
import ManageTechnicians from "../../components/TechnicalHubComponents/ManageTechnicians";
import TechnicalHubAll from "../../components/TechnicalHubComponents/TechnicalHubAll";
import TechnicalReports from "../../components/TechnicalHubComponents/TechnicalReports";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import classes from "../../styles/AdminTechnicalHub.module.css";

const AdminTechnicalHub = (): JSX.Element => {
  const [currentNav, setCurrentNav] = useState({
    all: false,
    techIsActive: true,
    reportIsActive: false,
  });



  return (
    <AdminMain>
      <div>
        <h3 className={classes.title}>Technicians Hub</h3>
        <br />
        <div className={classes.nav_container}>
          <button
            style={{
              backgroundColor: currentNav.all ? "black" : "unset",
              color: currentNav.all ? "white" : "#7692B4",
            }}
            onClick={() => {
              setCurrentNav({
                all: true,
                techIsActive: false,
                reportIsActive: false,
              });
            }}
          >
            All jobs
          </button>
          <button
            style={{
              backgroundColor: currentNav.techIsActive ? "black" : "unset",
              color: currentNav.techIsActive ? "white" : "#7692B4",
            }}
            onClick={() => {
              setCurrentNav({
                all: false,
                techIsActive: true,
                reportIsActive: false,
              });
            }}
          >
            Manage technicians
          </button>
          <button
            style={{
              backgroundColor: currentNav.reportIsActive ? "black" : "unset",
              color: currentNav.reportIsActive ? "white" : "#7692B4",
            }}
            onClick={() => {
              setCurrentNav({
                all: false,
                techIsActive: false,
                reportIsActive: true,
              });
            }}
          >
            Reports
          </button>
        </div>
        <br />

        <div className={classes.order_header}>
          <div className={classes.order_list}>
            <p>From</p>
            <input type="date" name="" id="" />
          </div>

          <div className={classes.order_list}>
            <p>To</p>
            <input type="date" name="" id="" />
          </div>
          <div className={classes.order_list}>
            <p>Status</p>
            <select name="" id="">
              <option value="all">All</option>
            </select>
          </div>
        </div>
        {/* <AdminSearch /> */}
        <br />
        <br />
        {currentNav.all && <TechnicalHubAll />}
        {currentNav.techIsActive && <ManageTechnicians />}
        {currentNav.reportIsActive && <TechnicalReports />}
      </div>
    </AdminMain>
  );
};

export default AdminTechnicalHub;
