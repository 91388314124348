export const ResetPasswordData = [
  {
    type: "password",
    placeHolder: "Current Password",
    labelFor: "Current Password",
    name: "oldPassword",
    value: "",
    isInput: true,
  },
  {
    type: "password",
    placeHolder: "New Password",
    labelFor: "New Password",
    name: "newPassword",
    value: "",
    isInput: true,
  },
  {
    type: "password",
    placeHolder: "Confrim New Password",
    labelFor: "Confrim New Password",
    name: "confirmPassword",
    value: "",
    isInput: true,
  },
];
