import React from "react";
import classes from "./ProductsAds.module.css";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const ProductsAds = ({ images = [], className = "", style = {} }) => {
  console.log("ProductsAds -> images", images);
  return (
    <div className={`${classes.product_container} ${className}`} style={style}>
      {images?.length > 0 ? (
        images.map((image, i) => (
          <div key={i} className={classes.product_image_}>
            <LazyLoadImage alt={"image.alt"} effect="blur" src={image.url} 
              // style={{
              //     width: "100%",
              //     height: '250px'
              //   }}
              />
          </div>
        ))
      ) : (
        <>
          {/* <div className={classes.product_image_}>
            <LazyLoadImage
              alt={"image.alt"}
              effect="blur"
              src={"/images/products/product-image1.png"}
            />
          </div>
          <div className={classes.product_image_}>
            <LazyLoadImage
              alt={"image.alt"}
              effect="blur"
              src={"/images/products/product-image1.png"}
            />
          </div> */}
        </>
      )}
    </div>
  );
};

export default ProductsAds;
