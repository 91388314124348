import React from "react";
import { orderData } from "../../data/OrderData";
import classes from "./AdminVendorProduct.module.css";
const AdminVendorProduct = (): JSX.Element => {
  return (
    <div className={classes.order_table}>
      <table className={classes.table_section}>
        <tr className={classes.header_section}>
          <td>#</td>
          <td>Prodcut Name</td>
          <td>Brand</td>
          <td>Category</td>
          <td>Review</td>
          <td>Status</td> 
        </tr>
        {orderData.map((cur: any, index: number) => (
          <tr key={cur.id} className={classes.lists_}>
            <td>{index + 1}</td>
            <td>{cur.orderNumber}</td>
            <td>{cur.brand}</td>
            <td>{cur.category}</td>
            <td>{cur.review}</td>
            <td>{cur.status}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default AdminVendorProduct;
