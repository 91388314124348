export const Domain = () => {
  return "https://api.electronicshop.com.ng";
};

// export const Domain = () => {
//   return "http://localhost:65533"; 
// };

export const Domain2 = 'https://apiv2.electronicshop.com.ng'; 

//export const API_URL = 'http://localhost:65533'; 
export const API_URL = 'https://api.electronicshop.com.ng'; 

