import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  promotionsArray: [],
  promotionsObj: {},
};

const promotionSlice = createSlice({
  name: "promotion",
  initialState,
  reducers: {
    setPromotionsArray(state, { payload }) {
      state.promotionsArray = payload;
    },
    generatePromotionsObj(state, { payload }) {
      console.log("state.promotionsObj");
      state.promotionsObj = payload.reduce((acc, item) => {
        acc[item.name] = [];
        console.log((acc[item.name] = []));
        return acc;
      }, {});

      console.log({ promotionsObj: state.promotionsObj });
    },
    addPromotionData(state, { payload }) {
      state.promotionsObj[payload?.name] = payload?.data;
      // console.log("hello state" + payload?.data) 
    },
    
  },
});

export const { setPromotionsArray, generatePromotionsObj, addPromotionData } =
  promotionSlice.actions;

export default promotionSlice;
