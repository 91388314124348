import Pagination from "../Pagination/Pagination";
import classes from "../../styles/Account.module.css";
import { useDispatch } from "react-redux";
import { getPaySmallProducts } from "../../store/slices/paymentSlice";
import { Link } from "react-router-dom";

const OrderList = ({
  list,
  totalPages,
  currentPage,
  updateCurrentPage,
}: {
  list: UserOrder[];
  totalPages: number;
  currentPage: number;
  updateCurrentPage: (page: number) => void;
}) => {
  const dispatch = useDispatch();

  const handlePageChange = (page: number) => {
    updateCurrentPage(page);
  };

  // render list of orders with pagination
  return (
    <div className={classes.orderList}>
      <div className={classes.orderListBody}>
        {list.length > 0 ? (
          list.map((order: UserOrder) => {
            dispatch(getPaySmallProducts(order?.products));

            return (
              <Link
                //   onClick={() => handlePaySmallDetail(cur?.products)}
                to={"/pay-small-order-detail?order_id=" + order?.order_id}
                key={order?.id}
              >
                <div className={classes.order_container}>
                  <h3>
                    {order?.firstName} {order?.otherName}
                  </h3>
                  <p>{order?.address}</p>
                  <p> {new Date(order?.updatedAt).toLocaleDateString()}</p>
                </div>
              </Link>
            );
          })
        ) : (
          <div className={classes.noOrder}>
            <h3>You have no orders yet</h3>
          </div>
        )}
      </div>
      {/* add pagination */}
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        updateCurrentPage={handlePageChange}
      />
      {/* <div className={classes.pagination}>
          <div className={classes.paginationLeft}>
            <p>
              Page {currentPage} of {totalPages}
            </p>
          </div>
          <div className={classes.paginationRight}>
            <button
              className={classes.btn}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Prev
            </button>
            <button
              className={classes.btn}
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div> */}
    </div>
  );
};

export default OrderList;
