import React, { useState, useEffect } from "react";
import { BsCartCheckFill, BsCheck2 } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import Cart from "../components/Cart/Cart";
import VendorCartSummary from "../components/Cart/VendorCartSummary";
import Main from "../components/UI/Main/Main";
import useGetVendorCartDetailsHook from "../Hooks/useGetVendorCartDetailsHook";
import classes from "../styles/CartPageStyles.module.css";
import { usePaystackPayment } from "react-paystack";
import useVendorCartCheckoutHook from "../Hooks/useVendorCartCheckoutHook";
import dommyText from "../data/dommyText";
import { useDispatch, useSelector } from "react-redux";
import { getClientData } from "../components/cookie/getClientData";

const VendorCartPaymentPage = () => {
  const { cartLength, cart, cartTotalPrice } = useGetVendorCartDetailsHook();
  const { loader, onSuccess } = useVendorCartCheckoutHook();
  const dispatch = useDispatch();
  const [paymentType, setPaymentType] = useState<string | null>("");
  const navigate = useNavigate();
  const paymentSelectror = useSelector((value: any) => value.payment);

  const onSelectPaymentType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentType(e.target.id);
  };

  // Scroll top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const config = {
    reference: new Date().getTime().toString(),
    email: getClientData().email,
    amount: cartTotalPrice * 100,
    publicKey: dommyText(),
  };
  // you can call this function anything
  // const onSuccess: any = (reference: any) => {
  //   // Implementation for whatever you want to do with reference and after success call.
  //   console.log(reference);
  // };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const PaystackHookExample = () => {
    const initializePayment = usePaystackPayment(config);
    return (
      <button
        className={classes.check_out_btn}
        onClick={() => {
          initializePayment(onSuccess as any, onClose);
        }}
      >
        NEXT
      </button>
    );
  };


  return (
    <Main>
      <div>
        <div className={classes.cart_navs_container}>
          <Link to={"/vendor-cart"}>
            <button>Cart details</button>
          </Link>
          <Link to={"/vendor-cart-account-addresses"}>
            <button>Delivery Address</button>
          </Link>
          <Link to={"/vendor-cart-payment"}>
            <button>Payment method</button>
          </Link>
        </div>
        <br />

        <div className={classes.cart_main}>
          <div className={classes.first_col}>
            <div className={classes.cart_hed_cetails_line}>
              <h3>Vendor Cart Payment Method</h3>
            </div>
            <br />
            <div style={{ backgroundColor: 'white', padding: '30px 0px', display: 'flex', alignItems: 'center',marginBottom: '10px'}}>
              <input
                onChange={onSelectPaymentType}
                type="radio"
                id="payStack"
                name="paymentType"
              />
              <div>
                <p>Pay with bank ATM card </p>
              </div>
              <div>
                <img src="/images/paystack.png" alt="" />
              </div>
            </div>
            <br />

            <div className={classes.navigating_button}>
              <button onClick={() => navigate(-1)}>BACK</button>
              {paymentType === "payStack" && (
                <>
                  {paymentType === "payStack" ? (
                    <PaystackHookExample />
                  ) : (
                    <button disabled>NEXT</button>
                  )}
                </>
              )}
            </div>
          </div>

          <div className={classes.summarry_section}>
            <div className={classes.summary_wrapper}>
              <div className={classes.check_out_btn}>
                <VendorCartSummary />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default VendorCartPaymentPage;
