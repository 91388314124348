import axios from "axios";
import React, { useState } from "react";
import { getCookie } from "../components/cookie/getCookie";
import { Domain } from "../Domain/Domain";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../store/slices/alertSlice";
const useCreateEnums = () => {
  const dispatch = useDispatch();
  const [enteredValue, setEnteredValue] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onSubmitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(
        Domain() + "/api/v1/promotion/create-enum",
        JSON.stringify({ name: enteredValue }),
        {
          headers: {
            Authorization: getCookie(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
      .then((response: any) => {
        setIsLoading(false);
        // alert(response.data.message);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: response.data.message,
            status: "",
          })
        );
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        // alert(error.response.data.message);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: error.response.data.message,
            status: "error",
          })
        );
      });
  };

  const onEnumsChnageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredValue(e.target.value);
  };
  return { onSubmitHandler, onEnumsChnageHandler, isLoading };
};

export default useCreateEnums;
