import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getClientToken } from "../../components/cookie/getClientToken";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import VendorMain from "../../components/UI/VendorMain/VendorMain";
import { Domain, Domain2 } from "../../Domain/Domain";
import classes from "../../styles/Account.module.css";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../../store/slices/alertSlice";
import { API_URL } from "../../Domain/Domain";

const VendorEditBankDetails = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState<boolean>(false);
  const [message, setMessage] = useState({ error: "", success: "" });
  const [enteredData, setEnteredData] = useState({
    account_name: "",
    bank_name: "",
    bank_code: "",
    account_number: "",
  });
  const [bankList, setBankList] = useState([]); 

  function refreshPage() {
    window.location.reload();
  }

  const onChangeHandler = (e: any) => {
    setEnteredData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onBankSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedBankName = e.target.value;

    const selectedBankDetails = bankList.find(
      (bank: any) => bank.name === selectedBankName
    ) as { code: string } | undefined;

    if (selectedBankDetails) {
      setEnteredData((prevState) => {
        return {
          ...prevState,
          bank_name: selectedBankName,
          bank_code: selectedBankDetails.code,
        };
      });
    }
  };

  useEffect(() => {
    let vendorData = localStorage.getItem("vendor") as any;
    if (vendorData === null) {
      dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "Please login",
          status: "error",
        })
      );
    }

    vendorData = JSON.parse(vendorData);

    fetch(Domain() + `/api/v1/vendor/profile/` + vendorData.vendorId, {
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("vendor-token") as string,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response: any) => {
        if (response.status === 200) {
          console.log("===>", response.data);

          let obj = response.data.vendorProfileDetails;
          setEnteredData((prevState) => {
            return {
              ...prevState,
              ...obj,
            };
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // Fetch the list of banks from the Paystack API
    axios
      .get("https://api.paystack.co/bank?country=nigeria")
      .then((response) => {
        const bankData = response.data.data;
        setBankList(bankData);
      })
      .catch((error) => {
        console.error("Error fetching bank data:", error);
      });
  }, []);

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoader(true);

    let vendorData = localStorage.getItem("vendor") as any;
    if (vendorData === null)
      return dispatch(
        toggleAlertAction({
          isAlerting: true,
          message: "Please login",
          status: "error",
        })
      );

    vendorData = JSON.parse(vendorData);

    console.log(localStorage.getItem("vendor-token") as string);

    const token = localStorage.getItem("vendor-token") as string;
    let vendorDetails = {
      account_name: enteredData.account_name,
      bank_name: enteredData.bank_name,
      bank_code: enteredData.bank_code,
      account_number: enteredData.account_number,
    };

    // Update account details
    fetch(`${Domain2}/vendor/create_Account`, {
      body: JSON.stringify(vendorDetails),
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response: any) => {
        setLoader(false);

        console.log("submit response: ", response);
        if (response.status === 200) {
          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: response.message,
              status: "success",
            })
          );
        } else {
          throw new Error(response.message);
        }
        refreshPage()
      })
      .catch((error) => {
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: error.message,
            status: "error",
          })
        );
        setLoader(false);
        console.log(error);
      });
  };

  return (
    <VendorMain>
      <form onSubmit={onSubmitHandler}>
        <h1>UPDATE BANK DETAILS</h1>
        <br />
        <center>
        <div style={{ margin: 'auto'}}>
          <div className={classes.messages_data}>
            <p className="error_message">
              {message.error !== "" && message.error}
            </p>
            <p className="success_message">
              {message.success !== "" && message.success}
            </p>
          </div>

          <div className={classes.application_form}>
            <>
              <label htmlFor="">{"Account name"}</label>
              <input
                type={"text"}
                name={"account_name"}
                placeholder={"Account name"}
                value={enteredData.account_name}
                required
                onChange={onChangeHandler}
              />
            </>
            <br />

            <>
              <label htmlFor="">{"Bank Name"}</label>
              <select
                name="bank_name"
                onChange={onBankSelect}
                value={enteredData.bank_name}
                required
              >
                <option value="">Select a bank</option>
                {bankList.map((bank: any, index: number) => (
                  <option
                  key={`${bank.code}_${index}`}
                    value={bank.name}
                    selected={bank.name === enteredData.bank_name} 
                  >
                    {bank.name}
                  </option>
                ))}
              </select>
            </>
            <br />

            <>
              <label htmlFor="">{"Bank Code"}</label>
              <input
                type={"text"}
                name={"bank_code"}
                placeholder={"Bank Code"}
                value={enteredData.bank_code}
                required
                readOnly
              />
            </>
            <br />

            <>
              <label htmlFor="">{"Account Number"}</label>
              <input
                type={"number"}
                name={"account_number"}
                placeholder={"Account Number"}
                value={enteredData.account_number}
                required
                onChange={onChangeHandler}
              />
            </>
            <br />
          </div>
          <br />
          <br />
          <div className={classes.add_adderess_button + " " + classes.btn_2}>
            <button>UPDATE</button>
            {loader && <SmallSpinner />}
          </div>
        </div>
        </center>
      </form>
    </VendorMain>
  );
};

export default VendorEditBankDetails;
