import React from "react";
import { orderData } from "../../data/OrderData";
import classes from "./TechnicalHubStyles.module.css";
import { useNavigate } from "react-router-dom";
const TechnicalReports = () => {
  const naviagte = useNavigate();
  const onNavigateHandler = (data: any) => {
    naviagte("/admin-technical-details?id=" + data);
  };
  return (
    <div className={classes.order_table}>
      <table className={classes.table_section}>
        <tr className={classes.header_section}>
          <td>#</td>
          <td>Ticket Number</td>
          <td>From</td>
          <td>Technician</td>
          <td>User</td>
          <td>Status</td>
          <td>Date/Time</td>
        </tr>
        {orderData.map((cur: any, index: number) => (
          <tr key={cur.id} className={classes.lists_} onClick={() => onNavigateHandler(cur.orderNumber)}>
            <td>{index + 1}</td>
            <td>{cur.orderNumber}</td>
            <td>{cur.items}</td>
            <td>{cur.price}</td>
            <td>{cur.location}</td>
            <td>{cur.dateAndTime}</td>
            <td>{cur.status}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default TechnicalReports;
