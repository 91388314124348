import React, { useState } from "react";
import axios from "axios";
import { getCookie } from "../components/cookie/getCookie";
import { Domain } from "../Domain/Domain";
import { useDispatch } from "react-redux";
import { toggleAlertAction } from "../store/slices/alertSlice";
const useCreateBrandHook = () => {
  const dispatch = useDispatch();
  const [imageFIle, setImageFile] = useState<any>({});
  const token = getCookie();
  const [brandName, setBrandName] = useState<string>("");
  const [enteredDescription, setEnteredDescription] = useState<string>("");
  const [isBrandorCategory, setIsBrandorCategory] = useState<String>("brand");
  const [isSubmitting, setIsSUbmittng] = useState<boolean>(false);

  const onhandleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBrandName(e.target.value);
  };
  // PINKCING THE FILE
  const onImageChangeHandler = (e: any) => {
    setImageFile(e.target.files[0]);
  };

  // TODO: CHOOZING BRAND OR CATEGORY FOR UPDATEING
  const onBrandOrToggleChange = (e: any) => {
    setIsBrandorCategory(e.target.value);
  };

  const onChangeDescription = (e: any) => {
    setEnteredDescription(e.target.value);
  };

  // @ Create Brands
  const onCreateHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSUbmittng(true);
    if (isBrandorCategory === "brand") {
      let formData = new FormData();
      formData.append("name", brandName);
      formData.append("description", enteredDescription);
      formData.append("image", imageFIle);

      axios
        .post(Domain() + "/api/v1/brand/new", formData, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        })

        .then((response: any) => {
          if (response.data.status === 200) {
            // alert(response.data.message);
            dispatch(
              toggleAlertAction({
                isAlerting: true,
                message: response.data.message,
                status: "",
              })
            );
            setIsSUbmittng(false);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        })
        .catch((error) => {
          console.log(error);
          // alert(error.response.data.message);
          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: error.response.data.message,
              status: "error",
            })
          );
          console.log(error.response.data.message);
        });
    }

    if (isBrandorCategory === "category") {
      let formData = new FormData();
      formData.append("name", brandName);
      formData.append("description", enteredDescription);
      formData.append("image", imageFIle);
      axios
        .post(Domain() + "/api/v1/category/new", formData, {
          headers: {
            Authorization: token,
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then((response: any) => {
          if (response.data.status === 200) {
            // refetch();
            // alert(response.data.message);
            dispatch(
              toggleAlertAction({
                isAlerting: true,
                message: response.data.message,
                status: "",
              })
            );
            setIsSUbmittng(false);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response.data.message);
          // alert(error.response.data.message);
          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: error.response.data.message,
              status: "error",
            })
          );
          setIsSUbmittng(false);
        });
    }
  };
  return {
    onhandleChangeName,
    onImageChangeHandler,
    onBrandOrToggleChange,
    onChangeDescription,
    onCreateHandler,
    isBrandorCategory,
    isSubmitting,
  };
};

export default useCreateBrandHook;
