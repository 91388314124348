import React, { useState, useEffect } from "react";
import MarketPlaceProduct from "../components/MarketPlaceProduct/MarketPlaceProduct";
import ProductsAds from "../components/ProductAds/ProductsAds";
import ProductItem from "../components/ProductItem/ProductItem";
import Main from "../components/UI/Main/Main";
import { titleStyles } from "../styles/ProductStyles";
import SimpleImageSlider from "react-simple-image-slider";
import axios from "axios";
import { Domain } from "../Domain/Domain";
import Spinner from "../components/Spinner/Spinner";
import { useSelector } from "react-redux";
import BackButton from "../components/UI/BackButton/BackButton";
import AdminPagination from "../components/AdminPagination/AdminPagination";

const SeeMorePage = () => {
  const [products, setProducts] = useState<any>([]);
  const [title, setTitle] = useState<any>("");
  const [loading, setLoading] = useState<any>(false);
  const [pageNumber, setPageNumber] = useState<number>(0);  

  useEffect(() => {
    setLoading(true);
      //check if local storage item exists and pass pageNumber to it.
      //the value of this is reset in the homepage.tsx
      if(localStorage.getItem('SeeMorepage')){
        console.log("refreshedddddd", performance.navigation)
        const isPageRefreshed = performance.navigation.type == 1;
       
        setPageNumber(0)
        if(isPageRefreshed){
          //load from local storage
          const count = Number(localStorage.getItem('SeeMorepage'))
          setPageNumber(count)
        }else{
          setPageNumber(0)
          localStorage.setItem('SeeMorepage', '0')
        }
        
      }else{
      //set local storage
        localStorage.setItem('SeeMorepage', '0')
      }

      console.log("referrrrer", document.referrer)

    axios
      // .get(`https://api.electronicshop.com.ng/api/v1/product/new-arrivals/more?page=${pageNumber}`)
      .get("https://api.electronicshop.com.ng/api/v1/product/new-arrivals")
      .then((response: any) => {
        // console.log(response);
        setLoading(false);
        if (response.data.status === 200) setProducts(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const onDecreaseHandler = () => {
    if (pageNumber > 0){
      setPageNumber((prevState) => prevState - 1)
    }
    // else{
    //   setPageNumber(0)
    // }
  };

  const onIncreaseHandler = () => setPageNumber((prevState) => prevState + 1);
  useEffect(() => {
    if (pageNumber >=0) {
      setLoading(true);
      localStorage.setItem('SeeMorepage', `${pageNumber}`)
      const url = `https://api.electronicshop.com.ng/api/v1/product/new-arrivals/more?page=${pageNumber}`
      console.log('URL : ', url)
      axios
        .get(
          url
        )
        // /api/v1/product/new-arrivals/more?page=100&size=80
        // https://api.electronicshop.com.ng/api/v1/product/new-arrivals
        .then((response: any) => {
          console.log(response.data.data);
          setLoading(false);
          setProducts([]);
          if (response.data.status === 200) {
            // console.log(response.data.data.products)
            setProducts(response.data.data.products);
            window.scrollTo(0, 0);

          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [pageNumber]);

  console.log('page number: ', pageNumber)

  return (
    <Main>
      <article>
        <div style={titleStyles}>
          <h1>{title}</h1>
          <BackButton />
        </div>
        <ProductItem data={products} />
        {/* {
          !products.length && <h3 style={{textAlign: 'center'}}>No More Products</h3>
        } */}
        <div className="center-pagination">
          <AdminPagination
            onDecreaseHandler={onDecreaseHandler}
            onIncreaseHandler={onIncreaseHandler}
            pageNumber={pageNumber}
            hasReactedLimit={products?.length! >= 20}
          />
        </div>
        {loading && <Spinner />}
      </article>
    </Main>
  );
};

export default SeeMorePage;
