import axios from "axios";
import React, { useState, useEffect } from "react";
import AdminListedProducts from "../../components/AdminListedProducts/AdminListedProducts";
import AdminPagination from "../../components/AdminPagination/AdminPagination";
import { getCookie } from "../../components/cookie/getCookie";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import BackButton from "../../components/UI/BackButton/BackButton";
import { Domain } from "../../Domain/Domain";
import classes from "../../styles/VendorDetailPage.module.css";
import { toggleAlertAction } from "../../store/slices/alertSlice";
import { useDispatch } from "react-redux";

const VendorDetailPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const [vendor, serVendor] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(false);
  const [productCount, setProductCount] = useState<number | string>(0);

  function fetchVendorDetails() {
    const url = new URLSearchParams(window.location.search);
    axios
      .get(Domain() + "/api/v1/vendor/profile/" + url.get("id"), {
        headers: {
          Authorization: getCookie(),
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response: any) => {
        console.log("=======>", response?.data?.data);
        if (response.data.status === 200) {
          serVendor(response?.data?.data?.vendorProfileDetails);
          setProductCount(response?.data?.data?.allProductsCount);
        }
      })
      .catch((error) => {
        // console.log(error);
        // alert("Failed to fetch vendor detail.");
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: "Failed to fetch vendor detail",
            status: "error",
          })
        );
      });
  }
  useEffect(() => {
    fetchVendorDetails();
  }, []);

  console.log("======>", vendor);

  const onVendorActionHandler = (status: string, id: string | number) => {
    setLoading(true);
    fetch(
      status === "activate"
        ? Domain() + "/api/v1/admin/vendor/activate/" + id
        : Domain() + "/api/v1/admin/vendor/deactivate/" + id,
      {
        method: "PATCH",
        headers: {
          Authorization: getCookie(),
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    )
      .then((res) => res.json())
      .then((response: any) => {
        // alert(response?.message);
        dispatch(
          toggleAlertAction({
            isAlerting: true,
            message: response?.message,
            status: "",
          })
        );
        setLoading(false);
        fetchVendorDetails();
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
      });
  };

  return (
    <AdminMain>
      <div>
        <BackButton />
        <br />
        <div className={classes.vendor_head_name}>
          <p>{vendor?.shopName!}</p>
          <p>Vendor ID: {vendor?.vendorId!}</p>
        </div>
        <div className={classes.contact_detail}>
          <p>
            <b>Email</b>
          </p>
          <p>{vendor?.email!}</p>
        </div>
        <div className={classes.contact_detail}>
          <p>
            <b>Phone</b>
          </p>
          <p>{vendor?.phoneNumber!}</p>
        </div>
        <div className={classes.contact_detail}>
          <p>
            <b>Address</b>
          </p>
          <p>
            {vendor?.address!} {vendor?.city!} {vendor?.state!}{" "}
            {vendor?.country!}
          </p>
        </div>
        <div className={classes.contact_detail}>
          <p>
            <b>Verification status</b>
          </p>
          <p>{vendor?.is_verified! ? "Verified" : "Unverified"}</p>
        </div>

        <div className={classes.contact_detail}>
          <p>
            <b>Active status</b>
          </p>
          <p>{vendor?.is_active! ? "Active" : "Inactive"}</p>
        </div>
        <br />
        {/* Other details section */}
        <div className={classes.other_detail_section}>
          <div className={classes.other_detail_list}>
            <p>Joined</p>
            <p>{new Date(vendor?.createdAt!).toDateString()}</p>
          </div>

          <div className={classes.other_detail_list}>
            <p>Product count</p>
            <p>{productCount?.toLocaleString()!}</p>
          </div>
        </div>
        <div className={classes.activate_button}>
          <button
            onClick={() => onVendorActionHandler("activate", vendor.id)}
            className={classes.action_buttons + " " + classes.activate_btn}
          >
            Activate
          </button>
          <button
            onClick={() => onVendorActionHandler("deactivate", vendor.id)}
            className={classes.action_buttons + " " + classes.deactivate_button}
          >
            Deactivate
          </button>
        </div>
        {isLoading && (
          <>
            <br />
            <SmallSpinner />
            <br />
          </>
        )}

        <br />
        {/* listed products */}
        {/* <div className={classes.listed_products}>
          <p>Listed Products</p>
          <div>
            <AdminListedProducts />
          </div>
        </div> */}
        {/* Paginations */}

        {/* <AdminPagination /> */}
      </div>
    </AdminMain>
  );
};

export default VendorDetailPage;
