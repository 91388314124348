import { BsImage, BsImageFill } from "react-icons/bs";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import useCreatepromotionHook from "../../Hooks/useCreatepromotionHook";
import classes from "../../styles/AdminNewPromotion.module.css";
import useCreateEnums from "../../Hooks/useCreateEnums";
import SmallSpinner from "../../components/Spinner/SmallSpinner";
import useFetchPromotionEnums from "../../Hooks/useFetchPromotionEnums";
import { useState } from "react";

const ImageField = (props: any) => {
  console.log({
    props,
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className={classes.media_container}>
        <label htmlFor={props.id}>
          {props.loader === false && (
            <i>
              <BsImage />
            </i>
          )}

          {props.loader && (
            <div className={classes.image_loader}>
              <SmallSpinner />
            </div>
          )}

          {props.file !== "" ? (
            <div className={classes.previewed_images}>
              <img src={props.file} alt="" />
            </div>
          ) : (
            <input
              type="file"
              accept="image/*"
              style={{
                // visibility: "hidden",
                opacity: 0,
                pointerEvents: "none",
                position: "absolute",
              }}
              name={props.name}
              id={props.id}
              onChange={props.onChange}
            />
          )}

          {props.file === "" && (
            <div id="fileLoader1" className="file_loader"></div>
          )}

          {/* {props.loader === false && (
          <img
            src={props.file}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "5px",
            }}
          />
        )} */}
        </label>
      </div>
      {props.file !== "" && (
        <button
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: "red",
            color: "white",
            border: "none",
            borderRadius: "5px",
            marginTop: "10px",
          }}
          onClick={() => {
            props.onDelete(props.index);
          }}
          type="button"
        >
          X
        </button>
      )}
    </div>
  );
};

const AdminNewPromotion = (): JSX.Element => {
  const {
    onPromotionalChangeHandler,
    enteredData,
    isLoading: creatingLoader,
    files,
    imageFiles,
    setImageFiles,
    isLoadingImages,
    onImageChangeHandler,
    onCreatehandler,
    onImageFileDeleteHandler,
  } = useCreatepromotionHook();

  const { onSubmitHandler, onEnumsChnageHandler, isLoading } = useCreateEnums(); // Creating enums (POSITION)

  const { fetchedData } = useFetchPromotionEnums(); //Fetch list of promotions
  return (
    <AdminMain>
      <div>
        <div>
          <form onSubmit={onSubmitHandler}>
            <h1>Create Enums</h1>
            <br />
            <div className={classes.detail_text}>
              <input
                type="text"
                onChange={onEnumsChnageHandler}
                placeholder="Slide"
              />
              <br />
              <br />

              <div className={classes.action_button}>
                <button>CREATE ENUMS</button>
              </div>

              <br />
              {isLoading && <SmallSpinner />}
            </div>
          </form>
        </div>
        <br />
        <br />
        <br />
        <h1>New Promotion</h1>
        <br />
        <form onSubmit={onCreatehandler}>
          <div className={classes.select_section}>
            <select
              name="category"
              id=""
              defaultValue={"DEFAULT"}
              onChange={onPromotionalChangeHandler}
              required
            >
              <option value="DEFAULT" disabled>
                Please select enums
              </option>
              {fetchedData.map((cur: any) => {
                return (
                  <option key={cur.id} value={cur.id}>
                    {cur.name}
                  </option>
                );
              })}
            </select>
          </div>
          <br />
          <div className={classes.detail_text}>
            <p>
              <b>Title</b> <span>(Name of the promotion)</span>
            </p>
            <br />
            <input
              type="text"
              name="title"
              required
              onChange={onPromotionalChangeHandler}
            />
          </div>
          <br />
          <div className={classes.detail_text}>
            <p>
              Site Side Bar Ad  (400px by 400px )<br /><br /> 

              Home Page Header Ad (900px by 350px )<br /><br />

              Product Page Middle Ad (600px by 600px ) <br /><br />

              Market Place Home Page Middle Ad  (400px by 400px )<br /><br />

              Market Place Product Page Middle Ad  (400px by 400px )<br /><br />

              Product Search Ad  (400px by 400px )<br /><br />

              Market Place Product Search Ad  (400px by 400px )<br /><br />

              test promotion  (400px by 400px )<br /><br />

              Test (400px by 400px )<br /><br />
            </p>

          </div>
          <br />
          {/* <div className={classes.media_container}>
            <label htmlFor="file1">
              <div className={classes.upload_list}>
                {isLoadingImages.loader1 === false && (
                  <i>
                    <BsImage />
                  </i>
                )}

                {isLoadingImages.loader1 && (
                  <div className={classes.spinner_container}>
                    <SmallSpinner />
                  </div>
                )}

                {files.file1 !== "" && (
                  <div className={classes.previewed_images}>
                    <img src={files.file1} alt="" />
                  </div>
                )}
              </div>
              <input
                type="file"
                accept="image/*"
                id="file1"
                style={{ display: "none" }}
                onChange={onImageChangeHandler}
              />
            </label>

          </div> */}
          <br />
          <div
              className="media-container"
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              {imageFiles.map((imageFile, i) => {
                return (
                  <ImageField
                    key={imageFile.file}
                    id={`file${i}`}
                    name={`file${i}`}
                    index={i}
                    // name={imageFile.file}
                    file={imageFile.file}
                    loader={isLoadingImages.loader1}
                    onChange={onImageChangeHandler}
                    onDelete={onImageFileDeleteHandler}
                  />
                );
              })}
          </div>

          <div className="info-box">
              <p>
                Make sure the image is in a proper ratio and the size is not
                pixelated
              </p>
            </div>

          <br />
          <div className={classes.action_button}>
            <button>CREATE PROMOTION</button>
          </div>
          <br />
          {creatingLoader && <SmallSpinner />}
        </form>
      </div>
    </AdminMain>
  );
};

export default AdminNewPromotion;
