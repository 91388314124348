import React from "react";
import classes from "./AdminCartSummary.module.css";
interface Props {
  inputIsEditable?: boolean;
}
const AdminCartSummary: React.FC<Props> = ({
  inputIsEditable = false,
}): JSX.Element => {
  return (
    <div className={classes.admin_cart_list}>
      <div className={classes.image_div}>
        <img src="/images/products/product2.png" alt="" />
      </div>
      <div className={classes.details_section}>
        <p>Panasonic 1.5HP Split Air Conditioner (XV12)</p>
        <h1>1 piece</h1>
        <div className={classes.footer_section}>
          <div>
            <input
              placeholder="SKU: 1669KASP"
              type="text"
              readOnly={inputIsEditable ? false : true}
              style={{ borderColor: `${inputIsEditable ? "black" : "white"}` }}
            />{" "}
            {inputIsEditable && <button>ADD</button>}
          </div>
          <h2>₦280,000</h2>
        </div>
      </div>
    </div>
  );
};

export default AdminCartSummary;
