import React from "react";
import { AiFillSchedule, AiOutlineCloseSquare } from "react-icons/ai";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { orderData } from "../../data/OrderData";
import classes from "./AvailablePromotionsList.module.css";
import classes2 from "../../styles/AdminManagePromotions.module.css";
const PromoList = () => {
  return (
    <div className={classes.order_table}>
      <table className={classes.table_section}>
        <tr className={classes.header_section}>
          <td>#</td>
          <td>
            <input type="checkbox" />
          </td>
          <td>Title</td>
          <td>Last modified</td>
        </tr>
        {orderData.splice(0, 5).map((cur: any, index: number) => (
          <tr key={cur.id} className={classes.lists_}>
            <td>{index + 1}</td>
            <td>
              <input type="checkbox" />
            </td>
            <td>{cur.title}</td>
            <td>{cur.dateAndTime}</td>
          </tr>
        ))}
      </table>

      <div className={classes2.live_controller}>
        <div>
          <i>
            <FaArrowUp />
          </i>
          <p>Move forward</p>
        </div>

        <div>
          <i>
            <AiOutlineCloseSquare />
          </i>
          <p>Remove</p>
        </div>

        <div>
          <i>
            <FaArrowDown />
          </i>
          <p>Move backward</p>
        </div>

        <div>
          <i>
            <AiFillSchedule />
          </i>
          <p>Schedule</p>
        </div>
      </div>
    </div>
  );
};

export default PromoList;
