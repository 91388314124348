import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../components/cookie/getCookie";

const useAdminAuth = () => {
  const [adminAuth, adminIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    function checkAuth() {
      const parseJwt = (token: string) => {
        try {
          return JSON.parse(atob(token.split(".")[1]));
        } catch (e: any) {
          return null;
        }
      };

      const decodedJwt: any = parseJwt(getCookie()) as unknown as any;

      if (window.location.href.includes("admin")) {
        if (decodedJwt === null) return navigate("/login-v6");

        if (decodedJwt?.exp! * 1000 < Date.now()) {
          adminIsAuthenticated(false);
          navigate("/login-v6");
        }
      } else {
        // adminIsAuthenticated(true);
      }
    }
    checkAuth();
    let interval = setInterval(() => {
      checkAuth();
    }, 1000);

    return () => clearTimeout(interval);
  }, []);
  return { adminAuth };
};

export default useAdminAuth;
