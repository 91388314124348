import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getCookie } from "../../components/cookie/getCookie";
import LazyImage from "../../components/LazzyLoad/LazyImage";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import BackButton from "../../components/UI/BackButton/BackButton";
import { API_URL, Domain } from "../../Domain/Domain";
import classes from "../../styles/AdminFoundProduct.module.css";
import { toggleAlertAction } from "../../store/slices/alertSlice";
import { useDispatch } from "react-redux";
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";


const AdminFoundProduct = () => {
  const dispatch = useDispatch();
  
  const [initproduct, setInitProduct] = useState<any>({});
  const [productYear, setProductYear] = useState<any>(new Date().getFullYear());
  const [product, setProduct] = useState<any>({});
  const [productStats, setProductStats] = useState([]);
  let [images, setImages] = useState([]);
  let [specification, setSpecification] = useState([]);
  let [colors, setColors] = useState<any>([]);

  useEffect(() => {
    let url = new URLSearchParams(window.location.search);
    axios
      .get(`${API_URL}/api/v1/product/` + url.get("id"))
      .then((response: any) => {
        console.log("=========", response.data);

        if (response.data.data !== null) setProduct(response.data?.data!.products);
       

        // if (response.data.data !== null) setInitProduct(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let imageArr = product?.pictures!;
    if (imageArr !== undefined) setImages(JSON.parse(imageArr));

    let specArray = product?.specification!;
    if (specArray !== undefined) setSpecification(JSON.parse(specArray));

    let colorArray = product?.colours!;
    if (colorArray !== undefined) setColors(JSON.parse(colorArray));
  }, [product.pictures]);

  // console.log("=====>", colors);

  const onDeleteHandler = () => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      let url = new URLSearchParams(window.location.search);
      axios
        .delete(Domain() + "/api/v1/product/" + url.get("id"), {
          headers: {
            Authorization: getCookie(),
            "Content-Type": "application/json",
          },
        })
        .then((response: any) => {
          console.log(response);
          // alert("Product deleted successfully.");
          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: "Product deleted successfully",
              status: "",
            })
          );
          setTimeout(() => {
            window.history.back();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          // alert("Unable to delete product.");
          dispatch(
            toggleAlertAction({
              isAlerting: true,
              message: "Unable to delete product",
              status: "error",
            })
          );
        });
    }
  };

  const getProductStats = () => {
    axios
      .get(
        `https://apiv2.electronicshop.com.ng/product-sales-stats?year=${productYear}&product_id=${product.id}&price=${product.pricing}`
      )
      .then((res) => {
        console.log(res.data);
        const data = res.data.map((item: any, i: any) => {
          const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];

          return {
            value: item,
            name: monthNames[i],
          };
        });

        setProductStats(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleYearChange = (e: any) => {
    setProductYear(e.target.value);
  };

  useEffect(() => {
    getProductStats();
  }, [product, productYear]);

  useEffect(() => {
    console.log(productStats);
  }, [productStats]);

  return (
    <AdminMain>
      <div>
        <BackButton />
        <br />
        <div className={classes.product_container}>
          <p className={classes.title}>{product?.productName!}</p>
          <h5>
            Brand: {product.brand_id?.name!}; Category:{" "}
            {product.category_id?.name!}
          </h5>
          <div className={classes.product_images}>
            {images.map((cur, index) => (
              <span key={index}>
                {cur !== "" && <LazyImage image={cur} alt="" />}
              </span>
            ))}
          </div>
        </div>
        <br />
        <div className={classes.performance}>
          <p>Performance</p>

          <div className={classes.performance_container}>
            <select
              name="select-year"
              id="select-year"
              onChange={handleYearChange}
            >
              {/* generate product years from 2021 to current year */}
              {Array.from(
                new Array(new Date().getFullYear() - 2020),
                (val, index) => index + 2021
              ).map((cur, index) => (
                <option key={index} value={cur}>
                  {cur}
                </option>
              ))}
            </select>
            <ComposedChart width={830} height={250} data={productStats}>
              <XAxis dataKey="name" />
              <YAxis dataKey={"value"} />
              <Tooltip />
              <Legend />
              <CartesianGrid stroke="#f5f5f5" />
              <Area
                type="monotone"
                dataKey="value"
                fill="#bdbdbdab"
                stroke="#F14306"
              />
              <Bar dataKey="value" barSize={20} fill="#F14306" />
              <Line type="monotone" dataKey="value" stroke="#ff7300" />
            </ComposedChart>
          </div>
          {/* <img src="/images/chart/chart.png" alt="" /> */}
        </div>
        <br />
        <div className={classes.detail_container}>
          <p className={classes.price_property}>
            <span>Price:</span>
            <b> ₦ {product.promotionalPrice?.toLocaleString()!} </b>
            <del> ₦ {product.pricing?.toLocaleString()!} </del>
          </p>
          <p className={classes.color_property}>
            <span>Color:</span>
            {Array.isArray(colors) && (
              <b>
                {colors?.map((color: string) => (
                  <span key={color}> {color}</span>
                ))}
              </b>
            )}
          </p>
        </div>
        <p className={classes.descriptio_property}>
          <span>Description:</span>
          <b> {product?.productDescription!}</b>
        </p>
        <div className={classes.tabler_section}>
          <p>Specifications:</p>
          <table>
            <tbody>
              {specification.map((cur: any, index: number) => (
                <tr key={index}>
                  {cur.attribute !== "" && cur.value !== "" && (
                    <>
                      <td>{cur.attribute}</td>
                      <td>{cur.value}</td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={classes.edit_button}>
          <Link
            to={"/admin-edit-product?id=" + product?.id!}
            style={{ marginRight: "20px" }}
          >
            <button>EDIT PRODUCT</button>
          </Link>

          <button onClick={onDeleteHandler} style={{ backgroundColor: "red" }}>
            DELETE PRODUCT
          </button>
        </div>
      </div>
    </AdminMain>
  );
};

export default AdminFoundProduct;
