import React from "react";
import AdminAside from "../AdminAside/AdminAside";
import Header from "../Header/Header";
import AccountAside from "./AccountAside";
import classes from "./Account.module.css";
import Footer from "../Footer/Footer";
interface Props {
  children: JSX.Element | JSX.Element[];
}
const Account: React.FC<Props> = ({ children }) => {
  return (
    <div>
      <Header />
      <main className={classes.main}>
        <aside className={classes.aside}>
          <AccountAside />
        </aside>
        <article className={classes.article}>{children}</article>
      </main>
      <Footer />
    </div>
  );
};

export default Account;
