import React from "react";
import { BsImageFill } from "react-icons/bs";
import AdminMain from "../../components/UI/AdminMain/AdminMain";
import BackButton from "../../components/UI/BackButton/BackButton";
import classes from "../../styles/AdminVendorReportPage.module.css";
const AdminVendorReportPage = (): JSX.Element => {
  return (
    <AdminMain>
      <div>
        <BackButton />
        <br />
        <div className={classes.report_wrapper}>
          <p>DR58AOP: The vendor charged me extra</p>
          <p>From: user; User: John Doe; Vendor: BIRON</p>
          <p>17:59; 05/11/22</p>
        </div>
        <br />
        <div className={classes.report_body}>
          <div className={classes.body_text}>
            <p>
              I contacted the vendor BIRON after finding their post about a
              brand new Nexus freezer for N150,000. Only for me to be asked to
              pay some extra charges for VAT and insurance to the sum of 15,000.
              Kindly advise before I involve the police.
            </p>
          </div>
          <br />
          <br />
          <input type="text" placeholder="Reply" />
          <br />
          <br />
          <div className={classes.uplaod_div}>
            <i>
              <BsImageFill />
            </i>
          </div>
          <br />
          <div className={classes.action_button}>
            <button>SEND MESSAGE</button>
            <button>CLOSE TICKET</button>
          </div>
        </div>
      </div>
    </AdminMain>
  );
};

export default AdminVendorReportPage;
