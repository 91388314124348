import React, { useEffect, useState } from "react";
import { BsStarFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Domain } from "../../Domain/Domain";
import LazyImage from "../LazzyLoad/LazyImage";
import classes from "./ProductItemList.module.css";
interface Props {
  image: string;
  description: string;
  name: string;
  colorsArray: string;
  rating: string | number;
  pricing: number | string;
  oldAmount: number | string;
  id: number | string;
  promotionalPrice: string | number;
  brand: string | number;
}
const ProductItemListMarketplace: React.FC<Props> = ({
  pricing, 
  description,
  name,
  image,
  oldAmount,
  rating,
  id,
  colorsArray,
  brand,
  promotionalPrice,
}) => {
  // console.log(image);

  let [images, setImages] = useState([]);
  let [colors, setColors] = useState([]);
  let [brandName, setBrandName] = useState("");
  useEffect(() => {
    let imageArr = image;
    if (imageArr !== undefined) setImages(JSON.parse(imageArr));

    // let colorsArray: string = colors;

    if (colorsArray !== undefined) setColors(JSON.parse(colorsArray));
  }, []);

  useEffect(() => {
    fetch(Domain() + "/api/v1/brand/" + brand, {
      headers: {
        "Content-Type": "Application/json",
      },
    })
      .then((res) => res.json())
      .then((response: any) => {
        setBrandName(response.data.name);
        console.log("=======================?", response.data.name);
      })
      .catch((error) => {});
  }, []);
  // console.log("====>", colors);

  return (
    <Link to={"/marketplace-deal?id=" + id}>
      <div className={classes.product_item}>
        <div className={classes.product_list_item}>
          
            <div className={classes.product_image_container}>
              <LazyImage image={images[0]} className={classes.product_img} />
            </div>
          
          <div className={classes.bottom_section}>
            <p className={classes.product_description}>
              {brandName} {name?.substr(0, 20)!}
            </p>
            <div className={classes.rating_div}>
              {/* <i>
                <BsStarFill />
              </i>
              <i>
                <BsStarFill />
              </i>
              <i>
                <BsStarFill />
              </i>
              <i>
                <BsStarFill />
              </i>
              <i>
                <BsStarFill />
              </i>
              <span>{rating}</span> */}
              <h3 className={classes.price}>
                ₦ {(promotionalPrice as number)?.toLocaleString()}
              </h3>
              <small>
                <del>₦ {pricing?.toLocaleString()}</del>
              </small>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProductItemListMarketplace;
