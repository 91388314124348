import React from "react";
import { orderData } from "../../data/OrderData";
import classes from "../../components/AdminOrder/AdminOrder.module.css";
import axios from "axios";
import { Domain } from "../../Domain/Domain";
import { getCookie } from "../cookie/getCookie";
import { Link, useNavigate } from "react-router-dom";
interface Props {
  vendors: any;
}
const AdminVendor: React.FC<Props> = ({ vendors }): JSX.Element => {
  const naviagte = useNavigate();
  const onNavigateHandler = (id: number | string) => {
    naviagte("/admin-vendor-detail?id=" + id);
  };
  return (
    <div className={classes.order_table}>
      <table className={classes.table_section}>
        <tr className={classes.header_section}>
          <td>#</td>
          <td>Shop Name</td>
          <td>Phone number</td>
          <td>Vendor Id</td>
          <td>Address</td>
          <td>State</td>
          <td>country</td>
          {/* <td>Activate</td>
          <td>Deactivate</td> */}
        </tr>
        {vendors.map((cur: any, index: number) => (
          <tr key={cur.id} className={classes.lists_}>
            <td style={{ width: "50px" }}>{index + 1}</td>
            <td onClick={() => onNavigateHandler(cur.vendorId)}>
              {cur.shopName}
            </td>
            <td onClick={() => onNavigateHandler(cur.vendorId)}>
              {cur.phoneNumber}
            </td>
            <td onClick={() => onNavigateHandler(cur.vendorId)}>
              {cur.vendorId}
            </td>
            <td onClick={() => onNavigateHandler(cur.vendorId)} style={{fontSize: '12px', width: '250px'}}>
              {cur.address}
            </td>
            <td onClick={() => onNavigateHandler(cur.vendorId)}>{cur.state}</td>
            <td onClick={() => onNavigateHandler(cur.vendorId)}>
              {cur.country}
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default AdminVendor;
