import { Fragment, useEffect, useState } from "react";

import HomePage from "./Pages/HomePage";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import CategoriesPage from "./Pages/CategoriesPage";
import MarketPlacePage from "./Pages/MarketPlacePage";
import MarketPlaceDealPage from "./Pages/MarketPlaceDealPage";
import ProductPage from "./Pages/ProductPage";
import CartPage from "./Pages/CartPage";
import Login from "./Pages/Forms/Login"; 
import Register from "./Pages/Forms/Register";
import AdminLogin from "./Pages/Admin/AdminLogin";
import AdminDashboard from "./Pages/Admin/AdminDashboard";
import AdminOrderPage from "./Pages/Admin/AdminOrderPage";
import AdminNewProductPage from "./Pages/Admin/AdminNewProductPage";
import AdminOrderCartSummary from "./Pages/Admin/AdminOrderCartSummary";
import AdminOrderCartSummaryCompleted from "./Pages/Admin/AdminOrderCartSummaryCompleted";
import AdminManageFindProducts from "./Pages/Admin/AdminManageFindProducts";

import AdminManageTechnicianSubscription from "./Pages/Admin/AdminManageTechnicianSubscription"

import AdminFoundProduct from "./Pages/Admin/AdminFoundProduct";
import AdminEditManageProduct from "./Pages/Admin/AdminEditManageProduct";
import AdminNewBrandPage from "./Pages/Admin/AdminNewBrandPage";
import AdminVendorsPage from "./Pages/Admin/AdminVendorsPage";
import VendorDetailPage from "./Pages/Admin/VendorDetailPage";
import SearchAdminOrders from "./Pages/Admin/SearchAdminOrders";
import AdminProductDeactivationPage from "./Pages/Admin/AdminProductDeactivationPage";
import AdminVendorProductPage from "./Pages/Admin/AdminVendorProductPage";
import AdminVendorproductRequest from "./Pages/Admin/AdminVendorproductRequest";
import AdminVendorReportPage from "./Pages/Admin/AdminVendorReportPage";
import AdminMessageCenter from "./Pages/Admin/AdminMessageCenter";
import AdminOrderStatusPage from "./Pages/Admin/AdminOrderStatusPage";
import AdminNewPromotion from "./Pages/Admin/AdminNewPromotion";
import AdminSettings from "./Pages/Admin/AdminSettings";
import AdminTechnicalHub from "./Pages/Admin/AdminTechnicalHub";
import AdminOrderStatusPageReply from "./Pages/Admin/AdminOrderStatusPageReply";
import AdminNewPromotionCreateList from "./Pages/Admin/AdminNewPromotionCreateList";
import AdminManagePromotions from "./Pages/Admin/AdminManagePromotions";
import AdminSettingsChangePassword from "./Pages/Admin/AdminSettingsChangePassword";
import AdminNewAdmin from "./Pages/Admin/AdminNewAdmin";
import ManageAdmin from "./Pages/Admin/ManageAdmin";
import AdminFAQ from "./Pages/Admin/AdminFAQ";
import AdminReport from "./Pages/Admin/AdminReport";
import AdminGeneralOrder from "./Pages/Admin/AdminGeneralOrder";
import useAdminAuth from "./Auth/useAdminAuth";
import VerifyCode from "./Pages/Forms/VerifyCode";
import ResetPassword from "./Pages/Forms/ResetPassword";
import CheckoutPage from "./Pages/CheckoutPage";
import PaymentPage from "./Pages/PaymentPage";
import VendorCartPaymentPage from "./Pages/VendorCartPaymentPage";
import PaySmallSmallApplication from "./Pages/PaySmallSmallApplication";
import MyOrderPage from "./Pages/Account/MyOrderPage";
import PaySmall from "./Pages/Account/PaySmall";
import PaySmallOrderDetail from "./Pages/Account/PaySmallOrderDetail";
import MyOrderDetailPage from "./Pages/Account/MyOrderDetailPage";
import DeliveryAddressPage from "./Pages/Account/DeliveryAddressPage";
import VendorCartDeliveryAddressPage from "./Pages/Account/VendorCartDeliveryAddressPage";
import CreateAddress from "./Pages/Account/CreateAddress";
import AccountWalletPage from "./Pages/Account/AccountWalletPage";
import EditProfilePage from "./Pages/Account/EditProfilePage";
import ResetPasswordPage from "./Pages/Account/ResetPasswordPage";
import AccountInbox from "./Pages/Account/AccountInbox";
import FeecbackProfilePage from "./Pages/Account/FeecbackProfilePage";
import UpdateAddress from "./Pages/Account/UpdateAddress";
import { RegisterVendor } from "./Pages/Forms/RegisterVendor";
import { VendorLogin } from "./Pages/Forms/VendorLogin";
import { TechnicianLogin } from "./Pages/Forms/TechnicianLogin";
import useClientAuth from "./Auth/useClientAuth";
import AdminPaySmallSmallPage from "./Pages/Admin/AdminPaySmallSmallPage";
import AdminPaySmallSmallDetail from "./Pages/Admin/AdminPaySmallSmallDetail";
import VendorDashboard from "./Pages/Vendor/VendorDashboard";
import VendorCreateProduct from "./Pages/Vendor/VendorCreateProduct";
import VendorEditProduct from "./Pages/Vendor/VendorEditProduct";
import VendorProduct from "./Pages/Vendor/VendorProduct";
import VendorProfileUpdate from "./Pages/Vendor/VendorProfileUpdate";
import AdminTechnicianDetail from "./Pages/Admin/AdminTechnicianDetail";
import MoreProductFromVendor from "./Pages/Vendor/MoreProductFromVendor";
import VendorResetPassword from "./Pages/Forms/VendorResetPassword";
import VendorChangePassword from "./Pages/Vendor/VendorChangePassword";
import AdminMessages from "./Pages/Admin/AdminMessages";
import SeeMorePage from "./Pages/SeeMorePage";
import AdminCreateFaq from "./Pages/Admin/AdminCreateFaq";
import AccountFAQ from "./Pages/Account/AccountFAQ";
import Alert from "./components/Alert/Alert";
import { useDispatch, useSelector } from "react-redux";
import AdminSettingsUpdateEmail from "./Pages/Admin/AdminSettingsUpdateEmail";
import AdminUserSearch from "./Pages/Admin/AdminUserSearch";
import { getPromotions } from "./utils/promotionHelpers";
import {
  generatePromotionsObj,
  setPromotionsArray,
} from "./store/slices/promotionsSlice";
import MarketPlaceVendorProducts from "./Pages/MarketPlaceVendorDashboard";
import MessageCenter from "./Pages/Account/MessageCenter";
import AdminFeedbackPage from "./Pages/Admin/AdminFeedbackPage";
import AdminPaySmallSmallSearchPage from "./Pages/Admin/AdminPaySmallSmallSearchPage";
import MarketPlaceVendorView from "./Pages/MarketPlaceVendorView";
import CategoriesPageMarketplace from "./Pages/CategoriesPageMarketplace";
import AdminMessagesDetails from "./Pages/Admin/AdminMessagesDetails";
import AdminMessageSearch from "./Pages/Admin/AdminMessageSearch";

import VendorShop from "./Pages/VendorShop";
import VendorWithdraw from "./Pages/Vendor/VendorWithdraw";

// technician 
import TechnicianDashboard from "./Pages/Technician/TechnicianDashboard"
import SubscriptionPaymentPage from "./Pages/Technician/SubscriptionPaymentPage";
import SubscriptionPlansPage from "./Pages/Technician/SubscriptionPlansPage";
import VendorEditBankDetails from "./Pages/Vendor/VendorEditBankDetails";
import VendorCartPage from "./Pages/VendorCartPage";
import MyVendorOrderList from "./Pages/Account/MyVendorOrderList";
import VendorOrders from "./Pages/Vendor/VendorOrders";

function App() {
  const { clineIsLoggedIn = true } = useClientAuth();
  const alertSelector = useSelector((value: any) => value.alert);
  const {} = useAdminAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    getPromotions().then((res) => {
      console.log("getPromotions", res);

      setPromotionsArray(res);
      dispatch(setPromotionsArray(res));
      dispatch(generatePromotionsObj(res));
    });
  }, []);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [window.location.href]);
  return (

      <Fragment>
           
        {alertSelector.isAlerting && <Alert />}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/seemore" element={<SeeMorePage />} />
          {/* <Route path="/categories" element={<CategoriesPage />} /> */}
          <Route path="/search" element={<CategoriesPage />} />
          <Route path="/search-marketplace" element={<CategoriesPageMarketplace />} />
          <Route path="/marketplace" element={<MarketPlacePage />} />
          <Route path="/marketplace-deal" element={<MarketPlaceDealPage />} />
          <Route
            path="/vendor-more-products"
            element={<MarketPlaceVendorProducts />}
          />
          <Route
            path="/vendor-more-products-view"
            element={<MarketPlaceVendorView />}
          /> 
          <Route path="/product" element={<ProductPage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/vendor-cart" element={<VendorCartPage />} />
          <Route
            path="/payment"
            element={clineIsLoggedIn ? <PaymentPage /> : <Login />}
          />
           <Route
            path="/vendor-cart-payment"
            element={clineIsLoggedIn ? <VendorCartPaymentPage /> : <Login />}
          />
          <Route
            path="/my-vendor-cart-order-list"
            element={clineIsLoggedIn ? <MyVendorOrderList /> : <Login />}
          />
          
          <Route
            path="/pay-small-small-application"
            element={<PaySmallSmallApplication />}
          />
          <Route path="/vendorshop/:customisedShopId" element={<VendorShop />} />
          
          {/* forms */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verify-account" element={<VerifyCode />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          {/* TODO Accouint */}
          {/* *********************** */}
          <Route path="/my-order" element={<MyOrderPage />} />
          <Route path="/pay-small" element={<PaySmall />} />
          <Route
            path="/pay-small-order-detail"
            element={<PaySmallOrderDetail />}
          />
          <Route path="/my-order-detail" element={<MyOrderDetailPage />} />
          <Route path="/account-addresses" element={<DeliveryAddressPage />} />
          <Route path="/vendor-cart-account-addresses" element={<VendorCartDeliveryAddressPage />} />
          
          <Route path="/create-address" element={<CreateAddress />} />
          <Route path="/update-address" element={<UpdateAddress />} />
          <Route path="/account-wallet" element={<AccountWalletPage />} />
          <Route path="/edit-profile" element={<EditProfilePage />} />
          <Route path="/account-reset-password" element={<ResetPasswordPage />} />
          <Route path="/account-inbox" element={<AccountInbox />} />
          <Route path="/create-feedback" element={<FeecbackProfilePage />} />
          <Route path="/message-center" element={<MessageCenter />} />
          <Route path="/faqs" element={<AccountFAQ />} />
    
          {/* Technician */}
          {/* Technician */}
          <Route path="/login-t" element={<TechnicianLogin />} />
          <Route path="/technician-dashboard" element={<TechnicianDashboard />} />
          <Route path="/technician-subscription-plans" element={<SubscriptionPlansPage />} />
          <Route path="/technician-subscription-payment/:planId" element={<SubscriptionPaymentPage />} />

          {/* Vendor  */}
          {/* Vendor  */}
          {/* Vendor  */}
          <Route path="/register-v" element={<RegisterVendor />} />
          <Route path="/login-v" element={<VendorLogin />} />
          <Route path="/reset-password-v" element={<VendorResetPassword />} />
          <Route
            path="/vendor-profile-update"
            element={<VendorProfileUpdate />}
          />
          <Route path="/vendor-dashboard" element={<VendorDashboard />} />
          <Route
            path="/vendor-create-product"
            element={<VendorCreateProduct />}
          />
          <Route path="/vendor-edit-product" element={<VendorEditProduct />} />
          <Route
            path="/vendor-change-password"
            element={<VendorChangePassword />}
          />
          <Route path="/vendor-product" element={<VendorProduct />} />
          <Route path="/vendor-withdraw" element={<VendorWithdraw />} />
          <Route path="/vendor-edit-bank-details" element={<VendorEditBankDetails />} />
          
          <Route path="/vendor-user-orders" element={<VendorOrders />} />
          
          {/* Accouint */}
          {/* Admin routs */}
          {/* Admin routs */}
          {/* Admin routs */}
          {/* Admin routs */}
          {/* Admin routs */}
          <Route path="/login-v6" element={<AdminLogin />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/admin_create_order" element={<AdminOrderPage />} />
          <Route path="/admin_order_pending" element={<AdminOrderPage />} />
          <Route path="/admin_order_completed" element={<AdminOrderPage />} />
          <Route path="/admin_order_approved" element={<AdminOrderPage />} />
          <Route path="/admin_order_completed" element={<AdminOrderPage />} />
          <Route path="/admin_order_canceled" element={<AdminOrderPage />} />
          <Route path="/admin-new-product" element={<AdminNewProductPage />} />
          <Route
            path="/admin-pay-small-small-1"
            element={<AdminPaySmallSmallPage />}
          />
          <Route
            path="/admin-pay-small-small-2"
            element={<AdminPaySmallSmallPage />}
          />
          <Route
            path="/admin-pay-small-small-3"
            element={<AdminPaySmallSmallPage />}
          />
          <Route
            path="/admin-pay-small-small-search"
            element={<AdminPaySmallSmallSearchPage />}
          />
          <Route path="/all_admin_orders" element={<SearchAdminOrders />} />
          <Route
            path="/admin-pay-small-small-detail"
            element={<AdminPaySmallSmallDetail />}
          />
          <Route
            path="/admin-edit-product"
            element={<AdminEditManageProduct />}
          />
          {/* END */}
          <Route
            path="/admin-order-summary"
            element={<AdminOrderCartSummary />}
          />
          {/* Order Completed */}
          <Route path="/admin-order-detail" element={<AdminGeneralOrder />} />
          {/* Manage products */}
          <Route
            path="/admin-find-product"
            element={<AdminManageFindProducts />}
          />
          <Route path="/admin-found-product" element={<AdminFoundProduct />} />
          {/* API CALLED */}
          <Route path="/admin-new-brand" element={<AdminNewBrandPage />} />
          <Route path="/admin-vendor-all" element={<AdminVendorsPage />} />
          <Route path="/admin-vendor-detail" element={<VendorDetailPage />} />
          <Route
            path="/admin-product-deactivation"
            element={<AdminProductDeactivationPage />}
          />
          <Route
            path="/admin-product-page"
            element={<AdminVendorProductPage />}
          />
          <Route
            path="/admin-vendor-page-request"
            element={<AdminVendorproductRequest />}
          />
          {/* TODO MESSAGE SENTER */}
          {/* TODO MESSAGE SENTER */}
          {/* TODO MESSAGE SENTER */}
          {/* TODO MESSAGE SENTER */}
          <Route path="/admin-support-center" element={<AdminMessages />} />
          <Route path="/admin-support-center-details" element={<AdminMessagesDetails />} />
          <Route
            path="/admin-vendor-report"
            element={<AdminVendorReportPage />}
          />
          <Route path="/admin-message-center" element={<AdminMessageCenter />} />
          <Route path="/admin-feedbacks" element={<AdminFeedbackPage />} />
          <Route path="/admin-order-status" element={<AdminOrderStatusPage />} />
          <Route
            path="/admin-order-status-reply"
            element={<AdminOrderStatusPageReply />}
          />
          {/* promotions */}
          <Route path="/admin-new-promotion" element={<AdminNewPromotion />} />
          {/* <Route
            path="/admin-new-promotion-create-list"
            element={<AdminNewPromotionCreateList />}
          /> */}
          <Route
            path="/admin-new-manage-promotions"
            element={<AdminManagePromotions />}
          />
          {/* End of promotions */}
          <Route path="/admin-technical-hub" element={<AdminTechnicalHub />} />
          <Route
            path="/admin-technical-details"
            element={<AdminTechnicianDetail />}
          />
          <Route path="/admin-settings" element={<AdminSettings />} />
          <Route
            path="/admin-settings-change-password"
            element={<AdminSettingsChangePassword />}
          />
          <Route
            path="/admin-settings-update-email"
            element={<AdminSettingsUpdateEmail />}
          />
          <Route path="/admin-new-admin" element={<AdminNewAdmin />} />
          <Route path="/admin-user-search" element={<AdminUserSearch />} />
          <Route path="/admin-message-search" element={<AdminMessageSearch />} />
          <Route path="/admin-manage-admin" element={<ManageAdmin />} />
          <Route path="/admin-faq" element={<AdminFAQ />} />
          <Route path="/admin-create-faq" element={<AdminCreateFaq />} />
          <Route path="/admin-report" element={<AdminReport />} />
          <Route path='/admin-manage-technician-subscription' element={<AdminManageTechnicianSubscription />} />
          <Route path="*" element={<h1>Page not found</h1>} />
        </Routes>
     
      </Fragment>
    
  )
}

export default App;
